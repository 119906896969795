import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { BehaviorSubject } from 'rxjs';

declare const window: any;

@Component({
    selector: 'chat-widget-facade',
    templateUrl: './chat-widget-facade.component.html',
    styleUrl: './chat-widget-facade.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWidgetFacadeComponent implements OnInit {
    chatWidget = environment.chatWidget as "intercom" | "tidio";

    loaded: boolean = false;

    loadRequest = new BehaviorSubject(false);

    async onClick() {
        if (!this.loaded) {
            console.log("Loading chat widget after click");
            if (this.chatWidget === "intercom") {
                this._analyticsService.loadIntercom();
            } else if (this.chatWidget === "tidio") {
                this._analyticsService.loadTidio();
            }
            this.setLoaded();
            this.showWidget();
        }
    }

    constructor(
        private _analyticsService: AnalyticsService,
        private _utilities: UtilitiesService,
        private _chatWidgetService: ChatWidgetService,
        private _authService: EmbeddedAuthService,
        private _anonymousPersistentState: AnonymousPersistentState,
        private _platformService: PlatformService,
        private _cd: ChangeDetectorRef
    ) {
        
    }

    ngOnInit(): void {
        if (this._platformService.isBrowser()) {
            this._chatWidgetService.facadeLoadRequest = this.loadRequest;
            this.loadRequest.subscribe(shouldLoad => {
                if (shouldLoad) {
                    this.onClick();
                }
            });
        }
    }

    showWidget() {
        const userData = {
            email: this._authService.user?.email || this._anonymousPersistentState.email,
            name: this._authService.user?.name
        };
        const i = setInterval(() => {
            console.log("Checking for chat widget load");
            if (window['Intercom']) {
                console.log("Chat widget loaded, showing");
                clearInterval(i);
                this._chatWidgetService.open();
                this.loaded = true; // double check that our bubble is hidden
                this._cd.detectChanges();
            } else if (window['tidioChatApi'] && window['tidioChatApi'].readyEventWasFired) {
                console.log("Chat widget loaded, showing");
                clearInterval(i);
                window['tidioChatApi'].on("open", () => {
                    this._chatWidgetService.resetPosition(true);
                    this._chatWidgetService.state = "open";
                });
                window['tidioChatApi'].on("close", () => {
                    this._chatWidgetService.state = "close";
                });
                this._chatWidgetService.identifyUser(userData);
                this._chatWidgetService.open();
                this.loaded = true; // double check that our bubble is hidden
                this._cd.detectChanges();
            }
        }, 50);
    }

    private async setLoaded() {
        await this._utilities.wait(2000);
        this.loaded = true;
        this._cd.detectChanges();
    }
}
