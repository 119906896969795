import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import emailSpellChecker from '@zootools/email-spell-checker';
import { firstValueFrom, Subscription } from 'rxjs';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { Book } from 'src/app/models/book.model';
import { Genre } from 'src/app/models/genre.model';
import { Trope } from 'src/app/models/trope.model';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { LibraryService } from 'src/app/services/library.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'worldmaker-component-free-book-bundle',
    templateUrl: 'free-book-bundle.component.html',
    styleUrls: ['free-book-bundle.component.scss'],
    animations: [
        trigger(
          'stickyFooterAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ "max-height": 0 }),
                animate('1s ease-out', style({ "max-height": "250px" }))
              ]
            ),
            transition(
              ':leave', 
              [
                style({ "max-height": "250px" }),
                animate('1s ease-in', style({ "max-height": 0 }))
              ]
            )
          ]
        ),
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        )
    ]
})

export class FreeBookBundleComponent implements OnInit, OnChanges, OnDestroy {
    @Input() data!: {
        books: Book[];
        title: string;
        description: string;
        ctaText: string;
        aboutHeader: string;
        aboutContents: string;
        linksDisabled: boolean;
        coverImage: {data: {url: string}};

        // events
        acValue: string;
        eventAuthor: string;
        eventBook: string;
        eventPublisher: string;
    };
    
    emailForm = new FormGroup({
        email: new FormControl(this._anonymousState.email || "", [Validators.required, Validators.email])
    });

    loading: boolean = false;
    authorsBadges: BadgeConfig[] = [];
    genresBadges: BadgeConfig[] = [];
    tropesBadges: BadgeConfig[] = [];
    private _subs: Subscription[] = [];

    isLoggedIn$ = this._authService.isLoggedIn$;
    authChecked$ = this._authService.browserAuthCheckPassed$;
    isLoggedIn = false;
    hasEnteredEmail: boolean = false;

    constructor(
        private _utilitiesService: UtilitiesService,
        private _anonymousState: AnonymousPersistentState,
        private _libraryService: LibraryService,
        private _authService: EmbeddedAuthService,
        private _analyticsService: AnalyticsService,
        private _domSanitizer: DomSanitizer,
        private _renderer: Renderer2,
        private _platformService: PlatformService,
        private _router: Router,
        private _chatWidgetService: ChatWidgetService,
        @Inject(DOCUMENT) private _document: Document
    ) { }

    get isMobile() {
        return this._platformService.isMobile;
    }

    ngOnInit() {
        this.refresh();
        this._analyticsService.trackPageView(undefined, "Landing Page", {
            book: this.data.eventBook,
            author: this.data.eventAuthor,
            mode: "free",
            publisher: this.data.eventPublisher
        });

        if (this._anonymousState.email) {
            this.hasEnteredEmail = true;
        }

        this._authService.isLoggedIn$.subscribe((status) => {
            this.isLoggedIn = status;
        });
        for (const el of Array.from(this._document.querySelectorAll<HTMLDivElement>(".navlinks"))) {
            el.style.setProperty("display", "none", "important");
        }
    }

    scrolled: boolean = false;

    @HostListener("window:scroll")
    async onWindowScroll() {
        // const headerHeight = parseInt(getComputedStyle(document.querySelector(".book-header-container")!).height.replace("px", ""));
        const el = await firstValueFrom(this.isMobile) ? document.querySelector(".main-input-mobile") : document.querySelector(".main-input-desktop");
        const rect = el!.getBoundingClientRect();
        if (rect.y < -120) {
            this.scrolled = true;
            const sticky = document.querySelector(".sticky-container");
            if (sticky) {
                //add some default margin before the actual one we get after the animation
                if (await firstValueFrom(this._platformService.isMobile)) {
                    document.body.style.marginBottom = "228px";
                } else {
                    document.body.style.marginBottom = "93px";
                }
                setTimeout(() => {
                    const stickyHeight = getComputedStyle(sticky).height;
                    document.body.style.marginBottom = stickyHeight;
                    const stickyHeightNumber = parseInt(stickyHeight.replace("px", ""));
                    this._chatWidgetService.slideUp(stickyHeightNumber + 10);
                }, 1200);
            }
        } 
        else {
            this.scrolled = false;
            setTimeout(() => {
                this._chatWidgetService.resetPosition();
            }, 500);
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.refresh();
        }
    }

    get hasHtmlDescription() {
        return this.data.aboutContents?.includes("<p>");
    }

    ngOnDestroy(): void {
        this._subs.forEach(sub => sub.unsubscribe());
        for (const el of Array.from(this._document.querySelectorAll<HTMLDivElement>(".navlinks"))) {
            el.style.removeProperty("display");
        }
    }

    private refresh() {
        if (this.data) {
            this.loadAuthors();
            this.loadGenres();
            this.loadTropes();
        }
    }

    loadTropes() {
        const tropesMap = new Map<number, Trope>();
        this.data.books.forEach(book => {
            book.tropes.forEach(trope => {
                tropesMap.set(trope.id!, trope);
            });
        });
        const tropes = Array.from(tropesMap.values());
        this.tropesBadges = tropes.map(trope => {
            return {
                type: "default",
                text: trope.name,
                url: this._utilitiesService.getTropeUrl(trope.slug)
            };
        });
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";
        }
    }

    onStickyInputClicked(event: Event) {
        setTimeout(() => {
            this._utilitiesService.scrollStickyContainerVisible(event.target as HTMLElement);
        }, 500);
    }

    loadGenres() {
        const genresMap = new Map<number, Genre>();
        this.data.books.forEach(book => {
            book.genres.forEach(genre => {
                genresMap.set(genre.id!, genre);
            });
        });
        const genres = Array.from(genresMap.values());
        this.genresBadges = genres.map(genre => {
            return {
                type: "default",
                text: genre.name,
                url: this._utilitiesService.getGenreUrl(genre.slug)
            };
        });
    }

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    addBundleToLibrary() {
        if (!this.emailForm.valid) return;
        this._subs.push(this._authService.isLoggedIn$.subscribe(async(isLoggedIn) => {
            if (!isLoggedIn && this.emailForm.valid) {
                this._anonymousState.saveEmail(this.emailForm.value.email!);
            }
            this.loading = true;
            for (const book of this.data.books) {
                this._anonymousState.saveFreeBook(book.slug);
                if (isLoggedIn) {
                    await firstValueFrom(this._libraryService.addBookToFreeShelf(book.id!));
                }
                
            }
            this._analyticsService.trackClientOnly({event: "get_free_book_c", params: {
                acValue: this.data.acValue,
                book: {
                    slug: this.data.eventBook,
                    mainAuthorSlug: this.data.eventAuthor,
                    publisher: this.data.eventPublisher
                },
                type: "free_book",
                placement: "book_page"
            }});
            this._analyticsService.identifyAndTrack({
                email: this.emailForm.value.email!,
                traits:  {signupReason: {type: "free_book_flow", book: this.data.eventBook}}
            },{
                event: "get_free_book",
                params: {
                    acValue: this.data.acValue,
                    book: {
                        slug: this.data.eventBook,
                        mainAuthorSlug: this.data.eventAuthor,
                        publisher: this.data.eventPublisher
                    },
                    type: "free_book",
                    placement: "book_page"
                }
            });
            this._router.navigate(['/library'], {state: {freeFlowNotification: true}});
            this.loading = false;
        }));
    }


    emailSuggestionError = "";

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    private loadAuthors() {
        const authors = this.data.books.map(book => book.authors[0]);
        const authorIds = new Set(authors.map(author => author.id));
        this.authorsBadges = Array.from(authorIds).map(authorId => {
            const author = authors.find(author => author.id === authorId);
            return {
                type: "default",
                text: author!.name,
                image: author!.images.profile,
                url: this._utilitiesService.getAuthorUrl(author!)
            };
        });
    }

}