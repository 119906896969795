import { Component, Input, AfterViewInit, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import emailSpellChecker from '@zootools/email-spell-checker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BookExtras } from 'src/app/models/book-extras.model';
import { Book } from 'src/app/models/book.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformService } from 'src/app/services/platform.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'character-overviews',
    templateUrl: './character-overviews.component.html',
    styleUrls: ['./character-overviews.component.scss'],
    animations: [
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        ),
        trigger(
            'unlockNotification', 
            [
                transition(
                    ':enter',
                    [
                        style({ "bottom": "-40px" }),
                        animate('0.2s ease-in-out', style({ "bottom": "40px" }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ "bottom": "40px" }),
                        animate('0.2s ease-in-out', style({ "bottom": "-40px" }))
                    ]
                )
            ]
        )
    ]
})
export class CharacterOverviewsComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly _modalService: NgbModal,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _authService: EmbeddedAuthService
    ) {}

    @Input()
    characters!: BookExtras["data"]["characters"];

    @Input()
    book!: Book;

    @Input()
    autoTrigger = false;

    @ViewChild('charactersModal') charactersModal!: TemplateRef<any>;

    showBadgeOnHeader = false;
    showUnlockNotif = false;

    async ngOnInit() {
        let email = this._anonymousPersistentState.email;
        email = (await firstValueFrom(this._route.queryParamMap)).get("email") || email;
        if (this._authService.user) {
            email = this._authService.user.email;
        }
        if (email) {
            this.emailFormControl.setValue(email);
        }
    }

    ngAfterViewInit() {
        if (this.autoTrigger) {
            this.openCharacterModal();
        }
    }

    @HostListener('click', ['$event.target'])
    async openCharacterModal() {
        let badgeObserver: IntersectionObserver | null = null;
        await firstValueFrom(
            this._modalService.open(this.charactersModal, {
                centered: true,
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                scrollable: true,
                size: "xl",
                beforeDismiss() {
                    if (badgeObserver) {
                        badgeObserver.disconnect();
                    }
                    return true;
                },
            }).shown
        );
        badgeObserver = new IntersectionObserver(async (entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    this.showBadgeOnHeader = false;
                } else {
                    this.showBadgeOnHeader = true;
                }
            }
        }, {
            root: document.querySelector(".character-modal-body"),
            rootMargin: "120px",
            threshold: 1,
        });
        badgeObserver.observe(document.querySelector(".top-section")!);
    }

    get locked() {
        if (this._anonymousPersistentState.bonusContent[this.book.slug]) {
            return false;
        } else {
            return true;
        }
    }

    get pendingFinish() {
        const state = this._anonymousPersistentState.bonusContent[this.book.slug];
        if (!state || !state.finishedCharacterOverviews) {
            return true;
        } else {
            return false;
        }
    }

    emailSuggestionError = "";
    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }

    unlock() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;

        this._anonymousPersistentState.saveEmail(email);
        this._anonymousPersistentState.saveBonusContent(this.book.slug);
        setTimeout(() => {
            this.showUnlockNotif = true;
            setTimeout(() => {
                this.showUnlockNotif = false;
            }, 3000);
        }, 500);
        this._analyticsService.identifyAndTrack({email, traits: {signupReason: {type: "bonus_content_flow", book: this.book.slug}}}, {
            event: "unlock_bonus_content",
            params: {
                book: this.book,
                author: this.book.authors.find(a => a.slug === this.book.mainAuthorSlug)!,
                kindle: false,
                contentType: "character_overviews"
            }
        });
    }

    finish() {
        this._anonymousPersistentState.saveBonusContent(this.book.slug, {finishedCharacterOverviews: true});
        this._analyticsService.track({event: "finish_bonus_content", params: {
            book: this.book,
            author: this.book.authors.find(a => a.slug === this.book.mainAuthorSlug)!,
            kindle: false,
            contentType: "character_overviews"
        }});
        this._router.navigate([`${this._utilitiesService.getBookUrl(this.book)}/rate`], {
            queryParams: {
                action: "character_overviews"
            }
        });
        this._modalService.dismissAll();
    }
}