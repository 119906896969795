<div class="d-flex flex-column justify-content-between c-pt-10 c-pb-10 c-px-20">

    <div class="header no-select c-mb-10 text-center">
        <h1 class="title c-mb-0">
            You’re in! 🎉
        </h1>
    </div>
    <div class="description no-select c-mb-20 text-center">
        <h4 class="c-mb-0">
            Welcome to crewfiction!
        </h4>
    </div>
    <div class="name-form">
        <form [formGroup]="form" (ngSubmit)="continue()" class="d-flex flex-column">
            <div class="form-group my-1">
                <input name="name" type="text" formControlName="name" class="form-control dark-input rounded-input w-100" placeholder="What is your name?">
                <div *ngIf="nameFormControl.dirty && nameFormControl.invalid" class="text-danger">
                    <small>
                        {{nameErrors && nameErrors.length ? nameErrors[0] : ''}}
                    </small>
                </div>
            </div>
            <div class="form-group d-grid gap-2 mt-2 mb-3">
                <button class="btn btn-dark btn-round w-100" type="submit" [disabled]="form.invalid || loading">
                    <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                    Continue
                </button>
            </div>
        </form>
    </div>
</div>