<div class="row" *ngIf="dataSource && dataSource.data && dataSource.data.length" [ngClass]="{'horizontal-collection': horizontallyScollable, 'vertical-collection': !horizontallyScollable}">
    <div class="col-{{12 / columnLayout.mobile}} col-sm-{{12 / columnLayout.sm}} col-md-{{12 / columnLayout.md}} col-lg-{{12 / columnLayout.lg}}" *ngFor="let item of dataSource.data">
        <content-card [template]="template"
                      [title]="getTitle(item)"
                      [subtitle]="getSubtitle(item)"
                      [image]="getImage(item)"
                      [badge]="getBadge(item)"
                      [url]="getUrl(item)"
                      [onClick]="getOnClick(item)"
                      [externalLink]="externalLink"
                      [hideSubtitle]="hideSubtitle"
                      [lazy]="getLazy(item)"
                      [bottomBadge]="getBottomBadge(item)">
        </content-card>
    </div>
</div>