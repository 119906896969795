<div *ngIf="author && author.crew" class="text-center">
    <div class="row">
        <div class="col-12">
            <h4 class="mb-0">
                <img
                    [src]="author.images.profile"
                    alt="Author picture"
                    class="circular-pfp me-1"
                />
                {{ author.name }}
            </h4>
        </div>
    </div>
    <div class="row pt-4" *ngIf="!compact && settings.trigger">
        <div class="col-12">
            <div class="alert alert-success crew-form-alert text-start mb-0" role="alert">
                <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                <span class="fw-bold" *ngIf="settings.trigger === 'author_follow'">You are now following "{{author.name}}"</span>
                <span class="fw-bold" *ngIf="settings.trigger === 'world_follow'">You are now following "{{settings.world}}"</span>
                <span class="fw-bold" *ngIf="settings.trigger === 'prelaunch'">You are in!</span>
                <br><br>
                <p class="mb-0" *ngIf="settings.trigger !== 'prelaunch'">We will keep you updated on new content and discussions. Following books and authors will help us provide better book recommendations for your next read.</p>
                <p class="mb-0" *ngIf="settings.trigger === 'prelaunch'">{{settings.msg}}</p>
            </div>                      
        </div>
    </div>
    <div class="row pt-2" *ngIf="!compact && settings.trigger">
        <div class="col-12">
            <hr class="horizontal-break ms-auto me-auto">
        </div>
    </div>
    <div class="row pt-4 d-flex justify-content-center" *ngIf="crewImage">
        <img
            [src]="crewImage | cloudinaryScaleHeight : 250 | cloudinaryQuality : 85 | cloudinaryUrl"
            style="width: 250px;"
            alt="">
    </div>
    <div class="row pt-4">
        <div class="col-12">
            <h5 *ngIf="settings.trigger !== 'media_unlock'" class="mb-0">Subscribe to {{authorFirstNameWithS}} Book Crew for <span class="fw-bold">more exclusive benefits</span></h5>
            <h5 *ngIf="settings.trigger === 'media_unlock'"class="mb-0">Subscribe to {{authorFirstNameWithS}} Book Crew to unlock <span class="fw-bold">{{settings.media}}</span></h5>
        </div>
    </div>
    <div class="row text-start pt-4" *ngIf="!compact">
        <div class="col-12 d-flex justify-content-between">
            <feather-icon icon="book-open" class="me-3 mt-2"></feather-icon>
            <div class="flex-grow-1">
                <h6 class="mb-0">Get invited for Advanced Reader Copy</h6>
                <small class="text-muted">Read {{authorFirstNameWithS}} books for free before their release.</small>
            </div>
        </div>
    </div>
    <div class="row text-start pt-3" *ngIf="!compact">
        <div class="col-12 d-flex justify-content-between">
            <feather-icon icon="message-circle" class="me-3 mt-2"></feather-icon>
            <div class="flex-grow-1">
                <h6 class="mb-0">Open discussions with {{authorFirstName}}</h6>
                <small class="text-muted">Join group or personal discussions with the author.</small>
            </div>
        </div>
    </div>
    <div class="row text-start pt-3" *ngIf="!compact">
        <div class="col-12 d-flex justify-content-between">
            <feather-icon icon="film" class="me-3 mt-2"></feather-icon>
            <div class="flex-grow-1">
                <h6 class="mb-0">Dive into exclusive content from the community</h6>
                <small class="text-muted">From Fanart, videos, to quizzes and fanfics.</small>
            </div>
        </div>
    </div>
    <div class="row text-start pt-3" *ngIf="!compact">
        <div class="col-12 d-flex justify-content-between">
            <feather-icon icon="globe" class="me-3 mt-2"></feather-icon>
            <div class="flex-grow-1">
                <h6 class="mb-0">Discover similar up-and-coming, paid or free books</h6>
                <small class="text-muted">Get recommendation from {{authorFirstName}} on what to read next.</small>
            </div>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-12 ps-0 pe-0 ps-md-4 pe-md-4">
            <button type="button"
                (click)="joinCrew()"
                class="btn btn-dark btn-block text-nowrap rounded-input w-fit">
                Join Book Crew
            </button>
        </div>
    </div>
</div>
