import { Component, Input, Inject, OnInit, OnDestroy, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { interval, firstValueFrom, Subscription, filter, combineLatest } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from "dayjs";
import emailSpellChecker from '@zootools/email-spell-checker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { BooksService } from 'src/app/services/books.service';
import { LibraryStateService } from 'src/app/state/library.state.service';
import { Book } from 'src/app/models/book.model';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: "ecommerce-header-test",
    templateUrl: "./ecommerce-header-test.component.html",
    styleUrl: "./ecommerce-header-test.component.scss",
    standalone: true,
    imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
    animations: [
        trigger('carouselImage', [
            transition(
                ':enter',
                [
                  style({ "opacity": 0 }),
                  animate('1s ease-in', style({ "opacity": 1 }))
                ]
            ),
        ]),
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        ),
        trigger(
            'stickyFooterAnimation', 
            [
              transition(
                ':enter', 
                [
                  style({ "max-height": 0 }),
                  animate('1s ease-out', style({ "max-height": "120px" }))
                ]
              ),
              transition(
                ':leave', 
                [
                  style({ "max-height": "120px" }),
                  animate('1s ease-in', style({ "max-height": 0 }))
                ]
              )
            ]
          ),
    ]
})
export class EcommerceHeaderTest implements OnInit, OnDestroy {
    @Input() coverColor = "#B7994D";
    @Input() covers = [
        "https://res.cloudinary.com/dap6pju8g/image/upload/v1728289962/book-media/l3zixxxhuqphbfmzatra.png",
        "https://res.cloudinary.com/dap6pju8g/video/upload/vc_auto/v1728386089/book-media/ru2cjkcz2akbxor7xirl.mp4",
        "https://res.cloudinary.com/dap6pju8g/image/upload/v1728289994/book-media/jqaxwcmdhxkj0yaqqjlh.png",
        "https://res.cloudinary.com/dap6pju8g/image/upload/v1728290007/book-media/rlga8yfyjaveo5jnxnv5.png"
    ];
    @Input() title = "4+1 Free Romances to Escape into Highland Passion";
    @Input() subtitle = "Enjoy a captivating historical romance every weekend for a month, plus get an instant bonus book.";
    @Input() rating = 5;
    @Input() spicyMeter = 0;
    @Input() ratingText = "Loved by 2,650 readers";
    @Input() fomoText = "LIMITED-TIME OFFER ENDS IN ";
    @Input() coverBadgeText = "FREE";
    @Input() countDownPosition: "header" | "footer" | "both" = "both";
    @Input() price = 11.96;
    @Input() stickyHeader = "4 weekends, 4+1 free books";
    @Input() bookSlug = "married-to-a-heartless-laird";

    currentCoverIndex = 0;
    animateCarousel = false;
    countdown = this._getCountDown();
    subs: Subscription[] = [];
    book!: Book;

    screenSize = this._platformService.screenSize;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _platformService: PlatformService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _modalService: NgbModal,
        private readonly _analyticsService: AnalyticsService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _booksService: BooksService,
        private readonly _libraryStateService: LibraryStateService,
        private readonly _followService: FollowService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    @ViewChild("subscribeModal") subscribeModal!: TemplateRef<any>;

    async ngOnInit() {
        // if (this._platformService.isServer()) {
        //     this._utilitiesService.preloadImage(this.covers[0]);
        // }
        //disable navbar links
        try {
            for (const el of Array.from(this.document.querySelectorAll<HTMLDivElement>(".navlinks"))) {
                el.style.setProperty("display", "none", "important");
            }
        } catch (_e) {}
        let email = this._anonymousPersistentState.email;
        email = (await firstValueFrom(this._route.queryParamMap)).get("email") || email;
        if (this._authService.user) {
            email = this._authService.user.email;
        }
        if (email) {
            this.emailFormControl.setValue(email);
        }
        this.book = await firstValueFrom(this._booksService.findOneBySlug(this.bookSlug));
        this._analyticsService.trackPageView(undefined, this._router.url.match("/pages/") ? "Landing Page" : "Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            mode: "free",
            publisher: this.book.publisher || undefined
        });
        //if they have it go to library (loggedIn and anonymous cases)
        if (this._anonymousPersistentState.freeBooks.find(b => b === this.book.slug)) {
            return this._router.navigate(["/library/shelves/free"]);
        }
        const shelfSub = combineLatest([this._authService.isLoggedIn$, this._libraryStateService.freeShelf$]).pipe(
            filter(([isLoggedIn, shelf]) => isLoggedIn && shelf !== null)
        ).subscribe(async ([_isLoggedIn, shelf]) => {
            if (shelf!.books.find(b => b.slug === this.book.slug)) {
                this._router.navigate(["/library/shelves/free"]);    
            }
        });
        this.subs.push(shelfSub);
        //countdown setup
        if (this._platformService.isBrowser()) {
            const sub = interval(1000).subscribe(() => {
                this.countdown = this._getCountDown();
            });
            this.subs.push(sub);
        }

        //add bottom margin as much as the sticky footer
        if (this._platformService.isBrowser()) {
            if (await firstValueFrom(this._platformService.isMobile)) {
                const sticky = document.querySelector(".sticky-container.mobile");
                if (sticky) {
                    const stickyHeight = getComputedStyle(sticky).height;
                    document.body.style.marginBottom = stickyHeight;
                }
            }
        }

        return;
    }

    ngOnDestroy() {
        //show navlinks again
        try {
            for (const el of Array.from(this.document.querySelectorAll<HTMLDivElement>(".navlinks"))) {
                el.style.removeProperty("display");
            }
        } catch (_e) {}
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
        this.document.body.style.marginBottom = "0px";
    }

    get hasNextCover() {
        return this.covers.length - 1 > this.currentCoverIndex;
    }
    get hasPreviousCover() {
        return this.currentCoverIndex > 0;
    }
    get currentCover() {
        return this.covers[this.currentCoverIndex];
    }

    nextCover() {
        if (!this.hasNextCover) return;
        this.animateCarousel = true;
        this.currentCoverIndex += 1;
    }
    previousCover() {
        if (!this.hasPreviousCover) return;
        this.animateCarousel = true;
        this.currentCoverIndex -= 1;
    }

    async openSubscribeModal() {
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(this.subscribeModal, {
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                beforeDismiss() {
                    that._chatWidgetService.show();
                    return true;
                },
            }).shown
        );
    }

    loading = false;
    scrolled = false;
    emailSuggestionError = "";
    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }

    async subscribe() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;

        this._anonymousPersistentState.saveEmail(email);

        const acValue = "018evg";
        this._analyticsService.identifyAndTrack({email, traits: {signupReason: {type: "free_book_flow", book: this.book.slug}}}, {
            event: "get_free_book",
            params: {book: this.book, acValue, type: "free_book", placement: "book_page"}
        });

        this._analyticsService.trackClientOnly({
            event: "get_free_book_c",
            params: {book: this.book, acValue, type: "free_book", placement: "book_page"}
        });
    
        this._anonymousPersistentState.saveFreeBook(this.book.slug);
        if (this._authService.user) {
            await this._followService.followAuthor({source: "book_page", book: this.book.slug});
            this._libraryStateService.addBookToFreeShelf(this.book.id!);
        }
        this._modalService.dismissAll();
        await this._router.navigate(["/library/shelves/free"], {state: {freeFlowNotification: true}});
    }

    private _touchStart: number | null = null;
    private _touchCurrent: number | null = null;
    onTouchStart(event: TouchEvent) {
        // console.log("start", event);
        this._touchStart = event.touches[0].clientX;
    }
    onTouchMove(event: TouchEvent) {
        // console.log("move", event);
        this._touchCurrent = event.touches[0].clientX;
    }
    onTouchEnd(_event: TouchEvent) {
        // console.log("end", event);
        if (!this._touchStart || !this._touchCurrent) return;
        if (this._touchStart - this._touchCurrent < 0 && this.hasPreviousCover) {
            this.previousCover();
        } else if (this._touchStart - this._touchCurrent > 0 && this.hasNextCover) {
            this.nextCover();
        }
        this._touchStart = null;
        this._touchCurrent = null;
    }

    @HostListener("window:scroll")
    async onWindowScroll() {
        if ((await firstValueFrom(this._platformService.isMobile))) return;
        const headerHeight = parseInt(getComputedStyle(document.querySelector(".book-header-container")!).height.replace("px", ""));
        if (window.scrollY >= headerHeight) {
            this.scrolled = true;
            const sticky = document.querySelector(".sticky-container.desktop");
            if (sticky) {
                //add some default margin before the actual one we get after the animation
                document.body.style.marginBottom = "120px";
                setTimeout(() => {
                    const stickyHeight = getComputedStyle(sticky).height;
                    document.body.style.marginBottom = stickyHeight;
                }, 1200);
            }
        } else {
            this.scrolled = false;
        }
    }

    private _getCountDown() {
        const seconds = dayjs().endOf("day").diff(dayjs(), "seconds");

        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        const formattedMins = mins.toString().padStart(2, '0');
        const formattedSecs = secs.toString().padStart(2, '0');

        return {
            hours: hrs,
            minutes: formattedMins,
            seconds: formattedSecs
        };
    }
}
