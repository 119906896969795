import { BreadCrumb } from 'src/app/models/breadcrumbs.model';
import { Component, OnInit, Input } from '@angular/core';
import { AuthorsService } from 'src/app/services/authors.service';
import { BooksService } from 'src/app/services/books.service';
import { TropesService } from 'src/app/services/tropes.service';
import { GenresService } from 'src/app/services/genres.service';
import { PlatformService } from 'src/app/services/platform.service';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { JsonLdService } from 'src/app/services/json-ld.service';
import { BreadcrumbListSchema } from 'src/app/services/schema-markup.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
    //paths we don't want to include in the breadcrumb
    @Input() skip: string[] = [];

    @Input() linkColor = "#808080";
    @Input() currentColor = "#000000";
    @Input() hoverColor = "#000000";
    @Input() fontWeight = "400";
    @Input() fontSize= "14px";

    public breadcrumbs: BreadCrumb[];

    constructor(
        private platformService: PlatformService,
        private jsonLdService: JsonLdService,
        private router: Router
    ) {
        this.breadcrumbs = [];
    }

    private buildBreadcrumbs(url: string) {
        this.breadcrumbs = [];
        if (this.checkIfHidden(url)) {
            return;
        }
        let parts = url
            .split('?')[0]
            .split('/')
            .map((url) => url)
            .filter((u) => u)
            .filter(u => this.skip.indexOf(u) === -1)
        let breadcrumbs = parts.map((slug, index) => {
            return {
                label: slug
                    .split('-')
                    .map(
                        (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(' ')
                    //dont show anchor on the label
                    .split("#")[0],
                url: parts.slice(0, index + 1).map(p => (`/${p}`).replaceAll('//', '/'))
            };
        });
        this.breadcrumbs = breadcrumbs;
        // console.log('[bread]', this.breadcrumbs);
        this.jsonLdService.addBreadcrumbList(
            new BreadcrumbListSchema([
                {
                    name: 'Home',
                    item: environment.baseUrl,
                },
                ...this.breadcrumbs
                    .filter((b) => b.label != 'Home')
                    .map((b) => {
                        return {
                            item:
                                (environment.baseUrl +
                                '/' +
                                b.url.join('/')).replaceAll('//', '/'),
                            name: b.label,
                        };
                    }),
            ])
        );
    }


    async ngOnInit() {
        const currentUrl = this.router.url;
        if (currentUrl) {
            return this.buildBreadcrumbs(currentUrl);
        }

        if (this.checkIfHidden(currentUrl)) {
            return;
        }
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.buildBreadcrumbs(event.url);
            }
        });
    }

    private checkIfHidden(url: string): boolean {
        return url.startsWith('/404');
    }
}
