import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { BookSeries } from 'src/app/models/book-series.model';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { Author } from 'src/app/models/author.model';
import { ModalService } from 'src/app/services/modal.service';
import { combineLatestWith, filter, firstValueFrom } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformService } from 'src/app/services/platform.service';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'worldmaker-component-world-info-manual',
    templateUrl: './world-info-manual.component.html',
    styleUrls: ['./world-info-manual.component.scss'],
})
export class WorldInfoManual implements OnInit {
    @Input() data!: {
        header: string;
        description: string;
        referenceBook: Book;
        referenceSeries?: BookSeries;
        bannerImage: {
            channel: 'manual_image';
            exclusive?: boolean;
            data: { url: string; title: string; description: string, category: string };
        };
        breadcrumbColor?: string;
    };
    @Input() worldData!: {
        id: number;
        path: string;
    };
    referencedBook!: Book;
    referencedSeries?: BookSeries;

    followsWorld = false;
    joinedCrew = false;
    afterJoinCb = () => this._modalService.dismissAll();

    @ViewChild('joinModal') joinModal!: TemplateRef<any>;

    followRequestInProgress$ = this._activatedRoute.queryParams.pipe(
        filter(params => params["action"]),
        filter(params => params["action"] === "follow"),
        combineLatestWith(this._authService.isLoggedIn$)
    );

    constructor(
        private readonly _followService: FollowService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _activatedRoute: ActivatedRoute
    ) {}

    async ngOnInit() {
        this.referencedBook = this.data.referenceBook;
        this.referencedSeries = this.data.referenceSeries;

        this._followService.followsWorld$.subscribe(val => {
            this.followsWorld = val;
        });
        if (this.referencedBookMainAuthorData && this.referencedBookMainAuthorData.crew) {
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
        }
        await this._followService.init(
            null,
            this.referencedBookMainAuthorData || null,
            null
        );

        this.followRequestInProgress$.subscribe(() => {
            this.followWorld();
        })
    }

    get referencedBookMainAuthorData() {
        const main = this.referencedBook.authors.find(
            (author) => author.slug === this.referencedBook.mainAuthorSlug
        );
        if (main) {
            return main;
        } else {
            return this.referencedBook.authors[0];
        }
    }

    get crewFormSettings() {
        return {
            trigger: "world_follow",
            world: this.data.header
        } as const;
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "world_page",
            world: this.worldData.path.replace("/worlds/", "")
        };
    }

    async followWorld() {
        if (!this._authService.user) {
            await this._authService.login({
                sendStageTitle: "Sign in or sign up",
                sendStageDescription: "Enter your email to create your crewfiction account or sign-in to an existing one",
                skipOnboarding: false,
                signupReason: {type: "follow_world", world: this.worldData.path.replace("/worlds/", "")},
                action: "follow"
            });
        }
        this._followService.followWorld();

        if (!this.joinedCrew && this.referencedBookMainAuthorData && this.referencedBookMainAuthorData.crew) {
            //TODO make modalService.close() to wait for modal hidden event
            await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        return true;
                    },
                }).shown
            );
        }
    }

    async unfollowWorld() {
        await this._followService.unfollowWorld();
    }

    get tropes(): BadgeConfig[] {
        return this.data.referenceBook.tropes.map(trope => {
            return {
                text: trope.name,
                type: "default",
                url: this._utilitiesService.getTropeUrl(trope.slug)
            };
        })
    }

    get genres(): BadgeConfig[] {
        return this.data.referenceBook.genres.map(genre => {
            return {
                text: genre.name,
                type: "default",
                url: this._utilitiesService.getGenreUrl(genre.slug)
            };
        })
    }
}
