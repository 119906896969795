import { Component, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { PWAService } from 'src/app/pwa/pwa.service';
import { PlatformService } from 'src/app/services/platform.service';
import { firstValueFrom } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'push-notification-trigger',
    templateUrl: './push-notification-trigger.component.html',
    styleUrls: ['./push-notification-trigger.component.scss'],
    animations: [
        trigger('stickyFooterDesktop', [
            transition(
                ':enter',
                [
                  style({ "max-height": 0 }),
                  animate('0.5s ease-in', style({ "max-height": "91px" }))
                ]
              ),
              transition(
                ':leave',
                [
                  style({ "max-height": "91px" }),
                  animate('0.5s ease-out', style({ "max-height": 0 }))
                ]
            )
        ]),
        trigger('stickyFooterMobile', [
            transition(
                ':enter',
                [
                  style({ "max-height": 0 }),
                  animate('0.5s ease-in', style({ "max-height": "256px" }))
                ]
              ),
              transition(
                ':leave',
                [
                  style({ "max-height": "256px" }),
                  animate('0.5s ease-out', style({ "max-height": 0 }))
                ]
            )
        ]),
        trigger('bell', [
            transition(
                ':enter',
                [
                  style({ "opacity": 0 }),
                  animate('0.5s ease-in', style({ "opacity": 1 }))
                ]
              ),
              transition(
                ':leave',
                [
                  style({ "opacity": 1 }),
                  animate('0.5s ease-out', style({ "opacity": 0 }))
                ]
            )
        ])
    ]
})
export class PushNotificationTriggerComponent implements AfterViewInit, OnDestroy {
    constructor(
        private readonly _pwaService: PWAService,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    @Input()
    autoShow = false;

    canRequestNotifications$ = this._pwaService.canRequestNotifications$;

    showFooter = false;

    async ngAfterViewInit() {
        if (this._platformService.isBrowser()) {
            if (await firstValueFrom(this._platformService.isMobile)) {
                document.body.style.marginBottom = "320px";
            } else {
                document.body.style.marginBottom = "100px";
            }
            if (this.autoShow) {
                if (!localStorage.getItem("disable-notification-auto-trigger")) {
                    this.canRequestNotifications$.subscribe(weCan => {
                        if (weCan) {
                            this.show();
                        }
                    });
                }
            }
        }
    }

    async ngOnDestroy() {
        if (this._platformService.isBrowser()) {
            document.body.style.marginBottom = "0";
            this._chatWidgetService.show();
        }
    }

    skip() {
        this._analyticsService.track({
            event: "click_skip_notification",
            params: {
                subscription: {
                    device: this._utilitiesService.getDeviceName()
                }
            }
        });
        localStorage.setItem("disable-notification-auto-trigger", "1");
        this.showFooter = false;
        this._chatWidgetService.show();
    }

    show() {
        this._analyticsService.track({
            event: "view_notification_trigger",
            params: {
                subscription: {
                    device: this._utilitiesService.getDeviceName()
                }
            }
        });
        this.showFooter = true;
        this._chatWidgetService.hide();
    }

    async requestNotificationPermission() {
        this._analyticsService.track({
            event: "click_allow_notification",
            params: {
                subscription: {
                    device: this._utilitiesService.getDeviceName()
                }
            }
        });
        await this._pwaService.requestNotificationPermission();
        this._chatWidgetService.show();
    }
}
