<!-- <span *ngIf="!bold" class="hscrolled-text" [title]="text">
    {{text}}
</span>
<strong *ngIf="bold" class="hscrolled-text" [title]="text">
    {{text}}
</strong> -->
<!-- <span class="hscrolled-text {{active ? 'active' : ''}}" [title]="text">
    {{text}}
</span> -->
<span class="dot-text" [title]="text">
    {{text}}
</span>