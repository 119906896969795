<ng-container *ngIf="articles">
    <div class="row c-pb-20">
        <ng-container *ngIf="data.enableAnchor">
            <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
        </ng-container>
        <ng-container *ngIf="!data.enableAnchor">
            <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
        </ng-container>
    </div>
    <div class="row vertical-collection">
        <div *ngFor="let article of articles" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-4 slight-hover-no-bg">
            <a class="no-decoration" [title]="article.title" [href]="article.url" target="_blank" [rel]="article.nofollow ? 'nofollow' : ''">
                <div class="card">
                    <div class="card-body d-flex article-container">
                        <div class="favicon-container">
                            <img
                                *ngIf="article.favicon"
                                [src]="article.favicon | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleWidth:24 | cloudinaryUrl"
                                [alt]="article.domain + ' logo'"
                                loading="lazy">
                            <feather-icon *ngIf="!article.favicon" icon="globe" width="24" height="24"></feather-icon>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="article-title flex-grow-1">{{article.title | textLimit:50:'...'}}</span>
                            <span class="article-domain c-pt-10">{{article.domain | textLimit:25:'...'}}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <hr class="mb-0 c-mt-40 d-none d-md-block full-width-hr" *ngIf="data.showHR">
</ng-container>
