<div class="row vertical-collection">
    <div class="col-6 col-md-4 col-lg-2" *ngFor="let item of items; let i = index">
        <a [routerLink]="getRouterLink(item)" class="d-block no-decoration">
            <div class="card no-border cursor-pointer" [style.backgroundColor]="getNextAvailableBackground(i)">
                <div class="card-body d-flex flex-column justify-content-between">
                    <h5 class="card-title">{{item.name}}</h5>
                    <div class="d-flex align-self-end">
                        <img *ngIf="getIcon(item)" [src]="getIcon(item)" alt="icon">
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>