<div class="worldmaker-section-container">
    <ng-template world_page_template_slot_header></ng-template>
    <div class="container-xxl worldmaker-section-container">
        <ng-template world_page_template_slot_section1></ng-template>
        <ng-template world_page_template_slot_section2></ng-template>
        <ng-template world_page_template_slot_section3></ng-template>
        <ng-template world_page_template_slot_section4></ng-template>
        <ng-template world_page_template_slot_section5></ng-template>
        <ng-template world_page_template_slot_section6></ng-template>
        <ng-template world_page_template_slot_section7></ng-template>
        <ng-template world_page_template_slot_section8></ng-template>
        <ng-template world_page_template_slot_section9></ng-template>
        <ng-template world_page_template_slot_section10></ng-template>
    </div>
</div>
<div class="container-xxl">
    <div class="row c-pt-40">
        <div class="col-12 d-flex flex-column align-items-center">
            <div>
                <a [routerLink]="['/worlds']" class="btn btn-primary btn-round">Discover more Worlds</a>
            </div>
        </div>
    </div>
</div>
