import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ViewContainerRef, ComponentRef } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { ModalContainerComponent } from '../modules/shared/components/modal-container/modal-container.component';

declare const bootstrap: any;

export interface ModalOptions {
    showCloseButton?: boolean;
    showModalHeader?: boolean;
    fullscreen?: boolean;
    centered?: boolean;
    scrollable?: boolean;
    size?: "sm" | "md" | "lg" | "xl";
    title?: string;
    dialogClass?: string;
    closable?: boolean;
    childProps?: any;
    afterDismiss?: () => any;
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private _modalRef?: ComponentRef<ModalContainerComponent>;
    private _activeModal: any;
    private _defaultModalOptions: ModalOptions = {
        showCloseButton: true,
        showModalHeader: true,
        fullscreen: false,
        centered: false,
        scrollable: false,
        size: "md",
        title: "",
        dialogClass: "",
        closable: true,
        childProps: {}
    }

    constructor(
        private _appRef: ApplicationRef,
        private _platformService: PlatformService
    ) { }

    open(component: any, options: ModalOptions = {}): HTMLElement | null {
        let config: ModalOptions = {
            ...this._defaultModalOptions,
            ...options
        }
        if (this._platformService.isServer()) {
            console.warn("Calling modal service on the server. Aborting...");
            return null;
        }
        const viewContainerRef = this._appRef.components[0].injector.get(ViewContainerRef);
        this._modalRef = viewContainerRef.createComponent(ModalContainerComponent);
        this._modalRef.instance.config = config;
        // this._appRef.attachView(this.modalRef.hostView);

        const element = (this._modalRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(element);

        this._modalRef.instance.loadComponent(component);
        this._modalRef.changeDetectorRef.detectChanges();

        this._activeModal = new bootstrap.Modal(element.children[0]);
        element.children[0].addEventListener("hidden.bs.modal", () => {
            if (options.afterDismiss) {
                options.afterDismiss();
            }
        });
        this._activeModal.show();
        return element.children[0] as HTMLElement;
    }

    close() {
        if (this._platformService.isServer()) {
            console.warn("Calling modal service on the server. Aborting...");
            return;
        }
        if (this._activeModal) {
            this._activeModal.hide();
        }
        if (this._modalRef) {
            this._appRef.detachView(this._modalRef.hostView);
            this._modalRef.destroy();
        }
    }
}
