import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'pidToProfilePicture',
    pure: true
})

export class PidToProfilePicturePipe implements PipeTransform {
    constructor(private readonly _utilitiesService: UtilitiesService) {}
    
    transform(pid: string, isThumbnail: boolean = false): any {
        return this._utilitiesService.pidToProfilePictureUrl(pid, isThumbnail);
    }
}