<div class="h-100 d-flex flex-column justify-content-between {{inline ? 'inline' : 'c-pb-40 c-px-16 c-pt-40'}}">
    <!-- Start passwordless -->
    <ng-container *ngIf="currentStage == PasswordlessAuthStage.Send">
        <div class="header send-stage-header no-select c-pb-10" *ngIf="sendStageTitle">
            <h1 class="title c-mb-0">
                {{sendStageTitle}}
            </h1>
        </div>
        <div class="description no-select c-pb-60" *ngIf="sendStageDescription">
            {{sendStageDescription}}
        </div>
        <div class="login-form c-pb-20">
            <form [formGroup]="sendStageForm"
                  (ngSubmit)="onSendStageFormSubmit()"
                  class="d-flex">
                <div class="form-group c-pb-10">
                    <label for="email">Your email address</label>
                    <input id="email" name="email" type="email" formControlName="email" class="form-control dark-input rounded-input w-100" placeholder="franz@kafka.com" (keyup)="onEmailChanged()" >
                    <div class="text-danger c-pt-5" [@errorMessageAnimation]>
                        <small *ngIf="!emailSuggestionError && emailFormControl.dirty && emailFormControl.invalid">
                            {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                        </small>
                        <small *ngIf="emailSuggestionError">
                            Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-dark btn-round w-100 continue-btn" type="submit" [disabled]="loading" [ngClass]="{'c-mt-32': inline}">
                        <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                        Continue
                    </button>
                </div>
            </form>
        </div>
        <div class="terms d-flex justify-content-center">
            <small class="text-center footer-text">
                By tapping "Continue" you accept<br>
                <a href="https://crewfiction.com/privacy-policy" target="_blank">
                    crewfiction's Terms of Use & Privacy Policy
                </a>
            </small>
        </div>
    </ng-container>

    <!-- Verify OTP -->
    <ng-container *ngIf="currentStage == PasswordlessAuthStage.Verify">
        <div class="header verify-stage-header no-select c-pb-60" *ngIf="verifyStageTitle" [ngClass]="{'text-center': inline}">
            <h1 class="title c-mb-0">
                You’ve got mail! 📬
            </h1>
        </div>
        <div class="description no-select c-pb-20" [ngClass]="{'text-center': inline}">
            We emailed you at <span>{{emailFormControl.value}}</span>. Check your inbox to continue.
        </div>
        <!-- <div class="verify-form c-pb-20">
            <form [formGroup]="verifyStageForm"
                  (ngSubmit)="onVerifyStageFormSubmit()"
                  class="d-flex">
                <div class="form-group c-pb-10">
                    <input name="otp" type="text" formControlName="code" class="form-control dark-input rounded-input w-100" placeholder="Your code">
                    <div *ngIf="codeFormControl.dirty && codeFormControl.invalid && !invalidCodeError" class="text-danger c-pt-5" [@errorMessageAnimation]>
                        <small>
                            {{codeErrors && codeErrors.length ? codeErrors[0] : ''}}
                        </small>
                    </div>
                    <div class="text-danger" *ngIf="invalidCodeError" [@errorMessageAnimation]>
                        <small>Code is invalid. Please try again</small>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-dark btn-round w-100" type="submit" [disabled]="loading">
                        <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                        Continue
                    </button>
                </div>
            </form>
        </div>  -->
        <!-- <div class="terms d-flex justify-content-center" *ngIf="resendEnabled$ | async">
            <small class="text-center footer-text">
                <a href="javascript:void(0)" (click)="didNotGetCode()">
                    Resend email
                </a>
            </small>
        </div> -->
        <div class="resend d-flex flex-column justify-content-center align-items-center c-pt-30 text-muted">
            <small *ngIf="resendDisabled$ | async">
                Resend email
            </small>
            <small *ngIf="resendEnabled$ | async" (click)="didNotGetCode()">
                Resend email
            </small>
        </div>
        <div class="remaining d-flex justify-content-center">
            <span *ngIf="resendDisabled$ | async">
                {{timeToResend}}
            </span>
        </div>
    </ng-container>
    
</div>