import { Component, Input } from '@angular/core';

@Component({
    selector: 'worldmaker-component-featured-world-manual',
    templateUrl: './featured-world-manual.component.html',
    styleUrls: ['./featured-world-manual.component.scss'],
})
export class FeaturedWorldManual {
    @Input() data!: {
        worldPage: {
            id: number,
            name: string,
            path: string
        },
        title: string,
        description: string,
        bgImage: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        };
    };
}
