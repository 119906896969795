import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BooksService } from 'src/app/services/books.service';
import { firstValueFrom } from 'rxjs';
import { Book } from 'src/app/models/book.model';
import * as dayjs from "dayjs";
import { Router } from '@angular/router';

@Component({
    selector: "worldmaker-component-new-release",
    templateUrl: "./new-release.component.html",
    styleUrl: "./new-release.component.scss"
})
export class NewRelease implements OnInit {
    @Input() data!: {
        showHR: boolean;
        placement?: string;
    };

    constructor(
        private readonly _analyticsService: AnalyticsService,
        private readonly _booksService: BooksService,
        private readonly _router: Router
    ) {}

    book!: Book;

    async ngOnInit() {
        this.book = await firstValueFrom(this._booksService.getLastRelease())
    }

    get mainAuthor() {
        return this.book.authors.find(a => a.slug === this.book.mainAuthorSlug)!
    }

    //try for scottish or regency first
    //then historical
    //and then fallback to any genre that has 2 words (which are usually really specific)
    get mainGenre() {
        const scottishOrRegency = this.book.genres.find(genre => genre.slug === "scottish-romance" || genre.slug === "regency");
        if (scottishOrRegency) {
            return scottishOrRegency;
        }
        const historical = this.book.genres.find(genre => genre.slug === "historical-romance");
        if (historical) {
            return historical;
        }
        return this.book.genres.find(genre => genre.name.split(" ").length === 2)
    }

    get spicyMeterLabel() {
        switch (this.book.spicyMeter) {
            case 1:
                return "Sweet"
            case 2:
                return "Sensual";
            case 3:
                return "Sexy";
            case 4:
                return "Steamy";
            case 5:
                return "Spicy wildfire";
            default:
                return "";
        }
    }

    get releaseBadge() {
        if (!this.book.publishedAt) return;
        if (this.book.isPrelaunched || this.book.isFree) return;
        if (dayjs(this.book.publishedAt).isAfter(dayjs().subtract(7, "days"))) {
            return "New Release";
        } else if ((dayjs(this.book.publishedAt).isAfter(dayjs().subtract(14, "days")))) {
            return "Recent Release";
        }
        return;
    }

    handleClick(to: "amazon" | "book_page") {
        if (to === "amazon") {
            this._analyticsService.track({event: "go_to_amazon", params: {bookOrseries: this.book, clickSource: "web"}});
        }
        this._analyticsService.track({event: "recommendation_click", params: {
            book: this.book, 
            destinationType: to,
            placement: this.data.placement,
            recommendationType: this.releaseBadge || "Older Release",
            currentUrl: this._router.url
        }});
    }
}