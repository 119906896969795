<ng-container *ngIf="book">
    <book-header-container
        [book]="book"
        [headerText]="data.headerText"
        [enableBreadcrumbs]="data.enableBreadcrumbs"
        [enableRating]="data.showRating"
        [showSpicyMeter]="data.showSpicyMeter"
        [content]="content"
        [altCoverIndex]="data.altCoverIndex"
        [showHeader]="data.showHeader"
        [enableCountDown]="true"
        [showPriceCut]="true"
        coverBadgeOverwrite="Free">
    </book-header-container>

    <ng-template #content>
        <div class="book-header-cta-controls">
            @defer (when authChecked$ | async) {
                <ng-container *ngIf="!joinedArc">
                    <form [formGroup]="emailForm"
                      (ngSubmit)="readNow()">
                        <div class="input-container">
                            <input type="email" required class="form-control rounded-input dark-input input-large" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-mt-8 d-none d-md-flex" [@errorMessageAnimation]>
                                <span>{{emailErrors && emailErrors.length ? emailErrors[0] : ''}}</span>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger c-mt-8 d-none d-md-flex" [@errorMessageAnimation]>
                                <span>Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?</span>
                            </div>
                        </div>
                        <div class="button-container">
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round btn-large">
                                <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                                <span>{{ data.ctaText }}</span>
                            </button>
                            <div class="w-100 d-flex justify-content-center c-mt-8">
                                <span class="arc-left">{{arcCopies.left}}/{{arcCopies.total}} copies left</span>
                            </div>
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-mt-8 d-flex d-md-none" [@errorMessageAnimation]>
                                <span>{{emailErrors && emailErrors.length ? emailErrors[0] : ''}}</span>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger c-mt-8 d-flex d-md-none" [@errorMessageAnimation]>
                                <span>Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?</span>
                            </div>
                            <div class=" d-flex d-md-none c-mt-8" style="min-height: 20px;" *ngIf="(!emailForm.invalid || !emailForm.dirty) && !emailSuggestionError"></div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="joinedArc">
                    <div class="button-container">
                        <readables-reader-popup-v2 [readable]="readable" *ngIf="readable">
                            <button class="btn btn-primary btn-round btn-with-icon">
                                <feather-icon icon="book"></feather-icon>
                                Start Reading
                            </button>
                        </readables-reader-popup-v2>
                    </div>
                    <div class="button-container">
                        <download-readable [readable]="readable" [showInstructionsTrigger]="true" *ngIf="readable" buttonStyle="big_dark"></download-readable>
                    </div>
                </ng-container>
            } @placeholder (minimum 500ms) {
                
            }
        </div>
    </ng-template>
    
    <div class="card sticky-container container-xxl" *ngIf="data.enableStickyFooter && !joinedArc && scrolled" [@stickyFooterAnimation]>
        <div class="card-body c-pt-24 c-pb-16 c-px-4">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center c-pb-8">
                        <img class="sticky-cover c-me-8" [src]="book.images.cover | cloudinaryFormat | cloudinaryScaleHeight : 38 | cloudinaryQuality : 100 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                        <div class="d-flex flex-column">
                            <span class="sticky-book-title">{{book.title}}</span>
                            <span class="sticky-author-name d-none d-md-block">{{mainAuthor.name}}</span>
                            <div class="align-items-end d-flex d-md-none">
                                <span class="price-cut c-me-16">
                                    <span class="price">$0.99</span>
                                    <span class="free">Free</span>
                                </span>
                                <fomo-countdown colorScheme="red" [to]="book.publishedAt!" [showCountDown]="true" size="small" [showText]="false"></fomo-countdown>
                            </div>
                        </div>
                    </div>
                    <div class="align-items-end d-none d-md-flex">
                        <span class="price-cut c-me-8">
                            <span class="price">$0.99</span>
                            <span class="free">Free</span>
                        </span>
                        <fomo-countdown colorScheme="red" [to]="book.publishedAt!" [showCountDown]="true" size="small" [showText]="false"></fomo-countdown>
                    </div>
                </div>
                <div class="book-header-cta-controls c-pt-16 c-pt-md-0">
                    <form [formGroup]="emailForm"
                    (ngSubmit)="readNow()" class="">
                        <div class="input-container">
                            <input type="email" required class="form-control rounded-input dark-input input-large" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-mt-8 d-none d-md-flex" [@errorMessageAnimation]>
                                <span>{{emailErrors && emailErrors.length ? emailErrors[0] : ''}}</span>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger c-mt-8 d-none d-md-flex" [@errorMessageAnimation]>
                                <span>Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?</span>
                            </div>
                        </div>
                        <div class="button-container">
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round btn-large">
                                <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                                <span>{{ data.ctaText }}</span>
                            </button>
                            <div class="w-100 d-flex justify-content-center c-mt-8">
                                <span class="arc-left">{{arcCopies.left}}/{{arcCopies.total}} copies left</span>
                            </div>
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-mt-8 d-flex d-md-none" [@errorMessageAnimation]>
                                <span>{{emailErrors && emailErrors.length ? emailErrors[0] : ''}}</span>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger c-mt-8 d-flex d-md-none" [@errorMessageAnimation]>
                                <span>Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?</span>
                            </div>
                            <div class="d-flex d-md-none c-mt-8" style="min-height: 20px;" *ngIf="(!emailForm.invalid || !emailForm.dirty) && !emailSuggestionError"></div>
                        </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
    
</ng-container>
