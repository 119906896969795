import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PlatformService } from 'src/app/services/platform.service';
import { UserService } from 'src/app/services/user.service';
import { EmbeddedAuthService } from './services/embedded-auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private readonly _router: Router,
        private readonly _platformService: PlatformService,
        private readonly _userService: UserService,
        private readonly _authService: EmbeddedAuthService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._authService.user) {
            return true;    
        }
        else {
            return this._router.navigate(['/']);
        }
    }
}
