import { Component, Input } from '@angular/core';

@Component({
    selector: 'star-rating',
    templateUrl: './star-rating.component.html',
    styleUrl: './star-rating.component.scss'
})
export class StarRatingComponent {
    @Input() rating: number = 0;
    @Input() cursorPointer = false;
    stars: number[] = [1, 2, 3, 4, 5];


    getStarWidth(index: number): string {
        const fullStars = Math.floor(this.rating);
        const partialStar = this.rating - fullStars;
        if (index < fullStars) {
            return '100%';
        }
        else if (index === fullStars) {
            return `${partialStar * 100}%`;
        }
        else {
            return '0%';
        }
    }
}
