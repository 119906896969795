<div class="full-page">
    <div *ngIf="!whiteLabel" [ngClass]="{
        'header-wrapper': true,
        'bg-transparent': isTransparent,
        'fixed-header': isExplore,
        'normal-header': !isExplore
    }">
        <app-header></app-header>
    </div>
    
    <main class="overflow-x-hidden c-pb-10" [ngClass]="{
        'with-fixed-header': isExplore,
        'with-normal-header': !isExplore,
        'p-0': whiteLabel
    }">
        <router-outlet></router-outlet>
    </main>
    
    <footer *ngIf="!whiteLabel" class="py-2 c-mt-80 container-xxl">
        <div class="row">
            <div class="col-12 col-md-6 copyright-section c-mb-10 c-md-md-0">
                <p class="text-muted mb-0">© {{year}} crewfiction</p>
            </div>
            <div class="col-12 d-flex flex-column col-md-6 flex-md-row justify-content-md-end">
                <a href="https://crewfiction.com/cookie-policy" target="_blank" class="text-muted c-mb-10 c-md-md-0 c-me-md-20">Cookies</a>
                <a href="https://crewfiction.com/privacy-policy" target="_blank" class="text-muted c-mb-10 c-md-md-0 c-me-md-20">Privacy Policy</a>
                <a href="mailto:hello@crewfiction.com?subject=Feedback%20for%20crewfiction" target="_blank" class="text-muted">Give feedback & earn</a>
            </div>
        </div>
    </footer>
</div>
<chat-widget-facade></chat-widget-facade>