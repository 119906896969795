import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { Trope } from 'src/app/models/trope.model';
import { TropesService } from 'src/app/services/tropes.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PlatformService } from 'src/app/services/platform.service';

export interface WorldMakerTropeBadgeListConfig {
    tropes: Trope[];
    header: string;
    ctaUrl: string;
    ctaText: string;
    enableCta: boolean;
    showHR: boolean;
    enableAnchor: boolean;
    anchorSlug?: string;
}

@Component({
  selector: 'worldmaker-component-tropes-badge-list',
  templateUrl: './tropes-badge-list.component.html',
  styleUrls: ['./tropes-badge-list.component.scss']
})
export class TropesBadgeListComponent implements OnInit {
    @Input()
    data!: WorldMakerTropeBadgeListConfig

    badges!: BadgeConfig[];

    constructor(
        private readonly _utilitiesService: UtilitiesService,
        private readonly _tropesService: TropesService,
        private readonly _platformService: PlatformService
    ) {}

    async ngOnInit() {
        //load all if none are selected
        if (!this.data.tropes || this.data.tropes.length === 0) {
            this.generateBadgeConfig(await firstValueFrom(this._tropesService.findAll()));
        }  else {
            this.generateBadgeConfig(this.data.tropes);
        }
    }

    private generateBadgeConfig(tropes: Trope[]) {
        this.badges = tropes.map(trope => {
            return {
                text: trope.name,
                type: "default",
                url: this._utilitiesService.getTropeUrl(trope.slug)
            };
        });
    }

    get showCta() {
        return this.data.enableCta && this.data.ctaText && this.data.ctaUrl;
    }

    get isMobile() {
        return this._platformService.isMobile;
    }
}
