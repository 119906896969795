import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { CloudinaryImage } from '@cloudinary/url-gen';

@Pipe({
    name: 'cloudinaryQuality',
    pure: true
})

export class CloudinaryQualityPipe implements PipeTransform {

    constructor(private _cloudinaryService: CloudinaryService) { }

    transform(url: CloudinaryImage | string, quality: number | string = "auto"): CloudinaryImage {
        return this._cloudinaryService.changeQuality(url, quality);
    }
}