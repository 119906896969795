import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookPageComponent } from './book-page/book-page.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { BooksModule } from '../../modules/books/books.module';
import { WorldMakerModule } from 'src/app/modules/worldmaker/worldmaker.module';
import { FreeBookPageComponent } from './free-book-page/free-book-page.component';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { RouterModule } from '@angular/router';
import { DiscussionModule } from 'src/app/modules/discussion/discussion.module';
import { ReadablesModule } from 'src/app/modules/readables/readables.module';
import { BonusPageComponent } from './bonus-page/bonus-page.component';
import { RateBookPageComponent } from './rate-book-page/rate-book-page.component';

@NgModule({
    declarations: [BookPageComponent, FreeBookPageComponent, BonusPageComponent, RateBookPageComponent],
    imports: [
        CommonModule, 
        SharedModule, 
        BooksModule, 
        WorldMakerModule, 
        RouterModule,
        DiscussionModule,
        ReadablesModule
    ],
    providers: [FollowService],
    exports: [],
})
export class BookPageModule {}
