import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'title-card',
    templateUrl: './title-card.component.html',
    styleUrls: ['./title-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleCardComponent {
    @Input() title!: string;
    @Input() src!: string;
    @Input() url!: string[] | string;
    @Input() titlePos: "center" | "bottom" = "center";
    @Input() onClick?: () => void;

    cardClicked() {
        if (this.onClick) {
            this.onClick();
        }
    }
}
