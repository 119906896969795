<div>
    <div class="row" [ngClass]="containerClass">
        <div class="cover-container" [ngClass]="{ 'col-md-2': !showOnlyCover }">
            <book-cover [book]="book" [height]="70"></book-cover>
        </div>
        <div class="col-md-10" *ngIf="!showOnlyCover && book">
            <h5 class="fakt book-title">{{ book.title }}</h5>
            <span class="fakt author">{{ authors }}</span>
        </div>
    </div>
</div>
