import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TitleService } from './title.service';

@Injectable({providedIn: 'root'})
export class CustomTitleStrategy extends TitleStrategy {

    defaultTitle: string = 'Crewfiction';

    constructor(
        private readonly _title: Title,
        private readonly _titleService: TitleService
    ) {
        super();
    }


    override updateTitle(snapshot: RouterStateSnapshot): void {
        // checks for preconfigured title, e.g. set in routeConfig.title        
        let existingTitle = this.buildTitle(snapshot);
        if (!existingTitle) {
            this._title.setTitle("Crewfiction | Dive into up-and-coming fiction books and communities");
        }
        else {
            this._title.setTitle(existingTitle.includes(this.defaultTitle) ? existingTitle : existingTitle + " • " + this.defaultTitle)
        }
    }

    
}