import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PlatformService } from 'src/app/services/platform.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
    selector: 'worldmaker-component-similar-books-manual',
    templateUrl: './similar-books-manual.component.html',
    styleUrls: ['./similar-books-manual.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimilarBooksManual implements OnChanges {
    @Input() data!: { 
        header: string;
        books: Book[];
        ctaUrl: string;
        ctaText: string;
        enableCta: boolean;
        showHR: boolean;
        enableAnchor: boolean;
        anchorSlug?: string;
        goDirectlyToAmazon?: boolean;
    };

    similarBooksDatasource: ContentGridDataSource<Book> | null = null;

    layoutConfig: ColumnLayoutConfig = {
        mobile: 2,
        sm: 3,
        md: 4,
        lg: 6
    }

    constructor(
        private readonly _utilitiesService: UtilitiesService,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService
    ) {}
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && changes['data'].currentValue) {
            this.initDataSource();
        }
    }

    private initDataSource() {
        if (!this.data.books || !this.data.books.length) {
            this.similarBooksDatasource = null;
            return;
        }
        this.similarBooksDatasource = this._utilitiesService.getBookDataSource(this.data.books, {
            subtitle: "author",
            useAmazonLink: this.data.goDirectlyToAmazon,
            onClick: this.data.goDirectlyToAmazon ? (b) => {
                return () => {
                    this._analyticsService.track({event: "go_to_amazon", params: {bookOrseries: b, clickSource: "web"}});
                }
            } : undefined
        });
    }

    async ngOnInit() {
        if (this.data.books) {
            this.initDataSource();
        }
    }

    get showCta() {
        return this.data.enableCta && this.data.ctaText && this.data.ctaUrl;
    }

    get isMobile() {
        return this._platformService.isMobile;
    }
}
