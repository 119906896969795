import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import {
    worldMakerComponentTable,
    WorldMakerComponentNames,
} from '../../worldmaker.module';

import {
    ExplorePageTemplateSlotHeader,
    ExplorePageTemplateSlotSection1,
    ExplorePageTemplateSlotSection2,
    ExplorePageTemplateSlotSection3,
    ExplorePageTemplateSlotSection4,
    ExplorePageTemplateSlotSection5,
    ExplorePageTemplateSlotSection6,
    ExplorePageTemplateSlotSection7,
    ExplorePageTemplateSlotSection8,
    ExplorePageTemplateSlotSection9,
    ExplorePageTemplateSlotSection10
} from './explore_page_template.slots';

type WorldMakerInput = {
    path: string;
    template: string;
    components: {
        data: object;
        name: WorldMakerComponentNames;
        slot: 'header' | 'section1' | 'section2';
    }[];
}

@Component({
    selector: 'worldmaker-explore-page-template',
    templateUrl: './explore_page_template.component.html',
    styleUrls: ['./explore_page_template.component.scss'],
})
export class ExplorePageTemplate {
    @ViewChild(ExplorePageTemplateSlotHeader, { static: true })
    header!: ExplorePageTemplateSlotHeader;
    @ViewChild(ExplorePageTemplateSlotSection1, { static: true })
    section1!: ExplorePageTemplateSlotSection1;
    @ViewChild(ExplorePageTemplateSlotSection2, { static: true })
    section2!: ExplorePageTemplateSlotSection2;
    @ViewChild(ExplorePageTemplateSlotSection3, { static: true })
    section3!: ExplorePageTemplateSlotSection3;
    @ViewChild(ExplorePageTemplateSlotSection4, { static: true })
    section4!: ExplorePageTemplateSlotSection4;
    @ViewChild(ExplorePageTemplateSlotSection5, { static: true })
    section5!: ExplorePageTemplateSlotSection5;
    @ViewChild(ExplorePageTemplateSlotSection6, { static: true })
    section6!: ExplorePageTemplateSlotSection6;
    @ViewChild(ExplorePageTemplateSlotSection7, { static: true })
    section7!: ExplorePageTemplateSlotSection7;
    @ViewChild(ExplorePageTemplateSlotSection8, { static: true })
    section8!: ExplorePageTemplateSlotSection8;
    @ViewChild(ExplorePageTemplateSlotSection9, { static: true })
    section9!: ExplorePageTemplateSlotSection9;
    @ViewChild(ExplorePageTemplateSlotSection10, { static: true })
    section10!: ExplorePageTemplateSlotSection10;

    @Input()
    set worldMakerData(value: WorldMakerInput){
        this.dataObsevable$.next(value);
    }

    dataObsevable$ = new ReplaySubject<WorldMakerInput>();

    ngOnInit() {
        this.dataObsevable$.subscribe((current) => {
            //clear all slots first
            this.header.viewContainerRef.clear();
            this.section1.viewContainerRef.clear();
            this.section2.viewContainerRef.clear();
            this.section3.viewContainerRef.clear();
            this.section4.viewContainerRef.clear();
            this.section5.viewContainerRef.clear();
            this.section6.viewContainerRef.clear();
            this.section7.viewContainerRef.clear();
            this.section8.viewContainerRef.clear();
            this.section9.viewContainerRef.clear();
            this.section10.viewContainerRef.clear();
            //re-create them
            for (const c of current.components) {
                // if (c.slot === 'meta') continue;
                const viewContainerRef = this[c.slot].viewContainerRef;
    
                const toLoad = worldMakerComponentTable[c.name];
                const componentRef = viewContainerRef.createComponent<any>(toLoad);
                componentRef.instance.data = c.data;
            }
        });
    }
}
