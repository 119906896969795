import { Injectable, Renderer2, RendererFactory2, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AboutPageSchema, ArticleSchema, BlogPostingSchema, BookSchema, BookSeriesSchema, BreadcrumbListSchema, CollectionPageSchema, CommentSchema, ContactPageSchema, ItemListSchema, OrganizationSchema, PersonSchema, SchemaMarkupService, WebPageSchema, WebSiteSchema } from './schema-markup.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from './platform.service';

@Injectable({
    providedIn: 'root'
})
export class JsonLdService {
    private renderer: Renderer2;

    constructor(
        private _rendererFactory: RendererFactory2,
        private readonly _schemaMarkupService: SchemaMarkupService,
        private readonly _sanitizer: DomSanitizer,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly _platformService: PlatformService,
        private _router: Router
    ) {
        this.renderer = this._rendererFactory.createRenderer(null, null);
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.removeWebPage();
            }
        });
    }

    addWebsite(schema: WebSiteSchema): void {
        this.appendToHead("schema-website", this._schemaMarkupService.webSite(schema));
    }

    removeWebsite(): void {
        this.removeFromHeadById("schema-website");
    }

    addWebPage(schema: WebPageSchema, comments: CommentSchema[] | undefined = undefined): void {
        this.appendToHead("schema-webpage", this._schemaMarkupService.webPage(schema, comments));
    }

    removeWebPage(): void {
        this.removeFromHeadById("schema-webpage");
    }

    addBlogPosting(schema: BlogPostingSchema): void {
        this.appendToHead("schema-blogposting", this._schemaMarkupService.blogPosting(schema));
    }

    removeBlogPosting(): void {
        this.removeFromHeadById("schema-blogposting");
    }

    addAboutPage(schema: AboutPageSchema): void {
        this.appendToHead("schema-aboutpage", this._schemaMarkupService.aboutPage(schema));
    }

    removeAboutPage(): void {
        this.removeFromHeadById("schema-aboutpage");
    }

    addContactPage(schema: ContactPageSchema): void {
        this.appendToHead("schema-contactpage", this._schemaMarkupService.contactPage(schema));
    }

    removeContactPage(): void {
        this.removeFromHeadById("schema-contactpage");
    }

    addOrganization(schema: OrganizationSchema): void {
        this.appendToHead("schema-organization", this._schemaMarkupService.organization(schema));
    }

    removeOrganization(): void {
        this.removeFromHeadById("schema-organization");
    }

    addArticle(schema: ArticleSchema): void {
        this.appendToHead("schema-article", this._schemaMarkupService.article(schema));
    }

    removeArticle(): void {
        this.removeFromHeadById("schema-article");
    }

    addItemList(schema: ItemListSchema): void {
        this.appendToHead("schema-itemlist", this._schemaMarkupService.itemList(schema));
    }

    removeItemList(): void {
        this.removeFromHeadById("schema-itemlist");
    }

    addCollectionPage(schema: CollectionPageSchema): void {
        this.appendToHead("schema-collectionpage", this._schemaMarkupService.collectionPage(schema));
    }

    removeCollectionPage(): void {
        this.removeFromHeadById("schema-collectionpage");
    }

    addBook(schema: BookSchema): void {
        this.appendToHead("schema-book", this._schemaMarkupService.book(schema));
    }

    removeBook(): void {
        this.removeFromHeadById("schema-book");
    }

    addBookSeries(schema: BookSeriesSchema): void {
        this.appendToHead("schema-bookseries", this._schemaMarkupService.bookSeries(schema));
    }

    removeBookSeries(): void {
        this.removeFromHeadById("schema-bookseries");
    }

    addPerson(schema: PersonSchema): void {
        this.appendToHead("schema-person", this._schemaMarkupService.person(schema));
    }

    removePerson(): void {
        this.removeFromHeadById("schema-person");
    }

    addBreadcrumbList(schema: BreadcrumbListSchema): void {
        this.appendToHead("schema-breadcrumblist", this._schemaMarkupService.breadcrumbList(schema));
    }

    removeBreadcrumbList(): void {
        this.removeFromHeadById("schema-breadcrumblist");
    }

    appendToHead(scriptId: string, json: {}): void {
        if (!isPlatformBrowser(this.platformId)) {
            // remove existing
            this.removeFromHeadById(scriptId);
            const script = this.createScriptElement(scriptId, json);
            // this.renderer.appendChild(head, script);
            this.renderer.appendChild(this._document.head, script);
        }
    }

    removeFromHeadById(scriptId: string): void {
        if (!isPlatformBrowser(this.platformId)) {
            try {
                const script = this.renderer.selectRootElement(`#${scriptId}`, true);
                this.renderer.removeChild(script.parentNode, script);
            }
            catch (e) {
                // ignore failure to find the element
            }
        }
    }

    
    private createScriptElement(scriptId: string, json: any): any {
        if (json["@context"] === undefined) {
            json["@context"] = "https://schema.org/";
        }
        const script = this.renderer.createElement('script');
        this.renderer.setAttribute(script, 'type', 'application/ld+json');
        this.renderer.setAttribute(script, 'id', scriptId);
        const jsonLdString = JSON.stringify(json, null, 2);
        script.text = jsonLdString;
        // this.renderer.appendChild(this._document.head, script);
        // const sanitizedJsonLd = this._sanitizer.bypassSecurityTrustHtml(jsonLdString);
        // const text = this.renderer.createText(sanitizedJsonLd as string);
        // this.renderer.setValue(script, text);
        return script;
    }

}
