import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Pipe({
    name: 'cloudinaryScale',
    pure: true
})

export class CloudinaryScalePipe implements PipeTransform {
    constructor(private _cloudinaryService: CloudinaryService) { }

    transform(url: CloudinaryImage | string, width: number, height: number): CloudinaryImage {
        return this._cloudinaryService.scale(url, width, height);
    }
}