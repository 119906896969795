import { Component, Input } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
    selector: 'read-on-amazon-button',
    templateUrl: './read-on-amazon-button.component.html',
    styleUrls: ['./read-on-amazon-button.component.scss']
})
export class ReadOnAmazonButtonComponent {

    @Input()
    amazonUrl: string = "";

    @Input()
    book?: Book;

    constructor(private _analyticsService: AnalyticsService) {

    }

    get _amazonUrl() {
        return this.amazonUrl || this.book?.links?.amazon || "";
    }

    onClick() {
 
    }
}
