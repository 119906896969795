<ol
    class="c-breadcrumb c-py-md-10 c-py-5 c-px-0"
    style="--link-color: {{linkColor}};--current-color: {{currentColor}};--hover-color: {{hoverColor}};--font-size: {{fontSize}};--font-weight: {{fontWeight}}">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
        <ng-container *ngIf="i != breadcrumbs.length - 1">
            <a [routerLink]="breadcrumb.url.join('')">{{ breadcrumb.label }}<feather-icon icon="chevron-right" width="20" height="20" class="c-ms-8"></feather-icon></a>
        </ng-container>
        <span class="current c-me-20" *ngIf="i == breadcrumbs.length - 1">
            {{ breadcrumb.label }}
        </span>
    </li>
</ol>