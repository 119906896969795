import { Pipe, PipeTransform } from '@angular/core';
import { Author } from 'src/app/models/author.model';
import { Book } from 'src/app/models/book.model';

@Pipe({
    name: 'authorUrl',
    pure: true
})

export class AuthorUrlPipe implements PipeTransform {
    transform(author: Author): any {
        return `/authors/${author.slug}`;
    }
}
