import { AfterViewInit, Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Subscription, filter } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {DOCUMENT} from "@angular/common";
import { PlatformService } from './services/platform.service';
import { CanonicalService } from './services/canonical.service';
import { TitleService } from './services/title.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { PlatformLocation } from '@angular/common';
import { PWAService } from './pwa/pwa.service';
import { UtilitiesService } from './services/utilities.service';
import { ChatWidgetService } from './services/chatWidget.service';

export interface Product {
    id: number;
    name: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    title = 'client';
    seoMessage: string = "";
    products: Product[] = [];
    year: number = new Date().getFullYear();
    isSplashScreen: boolean = true;
    isTransparent: boolean = false;
    isExplore: boolean = false;

    whiteLabel: boolean = false;

    private _subs: Subscription[] = [];

    constructor(
        private readonly _canonicalService: CanonicalService,
        private readonly _router: Router,
        private readonly _titleService: TitleService,
        private readonly _analyticsService: AnalyticsService,
        private _activatedRoute: ActivatedRoute,
        private _platform: PlatformService,
        private _location: PlatformLocation,
        private _pwaService: PWAService,
        private readonly _utilitiesService: UtilitiesService,
        @Inject(DOCUMENT) private _document: Document,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    ngAfterViewInit() {
        if (this._platform.isBrowser()) {
            this.viewInit$.next(true);
        }
    }

    ngOnDestroy(): void {
        this._subs.forEach(s => s.unsubscribe());
        this._subs = [];
    }

    authLoading: boolean = false;
    viewInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // isFullScreen: boolean = true;
    // private isFullPagePath(path: string): boolean {
    //     const regexes = [
    //         /\/authors\/[^\/]+\/books\/[^\/]+/,
    //         /\/pages\/[^\/]+/,
    //         /\/explore/,
    //         /\/library\/shelves\/free/
    //     ];
    //     return regexes.some(regex => regex.test(path));
    // }

    async ngOnInit() {
        this._canonicalService.watchAndUpdate();
        this._pwaService.init();

        if (this._platform.isBrowser()) {
            this._router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.isTransparent = event.urlAfterRedirects.includes("fiction-facts");
                }
            });

            //on magic-link page, we want to manually start tracking (after we have restored the previous anonymousState)
            const url = new URL(this._location.href);
            if (url.pathname !== "/magic") {
                this._analyticsService.load();
            }
        }

        this._router.events.subscribe(async event => {
            if (event instanceof NavigationEnd) {
                this._chatWidgetService.resetPosition();
                this.isSplashScreen = event.urlAfterRedirects === "/";
                this.isExplore = event.urlAfterRedirects === "/explore";
                this.handleDataForAnalytics();
                //special page options
                if (event.urlAfterRedirects.startsWith("/pages/")) {
                    this._chatWidgetService.disable();
                } else if (event.urlAfterRedirects.startsWith("/subscriptions/")) {
                    this.whiteLabel = true;
                    this._titleService.setTitle("Unsubscribe");
                    this._chatWidgetService.disable();
                } else if (event.urlAfterRedirects.includes("/bonus")) {
                    this.whiteLabel = true;
                    this._chatWidgetService.disable();
                } else if (event.urlAfterRedirects.includes("/author-pages")) {
                    this.whiteLabel = true;
                    this._chatWidgetService.disable();
                } else if (event.urlAfterRedirects.includes("/rate")) {
                    this.whiteLabel = true;
                    this._chatWidgetService.disable();
                } else {
                    this.whiteLabel = false;
                    this._chatWidgetService.enable();
                }
            }
        })
        
    }

    private handleDataForAnalytics() {
        let currentSnapshot = this._activatedRoute.snapshot;
        let mergedData = {
            ...currentSnapshot.data
        };
        let mergedParams = {
            ...currentSnapshot.params
        };
        let child = currentSnapshot.root.firstChild;
        if (child) {
            mergedData = {
                ...mergedData,
                ...child.data
            };
            mergedParams = {
                ...mergedParams,
                ...child.params
            };
            while (child.firstChild) {
                child = child.firstChild;
                mergedData = {
                    ...mergedData,
                    ...child.data
                };
                mergedParams = {
                    ...mergedParams,
                    ...child.params
                };
            }
        }
        if (mergedData["trackPageView"] !== false) {
            let properties: any = {};
            for (let key in mergedParams) {
                properties[key.replace("-slug", "")] = mergedParams[key];
            }
            let category = typeof mergedData['category'] == "function" ? mergedData['category'](properties) : mergedData['category'];
            this._analyticsService.trackPageView(undefined, category, properties);
        }
    }
}
