import { Component, Input, AfterViewInit, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BookSeries } from 'src/app/models/book-series.model';
import { Book } from 'src/app/models/book.model';
import { Readable } from 'src/app/models/readable.model';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { BookExtras } from 'src/app/models/book-extras.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ReadablesService } from 'src/app/modules/readables/services/readables.service';
import { BooksService } from 'src/app/services/books.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'book-info',
    templateUrl: './book-info.component.html',
    styleUrls: ['./book-info.component.scss'],
})
export class BookInfo implements OnInit, AfterViewInit {
    @Input() book!: Book;
    @Input() series?: BookSeries;
    @Input() linksDisabled = false;
    @Input() showPageCount = true;
    @Input() readable?: Readable;
    @Input() readCTA = "Read Sample";
    @Input() ctaBackground?: {
        channel: "manual_image" | "manual_video",
        data: {
            url: string
        }
    };

    @ViewChild("description") description!: ElementRef<HTMLDivElement>;
    
    sneakPeekHasPlayButton = false;
    bonusScene!: Readable;
    characters!: BookExtras["data"]["characters"];

    constructor(
        private readonly _utilitiesService: UtilitiesService,
        private readonly _readablesService: ReadablesService,
        private readonly _booksService: BooksService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _anonymousPersistentState: AnonymousPersistentState
    ) {}

    async ngOnInit() {
        //load bonus scene and extras only for published non-free books by NB
        if (!this.book.isPrelaunched && !this.book.isFree && this.book.publisher === "NB") {
            const bs = await firstValueFrom(this._readablesService.infoByBookSlug(this.book.slug, "bonus_scene"));
            if (bs) {
                this.bonusScene = bs;
            }
            const extras = await firstValueFrom(this._booksService.getBookExtras(this.book.slug, "characters"));
            if (extras) {
                this.characters = extras.data.characters;
            }
        }
    }

    async ngAfterViewInit() {
        this.description.nativeElement.innerHTML = this.book.description || "";
        //show a play icon if the sneak peek has a video
        if (this.readable) {
            const data = await firstValueFrom(this._readablesService.read(this.readable.slug, 0));
            const video = data.content.find(entry => entry.type === "video");
            if (video) {
                this.sneakPeekHasPlayButton = true;
            }
        }
    }

    trackStartBonusContentFlow(contentType: "bonus_scene" | "character_overviews") {
        this._analyticsService.track({
            event: "start_bonus_content_flow",
            params: {
                book: this.book,
                author: this.book.authors.find(a => a.slug === this.book.mainAuthorSlug)!,
                flowAlreadyCompleted: !!this._anonymousPersistentState.bonusContent[this.book.slug],
                kindle: false,
                contentType: contentType,
                triggeredFrom: "book_page"
            }
        });
    }

    get publishDate() {
        if (!this.book.publishedAt) return "";
        const date = new Date(this.book.publishedAt);
        return date.toLocaleDateString();
    }

    //books published before the rich description still have simple text
    get hasHtmlDescription() {
        return this.book.description?.includes("<p>");
    }

    get authorBadges(): BadgeConfig[] {
        return this.book.authors.map(author => {
            return {
                text: author.name,
                type: "default",
                url: this._utilitiesService.getAuthorUrl(author),
                image: author.images.profile
            }
        });
    }

    get tropeBadges(): BadgeConfig[] {
        return this.book.tropes.map(trope => {
            return {
                text: trope.name,
                type: "default",
                url: this._utilitiesService.getTropeUrl(trope.slug)
            };
        })
    }

    get genreBadges(): BadgeConfig[] {
        return this.book.genres.map(genre => {
            return {
                text: genre.name,
                type: "default",
                url: this._utilitiesService.getGenreUrl(genre.slug)
            };
        })
    }

    get seriesBadge(): BadgeConfig[] {
        if (!this.series) return [];
        return [{
            text: this.series.title,
            type: "default",
            url: this._utilitiesService.getBookSeriesUrl(this.series),
            image: this.series.images.cover
        }]
    }
}
