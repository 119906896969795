<div class="row c-pb-20" *ngIf="header">
    <ng-container *ngIf="enableAnchor">
        <anchor-header [header]="header" [anchorSlug]="anchorSlug"></anchor-header>
    </ng-container>
    <ng-container *ngIf="!enableAnchor">
        <h2 class="mb-0 worldmaker-section-title">{{header}}</h2>
    </ng-container>
</div>
<div class="row">
    <div class="col-12 d-flex flex-column">
        <ng-container>
            <div class="discussion" *ngIf="discussion">
                <ng-container>
                    <reply-input [nested]="false" [user]="user" [discussion]="discussion" (reply)="topLevelComment($event)" [posting]="posting" [ngClass]="{'d-none': !showCommentInput}"></reply-input>
                </ng-container>
                <div class="comment-section overflow-x-hidden" [ngClass]="{'c-pt-60': showCommentInput}">
                    <ng-container *ngIf="discussion && discussion.comments && discussion.comments.length > 0">
                        <comment 
                                id="comment-{{comment.id}}"
                                *ngFor="let comment of discussion.comments" 
                                [discussion]="discussion"
                                [user]="user"
                                [allowPin]="allowPin"
                                [newCommentId]="newCommentId"
                                [showReplies]="autoExpandReplies(comment)"
                                [comment]="comment"
                                (reply)="onReply($event)"
                                (remove)="onCommentRemove($event)"
                                (pinChange)="onPinChange($event)"
                                [posting]="postingInner"
                        >
                        </comment>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="!discussion">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>
