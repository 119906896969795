import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'slug',
    pure: true
})

export class SlugPipe implements PipeTransform {
    constructor(private readonly _utilitiesService: UtilitiesService) {}

    transform(value: string): string {
        return this._utilitiesService.convertToSlug(value); 
    }
}