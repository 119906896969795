<a [routerLink]="url" class="d-block w-100 h-100 position-relative card-wrapper cursor-pointer" style="--bg-img: url('{{src | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleHeight:165 | cloudinaryUrl}}')" (click)="cardClicked()">
    <div class="bg-image-wrapper position-absolute w-100 h-100 default-transition">
        <div class="w-100 h-100 bg-image default-transition" ></div>
    </div>
    <div class="w-100 h-100 position-absolute darker" ></div>

    <div class="w-100 h-100 position-absolute d-flex flex-column justify-content-center align-items-center" *ngIf="titlePos === 'center'">
        <h1 class="text-white text-center user-select-none title-text">{{title}}</h1>
    </div>
    <div class="w-100 h-100 position-absolute d-flex flex-column justify-content-end align-items-center" *ngIf="titlePos === 'bottom'">
        <h1 class="text-white text-center user-select-none title-text">{{title}}</h1>
    </div>
</a>
