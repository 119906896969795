<div class="d-flex stars-container">
    <div *ngFor="let star of [1,2,3,4,5]" class="star" (click)="onClick(star)" (mouseenter)="onMouseEnter(star)" (mouseleave)="onMouseLeave()">
        <svg *ngIf="star > active()" width="32" height="32" viewBox="0 0 32 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.6031 15.1438L27.6096 15.1427L23.3346 18.8315L23.105 19.0297L23.1747 19.3249L24.8884 26.5837L24.8888 26.5855C24.9603 26.8834 24.9419 27.1958 24.8359 27.4833C24.73 27.7708 24.5411 28.0204 24.2934 28.2006C24.0456 28.3809 23.7499 28.4836 23.4438 28.4959C23.138 28.5082 22.8354 28.4296 22.5741 28.2702C22.5739 28.27 22.5736 28.2698 22.5733 28.2697L16.2621 24.3855L16.0001 24.2242L15.7381 24.3854L9.42411 28.269C9.42382 28.2692 9.42353 28.2694 9.42324 28.2695C9.16204 28.4281 8.85982 28.5058 8.55454 28.4931C8.24892 28.4804 7.9539 28.3775 7.70664 28.1974C7.45937 28.0174 7.27091 27.7681 7.16499 27.4812C7.05907 27.1942 7.04042 26.8823 7.1114 26.5848L7.11157 26.5841L8.83157 19.3253L8.90158 19.0299L8.67169 18.8315L3.04699 13.978C2.81545 13.7779 2.64799 13.514 2.56553 13.2193C2.48307 12.9245 2.48927 12.6121 2.58335 12.3208C2.67743 12.0296 2.85522 11.7726 3.09452 11.5818C3.33338 11.3914 3.62295 11.2754 3.92719 11.2483C3.92776 11.2482 3.92832 11.2482 3.92888 11.2481L11.3003 10.6534L11.6053 10.6288L11.7221 10.346L14.5671 3.46099L14.5676 3.45988C14.6839 3.17639 14.882 2.9339 15.1365 2.76325C15.391 2.59259 15.6905 2.50146 15.9969 2.50146C16.3033 2.50146 16.6029 2.59259 16.8574 2.76325C17.1119 2.9339 17.3099 3.17639 17.4262 3.45988L17.4267 3.46092L20.2704 10.3459L20.3873 10.6288L20.6923 10.6534L28.0654 11.2483C28.0657 11.2483 28.066 11.2483 28.0663 11.2483C28.3714 11.2743 28.6622 11.3897 28.9021 11.58C29.1423 11.7705 29.3209 12.0277 29.4155 12.3193C29.5102 12.6108 29.5167 12.9239 29.4343 13.2191C29.3518 13.5144 29.1841 13.7787 28.952 13.9791L27.6031 15.1438Z"
                fill="#D9D9D9" stroke="#808080" />
        </svg>
        <svg *ngIf="star <= active()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.6031 15.1438L27.6096 15.1427L23.3346 18.8315L23.105 19.0297L23.1747 19.3249L24.8884 26.5837L24.8888 26.5855C24.9603 26.8834 24.9419 27.1958 24.8359 27.4833C24.73 27.7708 24.5411 28.0204 24.2934 28.2006C24.0456 28.3809 23.7499 28.4836 23.4438 28.4959C23.138 28.5082 22.8354 28.4296 22.5741 28.2702C22.5739 28.27 22.5736 28.2698 22.5733 28.2697L16.2621 24.3855L16.0001 24.2242L15.7381 24.3854L9.42411 28.269C9.42382 28.2692 9.42353 28.2694 9.42324 28.2695C9.16204 28.4281 8.85982 28.5058 8.55454 28.4931C8.24892 28.4804 7.9539 28.3775 7.70664 28.1974C7.45937 28.0174 7.27091 27.7681 7.16499 27.4812C7.05907 27.1942 7.04042 26.8823 7.1114 26.5848L7.11157 26.5841L8.83157 19.3253L8.90158 19.0299L8.67169 18.8315L3.04699 13.978C2.81545 13.7779 2.64799 13.514 2.56553 13.2193C2.48307 12.9245 2.48927 12.6121 2.58335 12.3208C2.67743 12.0296 2.85522 11.7726 3.09452 11.5818C3.33338 11.3914 3.62295 11.2754 3.92719 11.2483C3.92776 11.2482 3.92832 11.2482 3.92888 11.2481L11.3003 10.6534L11.6053 10.6288L11.7221 10.346L14.5671 3.46099L14.5676 3.45988C14.6839 3.17639 14.882 2.9339 15.1365 2.76325C15.391 2.59259 15.6905 2.50146 15.9969 2.50146C16.3033 2.50146 16.6029 2.59259 16.8574 2.76325C17.1119 2.9339 17.3099 3.17639 17.4262 3.45988L17.4267 3.46092L20.2704 10.3459L20.3873 10.6288L20.6923 10.6534L28.0654 11.2483C28.0657 11.2483 28.066 11.2483 28.0663 11.2483C28.3714 11.2743 28.6622 11.3897 28.9021 11.58C29.1423 11.7705 29.3209 12.0277 29.4155 12.3193C29.5102 12.6108 29.5167 12.9239 29.4343 13.2191C29.3518 13.5144 29.1841 13.7787 28.952 13.9791L27.6031 15.1438Z"
                fill="#edb56b" stroke="#edb56b" />
        </svg>

    </div>
</div>