<div class="worldmaker-section-container">
    <ng-template landing_page_template_slot_header></ng-template>
    <div class="container-xxl worldmaker-section-container">
        <ng-template landing_page_template_slot_section1></ng-template>
        <ng-template landing_page_template_slot_section2></ng-template>
        <ng-template landing_page_template_slot_section3></ng-template>
        <ng-template landing_page_template_slot_section4></ng-template>
        <ng-template landing_page_template_slot_section5></ng-template>
        <ng-template landing_page_template_slot_section6></ng-template>
        @if (showFreeBookFAQ) {
            <worldmaker-component-free-book-faq></worldmaker-component-free-book-faq>
        }
    </div>
</div>
