import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'change-username',
    templateUrl: './change-username.component.html',
    styleUrls: ['./change-username.component.scss']
})
export class ChangeUsernameComponent {
    
    private _username: string = "";

    @Input()
    public get username(): string {
        return this._username;
    }

    public set username(value: string) {
        this._username = value;
        this.usernameFormControl.setValue(value);
    }

    @Input()
    save?: (username: string) => Promise<void>;

    form = new FormGroup({
        username: new FormControl(this.username, [Validators.required, Validators.minLength(5), Validators.maxLength(25)])
    });

    loading = false;

    get usernameFormControl(): FormControl {
        return this.form.get('username') as FormControl;
    }

    get usernameErrors() {
        const errors = this.usernameFormControl?.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'minlength': return "Username must be at least 5 characters long";
                case "maxlength": return "Username must be at most 25 characters long"
                default: return "Unknown error";
            }
        });
    }

    async continue() {
        if (this.save && this.form.valid) {
            this.loading = true;
            await this.save(this.usernameFormControl.value);
            this.loading = false;
        }
    }
}
