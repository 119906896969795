<div *ngIf="data.world">
    <div
        class="breadcrumb-container"
        style="--bg-color: {{ data.breadcrumbColor || (data.world.banner && (data.world.banner | cloudinaryPublicId | cloudinaryReadableColor : 130 | async)) || '#DDD' }}">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <breadcrumbs linkColor="#222831" currentColor="#000000" hoverColor="#000000" fontWeight="400"></breadcrumbs>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.world.banner">
        <img [src]="data.world.banner | cloudinaryPublicId | cloudinaryQuality : 100 | cloudinaryUrl" [alt]="data.world.title" class="banner-img">
    </div>
    <div class="container-xxl c-pt-30">
        <div class="row">
            <div class="col-md-7 col-12">
                <h1 class="world-header c-mb-20">{{ data.world.title }}</h1>
                <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="!followsWorld" (click)="followWorld()">
                    <feather-icon icon="user-plus"></feather-icon>
                    Follow World
                </button>
                <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="followsWorld" (click)="unfollowWorld()">
                    <feather-icon icon="user-check"></feather-icon>
                    Following
                </button>
                <div #description class="world-description c-pt-60 c-pb-0"></div>
            </div>
            <div class="col-xxl-3 offset-xxl-2 col-md-5 offset-0 col-12 c-pt-40 c-pt-md-0">
                <div class="d-flex flex-column">
                    <ng-container *ngIf="series">
                        <h5 class="badge-section-title c-mb-10">Book Series</h5>
                        <div class="c-pb-20 c-pt-0 c-px-0">
                            <badge-list [data]="seriesBadge"></badge-list>
                        </div>
                    </ng-container>
                    <h5 class="badge-section-title c-mb-10" >{{authors.length > 1 ? "Authors": "Author"}}</h5>
                    <div class="c-pb-20 c-pt-0 c-px-0">
                        <badge-list [data]="authorBadges"></badge-list>
                    </div>
                    <h5 class="badge-section-title c-mb-10">Genres</h5>
                    <div class="c-pb-20 c-pt-0 c-px-0">
                        <badge-list [data]="genreBadges"></badge-list>
                    </div>
                    <h5 class="badge-section-title c-mb-10">Tropes</h5>
                    <div class="c-pt-0 c-px-0">
                        <badge-list [data]="tropeBadges" [expandable]="true" [showInitial]="6"></badge-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <join-crew-form
            [author]="authors[0]"
            [afterJoinCb]="afterJoinCb"
            [settings]="crewFormSettings"
            [eventMetadata]="joinCrewEventMetadata"
        ></join-crew-form>
    </div>
</ng-template>
