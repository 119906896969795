import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
//@ts-ignore
import * as tikTokEmbed from './tiktok-embed.js';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

type Media =
    | {
          channel: 'tiktok';
          data: {
              tags: string[];
              user: string;
              stats: {
                  views: number;
                  comments: number;
                  likes: number;
                  shares: number;
              };
              thumb: string;
              videoId: string;
              category: string;
              description: string;
              userPicture: string;
          };
      }
    | {
          channel: 'image';
          data: { url: string; title: string; description: string, category: string };
      }
    | {
          channel: 'manual_image';
          data: { url: string; title: string; description: string, category: string };
      }
    | {
        channel: 'manual_video';
        data: { url: string; title: string; description: string, category: string };
    };

@Component({
    selector: 'worldmaker-component-media-grid',
    templateUrl: './media-grid.component.html',
    styleUrls: ['./media-grid.component.scss'],
})
export class MediaGrid {
    @Input() data!: {
        media: Media[];
        header: string;
        enableAnchor: boolean;
        anchorSlug?: string;
    };

    activeTab!: string;

    activeMedia!: Media;

    mediaGroups: {[category: string]: {hidden: Media[], shown: Media[]}} = {};
    private readonly _loadLimit = 8;

    @ViewChild('tiktokModal') tiktokModal!: TemplateRef<any>;
    @ViewChild('imageModal') imageModal!: TemplateRef<any>;
    @ViewChild('videoModal') videoModal!: TemplateRef<any>;

    constructor(
        private modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private location: Location,
        private readonly _analyticsService: AnalyticsService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {

    }

    get mediaCategories() {
        if (!this.data || !this.data.media) return [];
        const categories = new Set<string>();
        categories.add("All");
        for (const m of this.data.media) {
            categories.add(m.data.category);
        }

        return Array.from(categories);
    }

    async ngOnInit() {
        if (!this.data || !this.data.media) return;
        this.activeTab = this.mediaCategories[0];
        //run this once on init to preload its styles etc
        if (this._platformService.isBrowser()) {
            tikTokEmbed.runTikTok();
        }
        for (const category of this.mediaCategories) {
            const media = this._getMediaForCategory(category);
            this.mediaGroups[category] = {
                shown: media.slice(0, this._loadLimit),
                hidden: media.slice(this._loadLimit, media.length)
            };
        }
    }

    async ngAfterViewInit() {
        //auto-play passed media if any
        const state = this.location.getState() as {media: Media};
        if (this._platformService.isBrowser() && state.media) {
            if (state.media.channel === "tiktok") {
                await this.tiktokModalOpen(this.tiktokModal, state.media);
            } else if (state.media.channel === "image" || state.media.channel === "manual_image") {
                await this.imageModalOpen(this.imageModal, state.media);
            } else if (state.media.channel === "manual_video") {
                await this.videoModalOpen(this.videoModal, state.media);
            }
        }
    }

    loadMore(category: string) {
        const toMove = this.mediaGroups[category].hidden.splice(0, this._loadLimit);
        this.mediaGroups[category].shown.push(...toMove);
    }

    onTabClick(tab: string) {
        this.activeTab = tab;
    }

    channelDisplayName(channel: string) {
        //customize display names here
        if (channel === 'tiktok') {
            return 'TikTok';
        }
        return channel;
    }

    async tiktokModalOpen(modal: TemplateRef<any>, media: Media) {
        if (media.channel !== 'tiktok') return;
        this.activeMedia = media;
        this.trackViewMediaEvent();
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this.modalService.open(modal, {
                centered: true,
                windowClass: 'tikTokModal',
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                beforeDismiss: () => {
                    that._chatWidgetService.show();
                    return true;
                }
            }).shown
        );
        //re-run embed script
        tikTokEmbed.runTikTok();
    }

    async imageModalOpen(modal: TemplateRef<any>, media: Media) {
        if (media.channel !== 'image' && media.channel !== "manual_image") return;
        this.activeMedia = media;
        this.trackViewMediaEvent();
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this.modalService.open(modal, {
                centered: true,
                size: "lg",
                modalDialogClass: "media-modal-dialog",
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                beforeDismiss: () => {
                    that._chatWidgetService.show();
                    return true;
                }
            }).shown
        );
    }

    async videoModalOpen(modal: TemplateRef<any>, media: Media) {
        if (media.channel !== 'manual_video') return;
        this.activeMedia = media;
        this.trackViewMediaEvent();
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this.modalService.open(modal, {
                centered: true,
                size: "lg",
                modalDialogClass: "media-modal-dialog",
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                beforeDismiss: () => {
                    that._chatWidgetService.show();
                    return true;
                }
            }).shown
        );
    }

    trackViewMediaEvent() {
        if (this.activeMedia.channel === "tiktok") {
            this._analyticsService.track({
                event: "view_media",
                params: {
                    category: this.activeMedia.data.category,
                    media: this.activeMedia.data.videoId,
                    mediaType: "tiktok"
                }
            });
        } else {
            this._analyticsService.track({
                event: "view_media",
                params: {
                    category: this.activeMedia.data.category,
                    media: this.activeMedia.data.title,
                    mediaType: this.activeMedia.channel === "image" || this.activeMedia.channel === "manual_image" ? "image" : "video"
                }
            });
        }
    }

    private _getMediaForCategory(category: string) {
        if (category === "All") {
            return this.data.media;
        }
        const grouped = [];
        for (const m of this.data.media) {
            if (m.data.category === category) {
                grouped.push(m);
            }
        }

        return grouped;
    }
}
