<ng-container *ngIf="author">
    <author-bio [author]="author"></author-bio>
</ng-container>
<ng-container *ngIf="seriesDataSource">
    <div class="container-xxl">
        <div class="row c-pt-40 c-pb-20">
            <div class="col-12">
                <h2 class="c-mb-0 worldmaker-section-title">Book Series by {{ author.name }}</h2>
            </div>
        </div>
        <content-grid [dataSource]="seriesDataSource" [columnLayout]="layoutConfig"></content-grid>
    </div>
</ng-container>