
// Angular
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { CloudinaryModule } from "@cloudinary/ng";

// Auth0
import { customAuthInterceptor } from './services/custom-auth.interceptor';
import { TitleStrategy } from '@angular/router';
import { CustomTitleStrategy } from './services/custom-title-strategy.service';

// must find a better way for this
const authenticatedApiUrls = [
    `${environment.baseUrl}/api/books/free`,
    `${environment.baseUrl}/public/books/free`,
    `${environment.baseUrl}/api/followers/books`,
    `${environment.baseUrl}/api/followers/authors`
];

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        AppModule,
        CloudinaryModule
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: environment.baseHref
        },
        {
            provide: TitleStrategy,
            useClass: CustomTitleStrategy
        },
        provideHttpClient(
            withFetch(),
            withInterceptors([customAuthInterceptor])
        )
    ],
    bootstrap: [AppComponent]
})
export class AppClientModule { }
