<ng-container *ngIf="book">
    <div class="substract-header cover-container position-relative w-100 overflow-hidden" [style]="'--cover-image:url(' + bgCover + ')'" *ngIf="book">
        <div class="container-xxl">
            <div class="row content position-relative">
                <div class="col-md-4 p-2 px-5 pt-4 p-md-5 text-md-start">
                    <book-cover [book]="book" [height]="bookHeight"></book-cover>
                </div>
                <div class="col-md-8 px-5 py-1 px-md-5">
                    <div class="d-flex flex-column justify-content-center h-100 pb-4 pb-md-0">
                        <!-- <breadcrumbs></breadcrumbs> -->
                        <span class="coming-soon-badge mb-2">
                            <img
                                [src]="book.authors[0].images.profile"
                                alt="Author picture"
                                class="circular-pfp me-1 mb-1"
                            />
                            Coming soon from&nbsp;<span class="fw-bold">{{ book.mainAuthorName }}</span>
                        </span>
                        <h1 class="display-4 text-white fw-bold d-block text-md-start">
                            {{ book.title }}
                        </h1>
                        <div class="d-flex">
                            <p class="text-white">
                                {{ data.headerText }}
                            </p>
                        </div>
                        <div class="row pt-2" *ngIf="!firstStepCompleted">
                            <div class="col-12 col-md-7">
                                <input type="email" required class="form-control rounded-input email-input mb-3 mb-md-0" placeholder="E-mail" [(ngModel)]="email">
                            </div>
                            <div class="col-12 col-md-5">
                                <button type="button"
                                    (click)="readItFirst()"
                                    class="btn btn-primary btn-block text-nowrap rounded-input w-100">
                                    {{ data.ctaText }}
                                </button>
                            </div>
                        </div>
                        <div class="row pt-2" *ngIf="firstStepCompleted">
                            <div class="alert alert-opaque text-start" role="alert">
                                <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                                <span class="fw-bold">You will get notified first!</span><br><br>
                                <p class="mb-0">We’ll let you know via email when the collection will be available to purchase.</p>
                                <p class="mb-0" *ngIf="firstStepCompleted && !flowCompleted">Join {{authorFirstNameWithS}} reader crew for exclusive content & more updates</p>
                                <button type="button"
                                    *ngIf="firstStepCompleted && !flowCompleted"
                                    (click)="openModalAgain()"
                                    class="btn btn-block btn-primary text-nowrap rounded-input mt-3">
                                    Join {{authorFirstNameWithS}} Crew
                                </button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card sticky-container container-xxl" *ngIf="scrolled && !firstStepCompleted">
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <!-- <div class="d-none d-sm-block">
                    <img class="book-cover__image" [src]="book.images.cover | cloudinaryScaleHeight : 50 | cloudinaryQuality : 70 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                </div> -->
                <div class="d-none d-sm-flex justify-content-between">
                    <div class="pe-3 d-flex flex-column justify-content-center">
                        <img class="book-cover__image" [src]="book.images.cover | cloudinaryScaleHeight : 50 | cloudinaryQuality : 70 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <h6 class="book-title">{{book.title}}</h6>
                        <span class="coming-soon-badge text-reset">
                            <img
                                [src]="book.authors[0].images.profile"
                                alt="Author picture"
                                class="circular-pfp me-1"
                            />
                            Coming soon from&nbsp;<span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-block d-sm-none pb-3 text-center">
                        <h6 class="book-title">{{book.title}}</h6>
                        <span class="coming-soon-badge text-reset">
                            <img
                                [src]="book.authors[0].images.profile"
                                alt="Author picture"
                                class="circular-pfp me-1"
                            />
                            Coming soon from&nbsp;<span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                    <div class="col-12 col-md-7">
                        <input type="email" required class="form-control rounded-input email-input with-border mb-3 mb-md-0" placeholder="E-mail" [(ngModel)]="email">
                    </div>
                    <div class="col-12 col-md-5">
                        <button type="button"
                            (click)="readItFirst()"
                            class="btn btn-primary btn-block text-nowrap rounded-input w-100">
                            {{ data.ctaText }}
                        </button>
                    </div>
                </div>
                <!-- <div style="width: 1px;"></div> -->
            </div>
        </div>
    </div>
</ng-container>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <div *ngIf="book" class="join-modal-container text-center">
            <div class="row">
                <div class="col-12">
                    <h4 class="mb-0">
                        <img
                            [src]="book.authors[0].images.profile"
                            alt="Author picture"
                            class="circular-pfp me-1"
                        />
                        {{ book.mainAuthorName }}
                    </h4>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-12">
                    <div class="alert alert-success text-start mb-0" role="alert">
                        <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                        <span class="fw-bold">You will get notified first!</span><br><br>
                        <p class="mb-0">You will receive an email when the collection will be available to purchase.</p>
                    </div>                      
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-12">
                    <hr class="horizontal-break ms-auto me-auto">
                </div>
            </div>
            <div class="row pt-2 d-flex justify-content-center">
                <img
                    [src]="crewImage | cloudinaryScaleHeight : 250 | cloudinaryQuality : 85 | cloudinaryUrl"
                    style="width: 250px;"
                    alt="">
            </div>
            <div class="row pt-4">
                <div class="col-12">
                    <h5 class="mb-0">Subscribe to {{authorFirstNameWithS}} Book Crew for <span class="fw-bold">more exclusive benefits</span></h5>
                </div>
            </div>
            <div class="row text-start pt-4">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="book-open" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Get invited for Advanced Reader Copy</h6>
                        <small class="text-muted">Read {{authorFirstNameWithS}} books for free, including this collection, before their release.</small>
                    </div>
                </div>
            </div>
            <div class="row text-start pt-3">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="message-circle" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Open discussions with {{authorFirstName}}</h6>
                        <small class="text-muted">Join group or personal discussions with the author.</small>
                    </div>
                </div>
            </div>
            <!-- <div class="row text-start pt-3">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="film" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Dive into exclusive content from the community</h6>
                        <small class="text-muted">From Fanart, videos, to quizzes and fanfics.</small>
                    </div>
                </div>
            </div>
            <div class="row text-start pt-3">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="globe" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Discover similar up-and-coming, paid or free books</h6>
                        <small class="text-muted">Get recommendation from {{authorFirstName}} on what to read next.</small>
                    </div>
                </div>
            </div> -->
            <div class="row pt-4">
                <div class="col-12 ps-0 pe-0 ps-md-4 pe-md-4">
                    <input type="email" required class="form-control rounded-input email-input with-border" placeholder="E-mail" [(ngModel)]="email">
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-12 ps-0 pe-0 ps-md-4 pe-md-4">
                    <button type="button"
                        (click)="joinCrew()"
                        class="btn btn-dark btn-block text-nowrap rounded-input w-100">
                        Subscribe for Free
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
