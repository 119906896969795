<div *ngIf="author">
    <div class="breadcrumb-container" style="--bg-color: #AEBCC2">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <breadcrumbs linkColor="#222831" currentColor="#000000" hoverColor="#000000" fontWeight="400"></breadcrumbs>
                </div>
            </div>
        </div>
    </div>
    <div class="section-container c-pt-12 c-py-md-20">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12 col-xxl-2 col-xl-2 col-md-3 d-flex justify-content-center justify-content-md-start c-pb-10 c-pb-md-0">
                    <img [src]="author.images.profile" [alt]="author.name" class="bubble-image">
                </div>
                <div class="col-12 col-md-5">
                    <div class="c-pb-20 d-flex justify-content-center justify-content-md-start">
                        <h1 class="author-name-header">
                            {{ author.name }}
                        </h1>
                    </div>
                    <div class="book-header-cta-controls c-mb-20">
                        <div class="button-container">
                            <a class="btn btn-round btn-primary btn-with-icon" [routerLink]="'books'">
                                <feather-icon icon="book"></feather-icon>
                                Books by {{author.name}}
                            </a>
                        </div>
                        <div class="button-container">
                            <button class="btn btn-round btn-dark btn-with-icon" *ngIf="!(followsAuthor$ | async)" (click)="followAuthor()">
                                <feather-icon icon="user-plus"></feather-icon>
                                Follow Author
                            </button>
                            <button class="btn btn-round btn-dark btn-with-icon" *ngIf="followsAuthor$ | async" (click)="unfollowAuthor()">
                                <feather-icon icon="user-check"></feather-icon>
                                Following
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3 offset-xxl-2 offset-0 col-md-3 col-12 c-pt-md-0" *ngIf="optionalAuthorProperties.length > 0">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-column c-pb-12" *ngFor="let prop of optionalAuthorProperties">
                            <span class="optional-property-label">
                                {{ prop.label }}
                            </span>
                            <span class="optional-property-value">
                                {{ prop.value }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-xxl c-pt-40">
        <div class="row">
            <div class="col-md-7 col-12">
                <h2 class="about-section-title c-mb-20">About</h2>
                <div #authorBioContainer class="author-bio c-mb-0"></div>
            </div>
            <div class="col-xxl-3 offset-xxl-2 col-md-5 offset-0 col-12 c-pt-40 c-pt-md-0">
                <div class="d-flex flex-column">
                    <ng-container *ngIf="(authorGenres$ | async) as genres">
                        <ng-container *ngIf="genres.length > 0">
                            <h5 class="badge-section-title c-mb-10">Genres</h5>
                            <div class="c-pb-20 c-pt-0 c-px-0">
                                <badge-list [data]="genres"></badge-list>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(authorTropes$ | async) as tropes">
                        <ng-container *ngIf="tropes.length > 0">
                            <h5 class="badge-section-title c-mb-10">Tropes</h5>
                            <div class="c-pb-20 c-pt-0 c-px-0">
                                <badge-list [data]="tropes" [expandable]="true" [showInitial]="6"></badge-list>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <join-crew-form
            [author]="author"
            [afterJoinCb]="afterJoinCb"
            [settings]="crewFormSettings"
            [eventMetadata]="joinCrewEventMetadata">
        ></join-crew-form>
    </div>
</ng-template>