import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'generic-user-icon',
  templateUrl: './generic-user-icon.component.html',
  styleUrls: ['./generic-user-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericUserIconComponent {

}
