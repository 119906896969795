import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryVideo } from '@cloudinary/url-gen';

@Pipe({
    name: 'cloudinaryAnimatedLoop',
    pure: true
})

export class cloudinaryAnimatedLoop implements PipeTransform {
    transform(value: CloudinaryVideo | string): string {
        let url;
        if (typeof value === "string") {
            url = value.replace("/upload/", "/upload/e_loop/q_auto:good,fl_lossy,fl_animated,fl_awebp/");
        } else {
            url = value.toURL().replace("/upload/", "/upload/e_loop/q_auto:good,fl_lossy,fl_animated,fl_awebp/");
        }
        const parts = url.split(".");
        parts.pop();
        parts.push("webp");
        return parts.join(".");
    }
}
