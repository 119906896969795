import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { ɵgetDOM } from '@angular/platform-browser';

export enum SchemaMarkupType {
    WebSite = "WebSite",
    Organization = "Organization",
    BreadcrumbList = "BreadcrumbList",
    Article = "Article",
    ItemList = "ItemList",
    CollectionPage = "CollectionPage",
    Book = "Book",
    BookSeries = "BookSeries",
    BlogPosting = "BlogPosting",
    AboutPage = "AboutPage",
    ContactPage = "ContactPage",
    WebPage = "WebPage",
    Comment = "Comment",
}

export interface WebSiteSchema {
    name: string;
    url: string;
}

export interface OrganizationSchema {
    name: string;
    url: string;
    logo: string;
    description: string;
    address: AddressSchema;
    contactPoint: ContactPointSchema;
    sameAs: string[];
}

export interface PersonSchema {
    name?: string;
    url?: string;
    image?: string;
    jobTitle?: string;
    knowsAbout?: string;
    sameAs?: string[];
}
  
export interface CommentSchema {
    "@id": string;
    author: PersonSchema;
    datePublished: string;
    text: string;
}

export interface ListItemSchema {
    "@type": string;
    position: number;
    name: string;
    item: string;
}

export class BreadcrumbListSchema {
    items: ListItemSchema[] = [];

    constructor(items: { name: string, item: string }[]) {
        this.items = items.map((item, index) => {
            return {
                "@type": "ListItem",
                position: index + 1,
                ...item
            }
        })
    }
}

export interface ArticleSchema {
    name: string;
    headline: string;
    image: string;
    datePublished: string;
    dateModified: string;
    author: string;
}

export interface ItemListSchema {
    items: object[];
}

export interface CollectionPageSchema {
    name: string;
    url: string;
    description: string;
}

export interface BookSchema {
    name: string;
    author: string;
    isbn: string;
    image: string;
    numberOfPages: number;
    publisher: string;
    datePublished: Date;
    url: string;
    description: string;
    sameAs: string[];
}

export interface BookSeriesSchema {
    name: string;
    author: string;
    numberOfBooks: number;
}

export interface BlogPostingSchema {
    name: string;
    headline: string;
    image: string;
    datePublished: string;
    dateModified: string;
    author: string;
}

export interface AboutPageSchema {
    name: string;
    url: string;
    description: string;
}

export interface ContactPageSchema {
    name: string;
    url: string;
    description: string;
}

export interface WebPageSchema {
    name: string;
    url: string;
    description: string;
}

export interface AddressSchema {
    streetAddress?: string;
    addressLocality?: string;
    addressRegion?: string;
    postalCode?: string;
    addressCountry?: string;
}

export interface ContactPointSchema {
    "@type"?: string;
    telephone?: string;
    contactType?: string;
    email?: string;
}

@Injectable({
    providedIn: 'root'
})
export class SchemaMarkupService {

    constructor() { }

    webSite({ name, url }: WebSiteSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.WebSite,
            "name": name,
            "url": url
        };
    }

    organization({ name, url, logo, description, address, contactPoint, sameAs }: OrganizationSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.Organization,
            "name": name,
            "url": url,
            "logo": logo,
            "description": this.formatRichDescription(description),
            "address": address,
            "contactPoint": contactPoint,
            "sameAs": sameAs
        };
    }

    breadcrumbList({ items }: BreadcrumbListSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.BreadcrumbList,
            "itemListElement": items
        };
    }

    person(personSchema: PersonSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": "Person",
            ...personSchema
        }
    }

    article({ name, headline, image, datePublished, dateModified, author }: ArticleSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.Article,
            "name": name,
            "headline": headline,
            "image": image,
            "datePublished": datePublished,
            "dateModified": dateModified,
            "author": author
        };
    }

    itemList({ items }: ItemListSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.ItemList,
            "itemListElement": items
        };
    }

    collectionPage({ name, url, description }: CollectionPageSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.CollectionPage,
            "name": name,
            "url": url,
            "description": this.formatRichDescription(description)
        };
    }

    book({
        name,
        author,
        isbn,
        numberOfPages,
        publisher,
        datePublished,
        description,
        image,
        url
    }: BookSchema): object {
        return {
            "@context": "https://schema.org",
            "@type": "DataFeed",
            "dataFeedElement": [
                {
                    "@context": "https://schema.org",
                    "@type": "Book",
                    "@id": url,
                    "url": url,
                    "name": name,
                    "image": image,
                    "author": {
                        "@type": "Person",
                        "name": author
                    },
                    "isbn": isbn,
                    "numberOfPages": numberOfPages || undefined,
                    "publisher": publisher ? {
                        "@type": "Organization",
                        "name": publisher
                    } : undefined,
                    "datePublished": datePublished ? dayjs(datePublished).format("YYYY-MM-DD") : undefined,
                    "description": this.formatRichDescription(description)
                }
            ]
        };
    }
    

    bookSeries({ name, author, numberOfBooks }: BookSeriesSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.BookSeries,
            "name": name,
            "author": author,
            "numberOfBooks": numberOfBooks
        };
    }

    blogPosting({ name, headline, image, datePublished, dateModified, author }: BlogPostingSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.BlogPosting,
            "name": name,
            "headline": headline,
            "image": image,
            "datePublished": datePublished,
            "dateModified": dateModified,
            "author": author
        };
    }

    aboutPage({ name, url, description }: AboutPageSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.AboutPage,
            "name": name,
            "url": url,
            "description": this.formatRichDescription(description)
        };
    }

    contactPage({ name, url, description }: ContactPageSchema): object {
        return {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.ContactPage,
            "name": name,
            "url": url,
            "description": this.formatRichDescription(description)
        };
    }

    webPage({ name, url, description }: WebPageSchema, comments: CommentSchema[] | undefined = undefined): object {
        let result: any = {
            "@context": "http://schema.org",
            "@type": SchemaMarkupType.WebPage,
            "name": name,
            "url": url,
            "description": this.formatRichDescription(description)
        };
        if (comments && comments.length > 0) {
            result.comment = comments.map(comment => {
                return {
                    "@type": SchemaMarkupType.Comment,
                    author: {
                        "@type": "Person",
                        ...comment.author
                    },
                    datePublished: comment.datePublished,
                    text: comment.text
                }
            });
        }
        return result;
    }

    private formatRichDescription(description: string): string {
        description = description || "";
        let textDescription: string;
        if (description.includes("<p")) {
            const dom = ɵgetDOM();
            const div = dom.createElement("div");
            div.innerHTML = description;
            let text = "";
            for (const el of Array.from(div.children)) {
                //@ts-ignore
                text += (el.innerText + " ");
            }
            textDescription = text.trim();
        } else {
            textDescription = description;
        }
        textDescription = textDescription.replace(/\s\s+/g, ' ');

        return textDescription;
    }
}
