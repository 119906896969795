<book-header
    [book]="data.book"
    [headerText]="data.headerText"
    [showAmazonCta]="data.showAmazonCta"
    [amazonCtaText]="data.amazonCtaText"
    [enableStickyFooter]="data.enableStickyFooter"
    [enableRating]="data.showRating"
    [showSpicyMeter]="data.showSpicyMeter"
    [altCoverIndex]="data.altCoverIndex"
    [showHeader]="data.showHeader"
></book-header>
