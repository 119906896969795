import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Comment } from "src/app/models/comment.model";

@Injectable({providedIn: "root"})
export class DiscussionShareService {
    newComment$: BehaviorSubject<Comment | null> = new BehaviorSubject<Comment | null>(null);

    addComment(comment: Comment) {
        this.newComment$.next(comment);
    }
}