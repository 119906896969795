import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[book_page_template_slot_header]',
})
export class BookPageTemplateSlotHeader {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[book_page_template_slot_section1]',
})
export class BookPageTemplateSlotSection1 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[book_page_template_slot_section2]',
})
export class BookPageTemplateSlotSection2 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[book_page_template_slot_section3]',
})
export class BookPageTemplateSlotSection3 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[book_page_template_slot_section4]',
})
export class BookPageTemplateSlotSection4 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[book_page_template_slot_section5]',
})
export class BookPageTemplateSlotSection5 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[book_page_template_slot_section6]',
})
export class BookPageTemplateSlotSection6 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}