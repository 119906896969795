<div class="row pb-2 mt-5">
    <h2>Featured Worlds</h2>
</div>
<div class="row">
    <div class="col-12 col-md-6 mb-3" *ngIf="data.world1_title && data.world1_bgImage && data.world1_worldPage">
        <title-card [src]="data.world1_bgImage.data.url" [title]="data.world1_title" [url]="data.world1_worldPage.path"></title-card>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="data.world2_title && data.world2_bgImage && data.world2_worldPage">
        <title-card [src]="data.world2_bgImage.data.url" [title]="data.world2_title" [url]="data.world2_worldPage.path"></title-card>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="data.world3_title && data.world3_bgImage && data.world3_worldPage">
        <title-card [src]="data.world3_bgImage.data.url" [title]="data.world3_title" [url]="data.world3_worldPage.path"></title-card>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="data.world4_title && data.world4_bgImage && data.world4_worldPage">
        <title-card [src]="data.world4_bgImage.data.url" [title]="data.world4_title" [url]="data.world4_worldPage.path"></title-card>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="data.world5_title && data.world5_bgImage && data.world5_worldPage">
        <title-card [src]="data.world5_bgImage.data.url" [title]="data.world5_title" [url]="data.world5_worldPage.path"></title-card>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="data.world6_title && data.world6_bgImage && data.world6_worldPage">
        <title-card [src]="data.world6_bgImage.data.url" [title]="data.world6_title" [url]="data.world6_worldPage.path"></title-card>
    </div>
</div>