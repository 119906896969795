<ng-container *ngIf="book">
    <book-info
        [book]="book"
        [series]="bookSeries"
        [linksDisabled]="data.linksDisabled"
        [readCTA]="data.readCTA"
        [readable]="readable"
        [ctaBackground]="data.ctaBackground"
        [showPageCount]="data.showPageCount"
    ></book-info>
    <hr class="mb-0 c-mt-40 d-none d-md-block full-width-hr" *ngIf="data.showHR">
</ng-container>
