import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map, filter, distinctUntilChanged, shareReplay, combineLatest, switchMap, tap, catchError, of } from 'rxjs';
import { BookSeoService } from 'src/app/services/book-seo.service';
import { PlatformService } from 'src/app/services/platform.service';
import { WorldMakerService } from 'src/app/modules/worldmaker/services/worldmaker.service';

@Component({
    selector: 'book-page',
    templateUrl: './book-page.component.html',
    styleUrls: ['./book-page.component.scss'],
})
export class BookPageComponent {
    bookSlug$: Observable<string> = this._route.params.pipe(
        map((params) => params['book-slug']),
        filter((slug) => slug),
        distinctUntilChanged(),
        shareReplay(1)
    )

    authorSlug$: Observable<string> = this._route.params.pipe(
        map((params) => params['author-slug']),
        filter((slug) => slug),
        distinctUntilChanged(),
        shareReplay(1)
    )

    worldMakerStatus$ = combineLatest([this.bookSlug$, this.authorSlug$]).pipe(
        switchMap(([bookSlug, authorSlug]) => {
            return this._worldMakerService.getWorldMakerPage(`/authors/${authorSlug}/books/${bookSlug}`);
        }),
        filter((status) => !!status),
        tap((status) => {
            if (!status.exists) {
                this._platformService.goTo404();
            } else {
                //find the book from one of the headers without re-querying findBook
                try {
                    const headerComponent = status.data.components.find((c: any) => {
                        return [
                            "smart_book_cover_header",
                            "subscribe_cover_header",
                            "prelaunch_cover_header_2",
                            "free_book_cover_header"
                        ].includes(c.name);
                    });
                    if (!headerComponent) return;
                    if (headerComponent.data?.book) {
                        this._bookSeoService.update(headerComponent.data.book);
                    }
                } catch(_) {}
            }
        }),
        catchError((err) => {
            console.error(err);
            this._platformService.goTo404();
            return of(null);
        }),
        shareReplay(1)
    )

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _bookSeoService: BookSeoService,
        private readonly _worldMakerService: WorldMakerService
    ) {

    }
}
