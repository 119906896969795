<ng-container *ngIf="state">
    <ng-container *ngIf="state === 'prelaunch'">
        <worldmaker-component-prelaunch-cover-header-2
            [data]="{
                book: this.data.book,
                joinCrewImage: this.data.joinCrewImage,
                headerText: this.data.prelaunch_headerText,
                showHeader: this.data.prelaunch_showHeader,
                ctaText: this.data.prelaunch_ctaText,
                alertText: this.data.prelaunch_alertText,
                enableStickyFooter: this.data.prelaunch_enableStickyFooter,
                showRating: this.data.showRating,
                showSpicyMeter: this.data.showSpicyMeter,
                altCoverIndex: this.data.altCoverIndex,
            }"
        ></worldmaker-component-prelaunch-cover-header-2>
    </ng-container>
    <ng-container *ngIf="state === 'free'">
        <worldmaker-component-free-book-cover-header
            [data]="{
                book: this.data.book,
                joinCrewImage: this.data.joinCrewImage,
                headerText: this.data.free_headerText,
                showHeader: this.data.free_showHeader,
                ctaText: this.data.free_ctaText,
                recommendedBooks: this.data.free_recommendedBooks,
                enableStickyFooter: this.data.free_enableStickyFooter,
                enableBreadcrumbs: this.data.free_enableBreadcrumbs,
                showRating: this.data.showRating,
                showSpicyMeter: this.data.showSpicyMeter,
                acValuePostfix: this.data.acValuePostfix,
                altCoverIndex: this.data.altCoverIndex,
                altReadableSlug:  this.data.altReadableSlug
            }"
        ></worldmaker-component-free-book-cover-header>
    </ng-container>
    <ng-container *ngIf="state === 'arc'">
        <worldmaker-component-arc-cover-header
            [joinedArc]="joinedArc"
            [arcSlots]="arcSlots"
            [data]="{
                book: this.data.book,
                joinCrewImage: this.data.joinCrewImage,
                headerText: this.data.free_headerText,
                showHeader: this.data.free_showHeader,
                ctaText: this.data.free_ctaText,
                recommendedBooks: this.data.free_recommendedBooks,
                enableStickyFooter: this.data.free_enableStickyFooter,
                enableBreadcrumbs: this.data.free_enableBreadcrumbs,
                showRating: this.data.showRating,
                showSpicyMeter: this.data.showSpicyMeter,
                acValuePostfix: this.data.acValuePostfix,
                altCoverIndex: this.data.altCoverIndex,
                altReadableSlug:  this.data.altReadableSlug
            }"
        ></worldmaker-component-arc-cover-header>
    </ng-container>
    <ng-container *ngIf="state === 'live'">
        <worldmaker-component-subscribe-cover-header
            [data]="{
                book: this.data.book,
                joinCrewImage: this.data.joinCrewImage,
                headerText: this.data.live_headerText,
                showHeader: this.data.live_showHeader,
                amazonCtaText: this.data.live_amazonCtaText,
                showAmazonCta: this.data.live_showAmazonCta,
                enableStickyFooter: this.data.live_enableStickyFooter,
                showRating: this.data.showRating,
                showSpicyMeter: this.data.showSpicyMeter,
                altCoverIndex: this.data.altCoverIndex
            }"
        ></worldmaker-component-subscribe-cover-header>
    </ng-container>
</ng-container>
