import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { Genre } from 'src/app/models/genre.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'genreRouterLink',
    pure: true
})

export class GenreRouterLinkPipe implements PipeTransform {

    constructor(private readonly _utilitiesService: UtilitiesService) {}

    transform(genre: Genre | string): string  {
        let slug = typeof genre === 'string' ? genre : genre.slug;
        return this._utilitiesService.getGenreUrl(slug);
    }
}
