import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { ɵgetDOM } from '@angular/platform-browser';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'anchor-header',
  templateUrl: './anchor-header.component.html',
  styleUrls: ['./anchor-header.component.scss'],
  animations: [
    trigger(
      'anchorAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ "opacity": 0 }),
            animate('0.5s ease-out', style({ "opacity": "1" }))
          ]
        ),
        // transition(
        //   ':leave', 
        //   [
        //     style({ "opacity": 0 }),
        //     animate('0.5s ease-in', style({ "opacity": 1 }))
        //   ]
        // )
      ]
    )
]
})
export class AnchorHeaderComponent implements OnInit {
    constructor(
        private readonly _router: Router,
        private readonly _platformService: PlatformService,
        private readonly _utilitiesService: UtilitiesService
    ) {}

    @Input() 
    header!: string;

    @Input() 
    anchorSlug?: string;

    anchor!: string;
    showIcon = false;

    ngOnInit(): void {
        const dom = ɵgetDOM().getDefaultDocument()
        let anchor: string;
        if (this.anchorSlug) {
            anchor = this.anchorSlug;
        } else {
            anchor = this._utilitiesService.convertToSlug(this.header);
        }
        //add a -{number} in case we have multiple with the same id
        const matches = Array.from(dom.querySelectorAll<HTMLDivElement>(".anchor")).filter(a => a.id === anchor);
        if (matches.length > 1) {
            anchor += `-${matches.length}`;
        }

        this.anchor = anchor;
    }

    // ngAfterViewInit(): void {
    //     //we only do it for initial navigations, subscribe to router events if you need it to run on client navigation
    //     if (this._platformService.isBrowser()) {
    //         if (this._router.url.endsWith(`#${this.anchor}`)) {
    //             const el = document.querySelector(`#${this.anchor}`);
    //             if (!el) return;
    //             //stop pending scroll restorations, then re-enable scroll and go to our anchor
    //             document.body.style.overflow = 'hidden';
    //             setTimeout(() => {
    //                 document.body.style.overflow = 'auto';
    //                 //@ts-ignore
    //                 el.scrollIntoView({behavior: "instant"});
    //             }, 1000);
    //         }
    //     }
    // }

    getAnchor() {
        history.replaceState({}, document.title, `#${this.anchor}`);
    }

    showAnchorIcon() {
        this.showIcon = true;
    }

    hideAnchorIcon() {
        this.showIcon = false;
    }
}
