import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transparentize'
})

export class TransparentizePipe implements PipeTransform {
    //allowing null here so we can chain them with cloudinaryPrimaryColor | async
    transform(hex: string | null, amount = 0.1): string {
        if (!hex) return "";
        const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i ,(m, r, g, b) => '#' + r + r + g + g + b + b)
                    .substring(1).match(/.{2}/g)!
                    .map(x => parseInt(x, 16));
        rgb.push(amount);
        return `rgba(${rgb.join()})`;
    }
}