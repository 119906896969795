import { Pipe, PipeTransform } from '@angular/core';
import { firstValueFrom} from 'rxjs';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Pipe({
    name: 'cloudinaryReadableColor',
    pure: true
})

export class CloudinaryReadableColorPipe implements PipeTransform {
    constructor(private _cloudinaryService: CloudinaryService) { }
    
    async transform(publicId: string, minContrast = 100): Promise<string>{
        if (!publicId) return "#fff";
        const colors = await firstValueFrom(this._cloudinaryService.getAllColors(publicId));
        for (const [color, _score] of colors) {
            if (this._getContrast(color) >= minContrast) {
                return color;
            }
        }
        return colors[0][0];
    }

    private _getContrast(hex: string) {
        const rgb = this._hex2Rgb(hex);
        return (Math.round(rgb.r * 299) + Math.round(rgb.g * 587) + Math.round(rgb.b * 114)) / 1000;
    }

    private _hex2Rgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : {r: 0, g: 0, b: 0};
    };
}