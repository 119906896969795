<div class="card readable-intro-card" *ngIf="readable">
    <div class="card-body c-p-0 c-p-md-15">
        <div class="readable-header d-flex c-pb-15">
            <img class="c-me-15" *ngIf="readable.book?.images?.cover" [src]="coverSrc | cloudinaryScaleHeight:175 | cloudinaryFormat | cloudinaryUrl" alt="Book Cover">
            <div class="d-flex flex-column flex-grow-1">
                <fomo-countdown class="c-mb-5" colorScheme="red" [to]="readable.book!.publishedAt!" [showCountDown]="showCountDown" size="small"></fomo-countdown>
                <a *ngIf="readable.book" class="book-title no-decoration c-mb-5" [routerLink]="bookUrl">{{readable.title}}</a>
                <a *ngIf="readable.author" class="text-muted no-decoration flex-grow-1" [routerLink]="readable.author | authorUrl">{{readable.author.name}}</a>
                <div class="button-container d-flex flex-column c-pt-10">
                    <readables-reader-popup-v2 [readable]="readable" [onStopReading]="onStopReading">
                        <button class="btn btn-cta btn-round btn-small btn-with-icon">
                            <feather-icon icon="book" width="14" height="14"></feather-icon>
                            Read now
                        </button>
                    </readables-reader-popup-v2>
                    <download-readable [readable]="readable" buttonStyle="small_cta"></download-readable>
                </div>
            </div>
        </div>
        <div>
            <ng-container *ngIf="!finished && !readingProgress && isNew">
                <span class="new-badge">New</span>
            </ng-container>
            <ng-container *ngIf="!finished && !readingProgress && !isNew">
                <!-- to keep all the cards at the same height -->
                <div class="dummy-placeholder"></div>
            </ng-container>
            <ng-container *ngIf="!finished && readingProgress >= 1 && readingProgress <= 95">
                <div class="d-flex align-items-center progress-container">
                    <feather-icon icon="book" width="14" height="14"></feather-icon>
                    <span class="c-ps-10 c-pe-30">Reading&nbsp;•&nbsp;{{readingProgress}}%</span>
                    <div class="progress w-100" #tooltipTarget>
                        <div class="progress-bar" role="progressbar" [style]="'width:'+readingProgress+'%'"></div>
                    </div>
                    <feather-icon icon="info" width="14" height="14" class="c-ps-15" [positionTarget]="tooltipTarget" ngbTooltip="We’re able to show your reading progress only when you read through our app."></feather-icon>
                </div>
            </ng-container>
            <ng-container *ngIf="!finished && readingProgress > 95">
                <button class="btn mark-finished-btn btn-round btn-small btn-with-icon" (click)="markFinished()">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.5C1 12.5041 4.24594 15.75 8.25 15.75C12.2541 15.75 15.5 12.5041 15.5 8.5C15.5 4.49594 12.2541 1.25 8.25 1.25C4.24594 1.25 1 4.49594 1 8.5Z" stroke="#121212" stroke-width="1.5"/>
                        <path d="M5.75 9L7.25 10.5L10.75 7" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Mark as finished
                </button>
            </ng-container>
            <ng-container *ngIf="finished">
                <div class="finished d-flex align-items-center">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="#F69CBD" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.5C1 12.5041 4.24594 15.75 8.25 15.75C12.2541 15.75 15.5 12.5041 15.5 8.5C15.5 4.49594 12.2541 1.25 8.25 1.25C4.24594 1.25 1 4.49594 1 8.5Z" stroke="#F69CBD" stroke-width="1.5"/>
                        <path d="M5.75 9L7.25 10.5L10.75 7" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="c-ps-10">Finished</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
