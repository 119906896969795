<ng-container *ngIf="data && data.media">
    <div class="row c-pb-20">
        <ng-container *ngIf="data.enableAnchor">
            <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
        </ng-container>
        <ng-container *ngIf="!data.enableAnchor">
            <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
        </ng-container>
    </div>
    <div class="row c-pb-20">
        <div class="col-md-12">
            <nav class="nav d-flex gap-2 align-items-center justify-content-start">
                <a
                    class="nav-link rounded-pill"
                    aria-current="page"
                    [ngClass]="{ active: activeTab === tab }"
                    (click)="onTabClick(tab)"
                    *ngFor="let tab of mediaCategories"
                    >{{ tab }}</a
                >
            </nav>
        </div>
    </div>
    <div class="row animated-collection vertical-collection">
        <div
            *ngFor="let media of mediaGroups[activeTab].shown"
            class="col-12 col-sm-6 col-md-4 col-lg-3"
        >
            <div class="card slight-hover" *ngIf="media.channel === 'tiktok'" (click)="tiktokModalOpen(tiktokModal, media)">
                <img
                    [src]="media.data.thumb"
                    class="card-img-top"
                    alt="TikTok video"
                    style="height: 256px;width: 100%;"
                />
                <div class="card-body static-size d-flex flex-column">
                    <h6 class="card-title">
                        <img
                            class="rounded-circle"
                            [src]="media.data.userPicture"
                            alt="TikTok user profile picture"
                            style="height: 25px"
                        />
                        {{ media.data.user }}
                    </h6>
                    <small class="card-subtitle my-2 text-secondary flex-grow-1">
                        {{ channelDisplayName(media.channel) }}
                    </small>
                    <p class="card-text text-truncate">
                        {{ media.data.description }}
                    </p>
                </div>
            </div>
            <div class="card slight-hover" *ngIf="media.channel === 'image' || media.channel === 'manual_image'" (click)="imageModalOpen(imageModal, media)">
                <img
                    [src]="media.data.url"
                    class="card-img-top"
                    [alt]="media.data.title"
                    style="height: 256px;width: 100%;"
                />
                <div class="card-body static-size d-flex flex-column">
                    <h6 class="card-title">
                        {{ media.data.title }}
                    </h6>
                    <small class="card-subtitle my-2 text-secondary flex-grow-1">
                        Image
                    </small>
                    <p class="card-text text-truncate">
                        {{ media.data.description }}
                    </p>
                </div>
            </div>
            <div class="card slight-hover" *ngIf="media.channel === 'manual_video'" (click)="videoModalOpen(videoModal, media)">
                <img
                    [src]="media.data.url | cloudinaryThumb"
                    class="card-img-top"
                    [alt]="media.data.title"
                    style="height: 256px;width: 100%;"
                />
                <div class="card-body static-size d-flex flex-column">
                    <h6 class="card-title">
                        {{ media.data.title }}
                    </h6>
                    <small class="card-subtitle my-2 text-secondary flex-grow-1">
                        Video
                    </small>
                    <p class="card-text text-truncate">
                        {{ media.data.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center c-pt-40">
            <button class="btn btn-round btn-secondary w-fit" *ngIf="mediaGroups[activeTab].hidden.length > 0" (click)="loadMore(activeTab)">
                <feather-icon icon="chevron-down" class="me-2"></feather-icon>
                Load More
            </button>
        </div>
    </div>
</ng-container>

<ng-template #tiktokModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <blockquote
            *ngIf="activeMedia && activeMedia.channel === 'tiktok'"
            class="tiktok-embed"
            [attr.cite]="
                'https://www.tiktok.com/' +
                activeMedia.data.user +
                '/video/' +
                activeMedia.data.videoId
            "
            [attr.data-video-id]="activeMedia.data.videoId"
            data-embed-from="embed_page"
        >
            <section class="tiktok-pre-embed">
            </section>
        </blockquote>
    </div>
</ng-template>

<ng-template #imageModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body image-modal-body">
        <div *ngIf="activeMedia && (activeMedia.channel === 'image' || activeMedia.channel === 'manual_image')" class="d-flex flex-column">
            <img
                [src]="activeMedia.data.url"
                [alt]="activeMedia.data.title"
                class="flex-grow-1 media"
            />
            <h5 class="pt-2">
                {{ activeMedia.data.title }}
            </h5>
            <p>
                {{ activeMedia.data.description }}
            </p>
        </div>
    </div>
</ng-template>

<ng-template #videoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body video-modal-body">
        <div *ngIf="activeMedia && activeMedia.channel === 'manual_video'" class="d-flex flex-column">
            <video controls autoplay playsinline class="media"> 
                <source [src]="activeMedia.data.url" />
            </video>
            <h5 class="pt-2">
                {{ activeMedia.data.title }}
            </h5>
            <p>
                {{ activeMedia.data.description }}
            </p>
        </div>
    </div>
</ng-template>
