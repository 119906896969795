/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppClientModule } from './app/app.client.module';


platformBrowserDynamic().bootstrapModule(AppClientModule)
  .catch(err => console.error(err));

// // https://github.com/angular/angular/issues/52912#issuecomment-2060580099
// // logs all pending zone tasks
// // comment out the above bootstrap and enable this one to see it.
// import { NgZone } from '@angular/core';
// import "../node_modules/zone.js/fesm2015/task-tracking";
// import * as _ from "lodash";

// platformBrowserDynamic()
//     .bootstrapModule(AppClientModule)
//     .then(moduleInstance => {
//         // Ensure Angular destroys itself on hot reloads.
//         //@ts-ignore
//         if (window["ngRef"]) {
//             //@ts-ignore
//             window["ngRef"].destroy();
//         }
//         //@ts-ignore
//         window["ngRef"] = moduleInstance;

//         const ngZone = moduleInstance.injector.get(NgZone);
//         setInterval(() => {
//             var taskTrackingZone = (<any>ngZone)._inner.getZoneWith("TaskTrackingZone");
//             if (!taskTrackingZone) {
//                 throw new Error("'TaskTrackingZone' zone not found! Have you loaded 'node_modules/zone.js/dist/task-tracking.js'?");
//             }
//             var tasks: any[] = taskTrackingZone._properties.TaskTrackingZone.getTasksFor("macroTask");
//             tasks = _.clone(tasks);
//             if (_.size(tasks) > 0) {
//                 console.log("ZONE pending tasks=", tasks);
//             }
//         }, 1000);

//         // Otherwise, log the boot error
//     })
//     .catch(err => console.error(err));

