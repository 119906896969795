import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'change-name',
    templateUrl: './change-name.component.html',
    styleUrls: ['./change-name.component.scss']
})
export class ChangeNameComponent {
    
    private _name: string = "";

    @Input()
    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
        this.nameFormControl.setValue(value);
    }

    @Input()
    save?: (name: string) => Promise<void>;

    form = new FormGroup({
        name: new FormControl(this.name, [Validators.required, Validators.maxLength(50)])
    });

    loading = false;

    get nameFormControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get nameErrors() {
        const errors = this.nameFormControl?.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Enter your name";
                case "maxlength": return "Name must be at most 50 characters long"
                default: return "Unknown error";
            }
        });
    }

    async continue() {
        if (this.save && this.form.valid) {
            this.loading = true;
            await this.save(this.nameFormControl.value);
            this.loading = false;
        }
        if (!this.form.valid && !this.nameFormControl.value) {
            this.nameFormControl.markAsDirty();
        }
    }
}
