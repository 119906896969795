import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Discussion } from 'src/app/models/discussion.model';
import { Comment } from 'src/app/models/comment.model';

export enum DiscussionType {
    BOOK = 'book',
    WORLD = 'world'
    // Add other entities matching with a discussion here
}

export interface AddCommentDTO {
    discussionType: DiscussionType;
    entityId: number;
    text: string;
    threadId?: string; // Optional
    metadata?: {[key: string]: any}
  }
  

@Injectable({
    providedIn: 'root'
})
export class DiscussionService {

    private _apiUrl = environment.baseUrl + '/api/discussion';

    private getBookDiscussionUrl(bookId: number) {
        return `${this._apiUrl}/book/${bookId}`;
    }

    constructor(private http: HttpClient) { }

    async getDiscussionByBookId(bookId: number): Promise<Discussion> {
        try {
            return await firstValueFrom(this.http.get<Discussion>(this.getBookDiscussionUrl(bookId)));
        }
        catch (err: any) {
            if (err && err.status === 404) {
                return {
                    id: "",
                    comments: [],
                    createdAt: new Date(),
                    isActive: true,
                    updatedAt: new Date()
                };
            }
            throw err;
        }
    }

    async addComment(bookId: number, newComment: {text: string, threadId?: string, metadata?: {[key: string]: any}}): Promise<Comment> {
        let body: AddCommentDTO = {
            discussionType: DiscussionType.BOOK,
            entityId: bookId,
            text: newComment.text,
        }
        if (newComment.threadId) body.threadId = newComment.threadId;
        body.metadata = newComment.metadata || {};
        return await firstValueFrom(
            this.http.post<Comment>(this._apiUrl, body)
        );
    }

    async removeComment(commentId: string): Promise<Discussion> {
        return await firstValueFrom(
            this.http.delete<Discussion>(`${this._apiUrl}/${commentId}`)
        );
    }

    async pinComment(id: string): Promise<void> {
        await firstValueFrom(
            this.http.put<{commentId: string}>(`${this._apiUrl}/${id}/pin`, {})
        );
    }

    async unpinComment(id: string): Promise<void> {
        await firstValueFrom(
            this.http.delete<{commentId: string}>(`${this._apiUrl}/${id}/pin`)
        );
    }

    connectCommentsWithDFS(comments: Comment[], parent: Comment | null = null): Comment[] {
        for(let comment of comments) {
            comment.parent = parent;
            if (comment.comments && comment.comments.length > 0) {
                comment.comments = this.connectCommentsWithDFS(comment.comments, comment);
            }
        }
        return comments;
    }

    getTopMostComment(comment: Comment): Comment {
        let current = comment;
        while (current.parent) {
            current = current.parent;
        }
        return current;
    }

}
