import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform.service';
import { firstValueFrom, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatWidgetService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _platformService: PlatformService
    ) {}

    private kind = environment.chatWidget as "intercom" | "tidio";
    private enabled = true;

    state: "open" | "close" | null = null;

    enable() {
        this.enabled = true;
        this.show();
    }

    disable() {
        this.hide();
        this.enabled = false;
    }

    show() {
        if (!this.enabled) return;
        if (this.kind === "intercom") {
            this._showIntercom();
        } else if (this.kind === "tidio") {
            this._showTidio();
        }
    }

    hide() {
        if (!this.enabled) return;
        if (this.kind === "intercom") {
            this._hideIntercom();
        } else if (this.kind === "tidio") {
            this._hideTidio();
        }
    }

    private _facadeLoadRequest!: BehaviorSubject<boolean>;
    set facadeLoadRequest(value: BehaviorSubject<boolean>) {
        this._facadeLoadRequest = value;
    }

    open() {
        if (!this.enabled) return;
        try {
            const selector = this._widgetSelector();
            if (selector?.kind === "facade") {
                this._facadeLoadRequest.next(true);
            } else if (selector?.kind === "tidio") {
                //@ts-ignore
                window.tidioChatApi.popUpOpen();
            } else if (selector?.kind === "intercom") {
                //@ts-ignore
                window.Intercom("showNewMessage");
            }
        } catch (_e) {}
    }

    identifyUser(userData: {email?: string, name?: string}) {
        if (this.kind === "tidio") {
            try {
                //@ts-ignore
                window.tidioChatApi.setVisitorData(userData);
            } catch (_) {}
        }
    }

    async slideUp(bottom: number) {
        if (!this.enabled) return;
        if (this.state === "open") return;
        const selector = this._widgetSelector();
        if (!selector) return;
        selector.widget.style.setProperty("transition", `bottom 0.5s ease-in`);
        if (selector.kind === "intercom") {
            selector.widget.style.setProperty("bottom", `${bottom}px`);
        } else if (selector.kind === "tidio") {
            const isMobile = await firstValueFrom(this._platformService.isMobile);
            if (isMobile) {
                selector.widget.style.setProperty("inset", `auto -7px ${bottom}px auto`);
            } else {
                selector.widget.style.setProperty("inset", `auto 9px ${bottom}px auto`);
            }
        } else {
            selector.widget.style.setProperty("bottom", `${bottom}px`);
        }
    }

    async resetPosition(beforeOpen = false) {
        if (!this.enabled) return;
        if (this.state === "open") return;
        const selector = this._widgetSelector();
        if (!selector) return;
        selector.widget.style.setProperty("transition", `bottom 0.5s ease-in`);
        if (selector.kind === "intercom") {
            selector.widget.style.setProperty("bottom", `20px`);
        } else if (selector.kind === "tidio") {
            const isMobile = await firstValueFrom(this._platformService.isMobile);
            if (isMobile) {
                if (beforeOpen) {
                    selector.widget.style.setProperty("inset", `auto 0 0 auto`);
                } else {
                    selector.widget.style.setProperty("inset", `auto -7px 15px auto`);
                }
            } else {
                selector.widget.style.setProperty("inset", `auto 9px 35px auto`);
            }
        } else {
            selector.widget.style.setProperty("bottom", `20px`);
        }
    }

    private _hideIntercom() {
        if (!this.document.head.querySelector("#intercom-hide")) {
            const style = this.document.createElement("style");
            style.id = "intercom-hide";
            style.innerHTML = `
                .intercom-lightweight-app {
                    display: none !important;
                }
                .intercom-namespace {
                    display: none !important;
                }
                #intercom-frame {
                    display: none !important;
                }
                .chat-widget-facade {
                    display: none !important;
                }
            `;
            this.document.head.appendChild(style);
        }
    }

    private _showIntercom() {
        const style = this.document.head.querySelector("#intercom-hide");
        if (style) {
            style.parentElement?.removeChild(style);
        }
    }

    private _hideTidio() {
        if (!this.document.head.querySelector("#tidio-hide")) {
            const style = this.document.createElement("style");
            style.id = "tidio-hide";
            style.innerHTML = `
                #tidio-chat-iframe {
                    display: none !important;
                }
                .chat-widget-facade {
                    display: none !important;
                }
            `;
            this.document.head.appendChild(style);
        }
    }

    private _showTidio() {
        const style = this.document.head.querySelector("#tidio-hide");
        if (style) {
            style.parentElement?.removeChild(style);
        }
    }

    private _widgetSelector(): {kind: "intercom" | "tidio" | "facade", widget: HTMLDivElement} | null {
        if (!this._platformService.isBrowser) return null;
        if (this.kind === "intercom") {
            const widget = this.document.querySelector<HTMLDivElement>(".intercom-lightweight-app-launcher");
            if (widget) return {kind: "intercom", widget};
        } else if (this.kind === "tidio") {
            const widget = this.document.querySelector<HTMLDivElement>("#tidio-chat-iframe");
            if (widget) return {kind: "tidio", widget};
        }
        const widget = this.document.querySelector<HTMLDivElement>(".chat-widget-facade");
        if (widget) return {kind: "facade", widget};
        return null;
    }
}