export const environment = {
    production: false,
    baseHref: '/',
    baseUrl: 'https://staging.cr3w.co',
    auth: {
        domain: "cr3w-staging.eu.auth0.com",
        audience: "crew_nest_api_staging",
        clientId: "V8OfsW1RgdWJxBUwzDsBsv8R86DxeC2X",
        passwordless: {
            ttl: 30, // 30 minutes
            resendTtl: 1, // 1 minute
        }
    },
    NG_UNIVERSAL_LOGS: true,
    discussionUrl: "https://discussion-staging.crewio.workers.dev",
    redirectOnAuth: false,
    cloudinary: {
        cloudName: "dap6pju8g"
    },
    intercom: {
        appId: "cl6vzqrb"
    },
    tidio: {
        scriptUrl: "https://code.tidio.co/diwjyaotwdvkfjxenk0ahtuytts7dj39.js"
    },
    chatWidget: "tidio",
    segmentKey: "1GsmVmwPXrL5tk4tFiJs2SpWtlWsab7U",
    blogUrl: "https://crewfiction.com/blog",
    openGraph: {
        images: {
            default: "https://res.cloudinary.com/dap6pju8g/image/upload/v1703242077/crewfiction-splash-open-graph-min_ipa8t9.jpg"
        }
    },
    searchKey: "bzROSysyTC9VMENJQWxEV3NPaXVMWGlFdDdsNUpwZldxU1ZBMEowd29Ubz0yTVdkeyJsaW1pdCI6NiwibGltaXRfaGl0cyI6NiwiaGlnaGxpZ2h0X2ZpZWxkcyI6Im5vbmUiLCJxdWVyeV9ieSI6WyJ0aXRsZSIsImFzaW4iLCJpc2JucyIsInB1Ymxpc2hlciIsImJvb2tTZXJpZXMudGl0bGUiLCJib29rU2VyaWVzLmFzaW4iLCJhdXRob3JzLm5hbWUiLCJhdXRob3JzLmFzaW4iLCJnZW5yZXMubmFtZSIsInN1YkdlbnJlcy5uYW1lIiwidHJvcGVzLm5hbWUiLCJ3b3JsZHMudGl0bGUiLCJkZXNjcmlwdGlvbiIsImF1dGhvcnMuYmlvIiwiYXV0aG9ycy5pbnRybyIsImdlbnJlcy5kZXNjcmlwdGlvbiIsInRyb3Blcy5kZXNjcmlwdGlvbiIsIndvcmxkcy5kZXNjcmlwdGlvbiJdLCJleGNsdWRlX2ZpZWxkcyI6WyJvdXRfb2YiLCJzZWFyY2hfdGltZV9tcyJdLCJmaWx0ZXJfYnkiOiJpc1Zpc2libGU6dHJ1ZSJ9",
    searchHost: "https://search.cr3w.co",
    push: {
        firebaseConfig: {
            apiKey: "AIzaSyA_7uBrXfK7DX1afN5bo5kLcHHAVwNF18I",
            authDomain: "crew-notif-dev.firebaseapp.com",
            projectId: "crew-notif-dev",
            storageBucket: "crew-notif-dev.appspot.com",
            messagingSenderId: "465911774022",
            appId: "1:465911774022:web:09eb128c99748f905e0347"
        },
        vapidKey: "BPKRXjXdeculnMhiXBxFb9E-VXnFUbVN3PMBEbYW5w98QGv-solBKnEkxCtMcOCW1o-DNTVNDPBA4BxP8N2xsm0"
    }
};
