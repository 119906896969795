<div class="row c-pb-20" *ngIf="data.header">
    <ng-container *ngIf="data.enableAnchor">
        <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
    </ng-container>
    <ng-container *ngIf="!data.enableAnchor">
        <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
    </ng-container>
</div>
<div class="row">
    <div class="col-md-6 col-12">
        <div #spotifyPlayer></div>
    </div>
</div>
