<ng-container *ngIf="book">
    <book-header-container
        [book]="book"
        [headerText]="data.headerText"
        [content]="content"
        [enableRating]="data.showRating"
        [showSpicyMeter]="data.showSpicyMeter"
        [showHeader]="data.showHeader"
    ></book-header-container>

    <ng-template #content>
        @defer (when authChecked$ | async) {
            <div class="book-header-cta-controls" *ngIf="!joinedPrelaunch">
                <form [formGroup]="emailForm"
                (ngSubmit)="readItFirst()">
                  <div class="input-container">
                      <input type="email" required class="form-control rounded-input dark-input" formControlName="email" placeholder="E-mail" (keyup)="onEmailChanged()">
                  </div>
                  <div class="button-container">
                      <button type="submit"
                          [disabled]="loading"
                          class="btn btn-dark btn-round">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                          {{ data.ctaText }}
                      </button>
                  </div>
                  <div class="form-errors">
                      <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                          <small>
                              {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                          </small>
                      </div>
                      <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                          <small>
                              Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                          </small>
                      </div>
                  </div>
              </form>
            </div>
            <div class="" *ngIf="joinedPrelaunch">
                <div class="alert alert-opaque text-start mb-0" role="alert">
                    <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                    <span class="fw-bold">You are in!</span><br><br>
                    <p class="mb-0">{{data.alertText}}</p>
                    <p class="mb-0" *ngIf="joinedPrelaunch && !joinedCrew && mainAuthor.crew">Join {{mainAuthor.crew.crewName}} for exclusive content & more updates</p>
                    <div class="book-header-cta-controls c-pt-20">
                        <div class="button-container">
                            <button type="button"
                                *ngIf="joinedPrelaunch && !joinedCrew && mainAuthor.crew"
                                (click)="joinCrew()"
                                class="btn btn-round btn-primary">
                                Join {{mainAuthor.crew.crewName}}
                            </button>
                        </div>
                        <div class="button-container">
                            <button class="btn btn-round btn-dark btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                                <feather-icon icon="user-plus"></feather-icon>
                                Follow Author
                            </button>
                            <button class="btn btn-round btn-dark btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                                <feather-icon icon="user-check"></feather-icon>
                                Following
                            </button>
                        </div>
                    </div>
                </div>  
            </div>
        } @placeholder(minimum 600ms) {

        }
    </ng-template>

    <div class="card sticky-container container-xxl" [@stickyFooterAnimation] *ngIf="data.enableStickyFooter && scrolled && (!followsAuthor || !joinedCrew)">
        <div class="card-body c-py-20 c-px-0">
            <ng-container *ngIf="isMobile | async">
                <div class="row text-center">
                    <div class="col-12">
                        <span class="text-reset d-flex justify-content-center align-items-center">
                            <img
                                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                alt="Author picture"
                                class="circular-pfp c-me-10"
                            />
                            Coming soon from&nbsp;<span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                        <h6 class="book-title c-my-15">{{book.title}}</h6>
                    </div>
                    <form [formGroup]="emailForm"
                        (ngSubmit)="readItFirst()" *ngIf="!joinedPrelaunch">
                        <div class="col-12 c-pb-12">
                            <input type="email" required class="form-control rounded-input dark-input" formControlName="email" placeholder="E-mail" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
                        </div>
                        <div class="col-12">
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round w-100">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                                {{ data.ctaText }}
                            </button>
                        </div>
                        <div class="col-12 form-errors c-pt-12">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                                <small>
                                    {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                </small>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                                <small>
                                    Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                </small>
                            </div>
                        </div>
                    </form>
                    <div class="col-12 c-pb-12" *ngIf="joinedPrelaunch && !joinedCrew && mainAuthor.crew">
                        <button type="button"
                            (click)="joinCrew()"
                            class="btn btn-round btn-primary w-100">
                            Join {{mainAuthor.crew.crewName}}
                        </button>
                    </div>
                    <div class="" *ngIf="joinedPrelaunch">
                        <button class="btn btn-round btn-dark w-100 btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                            <feather-icon icon="user-plus"></feather-icon>
                            Follow Author
                        </button>
                        <button class="btn btn-round btn-dark w-100 btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                            <feather-icon icon="user-check"></feather-icon>
                            Following
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(isMobile | async)">
                <div class="d-flex justify-content-between c-px-20">
                    <div class="pe-3 d-flex flex-column justify-content-center">
                        <img class="book-cover__image" [src]="coverSrc | cloudinaryFormat | cloudinaryScaleHeight : 50 | cloudinaryQuality : 100 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <h6 class="book-title c-mb-10">{{book.title}}</h6>
                        <span class="text-reset d-flex justify-content-start align-items-center">
                            <img
                                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                alt="Author picture"
                                class="circular-pfp c-me-10"
                            />
                            Coming soon from&nbsp;<span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                    <form [formGroup]="emailForm"
                        (ngSubmit)="readItFirst()" *ngIf="!joinedPrelaunch"
                        class="d-flex flex-wrap justify-content-end">
                        <div class="c-pe-12">
                            <input type="email" required class="form-control rounded-input dark-input mb-3 mb-md-0" formControlName="email" placeholder="E-mail" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)" >
                        </div>
                        <div>
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round w-fit">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                                {{ data.ctaText }}
                            </button>
                        </div>
                        <div class="flex-break"></div>
                        <div class="form-errors">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-pt-12" [@errorMessageAnimation]>
                                <small>
                                    {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                </small>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger c-pt-12" [@errorMessageAnimation]>
                                <small>
                                    Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                </small>
                            </div>
                        </div>
                    </form>
                    <div class="d-flex flex-column justify-content-center c-pe-12" *ngIf="joinedPrelaunch && !joinedCrew && mainAuthor.crew">
                        <button type="button"
                            (click)="joinCrew()"
                            class="btn btn-round btn-primary w-fit">
                            Join {{mainAuthor.crew.crewName}}
                        </button>
                    </div>
                    <div class="d-flex flex-column justify-content-center" *ngIf="joinedPrelaunch">
                        <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                            <feather-icon icon="user-plus"></feather-icon>
                            Follow Author
                        </button>
                        <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                            <feather-icon icon="user-check"></feather-icon>
                            Following
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <div class="mt-0 c-m-0 c-m-md-20">
            <join-crew-form
                [author]="mainAuthor"
                [requestLoginCb]="requestLoginCb"
                [afterJoinCb]="afterJoinCb"
                [settings]="crewFormSettings"
                [eventMetadata]="joinCrewEventMetadata"
            ></join-crew-form>
        </div>
    </div>
</ng-template>
