import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textLimit'
})

export class TextLimitPipe implements PipeTransform {

    /**
     * 
     * @param value S0tring to be shortened
     * @param limit Maximum length of the string
     * @param symbol Symbol to be added at the end of the string
     * @param stripHtml If set to true will strip html tags from the string only of its length exceeds the limit. To always strip html tags use the stripHtml pipe
     * @returns 
     */
    transform(value: string, limit: number, symbol: string = "...", stripHtml: boolean = false): any {
        let transformed = value.length > limit ? value.slice(0, limit) + "..." : value;
        if (stripHtml && value.length > limit) {
            return transformed.replace(/<[^>]*>?/gm, '');
        }
        return transformed;
    }
}