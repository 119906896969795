import { Pipe, PipeTransform } from '@angular/core';
import { PillType } from 'src/app/models/badge-config.model';

@Pipe({
    name: 'pillType',
    pure: true
})
export class PillTypePipe implements PipeTransform {

    transform(value: PillType): string {
        if (value === "default") {
            return "";
        } else {
            return `text-bg-${value}`;
        }
    }
}
