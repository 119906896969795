<div class="container p-5">

    <form (submit)="submitForm(emailControl.value)" class="d-flex gap-3 flex-column">

        <h5>
            Sign up to join the Book CR3W
        </h5>

        <p class="text-muted">
            Join our community to discover new fiction books first and enjoy exclusive rewards.
        </p>

        <input type="text" [formControl]="emailControl" placeholder="Email address" required minlength="3"
            class="form-control mb-3 mb-md-0 md-rounded md-w-100" [formControl]="emailControl">

        <button class="btn btn-primary" type="submit" id="button-addon2">
            <!-- <loading *ngIf="loading" color="#fff"></loading> -->
            <span class="ms-2">
                Sign up
            </span>
        </button>
    </form>

</div>
