import { Component, Input } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Book } from 'src/app/models/book.model';

@Component({
  selector: 'floating-book-crew-cta',
  templateUrl: './floating-book-crew-cta.component.html',
  styleUrls: ['./floating-book-crew-cta.component.scss']
})
export class FloatingBookCrewCtaComponent {
  @Input()
  book: Book | null = null;

  @Input()
  isExpanded = false;

  closeResult = '';


  constructor(private offcanvasService: NgbOffcanvas) { }

  async open(content: any) {
    const result = await this.offcanvasService.open(content, {
      ariaLabelledBy: 'offcanvas-basic-title',
      position: 'bottom',
      panelClass: 'mobile-offcanvas'
    }).result.catch(e => {
      console.log("[FloatingBookCrewCtaComponent] error opening offcanvas: ", e)
      return e;
    })

    console.log("[FloatingBookCrewCtaComponent] open result: ", result)
  }
}
