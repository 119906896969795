<ng-container *ngIf="book">
    <book-header-container
        [book]="book"
        [headerText]="headerText"
        [altCoverIndex]="altCoverIndex"
        [content]="content"
        [enableRating]="enableRating"
        [showSpicyMeter]="showSpicyMeter"
        [showHeader]="showHeader"
    ></book-header-container>

    <ng-template #content>
        <div class="book-header-cta-controls">
            <div class="button-container" *ngIf="enableAmazonButton">
                <a [href]="book | amazonLink" class="btn btn-primary btn-round btn-with-icon btn-large" target="_blank" rel="noopener noreferrer" (click)="trackAmazonClick()">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="amazon">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3628 15.8963C13.5015 17.2688 10.8043 18 8.48227 18C5.21977 18 2.29364 16.7963 0.0757021 14.7921C-0.0930481 14.6346 0.0577021 14.4197 0.266952 14.5423C2.66039 15.9356 5.62477 16.7738 8.6769 16.7738C10.8811 16.7636 13.0615 16.3178 15.0928 15.462C15.4078 15.3281 15.6722 15.6679 15.3628 15.8963ZM16.1368 15.0109C15.9005 14.7071 14.564 14.8675 13.9655 14.9378C13.7827 14.9603 13.7552 14.8011 13.9194 14.6869C14.9882 13.9388 16.7285 14.1542 16.9322 14.4056C17.1358 14.6571 16.8793 16.4081 15.8803 17.2429C15.7267 17.3711 15.5805 17.3031 15.6553 17.1326C15.8803 16.5724 16.3832 15.3158 16.1447 15.0109" fill="#121212"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80409 7.79851C9.80409 8.75026 9.82828 9.54395 9.34734 10.3888C8.95359 11.0751 8.33484 11.4986 7.65703 11.4986C6.71934 11.4986 6.16978 10.7843 6.16978 9.72957C6.16978 7.64832 8.03503 7.26976 9.80466 7.26976L9.80409 7.79851ZM12.2684 13.752C12.107 13.8966 11.8735 13.9067 11.6913 13.8105C10.8813 13.1372 10.7328 12.8244 10.2895 12.1821C8.94965 13.5489 7.9979 13.9579 6.26315 13.9579C4.20553 13.9579 2.60802 12.69 2.60802 10.1514C2.60802 8.1692 3.67959 6.81919 5.2124 6.15938C6.53822 5.5755 8.39053 5.47313 9.80522 5.31563V5.00625C9.80522 4.42575 9.85022 3.73838 9.50709 3.23719C9.21178 2.78719 8.64084 2.60156 8.13628 2.60156C7.20534 2.60156 6.37734 3.07969 6.17484 4.06857C6.13378 4.29357 5.97234 4.50507 5.74903 4.51519L3.37471 4.24969C3.17559 4.20469 2.95284 4.04382 3.01077 3.74344C3.55921 0.866251 6.15628 0 8.47884 0C9.66741 0 11.2205 0.316125 12.1576 1.21613C13.3462 2.32594 13.2331 3.80644 13.2331 5.418V9.22501C13.2331 10.3691 13.7073 10.8703 14.1539 11.4891C14.3086 11.7141 14.3429 11.9728 14.1438 12.1382C13.5133 12.6713 12.8893 13.2119 12.2718 13.7599L12.2684 13.7531" fill="#121212"/>
                    </svg>
                    {{amazonCtaText}}
                </a>
            </div>
            <div class="button-container">
                <button class="btn btn-round btn-dark btn-with-icon btn-large" *ngIf="!followsAuthor" (click)="followAuthor()">
                    <feather-icon icon="user-plus"></feather-icon>
                    Follow Author
                </button>
                <button class="btn btn-round btn-dark btn-with-icon btn-large" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                    <feather-icon icon="user-check"></feather-icon>
                    Following
                </button>
            </div>
        </div>
    </ng-template>


    <div class="card sticky-container container-xxl" [@stickyFooterAnimation] *ngIf="enableStickyFooter && scrolled && !followsAuthor">
        <div class="card-body c-py-20 c-px-0">
            <ng-container *ngIf="isMobile | async">
                <div class="row text-center">
                    <div class="col-12">
                        <span class="text-reset d-flex justify-content-center align-items-center">
                            <img
                                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                alt="Author picture"
                                class="circular-pfp c-me-10"
                            />
                            <span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                        <h6 class="book-title c-my-15">{{book.title}}</h6>
                    </div>
                    <div class="col-12 c-pb-12" *ngIf="enableAmazonButton">
                        <a [href]="book | amazonLink" class="btn btn-primary btn-block text-nowrap rounded-input w-100 btn-with-icon" target="_blank" rel="noopener noreferrer" (click)="trackAmazonClick()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="amazon" fill="#121212" viewBox="0 0 448 512">
                                <path
                                    d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                            </svg>
                            {{amazonCtaText}}
                        </a>
                    </div>
                    <div class="">
                        <button class="btn btn-round btn-dark w-100 btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                            <feather-icon icon="user-plus"></feather-icon>
                            Follow Author
                        </button>
                        <button class="btn btn-round btn-dark w-100 btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                            <feather-icon icon="user-check"></feather-icon>
                            Following
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(isMobile | async)">
                <div class="d-flex justify-content-between c-px-20">
                    <div class="pe-3 d-flex flex-column justify-content-center">
                        <img class="book-cover__image" [src]="coverSrc | cloudinaryFormat | cloudinaryScaleHeight : 50 | cloudinaryQuality : 100 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <h6 class="book-title c-mb-10">{{book.title}}</h6>
                        <span class="text-reset d-flex justify-content-start align-items-center">
                            <img
                                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                alt="Author picture"
                                class="circular-pfp c-me-10"
                            />
                            <span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                    <div class="d-flex flex-column justify-content-center c-pe-12" *ngIf="enableAmazonButton">
                        <a [href]="book | amazonLink" class="btn btn-primary btn-block text-nowrap rounded-input w-fit btn-with-icon" target="_blank" rel="noopener noreferrer" (click)="trackAmazonClick()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="amazon" fill="#121212" viewBox="0 0 448 512">
                                <path
                                    d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                            </svg>
                            {{amazonCtaText}}
                        </a>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                            <feather-icon icon="user-plus"></feather-icon>
                            Follow Author
                        </button>
                        <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                            <feather-icon icon="user-check"></feather-icon>
                            Following
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <join-crew-form
            [author]="mainAuthor"
            [afterJoinCb]="afterJoinCb"
            [settings]="crewFormSettings"
            [eventMetadata]="joinCrewEventMetadata">
        ></join-crew-form>
    </div>
</ng-template>
