<div *ngIf="referencedBook">
    <div
        class="breadcrumb-container"
        style="--bg-color: {{ data.breadcrumbColor || (data.bannerImage && data.bannerImage.data.url && (data.bannerImage.data.url | cloudinaryPublicId | cloudinaryReadableColor : 130 | async)) || '#DDD' }}">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <breadcrumbs linkColor="#222831" currentColor="#000000" hoverColor="#000000" fontWeight="400"></breadcrumbs>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.bannerImage">
        <img [src]="data.bannerImage.data.url | cloudinaryPublicId | cloudinaryQuality : 100 | cloudinaryUrl" [alt]="data.header" class="banner-img">
    </div>
    <div class="container-xxl c-pt-30">
        <div class="row">
            <div class="col-md-7 col-12">
                <h1 class="world-header c-mb-20">{{ data.header }}</h1>
                <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="!followsWorld" (click)="followWorld()">
                    <feather-icon icon="user-plus"></feather-icon>
                    Follow World
                </button>
                <button class="btn btn-round btn-dark w-fit btn-with-icon" *ngIf="followsWorld" (click)="unfollowWorld()">
                    <feather-icon icon="user-check"></feather-icon>
                    Following
                </button>
                <p class="world-description c-mt-60 c-mb-0">
                    {{ data.description }}
                </p>
            </div>
            <div class="col-xxl-3 offset-xxl-2 col-md-5 offset-0 col-12 c-pt-40 c-pt-md-0">
                <div class="d-flex flex-column">
                    <h5 class="badge-section-title c-mb-10" *ngIf="data.referenceSeries">Book Series</h5>
                    <div class="badge-collection c-pb-20 c-pt-0 c-px-0" *ngIf="data.referenceSeries">
                        <span class="badge-with-image" [routerLink]="'/authors/' + data.referenceSeries.authors[0].slug + '/' + data.referenceSeries.slug">
                            <a class="d-flex align-items-center" [routerLink]="'/authors/' + data.referenceSeries.authors[0].slug + '/' + data.referenceSeries.slug">
                                <img
                                    *ngIf="data.referenceSeries.images && data.referenceSeries.images.cover"
                                    [src]="data.referenceSeries.images.cover | cloudinaryScaleHeight : 22 | cloudinaryQuality : 80 | cloudinaryUrl"
                                    alt="Book Series picture"
                                    class="circular-pfp c-me-8"
                                />
                                <span>{{ data.referenceSeries.title }}</span>
                            </a>
                        </span>
                    </div>
                    <h5 class="badge-section-title c-mb-10" >{{data.referenceBook.authors.length > 1 ? "Authors": "Author"}}</h5>
                    <div class="badge-collection c-pb-20 c-pt-0 c-px-0">
                        <span *ngFor="let author of data.referenceBook.authors" class="badge-with-image" [routerLink]="'/authors/' + author.slug">
                            <a class="d-flex align-items-center" [routerLink]="'/authors/' + author.slug">
                                <img
                                    [src]="author.images.profile | cloudinaryScaleHeight : 22 | cloudinaryQuality : 80 | cloudinaryUrl"
                                    alt="Author picture"
                                    class="circular-pfp c-me-8"
                                />
                                <span>{{ author.name }}</span>
                            </a>
                        </span>
                    </div>
                    <h5 class="badge-section-title c-mb-10">Genres</h5>
                    <div class="c-pb-20 c-pt-0 c-px-0">
                        <badge-list [data]="genres"></badge-list>
                    </div>
                    <h5 class="badge-section-title c-mb-10">Tropes</h5>
                    <div class="c-pt-0 c-px-0">
                        <badge-list [data]="tropes" [expandable]="true" [showInitial]="6"></badge-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <join-crew-form
            [author]="referencedBookMainAuthorData"
            [afterJoinCb]="afterJoinCb"
            [settings]="crewFormSettings"
            [eventMetadata]="joinCrewEventMetadata"
        ></join-crew-form>
    </div>
</ng-template>
