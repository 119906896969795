import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'worldmaker-component-spotify-player',
    templateUrl: './spotify-player.component.html',
    styleUrls: ['./spotify-player.component.scss'],
})
export class SpotifyPlayer {
    @Input() data!: {
        spotifyUrl: string;
        header?: string;
        enableAnchor: boolean;
        anchorSlug?: string;
    };

    spotifyScript: HTMLScriptElement | null = null;
    spotifyController!: any;

    @ViewChild('spotifyPlayer') spotifyPlayer!: ElementRef<HTMLDivElement>;

    constructor(
        private readonly _platformService: PlatformService,
    ) {}

    ngOnInit() {
        if (!this._platformService.isBrowser() || !this.data.spotifyUrl) return;
        //@ts-ignore
        window.onSpotifyIframeApiReady = (IFrameAPI) => {
            IFrameAPI.createController(this.spotifyPlayer.nativeElement, {
                uri: this.spotifyUri
            }, (EmbedController: any) => {
                this.spotifyController = EmbedController;
            });
        };
        const script = document.createElement("script");
        script.src = "https://open.spotify.com/embed/iframe-api/v1";
        script.async = true;
        this.spotifyScript = script;
        document.head.appendChild(script);
    }

    ngOnDestroy() {
        //full cleanup so different players can load on navigation
        if (this._platformService.isBrowser() && this.spotifyScript) {
            if (this.spotifyController) {
                this.spotifyController.destroy();
            }
            const playScript = document.getElementById("spotify-iframeapi-script");
            if (playScript) {
                playScript.parentElement?.removeChild(playScript);
            }
            //@ts-ignore
            delete window.SpotifyIframeConfig;
            //@ts-ignore
            delete window.onSpotifyIframeApiReady;
            document.head.removeChild(this.spotifyScript);
        }
    }

    get spotifyUri() {
        if (!this.data.spotifyUrl) return "";
        const parts = this.data.spotifyUrl.split("/");
        return `spotify:${parts[3]}:${parts[4]}`;
    }
}
