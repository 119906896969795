import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'worldmaker-component-html-embed',
    templateUrl: './html-embed.component.html',
    styleUrls: ['./html-embed.component.scss'],
})
export class HtmlEmbedComponent implements OnInit, AfterViewInit {
    @Input() data!: {
        body: string;
    };
    
    safeHtml: any;

    constructor(
        private readonly _domSanitizer: DomSanitizer,
        private readonly _platformService: PlatformService
    ) {}

    ngAfterViewInit(): void {
        
    }

    ngOnInit(): void {
        if (this._platformService.isBrowser()) {
            this.safeHtml = this._domSanitizer.bypassSecurityTrustHtml(this.data.body);
        }
    }
}
