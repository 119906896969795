import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'bookRouterLink',
    pure: true
})

export class BookRouterLinkPipe implements PipeTransform {

    constructor(private readonly _utilitiesService: UtilitiesService) {}

    transform(book: Book, launch: boolean = false): string[] {
        return this._utilitiesService.getBookUrl(book, launch).split("/")
    }
}
