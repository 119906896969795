import { Injectable } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { Author } from 'src/app/models/author.model';
import { FollowersService } from 'src/app/services/followers.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UserService } from 'src/app/services/user.service';
import * as nanoid from "nanoid";
import { BehaviorSubject } from 'rxjs';
import { EmbeddedAuthService } from '../../auth/services/embedded-auth.service';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { FollowAuthorEventMetadata } from 'src/app/services/analytics/events/followAuthorEventMetadata';
import { World } from 'src/app/models/world.model';

@Injectable({
    providedIn: 'root'
})
export class FollowService {

    private readonly _uuid: string;
    private _loadedFollows = false;

    private _activeBook: Book | null = null;
    private _activeAuthor: Author | null = null;
    private _activeWorld: World | null = null;

    private _followingBooks: Book[] = [];
    private _followingAuthors: Author[] = [];
    private _followingWorlds: World[] = [];
    private _joinedCrews: Author[] = [];

    followsBook$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    followsAuthor$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    followsWorld$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    joinedCrew$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _followersService: FollowersService,
        private _platformService: PlatformService,
        private _userService: UserService,
        private _authService: EmbeddedAuthService
    ) {
        this._uuid = nanoid.nanoid();
        this._userService.isLoggedIn$.subscribe(async _isLoggedIn => {
            await this.checkIfFollowing();
        });
    }

    async init(book: Book | null, author: Author | null, world: World | null) {
        this._activeBook = book;
        this._activeAuthor = author;
        this._activeWorld = world;
        await this.checkIfFollowing();
    }

    async followBook(): Promise<void> {
        if (!this._activeBook) return;
        if (this._followingBooks.find(b => b.id === this._activeBook?.id)) return;
        
        await this._followersService.followBook(this._activeBook.id as number);
        this._followingBooks.push(this._activeBook);
        this.followsBook$.next(true);
    }
    async unfollowBook(): Promise<void> {
        if (!this._activeBook) return;
        const fb = this._followingBooks.find(b => b.id === this._activeBook?.id);
        if (!fb) return;

        await this._followersService.unfollowBook(this._activeBook.id as number);
        this._followingBooks.splice(this._followingBooks.indexOf(fb), 1);
        this.followsBook$.next(false);
    }

    async followAuthor(eventMetadata: FollowAuthorEventMetadata): Promise<void> {
        if (!this._activeAuthor) return;
        if (this._followingAuthors.find(a => a.id === this._activeAuthor?.id)) return;
        
        await this._followersService.followAuthor(this._activeAuthor.id as number, eventMetadata);
        this._followingAuthors.push(this._activeAuthor);
        this.followsAuthor$.next(true);
    }
    async unfollowAuthor(): Promise<void> {
        if (!this._activeAuthor) return;
        const fa = this._followingAuthors.find(a => a.id === this._activeAuthor?.id);
        if (!fa) return;

        await this._followersService.unfollowAuthor(this._activeAuthor.id as number);
        this._followingAuthors.splice(this._followingAuthors.indexOf(fa), 1);
        this.followsAuthor$.next(false);
    }

    async followWorld(): Promise<void> {
        if (!this._activeWorld) return;
        if (this._followingWorlds.find(w => w.id === this._activeWorld?.id)) return;
        
        await this._followersService.followWorld(this._activeWorld.id as number);
        this._followingWorlds.push(this._activeWorld);
        this.followsWorld$.next(true);
    }
    async unfollowWorld(): Promise<void> {
        if (!this._activeWorld) return;
        const fw = this._followingWorlds.find(w => w.id === this._activeWorld?.id);
        if (!fw) return;

        await this._followersService.unfollowWorld(this._activeWorld.id as number);
        this._followingWorlds.splice(this._followingWorlds.indexOf(fw), 1);
        this.followsWorld$.next(false);
    }

    async joinCrew(eventMetadata: JoinCrewEventMetadata): Promise<void> {
        if (!this._activeAuthor || !this._activeAuthor.crew) return;
        if (this._joinedCrews.find(a => a.id === this._activeAuthor?.id)) return;
        
        await this._followersService.joinCrew(this._activeAuthor.id as number, eventMetadata);
        this._joinedCrews.push(this._activeAuthor);
        this.joinedCrew$.next(true);
    }
    async leaveCrew(): Promise<void> {
        if (!this._activeAuthor || !this._activeAuthor.crew) return;
        const crew = this._joinedCrews.find(a => a.id === this._activeAuthor?.id);
        if (!crew) return;

        await this._followersService.leaveCrew(this._activeAuthor.id as number);
        this._joinedCrews.splice(this._joinedCrews.indexOf(crew), 1);
        this.joinedCrew$.next(false);
    }

    async checkIfFollowing(): Promise<void> {
        if (!this._platformService.isBrowser()) return;
        if (!this._authService.user) return;
        if (!this._loadedFollows) {
            this._loadedFollows = true;
            const [fb, fa, fw, crews] = await Promise.all([
                this._followersService.getFollowingBooks(),
                this._followersService.getFollowingAuthors(),
                this._followersService.getFollowingWorlds(),
                this._followersService.getJoinedCrews()
            ]);
            this._followingBooks = fb;
            this._followingAuthors = fa;
            this._followingWorlds = fw;
            this._joinedCrews = crews;
        }
        if (this._activeBook) {
            if (this._followingBooks.find(b => b.id === this._activeBook?.id)) {
                this.followsBook$.next(true);
            } else {
                this.followsBook$.next(false);
            }
        }
        if (this._activeAuthor) {
            if (this._followingAuthors.find(a => a.id === this._activeAuthor?.id)) {
                this.followsAuthor$.next(true);
            } else {
                this.followsAuthor$.next(false);
            }
        }
        if (this._activeWorld) {
            if (this._followingWorlds.find(w => w.id === this._activeWorld?.id)) {
                this.followsWorld$.next(true);
            } else {
                this.followsWorld$.next(false);
            }
        }
        if (this._activeAuthor && this._activeAuthor.crew) {
            if (this._joinedCrews.find(a => a.id === this._activeAuthor?.id)) {
                this.joinedCrew$.next(true);
            } else {
                this.joinedCrew$.next(false);
            }
        }
    }
}
