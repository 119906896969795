<div class="worldmaker-section-container">
    <ng-template book_page_template_slot_header></ng-template>
    <div class="container-xxl worldmaker-section-container">
        <ng-template book_page_template_slot_section1></ng-template>
        <ng-template book_page_template_slot_section2></ng-template>
        <ng-template book_page_template_slot_section3></ng-template>
        <ng-template book_page_template_slot_section4></ng-template>
        <ng-template book_page_template_slot_section5></ng-template>
        <ng-template book_page_template_slot_section6></ng-template>
        @if (showRecommendations) {
            <worldmaker-component-book-recommendations [data]="{
                headerText: recommendationHeader,
                featuredNewReleaseMode: false,
                featuredGenre: '',
                hideLatestRelease: false,
                showHR: false,
                placement: 'book_page'
            }"></worldmaker-component-book-recommendations>
        }
        @if (showFreeBookFAQ) {
            <worldmaker-component-free-book-faq></worldmaker-component-free-book-faq>
        }
    </div>
</div>
