<ng-container *ngIf="readable && downloadLink">
    <ng-container *ngIf="buttonStyle === 'small_cta'">
        <a
            class="btn btn-cta btn-round btn-small btn-with-icon"
            (click)="download()"
            [ngClass]="{'disabled': loading}">
                <span class="spinner-border spinner-border-sm c-me-10" role="status" aria-hidden="true" *ngIf="loading"></span>
                <feather-icon icon="download" width="14" height="14" *ngIf="!loading"></feather-icon>
                Download for Kindle
        </a>
    </ng-container>
    <ng-container *ngIf="buttonStyle === 'big_dark'">
        @if (showInstructionsTrigger) {
            <div class="download-with-instructions">
                <a
                    class="btn btn-dark btn-round btn-with-icon"
                    (click)="download()"
                    [ngClass]="{'disabled': loading}">
                        <span class="spinner-border spinner-border-sm c-me-10" role="status" aria-hidden="true" *ngIf="loading"></span>
                        <feather-icon icon="download" *ngIf="!loading"></feather-icon>
                        Download for Kindle
                </a>
                <span (click)="showKindleInstructions()">Instructions</span>
            </div>
        } @else {
            <a
                class="btn btn-dark btn-round btn-with-icon"
                (click)="download()"
                [ngClass]="{'disabled': loading}">
                    <span class="spinner-border spinner-border-sm c-me-10" role="status" aria-hidden="true" *ngIf="loading"></span>
                    <feather-icon icon="download" *ngIf="!loading"></feather-icon>
                    Download for Kindle
            </a>
        }
    </ng-container>
</ng-container>

<ng-template #kindleInstructionsModal let-modal>
    <div class="modal-header c-px-30">
        <h5 class="modal-title">Send to Kindle</h5>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body c-px-30 c-pb-30 c-pt-10">
        <span>
            With Amazon's <span class="fw-bold">Send to Kindle</span>, you can add this book to your Kindle library and enjoy reading on any Kindle app or device!
        </span>
        <ol class="c-ps-20 c-my-20">
            <li>Go to <a href="https://www.amazon.com/sendtokindle" target="_blank" rel="noopener noreferrer" class="link">https://www.amazon.com/sendtokindle</a></li>
            <li>Log into your Amazon account if you haven't already</li>
            <li>Follow instructions to upload files to your Kindle library</li>
        </ol>
        <span class="trouble" (click)="havingTrouble()">Having trouble?</span>
    </div>
</ng-template>

<ng-template #addCommentModal let-modal>
    <div class="modal-body c-p-30">
        <h2 class="leave-comment-title">Your book is downloading 🎉</h2>
        <h4 class="leave-comment-description c-mb-20">Leave a comment to thank the author</h4>
        <form (submit)="postComment($event)" class="d-flex flex-column align-items-center">
            <textarea rows="4" name="replyText" type="text" class="form-control no-resize dark-input c-mb-10" [(ngModel)]="commentText" placeholder="What do you think?" (keyup)="onReplyTextChanged()" required></textarea>
            <button [disabled]="commentLengthError || posting || !commentText.trim()" class="c-mt-10 btn btn-dark btn-round" type="submit">
                <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="posting"></span>
                Post
            </button>
            <a class="skip c-mt-10" (click)="skipComment()">Maybe later</a>
            <div class="form-group">
                <small *ngIf="commentLengthError" class="text-danger">
                    Oops! You've gone over the character limit
                </small>
            </div>
        </form>
        
    </div>
</ng-template>
