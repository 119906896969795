<div class="comment c-mb-30" [id]="comment?.id">
    <div *ngIf="comment">
        <div class="col-12 d-flex px-0">
            <div class="text-end avatar-container c-me-15">
                <ng-container *ngIf="user && user.pid === comment.pid">
                    <img *ngIf="!comment.removed" [src]="(comment.pid | pidToProfilePicture) + '&c=' + profilePictureCacheBust" alt="Profile picture" class="rounded-circle" loading="lazy">
                </ng-container>
                <ng-container *ngIf="!user || user.pid !== comment.pid">
                    <img width="32" height="32" *ngIf="!comment.removed" [src]="comment.pid | pidToProfilePicture:true" alt="Profile picture" class="rounded-circle" loading="lazy">
                </ng-container>
                <generic-user-icon *ngIf="comment.removed"></generic-user-icon>
            </div>
            <div class="flex-grow-1 comment-container" [@newCommentAnimation]="newCommentId && comment && comment.id == newCommentId">
                <div class="d-flex">
                    <div class="avatar-label d-flex align-items-start size-medium c-pb-10">
                        <!-- <img [src]="user.profilePictureUrl" alt="Profile picture" class="rounded-circle me-3"> -->
                        <p class="mb-0 d-flex">
                            <!-- <span class="name fw-semibold">
                                {{ user.username }}
                            </span> -->
                            <span *ngIf="!comment.removed" class="username fw-semibold">
                                {{'@'}}{{ comment.username }}
                            </span>
                            <span class="badge text-bg-primary no-select d-inline author-badge" *ngIf="comment.byAuthor">
                                Author
                            </span>
                            <span *ngIf="comment.removed" class="py-2 comment-body">
                                <i *ngIf="comment.removalReason == 'user'">Comment removed by user</i>
                                <i *ngIf="comment.removalReason == 'moderator'">Comment removed by moderator</i>
                            </span>
                        </p>
                    </div>
                    
                    <i *ngIf="comment.pinned" class="ms-2">📌</i>
                </div>
                
                <div *ngIf="comment.metadata?.isQuestion && comment.metadata?.related" class="">
                    <span class="text-muted">{{comment.metadata!.related}}</span>
                </div>
                <p *ngIf="!comment.removed" class="c-mb-10 comment-body c-me-5">
                    <ng-container *ngIf="comment.metadata?.original && showOriginal">
                        {{comment.metadata?.original}}
                    </ng-container>
                    <ng-container *ngIf="!showOriginal || !comment.metadata?.original">
                        {{ comment.body }}
                    </ng-container>
                </p>

                <div class="fact-badge c-mb-15" *ngIf="comment.metadata?.isQuestion">Fact request</div>
                    
                <div class="d-flex flex-wrap justify-content-start align-items-center c-mb-10">
                    <button class="comment-btn c-me-20" *ngIf="!comment.removed && !comment.parentComment" (click)="toggleReplyInput()">
                        Reply
                    </button>
                    <button *ngIf="user && comment.pid == user.pid && !showDeleteToggle && !comment.removed" class="comment-btn c-me-20" (click)="toggleDelete()">
                        <span>Delete</span>
                    </button>
                    <button class="comment-btn c-me-20" *ngIf="!comment.removed && allowPin" (click)="togglePin()">
                        <span *ngIf="comment.pinned">Unpin</span>
                        <span *ngIf="!comment.pinned">Pin</span>
                    </button>
                    <div *ngIf="showDeleteToggle" class="d-flex align-items-center c-me-20">
                        <small class="comment-btn are-you-sure c-me-10">Are you sure?</small>
                        <button class="comment-btn c-me-10" (click)="removeReply(comment)">
                            <u>Yes</u>
                        </button>
                        <button class="comment-btn" (click)="toggleDelete()">
                            <u>No</u>
                        </button>
                    </div>
                    <small class="timeago c-me-20">{{ comment.createdAt | timeAgo }}</small>
                    <button class="comment-btn text-decoration-underline c-mt-10 c-mt-md-0" *ngIf="!comment.removed && comment.metadata?.original" (click)="toggleOriginal()">
                        <span>
                            <ng-container *ngIf="!showOriginal">(Translated)</ng-container>
                            Show 
                            <ng-container *ngIf="showOriginal">Translation</ng-container>
                            <ng-container *ngIf="!showOriginal">Original</ng-container>
                        </span>
                    </button>
                </div>
    
    
                <button class="comment-btn c-mb-20" (click)="toggleReplies()"
                    *ngIf="!showReplies && comment.comments.length">
                    <!-- {{ showReplies ? 'Hide replies' : 'Show replies' }} -->
                    <!-- Show 4 more replies -->
                    <span class="view-more">
                        View {{ comment.comments.length }} more {{ comment.comments.length === 1 ? 'reply' : 'replies'}}
                    </span>
                </button>
                <button class="comment-btn c-mb-20" (click)="toggleReplies()" *ngIf="showReplies">
                    <span class="view-more">
                        Hide replies
                    </span>
                </button>
    
                <div *ngIf="showReplyInput" [ngClass]="{'c-pb-20': showReplies}">
                    <reply-input [user]="user" [nested]="nested" [comment]="comment" [discussion]="discussion" [posting]="posting" (reply)="addReply($event)"></reply-input>
                </div>
    
            </div>
        </div>
    </div>
    <div *ngIf="comment && showReplies" class="row c-ps-40 c-ps-md-0">
        <div class="col-1 px-0 d-none d-md-block">
            <div class="thread-line"></div>
        </div>
        <div class="col-12 col-md-11 px-0">
            <div class="row">
                <comment *ngFor="let reply of comment.comments" [nested]="true" [comment]="reply" [user]="user" [newCommentId]="newCommentId" [discussion]="discussion" [allowPin]="allowPin" (reply)="addReply($event)" (remove)="removeReply($event)" (pinChange)="onNestedPinChange($event)"></comment>
            </div>
        </div>
    </div>
</div>
