import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHandlerFn } from '@angular/common/http';
import { Observable, flatMap, from, map, mergeMap, of } from 'rxjs';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform.service';

/**
 * We need this because auth0 drops public requests if not authenticatd
 */
export function customAuthInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
    const _platformService = inject(PlatformService);
    if (!_platformService.isBrowser()) return next(request);
    let accessToken = localStorage.getItem("access_token");
    let refreshToken = localStorage.getItem("refresh_token") || "";
    if (accessToken && request.url.includes(environment.baseUrl)) {            
        const authRequest = addTokenToRequest(request, accessToken, refreshToken);
        return next(authRequest);
    }
    return next(request);
}

function addTokenToRequest(request: HttpRequest<any>, accessToken: string, refreshToken: string) {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${accessToken}`,
            ["X-Refresh-Token"]: refreshToken
        }
    });
}
