import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Pipe({
    name: 'cloudinaryPrimaryColor',
    pure: true
})

export class CloudinaryPrimaryColorPipe implements PipeTransform {
    constructor(private _cloudinaryService: CloudinaryService) { }
    
    transform(publicId: string): Observable<string> {
        if (!publicId) return of("#000");
        return this._cloudinaryService.getPrimaryColor(publicId);   
    }
}