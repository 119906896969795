import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: "spicy-meter",
    templateUrl: "./spicy-meter.component.html",
    styleUrl: "./spicy-meter.component.scss"
})
export class SpicyMeterComponent {
    @Input() spicyMeter: number = 0;
    @Input() onlyPeppers = false;

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    @ViewChild("levelGuide") levelGuide!: TemplateRef<any>;

    guide = [{
        level: 1,
        label: "Sweet",
        description: `
            There can be kissing, hand holding, and hugging; very low level of tension between the characters. There is attraction and thinking about the other character, but here is absolutely no mention of sex.
            <br>Types of language used: kiss, hug, hold hands, embrace, etc.
        `,
        rating: "Family Friendly/E Rating"
    }, {
        level: 2,
        label: "Sensual",
        description: `
            Romantic tension with fade-to-black intimacy. There could be very light, sweet love scenes or implied sex that is alluded to off-page. No graphic descriptions beyond kissing and touching.
            <br>Types of language used: caress, brush of the lips, sparks on her skin, etc.
        `,
        rating: "Teen/PG Rating"
    }, {
        level: 3,
        label: "Sexy",
        description: `
            Romance with moderately sensual scenes. Typically, more “vanilla” or modest intimacy. Kissing and maybe a sex scene can take place on the page, but it's not particularly detailed. The love scenes can be explored primarily through emotion and implication, but any graphic action "below the belt" is usually only alluded to or hinted at. Metaphors and time jumps can be used.
            <br>Types of language used: "her desire," "taste of his kiss," "warmth in her body," "tightness in her stomach," etc.
        `,
        rating: "Young Adult/PG 13+ Rating"
    }, {
        level: 4,
        label: "Steamy",
        description: `
            Includes one or more scenes of an erotic nature. Still character and emotion-driven (this is "cliterature" not porn), but there are higher levels of sexual tension and detailed intimacy. Moderate adult language and tasteful descriptions can be used.
            <br>Types of language used: "his length," "her entrance," "her core," etc.
        `,
        rating: "New Adult/M Rating"
    }, {
        level: 5,
        label: "Spicy wildfire",
        description: `
            Includes at least one graphic erotic scene. Intimacy is described in detail. It can include more taboo subjects like age gaps, forbidden enemies, or elements of dark romance. Adult language and attention to detail.
            <br>Types of language used (subjective to time period): f**k, d**k, c**k, etc.
        `,
        rating: "Adult/R Rating"
    }];

    async openLevelGuide() {
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(this.levelGuide, {
                size: "lg",
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                beforeDismiss() {
                    that._chatWidgetService.show();
                    return true;
                },
            }).shown
        );
    }
}
