<ng-container *ngIf="ds">
    <div class="row c-pb-20">
        <ng-container *ngIf="data.enableAnchor">
            <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
        </ng-container>
        <ng-container *ngIf="!data.enableAnchor">
            <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
        </ng-container>
    </div>
    <content-grid [dataSource]="ds" [columnLayout]="layoutConfig" [hideSubtitle]="true" template="bubbles"></content-grid>
    <div class="row c-pt-40" *ngIf="showCta">
        <div class="d-flex justify-content-center">
            <a [routerLink]="data.ctaUrl" class="d-inline-block btn btn-cta no-decoration slight-hover-no-bg">
                {{data.ctaText}}
            </a>
        </div>
    </div>
    <hr class="mb-0 c-mt-40 d-none d-md-block full-width-hr" *ngIf="data.showHR">
</ng-container>
