import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BookLaunch } from '../models/book-launch.model';
import * as dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { Book } from '../models/book.model';
import { Genre } from '../models/genre.model';
import { Author } from '../models/author.model';
import { BookSeries } from '../models/book-series.model';
import { CloudinaryService } from './cloudinary.service';
import { World } from '../models/world.model';
import { ContentGridDataSource } from '../modules/shared/components/content-grid/content-grid.component';

dayjs.extend(relativeTime)
@Injectable({ providedIn: 'root' })
export class UtilitiesService {
    private _renderer2: Renderer2;

    constructor(
        private _cloudinaryService: CloudinaryService,
        _rendererFactory: RendererFactory2
    ) {
        this._renderer2 = _rendererFactory.createRenderer(null, null);
    }

    saveFile(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
    }

    wait(t: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, t));
    }

    getBookUrl(book: Book, launch: boolean = false): string {
        return `/authors/${book.mainAuthorSlug}/books/${book.slug}${launch ? '/launch' : ''}`;
    }

    getBookSeriesUrl(series: BookSeries): string {
        return `/authors/${series.mainAuthorSlug}/${series.slug}`;
    }

    getAuthorUrl(author: Author): string {
        return `/authors/${author.slug}`;
    }

    getGenreUrl(genreSlug: string): string {
        return `/genres/fiction/${genreSlug}`;
    }

    getTropeUrl(tropeSlug: string): string {
        return `/tropes/fiction/${tropeSlug}`;
    }

    getWorldUrl(world: World): string {
        return `/worlds/${world.slug}`;
    }

    convertToSlug(str: string): string {
        return str
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\s-]/g, "")
            .replace(/\s+/g, "-")
            .replace(/-+/g, "-");
    }

    pidToProfilePictureUrl(pid: string, isThumbnail: boolean): string {
        let publicId = "profile-pictures/" + pid as string;
        return isThumbnail ? this._cloudinaryService.getThumbnailUrl(publicId) : this._cloudinaryService.getImageUrl(publicId);
    }

    async retry(fn: Function, retriesLeft = 5, interval = 1000) {
        try {
            await fn();
        } catch (e) {
            console.error(e);
            if (retriesLeft - 1 == 0) throw Error('maximum retries exceeded');
            this.retry(fn, retriesLeft - 1, interval);
        }
    }

    distanceToLaunch(bookLaunch: BookLaunch) {
        if (bookLaunch?.startDate) {
            let crnDate = new Date();
            let startDate = new Date(bookLaunch?.startDate);
            if (startDate <= crnDate) {
                return -1;
            }
            return dayjs(crnDate).to(dayjs(startDate), true)
        }
        return '';
    }

    unslugify(slug: string): string {
        return slug.replace(/\-/g, " ").replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase());
    }

    objectToBase64(object: any): Promise<string> {
        return new Promise((resolve, reject) => {
            const jsonString = JSON.stringify(object);
            const blob = new Blob([jsonString], { type: 'application/json' });

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result!
                    .toString()
                    .split(',')[1];
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    base64ToObject(base64: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const binaryString = atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([bytes], { type: 'application/json' });

            const reader = new FileReader();
            reader.onloadend = () => {
                const jsonString = reader.result as string;
                resolve(JSON.parse(jsonString));
            };
            reader.onerror = reject;
            reader.readAsText(blob);
        });
    }

    preloadImage(url: string) {
        const link = this._renderer2.createElement('link');
        this._renderer2.setAttribute(link, 'rel', 'preload');
        this._renderer2.setAttribute(link, 'as', 'image');
        this._renderer2.setAttribute(link, 'href', url);

        const head = this._renderer2.selectRootElement('head', true);
        this._renderer2.appendChild(head, link);
    }

    addScriptTag(url: string, options: { type: string, where: "head" | "body" } = { type: 'text/javascript', where: 'head' }) {
        const script = this._renderer2.createElement('script');
        this._renderer2.setAttribute(script, 'src', url);
        this._renderer2.setAttribute(script, 'type', options.type);

        const body = this._renderer2.selectRootElement(options.where, true);
        this._renderer2.appendChild(body, script);
    }

    getBookDataSource(books: Book[], options: { subtitle: "author" | "publishedAt" | "none", useAmazonLink?: boolean, onClick?: (b: Book) => () => void }): ContentGridDataSource<Book> {
        return {
            data: books.filter(b => {
                if (!b.isVisible) return false;
                if (options.useAmazonLink && !b.links.amazon) return false;
                return true;
            }),
            title: (b: Book) => b.title,
            subtitle: (b: Book) => {
                if (options.subtitle === "author") {
                    return b.mainAuthorName;
                } else if (options.subtitle === "publishedAt") {
                    return b.publishedAt ? dayjs(b.publishedAt).format("MMMM D, YYYY") : ""
                } else {
                    return "";
                }
            },
            image: (b: Book) => b.images.cover,
            url: (b: Book) => options.useAmazonLink ? (b.shortAmazonLink || b.links.amazon!) : this.getBookUrl(b),
            badge: {
                type: "light",
                enabled: b => Boolean(b.isFree) || Boolean(b.isPrelaunched),
                text: b => {
                    if (b.isPrelaunched) {
                        return "COMING SOON";
                    }
                    else if (b.isFree) {
                        return "FREE BOOK";
                    }
                    else {
                        return "";
                    }
                }
            },
            onClick: options.onClick
        };
    }

    getDeviceName() {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.includes("android")) {
            return "Android";
        } else if (ua.includes("iphone") || ua.includes("ipad")) {
            return "IOS";
        }
        //@ts-ignore
        const platform = navigator?.userAgentData?.platform || navigator?.platform || "unknown";
        if (platform === "Win32") return "Windows";
        if (platform === "Linux") return "Linux";
        if (platform === "MacIntel") return "Mac";

        return "unknown";
    }

    /**
     * On mobile devices, mostly Safari, the sticky container sometimes hides behind the keyboard
     * @param el The text input
     */
    scrollStickyContainerVisible(el: HTMLElement) {
        const rect = el!.getBoundingClientRect()
        const visualViewport = window.visualViewport;
        const viewportTop = visualViewport!.offsetTop;
        const viewportBottom = viewportTop + visualViewport!.height;

        if (rect.bottom > viewportBottom) {
            window.scrollTo(0, rect.top + window.scrollY);
        }
    }
}
