import { Component, Input } from '@angular/core';

@Component({
    selector: 'avatar-label',
    templateUrl: './avatar-label.component.html',
    styleUrls: ['./avatar-label.component.scss']
})
export class AvatarLabelComponent {
    @Input()
    username!: string;

    @Input()
    size: 'small' | 'medium' | 'large' = 'medium';
}
