<div class="avatar-label d-flex align-items-start size-{{size}}">
    <!-- <img [src]="user.profilePictureUrl" alt="Profile picture" class="rounded-circle me-3"> -->
    <p class="mb-0">
        <!-- <span class="name fw-semibold">
            {{ user.username }}
        </span> -->
        <span class="username fw-semibold">
            {{'@'}}{{ username }}
        </span>
    </p>
</div>
