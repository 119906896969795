import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'feather-icon',
    templateUrl: './feather-icon.component.html',
    styleUrls: ['./feather-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatherIconComponent implements OnChanges, AfterViewInit {
        
    @Input()
    icon: string = '';

    @Input()
    width: string = "20";

    @Input()
    height: string = "20";

    @ViewChild("svgUse")
    svgUse?: ElementRef;

    iconPath: string = '';

    ngAfterViewInit(): void {
        this.refreshIcon();   
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['icon']) {
            this.refreshIcon();
        }
    }

    private refreshIcon() {
        if (this.icon && this.svgUse) {
            this.iconPath = `assets/icons/feather/feather-sprite.svg#${this.icon}`;
            this.svgUse.nativeElement.setAttribute('href', this.iconPath);
        }
    }
}
