@if (showCountDown && countdown) {
    <span class="fomo-text" [style]="size === 'big' ? 'font-size: 14px;' : 'font-size:12px;'">
        @if (showText) {
            <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.5 9.455V5C25.5 4.33696 25.2366 3.70107 24.7678 3.23223C24.2989 2.76339 23.663 2.5 23 2.5H9C8.33696 2.5 7.70107 2.76339 7.23223 3.23223C6.76339 3.70107 6.5 4.33696 6.5 5V9.5C6.50096 9.88794 6.59175 10.2704 6.76524 10.6174C6.93874 10.9644 7.19023 11.2665 7.5 11.5L13.5 16L7.5 20.5C7.19023 20.7335 6.93874 21.0356 6.76524 21.3826C6.59175 21.7296 6.50096 22.1121 6.5 22.5V27C6.5 27.663 6.76339 28.2989 7.23223 28.7678C7.70107 29.2366 8.33696 29.5 9 29.5H23C23.663 29.5 24.2989 29.2366 24.7678 28.7678C25.2366 28.2989 25.5 27.663 25.5 27V22.545C25.4996 22.1577 25.4099 21.7758 25.2377 21.4289C25.0656 21.082 24.8157 20.7795 24.5075 20.545L18.4875 16L24.5075 11.45C24.815 11.216 25.0645 10.9143 25.2366 10.5683C25.4087 10.2224 25.4989 9.84142 25.5 9.455ZM22.5 5.5V9H9.5V5.5H22.5ZM16 14.125L13.1663 12H18.8037L16 14.125ZM22.5 26.5H9.5V22.75L14.5 19V21C14.5 21.3978 14.658 21.7794 14.9393 22.0607C15.2206 22.342 15.6022 22.5 16 22.5C16.3978 22.5 16.7794 22.342 17.0607 22.0607C17.342 21.7794 17.5 21.3978 17.5 21V19.0138L22.5 22.7938V26.5Z" fill="#93344A"/>
            </svg>
            <span class="c-ms-5 c-me-8">{{text}}</span>
        }
        <span>
            <span class="countdown-badge" [ngClass]="{'red': colorScheme === 'red'}"  [style]="countdown.days ? 'width: 145px;' : 'width:115px;'">
                @if (countdown.days) {
                    <span>{{countdown.days}}d</span>
                    <span class="separator">|</span>
                }
                <span>{{countdown.hours}}h</span>
                <span class="separator">|</span>
                <span>{{countdown.minutes}}m</span>
                <span class="separator">|</span>
                <span>{{countdown.seconds}}s</span>
            </span>
        </span>
    </span>
}