import { Injectable } from "@angular/core";
import { FreeShelfOptions, LibraryService } from "../services/library.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { Library, Shelf } from "../models/library.model";
import { AnonymousPersistentState } from "../services/anonymous-persistent-state";


@Injectable({providedIn: "root"})
export class LibraryStateService {
    
    library$: BehaviorSubject<Library | null> = new BehaviorSubject<Library | null>(null);
    freeShelf$: BehaviorSubject<Shelf | null> = new BehaviorSubject<Shelf | null>(null);

    constructor(
        private readonly _libraryService: LibraryService,
        private readonly _anonymousPersistentState: AnonymousPersistentState
    ) { }

    init(): void {
        this.loadLibrary();
        this.loadFreeShelf();
    }

    private loadLibrary() {
        this._libraryService.find().subscribe(library => {
            this.library$.next(library);
        });
    }

    private loadFreeShelf() {
        this._libraryService.findFreeShelf().subscribe(freeShelf => {
            this.freeShelf$.next(freeShelf);
            freeShelf.reading = freeShelf.reading || {};
            //restore progress from shelf to local only if local doesn't have any progress for that readable
            for (const [readableSlug, value] of Object.entries(freeShelf.reading)) {
                if (!this._anonymousPersistentState.reading[readableSlug]) {
                    this._anonymousPersistentState.saveReading(readableSlug, value);
                }
            }
            //restore progress from local to shelf only if shelf doesn't have any progress for that readable
            const toSync: Shelf["reading"] = {};
            for (const [readableSlug, value] of Object.entries(this._anonymousPersistentState.reading)) {
                if (!freeShelf.reading[readableSlug]) {
                    toSync[readableSlug] = value;
                }
            }
            if (Object.keys(toSync).length) {
                firstValueFrom(this._libraryService.updateFreeBooksReadingProgress(toSync));
            }
        });
    }

    addBookToFreeShelf(bookId: number, options: FreeShelfOptions = {}): void {
        this._libraryService.addBookToFreeShelf(bookId, options).subscribe(library => {
            this.library$.next(library);
            this.freeShelf$.next(library.shelves.find(s => s.type === "free")!);
        });
    }

}