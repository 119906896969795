import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Book, BookReview } from 'src/app/models/book.model';


export interface FeaturedReviewsConfig {
    header: string;
    reviews: BookReview[];
    enableAnchor: boolean;
    anchorSlug?: string;
}

@Component({
  selector: 'worldmaker-component-featured-reviews',
  templateUrl: './featured-reviews.component.html',
  styleUrls: ['./featured-reviews.component.scss']
})
export class FeaturedReviewsComponent implements OnInit, OnChanges {

    @Input()
    data!: FeaturedReviewsConfig;

    singleBookMode: boolean = false;
    singleBook: Book | null = null;
    linksEnabled: boolean = true;

    constructor(
        private _router: Router
    ) {}

    ngOnInit(): void {
        if (this.data) {
            this.checkForSingleBookMode();
        }
        if (this._router.url.includes("/pages")) {
            this.linksEnabled = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            this.checkForSingleBookMode();
        }
    }


    private checkForSingleBookMode() {
        // console.log("Checking for single book mode");
        const bookIds = new Set<number>();
        this.data.reviews.forEach((review: BookReview) => {
            if (review.book && review.book.id) {
                bookIds.add(review.book.id);
            }
        });
        this.singleBookMode = bookIds.size === 1;
        this.singleBook = this.singleBookMode ? this.data.reviews[0].book : null;
    }
}
