<form (submit)="postReply($event)">
    <textarea [readOnly]="posting | async" rows="3" name="replyText" type="text" class="form-control no-resize" [(ngModel)]="replyText" [placeholder]="prompt" (keyup)="onReplyTextChanged()" required></textarea>
    <button [disabled]="lengthError || !replyText.trim()" class="c-mt-20 btn btn-primary btn-round" type="submit" [disabled]="posting | async">
        <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="posting | async"></span>
        {{(posting | async) ? "Posting" : "Post"}}
    </button>
    <div class="form-group">
        <small *ngIf="lengthError" class="text-danger">
            Oops! You've gone over the character limit
        </small>
    </div>
</form>
