import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';

@Pipe({
    name: 'mainAuthorRouterLink',
    pure: true
})

export class MainAuthorRouterLink implements PipeTransform {
    transform(book: Book): string[] {
        if (!book || !book.mainAuthorSlug) {
            console.warn("MainAuthorUrlPipe: invalid book", book);
            return [];
        }
        return `/authors/${book.mainAuthorSlug}`.split("/");
    }
}
