import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Comment } from '../../../../models/comment.model';
import { Discussion } from '../../../../models/discussion.model';
import { DiscussionService, DiscussionType } from '../../discussion.service';
import { UserService } from 'src/app/services/user.service';
import { nanoid } from "nanoid";
import { AddReplyDTO } from '../../models/add-reply.dto';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AddReplyEventArgs } from '../../models/add-reply-event';
import { Book } from 'src/app/models/book.model';
import { BookSeoService } from 'src/app/services/book-seo.service';
import { DiscussionShareService } from '../../discussionShare.service';
import { PlatformService } from 'src/app/services/platform.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { filter, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'discussion',
    templateUrl: './discussion.component.html',
    styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {
    @Input()
    book!: Book; // TODO: Make it nullable when we support worlds

    @Input()
    type: DiscussionType = DiscussionType.BOOK; //Not in use right now. Will be used when we support worlds

    @Input()
    allowPin: boolean | null = false;

    @Input()
    header = "Discussions";

    @Input()
    enableAnchor?: boolean;

    @Input()
    anchorSlug?: string;

    @Input()
    showCommentInput = true;

    @Input()
    autoAddSEOMetadata = true;

    posting = new BehaviorSubject(false);
    postingInner = new BehaviorSubject(false);

    private _newCommentGlowInterval: number = 3000;

    private _discussion: Discussion | undefined;

    get discussion(): Discussion | undefined {
        return this._discussion;
    }

    set discussion(value: Discussion | undefined) {
        this._discussion = value;
        this.onDiscussionChanged();
    }

    activeThreadId?: string | undefined = undefined;
    newCommentId: string = "";
    
    get prompt(): string {
        if (this.discussion?.comments && this.discussion.comments.length > 0) {
            return "Add a comment";
        }
        else {
            return "It seems you are the first one hear. Start the discussion!";
        }
    }

    get user() {
        return this._embeddedAuthService.user;
    }

    postRequestInProgress$ = this._route.queryParams.pipe(
        filter(params => params["action"] === "post_comment"),
        filter(() => this._platformService.isBrowser())
    );

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private discussionService: DiscussionService,
        private _discussionShareService: DiscussionShareService,
        private platformService: PlatformService,
        private _embeddedAuthService: EmbeddedAuthService,
        private _bookSeoService: BookSeoService,
        private readonly _anonymousPersistentState: AnonymousPersistentState
    ) { }

    async ngOnChanges() {
        await this.refresh();
    }

    async ngOnInit() {
        await this.refresh();
        //catch and show comments created "outside"
        this._discussionShareService.newComment$.pipe(
            filter(comment => !!comment && !!this.discussion && comment.discussion === this.discussion.id)
        ).subscribe((comment) => {
            this.setNewCommentId(comment!.id); 
            this.insertNewCommentAtBestSlotAndScroll(comment!);
        });
    }

    async refresh() {
        if (this.book) {
            // TODO: Handle worlds when we support them
            this.discussion = await this.discussionService.getDiscussionByBookId(this.book.id as number);
            //magic-link action (doing it here after we have loaded the discussion)
            this.postRequestInProgress$.subscribe(async () => {
                if (this._anonymousPersistentState.pendingComment && this._anonymousPersistentState.pendingComment.discussion === this.discussion!.id) {
                    await this.addComment(
                        this._anonymousPersistentState.pendingComment.content,
                        this.discussion!.comments.find(c => c.id === this._anonymousPersistentState.pendingComment!.threadId)!,
                        this._anonymousPersistentState.pendingComment.metadata
                    );
                }
            });
        }
    }

    async onReply(event: AddReplyEventArgs) {
        let data = event.data;
        this.postingInner.next(true);
        await this.addComment(data.replyText, data.comment, data.metadata);
        this.postingInner.next(false);
    }

    // Initiates the covnersation
    async topLevelComment(event: AddReplyEventArgs) {
        this.posting.next(true);
        await this.addComment(event.data.replyText, null, event.data.metadata);
        this.posting.next(false);
    }

    async onCommentRemove(comment: Comment) {
        this.discussion = await this.discussionService.removeComment(comment.id);
        await this.refresh();
    }

    //auto-expand the replies if there is a comment by the author in them
    autoExpandReplies(comment: Comment) {
        if (this.activeThreadId === comment.id) return true; //should also expand if we are the active thread
        if (!comment.comments || comment.comments.length === 0) return false;
        for (const c of comment.comments) {
            if (c.byAuthor) {
                return true;
            }
        }
        return false;
    }

    private async addComment(text: string, parentComment: Comment | null, metadata?: {[key: string]: any}) {
        this.activeThreadId = undefined;
        let threadId = parentComment?.id;
        let newComment = await this.discussionService.addComment(this.book.id as number, {text, threadId: parentComment?.id, metadata});
        if (this.discussion) {
            if (!parentComment) {
                this.insertNewCommentAtBestSlotAndScroll(newComment);
            }
            else {
                let activeComment = this.discussionService.getTopMostComment(parentComment);
                activeComment.comments = [
                    newComment,
                    ...activeComment.comments || []
                ]
                setTimeout(() => {
                    try {
                        //@ts-ignore
                        document.querySelector(`#comment-${activeComment.id}`)?.scrollIntoView({ behavior: 'instant', block: 'start' })
                    } catch (_) {}
                });
            }
        }
        // await this.refresh();
        if (threadId) {
            // Keep the thread expanded
            this.activeThreadId = threadId;
        }
        this.setNewCommentId(newComment.id);
        this._anonymousPersistentState.clearPendingComment();
    }

    private insertNewCommentAtBestSlotAndScroll(newComment: Comment) {
        //we are the first comment
        if (this.discussion!.comments.length === 0) {
            this.discussion!.comments.unshift(newComment);
        } else {
            //find the first slot after pinned comments, author comments and comments with replies
            let slot = -1;
            for (const [i, comment] of this.discussion!.comments.entries()) {
                if (comment.pinned) {
                    continue;
                } else if (comment.byAuthor) {
                    continue;
                } else if (comment.comments && comment.comments.length > 0) {
                    continue;
                }
                slot = i;
                break;
            }
            if (slot > -1) {
                this.discussion!.comments.splice(slot, 0, newComment);
            } else {
                this.discussion!.comments.unshift(newComment);
            }
        }
        setTimeout(() => {
            try {
                //@ts-ignore
                document.querySelector(`#comment-${newComment.id}`)?.scrollIntoView({ behavior: 'instant', block: 'start' })
            } catch (_) {}
        });
    }

    private onDiscussionChanged() {
        if (this.platformService.isServer()) {
            // Render JSONLD Schema
            if (this.autoAddSEOMetadata && this.discussion?.comments?.length) {
                this._bookSeoService.update(this.book, this.discussion.comments);
            }
        }
        else {
            if (this.discussion?.comments?.length) {
                this.discussion.comments = this.discussionService.connectCommentsWithDFS(this.discussion.comments);
            }
        }
    }

    private setNewCommentId(id: string) {
        this.newCommentId = id;
        setTimeout(() => {
            this.newCommentId = ""
        }, this._newCommentGlowInterval);
    }

    async onPinChange(comment: Comment) {
        await this.refresh();
    }
}
