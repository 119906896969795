import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';

@Pipe({
    name: 'amazonLink'
})

export class AmazonLinkPipe implements PipeTransform {
    transform(book: Book): string {
        return book.shortAmazonLink ?? book.links.amazon ?? "";
    }
}