<ng-container *ngIf="url && url.length && !externalLink">
    <a class="d-block no-decoration content-card slight-hover-no-bg" [routerLink]="url" (click)="cardClicked()">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
</ng-container>
<ng-container *ngIf="url && url.length && externalLink">
    <a class="d-block no-decoration content-card slight-hover-no-bg" [href]="url" target="_blank" (click)="cardClicked()">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
</ng-container>
<ng-container *ngIf="!url || !url.length">
    <div class="d-block no-decoration content-card slight-hover-no-bg" (click)="cardClicked()">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
</ng-container>

<ng-template #contentTemplate>
    <!-- DEFAULT TEMPLATE -->
    <ng-container *ngIf="template == 'default'">
        <div class="outer-container">
            <div class="position-relative inner-container">
                <div class="overlay">
                    <svg width="175" height="182" viewBox="0 0 175 182" fill="none" xmlns="http://www.w3.org/2000/svg"
                        *ngIf="video && !locked">
                        <g filter="url(#filter0_d_2648_4750)">
                            <path
                                d="M112.5 86.6699C115.833 88.5944 115.833 93.4056 112.5 95.3301L67.5 121.311C64.1667 123.235 60 120.83 60 116.981L60 65.0192C60 61.1702 64.1667 58.7646 67.5 60.6891L112.5 86.6699Z"
                                fill="white" />
                        </g>
                        <defs>
                            <filter id="filter0_d_2648_4750" x="0" y="0.0117798" width="175" height="181.976"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="30" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2648_4750" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2648_4750"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    <div *ngIf="locked">
                        <feather-icon icon="lock" height="60" width="60"></feather-icon>
                        <h5>Subscribe to unlock content</h5>
                    </div>
                </div>
                <img *ngIf="image" class="w-100 content-card-image" [src]="image | cloudinaryScaleHeight:400 | cloudinaryQuality:100 | cloudinaryFormat | cloudinaryUrl" alt="Content card image"
                    [ngClass]="{'exclusive': locked}" loading="{{lazy ? 'lazy' : 'eager'}}">
                <video *ngIf="video && !locked" [src]="video" autoplay playsinline [muted]="'muted'" loop
                    class="w-100 content-card-image">
                </video>
                <img *ngIf="video && locked" class="w-100 content-card-image exclusive" [src]="video | cloudinaryThumb"
                    alt="Content card image" loading="{{lazy ? 'lazy' : 'eager'}}">
                <span *ngIf="badge && badge.enabled"
                    class="position-absolute badge rounded-pill text-bg-{{badge.type}}">
                    {{badge.text}}
                </span>
                <span *ngIf="bottomBadge" class="position-absolute bottom-badge">
                    <span>{{bottomBadge}}</span>
                </span>
            </div>
        </div>

        <div class="mt-2 d-flex flex-column content-card-info">
            <horizontal-scroll-ticker [active]="hovering" [text]="title" [bold]="true"></horizontal-scroll-ticker>
            <!-- <strong>
                {{title}}
            </strong> -->
            <small *ngIf="!hideSubtitle && subtitle" class="subtitle">{{subtitle}}</small>
        </div>
    </ng-container>


    <!-- BUBBLES TEMPLATE -->
    <ng-container *ngIf="template == 'bubbles'">
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="image">
            <img [src]="image | cloudinaryFormat | cloudinaryScaleHeight : 300 | cloudinaryQuality : 100 | cloudinaryUrl" [alt]="title" class="bubble-image" loading="{{lazy ? 'lazy' : 'eager'}}">
            <h5 class="text-center bubble-header c-mb-0 c-mt-10">
                {{ title }}
            </h5>
        </div>
    </ng-container>
</ng-template>