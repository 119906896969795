import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[world_page_template_slot_header]',
})
export class WorldPageTemplateSlotHeader {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section1]',
})
export class WorldPageTemplateSlotSection1 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section2]',
})
export class WorldPageTemplateSlotSection2 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section3]',
})
export class WorldPageTemplateSlotSection3 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section4]',
})
export class WorldPageTemplateSlotSection4 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section5]',
})
export class WorldPageTemplateSlotSection5 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section6]',
})
export class WorldPageTemplateSlotSection6 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section7]',
})
export class WorldPageTemplateSlotSection7 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section8]',
})
export class WorldPageTemplateSlotSection8 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section9]',
})
export class WorldPageTemplateSlotSection9 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[world_page_template_slot_section10]',
})
export class WorldPageTemplateSlotSection10 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}