import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import {
    worldMakerComponentTable,
    WorldMakerComponentNames,
} from '../../worldmaker.module';

import {
    EmbeddedPageTemplateSlotSection1,
    EmbeddedPageTemplateSlotSection2,
    EmbeddedPageTemplateSlotSection3,
    EmbeddedPageTemplateSlotSection4,
    EmbeddedPageTemplateSlotSection5,
    EmbeddedPageTemplateSlotSection6
} from './embedded_page_template.slots';

type WorldMakerInput = {
    path: string;
    template: string;
    components: {
        data: object;
        name: WorldMakerComponentNames;
        slot: 'section1' | 'section2' | 'section3' | 'section4' | 'section5' | 'section6';
    }[];
}

@Component({
    selector: 'worldmaker-embedded-page-template',
    templateUrl: './embedded_page_template.component.html',
    styleUrls: ['./embedded_page_template.component.scss'],
})
export class EmbeddedPageTemplate {
    @ViewChild(EmbeddedPageTemplateSlotSection1, { static: true })
    section1!: EmbeddedPageTemplateSlotSection1;
    @ViewChild(EmbeddedPageTemplateSlotSection2, { static: true })
    section2!: EmbeddedPageTemplateSlotSection2;
    @ViewChild(EmbeddedPageTemplateSlotSection3, { static: true })
    section3!: EmbeddedPageTemplateSlotSection3;
    @ViewChild(EmbeddedPageTemplateSlotSection4, { static: true })
    section4!: EmbeddedPageTemplateSlotSection4;
    @ViewChild(EmbeddedPageTemplateSlotSection5, { static: true })
    section5!: EmbeddedPageTemplateSlotSection5;
    @ViewChild(EmbeddedPageTemplateSlotSection6, { static: true })
    section6!: EmbeddedPageTemplateSlotSection6;

    @Input()
    set worldMakerData(value: WorldMakerInput){
        this.dataObsevable$.next(value);
    }

    dataObsevable$ = new ReplaySubject<WorldMakerInput>();

    ngOnInit() {
        this.dataObsevable$.subscribe((current) => {
            //clear all slots first
            this.section1.viewContainerRef.clear();
            this.section2.viewContainerRef.clear();
            this.section3.viewContainerRef.clear();
            this.section4.viewContainerRef.clear();
            this.section5.viewContainerRef.clear();
            this.section6.viewContainerRef.clear();
            //re-create them
            for (const c of current.components) {
                const viewContainerRef = this[c.slot].viewContainerRef;
    
                const toLoad = worldMakerComponentTable[c.name];
                const componentRef = viewContainerRef.createComponent<any>(toLoad);
                componentRef.instance.data = c.data;
            }
        });
    }
}
