import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { RouterModule } from '@angular/router';
import { DiscussionModule } from '../discussion/discussion.module';
import { BooksModule } from '../books/books.module';
import { ReadablesModule } from '../readables/readables.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { BookPageTemplate } from './templates/book_page_template/book_page_template.component';
import { WorldPageTemplate } from './templates/world_page_template/world_page_template.component';
import { ExplorePageTemplate } from './templates/explore_page_template/explore_page_template.component';
import { SeriesPageTemplate } from './templates/series_page_template/series_page_template.component';
import { LandingPageTemplate } from './templates/landing_page_template/landing_page_template.component';
import { EmbeddedPageTemplate } from './templates/embedded_page_template/embedded_page_template.component';
import {
    BookPageTemplateSlotHeader,
    BookPageTemplateSlotSection1,
    BookPageTemplateSlotSection2,
    BookPageTemplateSlotSection3,
    BookPageTemplateSlotSection4,
    BookPageTemplateSlotSection5,
    BookPageTemplateSlotSection6
} from './templates/book_page_template/book_page_template.slots';
import {
    WorldPageTemplateSlotHeader,
    WorldPageTemplateSlotSection1,
    WorldPageTemplateSlotSection2,
    WorldPageTemplateSlotSection3,
    WorldPageTemplateSlotSection4,
    WorldPageTemplateSlotSection5,
    WorldPageTemplateSlotSection6,
    WorldPageTemplateSlotSection7,
    WorldPageTemplateSlotSection8,
    WorldPageTemplateSlotSection9,
    WorldPageTemplateSlotSection10
} from './templates/world_page_template/world_page_template.slots';
import {
    ExplorePageTemplateSlotHeader,
    ExplorePageTemplateSlotSection1,
    ExplorePageTemplateSlotSection2,
    ExplorePageTemplateSlotSection3,
    ExplorePageTemplateSlotSection4,
    ExplorePageTemplateSlotSection5,
    ExplorePageTemplateSlotSection6,
    ExplorePageTemplateSlotSection7,
    ExplorePageTemplateSlotSection8,
    ExplorePageTemplateSlotSection9,
    ExplorePageTemplateSlotSection10
} from "./templates/explore_page_template/explore_page_template.slots";
import {
    SeriesPageTemplateSlotHeader,
    SeriesPageTemplateSlotSection1,
    SeriesPageTemplateSlotSection2,
    SeriesPageTemplateSlotSection3,
    SeriesPageTemplateSlotSection4,
    SeriesPageTemplateSlotSection5,
    SeriesPageTemplateSlotSection6
} from "./templates/series_page_template/series_page_template.slots";
import {
    LandingPageTemplateSlotHeader,
    LandingPageTemplateSlotSection1,
    LandingPageTemplateSlotSection2,
    LandingPageTemplateSlotSection3,
    LandingPageTemplateSlotSection4,
    LandingPageTemplateSlotSection5,
    LandingPageTemplateSlotSection6
} from "./templates/landing_page_template/landing_page_template.slots";
import {
    EmbeddedPageTemplateSlotSection1,
    EmbeddedPageTemplateSlotSection2,
    EmbeddedPageTemplateSlotSection3,
    EmbeddedPageTemplateSlotSection4,
    EmbeddedPageTemplateSlotSection5,
    EmbeddedPageTemplateSlotSection6
} from './templates/embedded_page_template/embedded_page_template.slots';

import { SubscribeCoverHeader } from './components/subscribe-cover-header/subscribe-cover-header.component';
import { BookInfo } from './components/book-info/book-info.component';
import { SimilarBooksManual } from './components/similar-books-manual/similar-books-manual.component';
import { WorldInfoManual } from './components/world-info-manual/world-info-manual.component';
import { MediaGrid } from './components/media-grid/media-grid.component';
import { FeaturedWorldManual } from './components/featured-world-manual/featured-world-manual.component';
import { FeaturedWorldsGridManual } from './components/featured-worlds-grid-manual/featured-worlds-grid-manual.component';
import { SeriesInfo } from './components/series-info/series-info.component';
import { MediaSwimLane } from './components/media-swim-lane/media-swim-lane.component';
import { PrelaunchCoverHeader } from './components/prelaunch-cover-header/prelaunch-cover-header.component';
import { SpotifyPlayer } from './components/spotify-player/spotify-player.component';
import { LaunchCoverHeader } from './components/launch-cover-header/launch-cover-header.component';
import { Discussion } from './components/discussion/discussion.component';
import { PrelaunchCoverHeader2 } from './components/prelaunch-cover-header-2/prelaunch-cover-header-2.component';
import { FreeBookCoverHeader } from './components/free-book-cover-header/free-book-cover-header.component';
import { FeaturedAuthorsComponent } from './components/featured-authors/featured-authors.component';
import { TropesBadgeListComponent } from './components/tropes-badge-list/tropes-badge-list.component';
import { FeaturedWorldsGridComponent } from './components/featured-worlds-grid/featured-worlds-grid.component';
import { FeaturedCarouselComponent } from './components/featured-carousel/featured-carousel.component';
import { FeaturedReviewsComponent } from './components/featured-reviews/featured-reviews.component';
import { FeaturedPrelaunchesComponent } from './components/featured-prelaunches/featured-prelaunches.component';
import { SmartBookCoverHeader } from './components/smart-book-cover-header/smart-book-cover-header.component';
import { HtmlEmbedComponent } from './components/html-embed/html-embed.component';
import { TypeformEmbedComponent } from './components/typeform-embed/typeform-embed.component';
import { WorldInfoManual2 } from './components/world-info-manual-2/world-info-manual-2.component';
import { FreeBookBundleComponent } from './components/free-book-bundle/free-book-bundle.component';
import { FeaturedLinks } from './components/featured-links/featured-links.component';
import { CustomComponentLoader } from './components/custom-component-loader/custom-component-loader.component';
import { FreeBookFAQ } from './components/free-book-faq/free-book-faq.component';
import { NewRelease } from './components/new-release/new-release.component';
import { BookRecommendations } from './components/book-recommendations/book-recommendations.component';
import { ArcCoverHeader } from './components/arc-cover-header/arc-cover-header.component';

const TEMPLATES = [
    WorldPageTemplate,
    BookPageTemplate,
    ExplorePageTemplate,
    SeriesPageTemplate,
    LandingPageTemplate,
    EmbeddedPageTemplate
];

const COMPONENTS = [
    SubscribeCoverHeader,
    BookInfo,
    SimilarBooksManual,
    WorldInfoManual,
    MediaGrid,
    FeaturedWorldManual,
    FeaturedWorldsGridManual,
    SeriesInfo,
    MediaSwimLane,
    PrelaunchCoverHeader,
    SpotifyPlayer,
    LaunchCoverHeader,
    Discussion,
    PrelaunchCoverHeader2,
    FreeBookCoverHeader,
    FeaturedAuthorsComponent,
    TropesBadgeListComponent,
    FeaturedWorldsGridComponent,
    FeaturedCarouselComponent,
    FeaturedReviewsComponent,
    FeaturedPrelaunchesComponent,
    SmartBookCoverHeader,
    HtmlEmbedComponent,
    WorldInfoManual2,
    TypeformEmbedComponent,
    FreeBookBundleComponent,
    FeaturedLinks,
    CustomComponentLoader,
    FreeBookFAQ,
    NewRelease,
    BookRecommendations,
    ArcCoverHeader
];

const SLOTS = [
    BookPageTemplateSlotHeader,
    BookPageTemplateSlotSection1,
    BookPageTemplateSlotSection2,
    BookPageTemplateSlotSection3,
    BookPageTemplateSlotSection4,
    BookPageTemplateSlotSection5,
    BookPageTemplateSlotSection6,

    WorldPageTemplateSlotHeader,
    WorldPageTemplateSlotSection1,
    WorldPageTemplateSlotSection2,
    WorldPageTemplateSlotSection3,
    WorldPageTemplateSlotSection4,
    WorldPageTemplateSlotSection5,
    WorldPageTemplateSlotSection6,
    WorldPageTemplateSlotSection7,
    WorldPageTemplateSlotSection8,
    WorldPageTemplateSlotSection9,
    WorldPageTemplateSlotSection10,

    ExplorePageTemplateSlotHeader,
    ExplorePageTemplateSlotSection1,
    ExplorePageTemplateSlotSection2,
    ExplorePageTemplateSlotSection3,
    ExplorePageTemplateSlotSection4,
    ExplorePageTemplateSlotSection5,
    ExplorePageTemplateSlotSection6,
    ExplorePageTemplateSlotSection7,
    ExplorePageTemplateSlotSection8,
    ExplorePageTemplateSlotSection9,
    ExplorePageTemplateSlotSection10,

    SeriesPageTemplateSlotHeader,
    SeriesPageTemplateSlotSection1,
    SeriesPageTemplateSlotSection2,
    SeriesPageTemplateSlotSection3,
    SeriesPageTemplateSlotSection4,
    SeriesPageTemplateSlotSection5,
    SeriesPageTemplateSlotSection6,

    LandingPageTemplateSlotHeader,
    LandingPageTemplateSlotSection1,
    LandingPageTemplateSlotSection2,
    LandingPageTemplateSlotSection3,
    LandingPageTemplateSlotSection4,
    LandingPageTemplateSlotSection5,
    LandingPageTemplateSlotSection6,

    EmbeddedPageTemplateSlotSection1,
    EmbeddedPageTemplateSlotSection2,
    EmbeddedPageTemplateSlotSection3,
    EmbeddedPageTemplateSlotSection4,
    EmbeddedPageTemplateSlotSection5,
    EmbeddedPageTemplateSlotSection6
];

@NgModule({
    declarations: [...TEMPLATES, ...COMPONENTS, ...SLOTS],
    imports: [
        CommonModule,
        AuthModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DiscussionModule,
        RouterModule,
        BooksModule,
        ReadablesModule,
        NgbTooltipModule
    ],
    exports: [...TEMPLATES, ...COMPONENTS]
})
export class WorldMakerModule {}

export const worldMakerComponentTable = {
    subscribe_cover_header: SubscribeCoverHeader,
    book_info: BookInfo,
    similar_books_manual: SimilarBooksManual,
    world_info_manual: WorldInfoManual,
    media_grid: MediaGrid,
    featured_world_manual: FeaturedWorldManual,
    featured_worlds_grid_manual: FeaturedWorldsGridManual,
    series_info: SeriesInfo,
    media_swim_lane: MediaSwimLane,
    prelaunch_cover_header: PrelaunchCoverHeader,
    spotify_player: SpotifyPlayer,
    launch_cover_header: LaunchCoverHeader,
    discussion: Discussion,
    prelaunch_cover_header_2: PrelaunchCoverHeader2,
    free_book_cover_header: FreeBookCoverHeader,
    featured_authors: FeaturedAuthorsComponent,
    tropes_badge_list: TropesBadgeListComponent,
    featured_worlds_grid: FeaturedWorldsGridComponent,
    featured_carousel: FeaturedCarouselComponent,
    featured_reviews: FeaturedReviewsComponent,
    featured_prelaunches: FeaturedPrelaunchesComponent,
    smart_book_cover_header: SmartBookCoverHeader,
    html_embed: HtmlEmbedComponent,
    typeform_embed: TypeformEmbedComponent,
    world_info_manual_2: WorldInfoManual2,
    free_book_bundle: FreeBookBundleComponent,
    featured_links: FeaturedLinks,
    custom_component_loader: CustomComponentLoader,
    free_book_faq: FreeBookFAQ,
    new_release: NewRelease,
    book_recommendations: BookRecommendations
};

export type WorldMakerComponentNames = keyof (typeof worldMakerComponentTable);
