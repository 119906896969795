import { Injectable } from '@angular/core';
import { Cloudinary, CloudinaryImage, Transformation } from '@cloudinary/url-gen';
import { environment } from 'src/environments/environment';
import {thumbnail, scale, crop} from "@cloudinary/url-gen/actions/resize";
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import {FocusOn} from "@cloudinary/url-gen/qualifiers/focusOn";
import { HttpClient } from '@angular/common/http';
import { Observable, map, of, tap } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable({providedIn: 'root'})
export class CloudinaryService {
    private _cloudinary: Cloudinary;

    constructor(
        private readonly _http: HttpClient,
        private readonly _platformService: PlatformService
    ) { 
        this._cloudinary = new Cloudinary({
            cloud: {
                cloudName: environment.cloudinary.cloudName
            }
        })
    }

    getImage(publicId: string) {
        return this._cloudinary.image(publicId)
    }

    

    scale(input: CloudinaryImage | string, width: number, height: number): CloudinaryImage {
        let img: CloudinaryImage
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        }
        else {
            img = input;
        }
        return img?.resize(scale().width(width).height(height));
    }

    scaleByWidth(input: CloudinaryImage | string, width: number): CloudinaryImage {
        let img: CloudinaryImage;
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        } 
        else {
            img = input;
        }
        return img?.resize(scale().width(width));
    }
    
    scaleByHeight(input: CloudinaryImage | string, height: number): CloudinaryImage {
        let img: CloudinaryImage;
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        } 
        else {
            img = input;
        }
        return img?.resize(scale().height(height));
    }

    crop(input: CloudinaryImage | string, width: number, height: number): CloudinaryImage {
        let img: CloudinaryImage
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        }
        else {
            img = input;
        }
        return img?.resize(crop().width(width).height(height));
    }

    cropOnlyWidth(input: CloudinaryImage | string, width: number): CloudinaryImage {
        let img: CloudinaryImage
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        }
        else {
            img = input;
        }
        return img?.resize(crop().width(width).addFlag("ignore_aspect_ratio"));
    }

    cropOnlyHeight(input: CloudinaryImage | string, height: number): CloudinaryImage {
        let img: CloudinaryImage
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        }
        else {
            img = input;
        }
        return img?.resize(crop().height(height).addFlag("ignore_aspect_ratio"));
    }
    
    changeQuality(input: CloudinaryImage | string, quality: string | number): CloudinaryImage {
        let img: CloudinaryImage;
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        } 
        else {
            img = input;
        }
        return img?.quality(quality);
    }

    changeFormat(input: CloudinaryImage | string, format: string): CloudinaryImage {
        let img: CloudinaryImage;
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId);
        } 
        else {
            img = input;
        }
        return img?.format(format);
    }
    
    getThumbnailUrl(input: CloudinaryImage | string): string {
        let img: CloudinaryImage;
        if (typeof input === "string") {
            let publicId = this.extractPublicIdFromUrl(input);
            img = this._cloudinary.image(publicId)
        }
        else {
            img = input;
        }
        img = (img as CloudinaryImage).resize(
            thumbnail().width(64).height(64).gravity(focusOn(FocusOn.face()))
        ).quality(80).format("auto");
        return img?.toURL();
    }

    getImageUrl(input: string) {
        return this._cloudinary.image(this.extractPublicIdFromUrl(input)).toURL();
    }

    extractPublicIdFromUrl(url: string): string {
        // Support passing an already extracted public ID
        if (!url.includes("https")) {
            return url;
        }

        // If it's a URL it must be cloudinary
        if (!url.includes("res.cloudinary")) {
            throw new Error("Not a valid Cloudinary URL");
        }

        // if it has a subfolder, it is part of the id
        let splits = url.split("/");
        if (splits.length === 9) {
            let last = splits[splits.length - 1];
            let folder = splits[splits.length - 2];
            let version = splits[splits.length - 3];
            return version.startsWith("v") ? `${version}/${folder}/${last}` : `${folder}/${last}`;
        } else {
            let last = splits[splits.length - 1];
            let version = splits[splits.length - 2];
            return version.startsWith("v") ? `${version}/${last}` : last;
        }
    }

    publicIdSimplified(url: string): string {
        if (!url) return '';

        // if it has a subfolder, it is part of the id
        const parts = url.split('/');
        let filename;
        if (parts.length === 9) {
            filename = parts.slice(parts.length - 2).join("/");
        } else {
            filename = parts.pop();
        }

        return filename ? filename.split('.')[0] : '';
    }

    getPrimaryColor(publicId: string): Observable<string> {
        const cacheKey = `primaryColor:${publicId}`;
        if (this._platformService.isBrowser()) {
            let cached = localStorage.getItem(cacheKey);
            if (cached) {
                return of(cached);
            }
        }
        return this._http.get<{color: string}>(`${environment.baseUrl}/api/public/image/${publicId}/colors/primary`).pipe(
            map((result) => result.color),
            tap(color => {
                try {
                    if (this._platformService.isBrowser()) {
                        localStorage.setItem(cacheKey, color);
                    }
                }
                catch (e) {
                    console.warn("Caching primary color failed", e);
                }
            })
        )
    }

    getAllColors(publicId: string): Observable<[string, number][]> {
        return this._http.get<{colors: [string, number][]}>(`${environment.baseUrl}/api/public/image/${publicId}/colors/all`).pipe(
            map((result) => result.colors)
        )
    }
}
