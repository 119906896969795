<div *ngIf="book" class="book-header-container">
    <div class="breadcrumb-container" *ngIf="enableBreadcrumbs">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <breadcrumbs></breadcrumbs>
                </div>
            </div>
        </div>
    </div>
    <div class="section-container" style="--cover-color: {{coverSrc | cloudinaryPublicId | cloudinaryPrimaryColor | async | transparentize}}">
        <div class="">
            <div class="container-xxl">
                <!-- mobile + md -->
                <div class="row d-block d-lg-none">
                    <div
                        class="col-12 c-px-0 c-mt-lg-40 c-mt-0 book-cover-container d-flex justify-content-center align-items-center"
                        style="--cover-color: {{coverSrc | cloudinaryPublicId | cloudinaryPrimaryColor | async}}"
                        [ngClass]="{'push': releaseBadge}">
                        <span *ngIf="releaseBadge" class="new-release-badge">{{releaseBadge}}</span>
                        <img preloadImage
                             class="book-cover"
                             [src]="coverSrc | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleWidth:160 | cloudinaryUrl"
                             [alt]="book.title"
                             loading="eager"
                             fetchPriority="high"
                             width="160"
                             height="262">
                    </div>
                    <div class="col-12 c-pt-20">
                        <fomo-countdown colorScheme="red" [to]="book.publishedAt!" [showCountDown]="showCountDown"></fomo-countdown>
                        <h1 class="book-title c-mb-0">{{book.title}}</h1>
                        <div class="c-mt-8">
                            <span class="author-badge-text">
                                <img
                                    [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                    [alt]="book.authors[0].name"
                                    class="circular-pfp c-me-8"
                                />
                                <span>{{ book.mainAuthorName }}</span>
                            </span>
                        </div>
                        <h2 class="book-header c-mt-8 c-mb-0" *ngIf="header">{{header}}</h2>
                        <div class="price-cut c-mt-8" *ngIf="showPriceCut">
                            <span class="price">$0.99</span>
                            <span class="free">Free</span>
                        </div>
                        <div *ngIf="enableRating || enableSpicyMeter" class="d-flex c-mt-8 rating-spicy-container-mobile">
                            <ng-container *ngIf="enableRating" [ngTemplateOutlet]="rating"></ng-container>
                            <spicy-meter *ngIf="enableSpicyMeter" [spicyMeter]="book.spicyMeter!"></spicy-meter>
                        </div>
                        <div class="c-mt-16">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                </div>
                <!-- desktop >= lg -->
                <div class="row d-lg-block d-none">
                    <div class="col-12 d-flex justify-content-start">
                        <div
                            class="c-px-0 c-mt-md-40 c-mt-0 book-cover-container d-flex justify-content-center align-items-center"
                            style="--cover-color: {{coverSrc | cloudinaryPublicId | cloudinaryPrimaryColor | async}}"
                            [ngClass]="{'push': releaseBadge}">
                            <span *ngIf="releaseBadge" class="new-release-badge">{{releaseBadge}}</span>
                            <img 
                                 class="book-cover"
                                 [src]="coverSrc | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleWidth:220 | cloudinaryUrl"
                                 [width]="220"
                                 [height]="361"
                                 fetchPriority="high"
                                 loading="eager"
                                 [alt]="book.title">
                        </div>
                        <div class="c-ps-lg-60 c-ps-xxl-100 c-pt-40">
                            <fomo-countdown colorScheme="red" [to]="book.publishedAt!" [showCountDown]="showCountDown"></fomo-countdown>
                            <h1 class="book-title c-mb-0">{{book.title}}</h1>
                            <div class="c-mt-8">
                                <span class="author-badge-text">
                                    <img
                                        [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                        [alt]="book.authors[0].name"
                                        class="circular-pfp c-me-8"
                                    />
                                    <span>{{ book.mainAuthorName }}</span>
                                </span>
                            </div>
                            <h2 class="book-header c-mt-8 c-mb-0" *ngIf="header">{{header}}</h2>
                            <div class="price-cut c-mt-8" *ngIf="showPriceCut">
                                <span class="price">$0.99</span>
                                <span class="free">Free</span>
                            </div>
                            <div *ngIf="enableRating || enableSpicyMeter" class="d-flex c-mt-8" style="gap: 30px">
                                <ng-container *ngIf="enableRating" [ngTemplateOutlet]="rating"></ng-container>
                                <spicy-meter *ngIf="enableSpicyMeter" [spicyMeter]="book.spicyMeter!"></spicy-meter>
                            </div>
                            <div class="c-mt-16">
                                <ng-container [ngTemplateOutlet]="content"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #rating>
    <div class="d-flex align-items-center">
        <div class="c-me-8 rating-header">
            {{book.rating}}
        </div>
        <a href="#reviews" class="cursor-pointer c-me-5">
            <star-rating [rating]="book.rating"></star-rating>
        </a>
        <div class="d-flex align-items-center rating-count">
            ({{book.ratingCount}})
        </div>
    </div>
</ng-template>

<ng-template #badge>
    <!-- <ng-container *ngIf="book.isPrelaunched && !book.isFree">
        <div class="c-pb-16">
            <span class="badge-text">
                <img
                    [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                    alt="Author picture"
                    class="circular-pfp c-me-10"
                />
                Coming soon from&nbsp;<span class="fw-bold">{{ book.mainAuthorName }}</span>
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="book.isFree && !book.isPrelaunched">
        <div class="c-pb-16">
            <span class="badge-text">
                <img
                    [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                    alt="Author picture"
                    class="circular-pfp c-me-10"
                />
                Free Book from&nbsp;<span class="fw-bold">{{ book.mainAuthorName }}</span>
            </span>
        </div>
    </ng-container> -->
    <div class="c-mb-8">
        <span class="author-badge-text">
            <img
                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                [alt]="book.authors[0].name"
                class="circular-pfp c-me-8"
            />
            <span>{{ book.mainAuthorName }}</span>
        </span>
    </div>
</ng-template>
