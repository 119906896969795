import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import emailSpellChecker from '@zootools/email-spell-checker';
import { EmbeddedAuthService } from '../../services/embedded-auth.service';
import { SignupReason } from 'src/app/services/analytics/events/signupReason';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, map, tap } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { CountDownService } from 'src/app/services/countdown.service';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import * as dayjs from "dayjs";
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

export enum PasswordlessAuthStage {
    Send = "Send",
    Verify = "Verify"
}

@Component({
    selector: 'passwordless-auth',
    templateUrl: './passwordless-auth.component.html',
    styleUrls: ['./passwordless-auth.component.scss'],
    animations: [
        trigger(
            'errorMessageAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                ),
                // transition(
                //   ':leave', 
                //   [
                //     style({ "opacity": 1 }),
                //     animate('0.5s ease-in', style({ "opacity": 0 }))
                //   ]
                // )
            ]
        )
    ]
})
export class PasswordlessAuthComponent implements OnInit {

    @Input()
    sendStageTitle = "Sign in or sign up";

    @Input()
    sendStageDescription = "Enter your email to create your crewfiction account or sign-in to an existing one.";

    @Input()
    verifyStageTitle = "Verify your email";

    @Input()
    authSuccess?: Function;

    @Input()
    stageChange?: Function;

    @Input()
    skipOnboarding = false;

    @Input()
    inline: boolean = false;

    @Input()
    action?: string;

    @Input()
    actionParams?: { [key: string]: any };

    _currentStage = PasswordlessAuthStage.Send;

    private readonly authStageKey = "passwordless-auth-stage";
    private readonly authEmailKey = "passwordless-auth-email";
    private readonly resendKey = "passwordless-auth-resend";

    get currentStage(): PasswordlessAuthStage {
        return this._currentStage;
    }

    set currentStage(value: PasswordlessAuthStage) {
        this._currentStage = value;
        if (this._platformService.isBrowser()) {
            this._countDownService.localStorage.setItem(this.authStageKey, value, environment.auth.passwordless.ttl);
        }
        if (this.stageChange) {
            setTimeout(() => {
                this.stageChange?.call(undefined, value);
            })
        }
        this._cd.detectChanges();
    }

    @Input()
    email?: string;

    @Input()
    signupReason?: SignupReason;

    resendInterval: number = environment.auth.passwordless.resendTtl;

    resendDisabled$ = this._countDownService.localStorage.asObservable(this.resendKey, 1000).pipe(
        // tap(v => {
        //     console.log(`resendDisabled$: ${v}`);
        // }), 
        map(v => v != null)
    )
    resendEnabled$ = this.resendDisabled$.pipe(
        map(v => !v)
    )

    PasswordlessAuthStage = PasswordlessAuthStage;

    invalidCodeError: boolean = false;
    loading = false;
    emailSuggestionError = "";

    sendStageForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    })

    async ngOnInit() {
        this.currentStage = this.initCurrentStage();
        //capture email from url param
        const email = (await firstValueFrom(this._route.queryParamMap)).get("email");
        if (email) {
            this.emailFormControl.setValue(email);
        }
        //use email from prelaunches and free books
        if (this._platformService.isBrowser()) {
            try {
                if (localStorage.getItem(this.authEmailKey)) {
                    this.emailFormControl.setValue(localStorage.getItem(this.authEmailKey));
                }
                else if (this._anonymousPersistentState.email) {
                    this.emailFormControl.setValue(this._anonymousPersistentState.email);
                }
                else if (localStorage.getItem("PrelaunchPersistentState")) {
                    //in case we have any users from before _anonymousPersistentState
                    const state = JSON.parse(localStorage.getItem("PrelaunchPersistentState")!);
                    if (state.email) {
                        this.emailFormControl.setValue(state.email);
                    }
                }
            } catch (_e) { }
        }
        if (this.email) {
            this.emailFormControl.setValue(this.email);
        }
    }

    get emailFormControl(): FormControl {
        return this.sendStageForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    get timeToResend(): string {
        try {
            if (this._platformService.isServer()) {
                return "";
            }
            else {
                let remaining = localStorage.getItem(this.resendKey);
                if (remaining) {
                    const parsed = JSON.parse(remaining);
                    const expirationTimestamp = parsed.expirationTimestamp;

                    const formattedDifference = dayjs
                    // @ts-ignore
                        .duration(dayjs(expirationTimestamp).diff(dayjs()))
                        .format('mm:ss');

                    return formattedDifference;
                }
                return "00:00"
            }
        }
        catch {
            return "00:00";
        }
    }

    async onSendStageFormSubmit() {
        if (this.sendStageForm.valid) {
            this._countDownService.localStorage.setItem(this.resendKey, "true", this.resendInterval);
            this.loading = true;
            await this._authService.startPasswordlessLogin(this.emailFormControl.value, {
                method: "code",
                skipOnboarding: this.skipOnboarding,
                signupReason: this.signupReason,
                action: this.action,
                actionParams: this.actionParams
            });
            this.currentStage = PasswordlessAuthStage.Verify;
            this.loading = false;
            localStorage.setItem(this.authEmailKey, this.emailFormControl.value);
        }
    }

    // Verify stage
    verifyStageForm = new FormGroup({
        code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    });


    get codeFormControl(): FormControl {
        return this.verifyStageForm.get('code') as FormControl;
    }

    get codeErrors(): string[] {
        const errors = this.codeFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Code is required";
                case 'minlength': return "Code must be 6 characters long";
                case 'maxlength': return "Code must be 6 characters long";
                default: return "Unknown error";
            }
        });
    }

    async onVerifyStageFormSubmit() {
        this.invalidCodeError = false;
        if (this.verifyStageForm.valid) {
            this.loading = true;
            try {
                let authResult = await this._authService.verifyCode(this.emailFormControl.value, this.codeFormControl.value, {});
                if (this.authSuccess) {
                    this.authSuccess(authResult);
                }
                localStorage.removeItem(this.authEmailKey);
                this._countDownService.localStorage.removeItem(this.resendKey);
            }
            catch (err) {
                console.error(err);
                this.invalidCodeError = true;
                await this._authService.logClientAuthError(this.emailFormControl.value, this.codeFormControl.value, err);
            }
            this.loading = false;
        }
    }

    didNotGetCode() {
        this.codeFormControl.reset();
        this.currentStage = PasswordlessAuthStage.Send;
        this.invalidCodeError = false;
    }

    initCurrentStage(): PasswordlessAuthStage {
        if (this._platformService.isBrowser()) {
            const stage = this._countDownService.localStorage.getItem(this.authStageKey);
            if (stage && [PasswordlessAuthStage.Send, PasswordlessAuthStage.Verify].includes(stage as PasswordlessAuthStage)) {
                return stage as PasswordlessAuthStage;
            }
        }
        return PasswordlessAuthStage.Send;
    }

    onEmailChanged() {
        if (!this.sendStageForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }

    constructor(
        private readonly _authService: EmbeddedAuthService,
        private readonly _cd: ChangeDetectorRef,
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _countDownService: CountDownService,
        private readonly _analyticsService: AnalyticsService
    ) { }

}
