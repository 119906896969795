import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Author } from 'src/app/models/author.model';
import { BookSeries } from 'src/app/models/book-series.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';

@Component({
    selector: 'author',
    templateUrl: './author.component.html',
    styleUrls: ['./author.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorComponent {
    @Input()
    author!: Author;

    layoutConfig: ColumnLayoutConfig = {
        mobile: 2,
        sm: 3,
        md: 4,
        lg: 6
    }
    
    get seriesDataSource(): ContentGridDataSource<BookSeries> | null {
        if (!this.author.series || this.author.series.length === 0) return null;
        return {
            data: this.author.series,
            title: s => s.title,
            subtitle: _s => this.author.name,
            image: s => s.images.cover,
            url: s => `/authors/${this.author?.slug}/${s.slug}`
        };
    }
}
