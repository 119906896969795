import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { DiscussionService } from '../../discussion.service';
import { Comment } from "../../../../models/comment.model";
import { Discussion } from 'src/app/models/discussion.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Auth0User } from 'src/app/modules/auth/types/auth0-user';
import { AddReplyEventArgs } from '../../models/add-reply-event';

@Component({
    selector: 'comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger(
            'newCommentAnimation',
            [
                transition('void => false', []),
                transition(
                    'void => true',
                    [
                        style({ "opacity": 0 }),
                        animate('1.5s ease-in', style({ "opacity": 1 }))
                    ]
                )
            ]
        )
    ]
})
export class CommentComponent implements OnInit, OnDestroy {
    @Input()
    discussion!: Discussion;

    @Input() 
    comment?: Comment;

    @Input()
    newCommentId: string = "";

    @Input()
    user?: Auth0User;

    @Input()
    nested: boolean = false;

    @Output()
    reply = new EventEmitter<AddReplyEventArgs>();

    @Input()
    showReplies: boolean = false;

    @Input()
    allowPin: boolean | null = false;

    @Input()
    posting = new BehaviorSubject(false);
    
    @Output()
    remove = new EventEmitter<Comment>();

    @Output()
    pinChange = new EventEmitter<Comment>();

    // TODO: Add map for user badges to text/class here
    userBadges = {};

    showReplyInput = false;
    showDeleteToggle = false;

    showOriginal: boolean = false;

    profilePictureCacheBust!: number;

    toggleOriginal() {
        this.showOriginal = !this.showOriginal;
    }

    private _subs: Subscription[] = [];

    constructor(
        private _discussionService: DiscussionService,
        private _cd: ChangeDetectorRef,
    ) {}

    
    ngOnInit(): void {
        this.profilePictureCacheBust = Date.now();
    }
    
    ngOnDestroy(): void {
        this._subs.forEach(s => s.unsubscribe());
    }

    toggleReplies(): void {
        this.showReplies = !this.showReplies;
    }

    toggleReplyInput(): void {
        this.showReplyInput = !this.showReplyInput;
    }

    toggleDelete() {
        if (this.user && this.comment && this.comment.username === this.user.nickname) {
            this.showDeleteToggle = !this.showDeleteToggle;
        }
    }

    /**
     * @param data Default DTO for adding a reply to a comment
     * @param bubble If true, the dto will be modified to include current thread id.
     */
    addReply(event: AddReplyEventArgs): void {
        let { data, bubble } = event;
        console.log(`[${this.comment?.id}-${data.comment?.body}]: ${bubble}`);
        if (bubble && this.comment) {
            data.threadId = this.comment.id;
            data.comment = this.comment
        }
        this.reply.emit(event);
        let sub: Subscription | null = null;
        sub = this.posting.subscribe(posting => {
            if (!posting) {
                this.showReplyInput = false;
                if (sub) sub.unsubscribe();
            }
        });
    }

    removeReply(comment: Comment): void {
        if (this.user) {
            this.remove.emit(comment);
        }
    }

    togglePin() {
        if (this.comment && this.comment.pinned) {
            this.unpin();
        } else {
            this.pin();
        }
    }

    async pin() {
        if (this.comment) {
            await this._discussionService.pinComment(this.comment.id);
            this.comment.pinned = true;
            this.pinChange.emit(this.comment);
        }
    }

    async unpin() {
        if (this.comment) {
            await this._discussionService.unpinComment(this.comment.id);
            this.comment.pinned = false;
            this.pinChange.emit(this.comment);
        }
    }

    onNestedPinChange(comment: Comment) {
        this.pinChange.emit(comment);
    }
}
