import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'skeleton-placeholder',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './skeleton-placeholder.component.html',
    styleUrl: './skeleton-placeholder.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonPlaceholderComponent {

    @Input() mode: "library" | "read-lines" | "default" = "default";

}
