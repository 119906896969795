import { Pipe, PipeTransform } from '@angular/core';
import { World } from 'src/app/models/world.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'worldUrl',
    pure: true
})

export class WorldUrlPipe implements PipeTransform {

    constructor(private readonly _utilitiesService: UtilitiesService) {}

    transform(world: World): string  {
        return this._utilitiesService.getWorldUrl(world);
    }
}
