import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import {
    worldMakerComponentTable,
    WorldMakerComponentNames,
} from '../../worldmaker.module';

import {
    WorldPageTemplateSlotHeader,
    WorldPageTemplateSlotSection1,
    WorldPageTemplateSlotSection2,
    WorldPageTemplateSlotSection3,
    WorldPageTemplateSlotSection4,
    WorldPageTemplateSlotSection5,
    WorldPageTemplateSlotSection6,
    WorldPageTemplateSlotSection7,
    WorldPageTemplateSlotSection8,
    WorldPageTemplateSlotSection9,
    WorldPageTemplateSlotSection10
} from './world_page_template.slots';

type WorldMakerInput = {
    id: number;
    path: string;
    template: string;
    components: {
        data: object;
        name: WorldMakerComponentNames;
        slot: 'header' | 'section1' | 'section2' | 'section3' | 'section4' | 'section5' | 'section6'| 'section7'| 'section8'| 'section9'| 'section10' | 'meta';
    }[];
}

@Component({
    selector: 'worldmaker-world-page-template',
    templateUrl: './world_page_template.component.html',
    styleUrls: ['./world_page_template.component.scss'],
})
export class WorldPageTemplate {
    @ViewChild(WorldPageTemplateSlotHeader, { static: true })
    header!: WorldPageTemplateSlotHeader;
    @ViewChild(WorldPageTemplateSlotSection1, { static: true })
    section1!: WorldPageTemplateSlotSection1;
    @ViewChild(WorldPageTemplateSlotSection2, { static: true })
    section2!: WorldPageTemplateSlotSection2;
    @ViewChild(WorldPageTemplateSlotSection3, { static: true })
    section3!: WorldPageTemplateSlotSection3;
    @ViewChild(WorldPageTemplateSlotSection4, { static: true })
    section4!: WorldPageTemplateSlotSection4;
    @ViewChild(WorldPageTemplateSlotSection5, { static: true })
    section5!: WorldPageTemplateSlotSection5;
    @ViewChild(WorldPageTemplateSlotSection6, { static: true })
    section6!: WorldPageTemplateSlotSection6;
    @ViewChild(WorldPageTemplateSlotSection7, { static: true })
    section7!: WorldPageTemplateSlotSection7;
    @ViewChild(WorldPageTemplateSlotSection8, { static: true })
    section8!: WorldPageTemplateSlotSection8;
    @ViewChild(WorldPageTemplateSlotSection9, { static: true })
    section9!: WorldPageTemplateSlotSection9;
    @ViewChild(WorldPageTemplateSlotSection10, { static: true })
    section10!: WorldPageTemplateSlotSection10;

    @Input()
    set worldMakerData(value: WorldMakerInput){
        this.dataObsevable$.next(value);
    }

    dataObsevable$ = new ReplaySubject<WorldMakerInput>();

    ngOnInit() {
        this.dataObsevable$.subscribe((current) => {
            //clear all slots first
            this.header.viewContainerRef.clear();
            this.section1.viewContainerRef.clear();
            this.section2.viewContainerRef.clear();
            this.section3.viewContainerRef.clear();
            this.section4.viewContainerRef.clear();
            this.section5.viewContainerRef.clear();
            this.section6.viewContainerRef.clear();
            this.section7.viewContainerRef.clear();
            this.section8.viewContainerRef.clear();
            this.section9.viewContainerRef.clear();
            this.section10.viewContainerRef.clear();
            //re-create them
            for (const c of current.components) {
                if (c.slot === 'meta') continue;
                const viewContainerRef = this[c.slot].viewContainerRef;
    
                const toLoad = worldMakerComponentTable[c.name];
                const componentRef = viewContainerRef.createComponent<any>(toLoad);
                componentRef.instance.data = c.data;
            }
        });
    }
}
