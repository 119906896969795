import { Component, Input, OnInit } from '@angular/core';

type Media = {
        channel: 'article',
        exclusive?: boolean;
        nofollow?: boolean;
        data: {
            url: string;
            imageUrl: string; 
            title: string; 
            description: string, 
            category: string,
            favicon: string
        };
    }

@Component({
    selector: 'worldmaker-component-featured-links',
    templateUrl: './featured-links.component.html',
    styleUrls: ['./featured-links.component.scss'],
})
export class FeaturedLinks implements OnInit {
    @Input() data!: {
        header: string;
        media: Media[];
        showHR: boolean;
        enableAnchor: boolean;
        anchorSlug?: string;
    };

    articles!: {
        title: string;
        url: string;
        nofollow: boolean;
        favicon: string;
        domain: string;
    }[];

    ngOnInit(): void {
        this.articles = this.data.media
        .filter((media) => {
            if (media.channel !== "article") return false;
            if (!media.data.title || !media.data.url) return false;
            return true
        })
        .map((media) => {
            const url = new URL(media.data.url);

            return {
                title: media.data.title,
                url: media.data.url,
                nofollow: !!media.nofollow,
                favicon: media.data.favicon,
                domain: url.host
            }
        });
    }
}