import { Injectable } from '@angular/core';
import { FollowersService } from './followers.service';
import { Book } from '../models/book.model';
import { Author } from '../models/author.model';
import { BehaviorSubject, ReplaySubject, Subject, catchError, firstValueFrom, from, of, switchMap } from 'rxjs';
import { UtilitiesService } from './utilities.service';
import { PlatformService } from './platform.service';
import { CloudinaryService } from './cloudinary.service';
import { HttpClient } from '@angular/common/http';
import { EmbeddedAuthService } from '../modules/auth/services/embedded-auth.service';
import { User, UserMetadata } from '../models/user.model';
import { Auth0User } from '../modules/auth/types/auth0-user';
import { nanoid } from "nanoid";

@Injectable({ providedIn: 'root' })
export class UserService {

    private _user: User | undefined;

    isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    user$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
    profilePictureUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    profilePictureThumbnailUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    private _userCacheIdKey: string = "user-cache-id";

    userCacheId!: string;

    refreshUserCacheId() {
        this.userCacheId = nanoid(6);
        sessionStorage.setItem(this._userCacheIdKey, this.userCacheId);
    }

    loadUserCacheId() {
        this.userCacheId = sessionStorage.getItem(this._userCacheIdKey) as string;
    }

    getAccessToken(): string | undefined {
        return this._auth.accessToken;
    }

    get user(): User | undefined {
        return this._user;
    }

    constructor(
        private _auth: EmbeddedAuthService,
        private _http: HttpClient,
        private _followersService: FollowersService,
        private _platformService: PlatformService,
        private _cloudinaryService: CloudinaryService
    ) {
        this.init()
    }

    protected init() {
        if (this._platformService.isBrowser()) {
            this._auth.user$.subscribe(user => this.onUserChanged(user));
            this.loadUserCacheId();
            if (this.userCacheId) {
                this.refreshUserCacheId();
            }
        }
    }

    async getProfilePictureUrl(user: any, isThumbNail: boolean = false): Promise<string> {
        let publicId = "profile-pictures/" + user.pid as string;
        let url;
        try {
            url = isThumbNail ? this._cloudinaryService.getThumbnailUrl(user.picture) : this._cloudinaryService.getImageUrl(user.picture);
            // check if img exists
            await firstValueFrom(this._http.get(url, { responseType: 'blob' }));
        }
        catch (err) {
            // fallback to default
            url = user.picture as string;
        }
        let u = new URL(url);
        u.searchParams.set("c", this.userCacheId);
        return u.toString();
    }

    protected async onUserChanged(user: Auth0User | undefined) {
        if (user) {
            this._user = {
                ...user,
                id: user.sub,
                profile: await this.loadUserProfile(user)
            }
            this.user$.next(this._user);
            this.isLoggedIn$.next(true);
        }
        else {
            this._user = undefined;
            this.user$.next(undefined);
            this.isLoggedIn$.next(false);
        }
    }

    protected async loadUserProfile(user: Auth0User): Promise<UserMetadata> {
        return {
            images: {
                main: await this.getProfilePictureUrl(user),
                thumbnail: await this.getProfilePictureUrl(user, true),
                fallback: user.picture as string
            },
            following: {
                books: [],
                authors: []
            }
        }
    }

    protected async loadFollowingBooks(): Promise<Book[]> {
        return await this._followersService.getFollowingBooks();
    }

    protected async loadFollowingAuthors(): Promise<Author[]> {
        return await this._followersService.getFollowingAuthors();
    }
}
