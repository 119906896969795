import { Component, Input, OnInit } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { BooksService } from 'src/app/services/books.service';
import { firstValueFrom } from 'rxjs';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';

@Component({
    selector: 'worldmaker-component-smart-book-cover-header',
    templateUrl: './smart-book-cover-header.component.html',
    styleUrls: ['./smart-book-cover-header.component.scss'],
})
export class SmartBookCoverHeader implements OnInit {
    constructor(
        private readonly _booksService: BooksService,
        private readonly _anonymousPersistentState: AnonymousPersistentState
    ) {}

    @Input() data!: {
        book: Book;
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        };
        //live
        live_headerText: string;
        live_showHeader: boolean;
        live_amazonCtaText: string;
        live_showAmazonCta: boolean;
        live_enableStickyFooter: boolean;
        //prelaunch
        prelaunch_headerText: string;
        prelaunch_showHeader: boolean;
        prelaunch_alertText: string;
        prelaunch_ctaText: string;
        prelaunch_enableStickyFooter: boolean;
        //free
        free_headerText: string;
        free_showHeader: boolean;
        free_ctaText: string;
        free_recommendedBooks: Book[];
        free_enableStickyFooter: boolean;
        free_enableBreadcrumbs: boolean;

        showRating: boolean;
        showSpicyMeter: boolean;
        acValuePostfix: string;
        altCoverIndex?: string;
        altReadableSlug?: string;
    };

    state: "live" | "prelaunch" | "free" | "arc" | null = null;
    joinedArc!: boolean;
    arcSlots!: {left: number, total: number};

    async ngOnInit() {
        if (this.data.book.isPrelaunched) {
            const [availableArcs, joinedArcs] = await Promise.all([
                firstValueFrom(this._booksService.getAvailableArcs(this._anonymousPersistentState.email)),
                firstValueFrom(this._booksService.getJoinedArcs(this._anonymousPersistentState.email))
            ]);
            const availableArc = availableArcs.find(arc => arc.book.slug === this.data.book.slug);
            const joinedArc = joinedArcs.find(book => book.slug === this.data.book.slug);
            if (availableArc) {
                this.joinedArc = false;
                this.arcSlots = {left: availableArc.slots, total: availableArc.book.readables?.find(r => r.settings.type === "arc")!.settings.arcSlots!};
                this.state = "arc";
            } else if (joinedArc) { 
                this.joinedArc = true;
                this.state = "arc";
            } else {
                this.state = "prelaunch";
            }
        } else if (this.data.book.isFree) {
            this.state = "free";
        } else {
            this.state = "live";
        }
    }
}
