import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Readable } from 'src/app/models/readable.model';

export type ReadChunk = {
    content: any[],
    total: number,
    start: number,
    end: number
};

@Injectable({
    providedIn: 'root',
})
export class ReadablesService {
    private apiUrl = `${environment.baseUrl}/api/readables`;

    constructor(private http: HttpClient) { }

    read(slug: string, start: number = 0): Observable<ReadChunk> {
        return this.http.get<ReadChunk>(`${this.apiUrl}/read/${slug}?start=${start}`);
    }

    info(slug: string): Observable<Readable> {
        return this.http.get<Readable>(`${this.apiUrl}/info/${slug}`);
    }

    infoByBookSlug(slug: string, kind: Readable["settings"]["type"]): Observable<Readable | null> {
        return this.http.get<Readable | null>(`${this.apiUrl}/info/book/${slug}/${kind}`);
    }
}
