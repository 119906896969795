import { Component, Input } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { World } from 'src/app/models/world.model';

export interface FeaturedWorldsConfig {
    header: string;
    enableCta: boolean;
    ctaText: string;
    ctaUrl: string;
    showHR: boolean;
    worlds: World[];
    enableAnchor: boolean;
    anchorSlug?: string;
}

@Component({
  selector: 'worldmaker-component-featured-worlds-grid',
  templateUrl: './featured-worlds-grid.component.html',
  styleUrls: ['./featured-worlds-grid.component.scss']
})
export class FeaturedWorldsGridComponent {    
    constructor(private readonly _platformService: PlatformService) {}

    @Input()
    data!: FeaturedWorldsConfig;

    get showCta() {
        return this.data.enableCta && this.data.ctaText && this.data.ctaUrl;
    }

    get isMobile() {
        return this._platformService.isMobile;
    }
}
