import { OnInit, Component, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Author } from 'src/app/models/author.model';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { AuthorsService } from 'src/app/services/authors.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PlatformService } from 'src/app/services/platform.service';


export interface FeaturedAuthorsWorldMakerConfig {
    authors: Author[];
    header: string;
    ctaText: string;
    ctaUrl: string;
    enableCta: boolean;
    showHR: boolean;
    enableAnchor: boolean;
    anchorSlug?: string;
}
@Component({
  selector: 'worldmaker-component-featured-authors',
  templateUrl: './featured-authors.component.html',
  styleUrls: ['./featured-authors.component.scss']
})
export class FeaturedAuthorsComponent implements OnInit {
    constructor(
        private _authorsService: AuthorsService,
        private _utilitiesService: UtilitiesService,
        private _platformService: PlatformService
    ) {}

    @Input()
    data!: FeaturedAuthorsWorldMakerConfig;

    ds: ContentGridDataSource<Author> | null = null;
    layoutConfig: ColumnLayoutConfig = {
        mobile: 2,
        sm: 3,
        md: 4,
        lg: 6
    }

    async ngOnInit() {
        //load all if none are selected
        if (!this.data.authors || this.data.authors.length === 0) {
            this.data.authors = await firstValueFrom(this._authorsService.findAll());
        }
        this.ds = {
            data: this.data.authors,
            image: a => a.images.profile,
            title: a => a.name,
            subtitle: "",
            url: a => this._utilitiesService.getAuthorUrl(a)
        };
    }

    get showCta() {
        return this.data.enableCta && this.data.ctaText && this.data.ctaUrl;
    }

    get isMobile() {
        return this._platformService.isMobile;
    }
}
