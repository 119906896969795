import { Pipe, PipeTransform } from '@angular/core';
import { ɵgetDOM } from '@angular/platform-browser';

@Pipe({
    name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string | undefined, ...args: unknown[]): string {
        if (!value) {
            return "";
        }
        let textValue: string;
        if (value.includes("<p")) {
            const dom = ɵgetDOM();
            const div = dom.createElement("div");
            div.innerHTML = value;
            let text = "";
            for (const el of Array.from(div.children)) {
                //@ts-ignore
                text += (el.innerText + " ");
            }
            textValue = text.trim();
        } else {
            textValue = value;
        }
        textValue = textValue.replace(/\s\s+/g, ' ');

        return textValue;
    }
}