<ng-container *ngIf="book">
    <div class="substract-header cover-container position-relative w-100 overflow-hidden" [style]="'--cover-image:url(' + bgCover + ')'" *ngIf="book">
        <div class="container-xxl">
            <div class="row content position-relative">
                <div class="col-md-4 p-2 px-5 pt-4 p-md-5 text-md-start">
                    <book-cover [book]="book" [height]="bookHeight"></book-cover>
                </div>
                <div class="col-md-8 px-5 py-1 px-md-5">
                    <div class="d-flex flex-column justify-content-center h-100 pb-4 pb-md-0">
                        <!-- <breadcrumbs></breadcrumbs> -->
                        <span class="coming-soon-badge mb-2">
                            <img
                                [src]="book.authors[0].images.profile"
                                alt="Author picture"
                                class="circular-pfp me-1 mb-1"
                            />
                            <span class="fw-bold">{{ book.mainAuthorName }}</span>
                        </span>
                        <h1 class="display-4 text-white fw-bold d-block text-md-start">
                            {{ book.title }}
                        </h1>
                        <div class="d-flex">
                            <p class="text-white">
                                {{ data.headerText }}
                            </p>
                        </div>
                        <div class="row pt-2">
                            <div class="col-12 col-md-5 pb-4 pb-md-0">
                                <a [href]="book | amazonLink" class="btn btn-primary btn-block text-nowrap rounded-input w-100" target="_blank" rel="noopener noreferrer" (click)="trackAmazonClick()">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="me-2" fill="#000" viewBox="0 0 448 512">
                                        <path
                                            d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                                    </svg>
                                    {{data.amazonCtaText}}
                                </a>
                            </div>
                            <div class="col-12 col-md-7" *ngIf="!flowCompleted">
                                <button type="button"
                                    (click)="openJoinModal()"
                                    class="btn btn-secondary btn-block text-nowrap rounded-input w-100">
                                    <feather-icon icon="user-plus" class="me-2 fw-bold"></feather-icon>
                                    {{ data.ctaText }}
                                </button>
                            </div>
                            <!-- <div class="col-12 col-md-5" *ngIf="flowCompleted">
                                <button type="button"
                                    (click)="openModalAgain()"
                                    class="btn btn-secondary btn-block text-nowrap rounded-input w-100">
                                    <feather-icon icon="user-check" class="me-2 fw-bold"></feather-icon>
                                    Following
                                </button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card sticky-container container-xxl" *ngIf="scrolled">
        <div class="card-body pt-4 pb-4">
            <div class="d-flex justify-content-between">
                <!-- <div class="d-none d-sm-block">
                    <img class="book-cover__image" [src]="book.images.cover | cloudinaryScaleHeight : 50 | cloudinaryQuality : 70 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                </div> -->
                <div class="d-none d-sm-flex justify-content-between">
                    <div class="pe-3 d-flex flex-column justify-content-center">
                        <img class="book-cover__image" [src]="book.images.cover | cloudinaryScaleHeight : 50 | cloudinaryQuality : 70 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <h6 class="book-title">{{book.title}}</h6>
                        <span class="coming-soon-badge text-reset">
                            <img
                                [src]="book.authors[0].images.profile"
                                alt="Author picture"
                                class="circular-pfp me-1"
                            />
                            <span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-block d-sm-none pb-3 text-center">
                        <h6 class="book-title">{{book.title}}</h6>
                        <span class="coming-soon-badge text-reset">
                            <img
                                [src]="book.authors[0].images.profile"
                                alt="Author picture"
                                class="circular-pfp me-1"
                            />
                            <span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                    <div class="col-12 col-md-5 pb-4 pb-md-0" [ngClass]="{'col-md-12': flowCompleted}">
                        <a [href]="book | amazonLink" class="btn btn-primary btn-block text-nowrap rounded-input w-100" target="_blank" rel="noopener noreferrer" (click)="trackAmazonClick()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="me-2" fill="#000" viewBox="0 0 448 512">
                                <path
                                    d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                            </svg>
                            {{data.amazonCtaText}}
                        </a>
                    </div>
                    <div class="col-12 col-md-7" *ngIf="!flowCompleted">
                        <button type="button"
                            (click)="openJoinModal()"
                            class="btn btn-secondary btn-block text-nowrap rounded-input w-100">
                            <feather-icon icon="user-plus" class="me-2 fw-bold"></feather-icon>
                            {{ data.ctaText }}
                        </button>
                    </div>
                    <!-- <div class="col-12 col-md-6" *ngIf="flowCompleted">
                        <button type="button"
                            (click)="openModalAgain()"
                            class="btn btn-secondary btn-block text-nowrap rounded-input w-100">
                            <feather-icon icon="user-check" class="me-2 fw-bold"></feather-icon>
                            Following
                        </button>
                    </div> -->
                </div>
                <!-- <div style="width: 1px;"></div> -->
            </div>
        </div>
    </div>
</ng-container>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <div *ngIf="book" class="join-modal-container text-center">
            <div class="row">
                <div class="col-12">
                    <h4 class="mb-0">
                        <img
                            [src]="book.authors[0].images.profile"
                            alt="Author picture"
                            class="circular-pfp me-1"
                        />
                        {{ book.mainAuthorName }}
                    </h4>
                </div>
            </div>
            <div class="row pt-4" *ngIf="flowCompleted && firstTimeModalOpen">
                <div class="col-12">
                    <div class="alert alert-success text-start mb-0" role="alert">
                        <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                        <!-- <span class="fw-bold">You are now following “Harry Potter”</span><br><br> -->
                        <p class="mb-0">We will let you know for updates on new content and discussions. By following books and authors help us having better book recommendations for your next read.</p>
                    </div>                      
                </div>
            </div>
            <div class="row pt-2" *ngIf="flowCompleted && firstTimeModalOpen">
                <div class="col-12">
                    <hr class="horizontal-break ms-auto me-auto">
                </div>
            </div>
            <div class="row pt-4 d-flex justify-content-center">
                <img
                    [src]="crewImage | cloudinaryScaleHeight : 250 | cloudinaryQuality : 85 | cloudinaryUrl"
                    style="width: 250px;"
                    alt="">
            </div>
            <div class="row pt-4">
                <div class="col-12">
                    <h5 class="mb-0">Subscribe to {{authorFirstNameWithS}} Book Crew for <span class="fw-bold">more exclusive benefits</span></h5>
                </div>
            </div>
            <div class="row text-start pt-4">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="book-open" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Get invited for Advanced Reader Copy</h6>
                        <small class="text-muted">Read {{authorFirstNameWithS}} books for free, including this collection, before their release.</small>
                    </div>
                </div>
            </div>
            <div class="row text-start pt-3">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="message-circle" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Open discussions with {{authorFirstName}}</h6>
                        <small class="text-muted">Join group or personal discussions with the author.</small>
                    </div>
                </div>
            </div>
            <!-- <div class="row text-start pt-3">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="film" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Dive into exclusive content from the community</h6>
                        <small class="text-muted">From Fanart, videos, to quizzes and fanfics.</small>
                    </div>
                </div>
            </div>
            <div class="row text-start pt-3">
                <div class="col-12 d-flex justify-content-between">
                    <feather-icon icon="globe" class="me-3 mt-2"></feather-icon>
                    <div class="flex-grow-1">
                        <h6 class="mb-0">Discover similar up-and-coming, paid or free books</h6>
                        <small class="text-muted">Get recommendation from {{authorFirstName}} on what to read next.</small>
                    </div>
                </div>
            </div> -->
            <div class="row pt-4">
                <div class="col-12 ps-0 pe-0 ps-md-4 pe-md-4">
                    <input type="email" required class="form-control rounded-input email-input with-border" placeholder="E-mail" [(ngModel)]="email">
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-12 ps-0 pe-0 ps-md-4 pe-md-4">
                    <button type="button"
                        (click)="joinCrew()"
                        class="btn btn-dark btn-block text-nowrap rounded-input w-100">
                        Subscribe for Free
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
