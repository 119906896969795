import { Injectable, NgZone } from '@angular/core';

type UserAction = {
    action: "join_book_crew",
    book: string,
    author: string
};
type FakeUser = {
    email: string;
    actions: UserAction[];
};

@Injectable({
    providedIn: 'root'
})
export class FakeAuthService {
    private _loggedIn = false;
    private _user: FakeUser | null = null;
    private _afterLoadCb?: (user: FakeUser) => void;
    private _afterSaveCb?: () => void;
    private _afterActionCb?: (action: UserAction) => void;

    constructor(private _ngZone: NgZone) {}

    load() {
        this._ngZone.run(() => {
            try {
                const saved = localStorage.getItem("fa_user");
                if (saved) {
                    this._user = JSON.parse(saved);
                    this._loggedIn = true;
                }
            } catch (_e) {}
            if (this._afterLoadCb && this._user) {
                this._afterLoadCb(this._user);
            }
        });
    }

    save(email: string) {
        this._ngZone.run(() => {
            try {
                const saved = localStorage.getItem("fa_user");
                if (!saved) {
                    localStorage.setItem("fa_user", JSON.stringify({email, actions: []}));
                    this._user = {email, actions: []};
                }
                this._loggedIn = true;
            } catch (_e) {}
            if (this._afterSaveCb) {
                this._afterSaveCb();
            }
        });
    }

    madeAction(action: UserAction) {
        if (!this._user) return;
        this._user.actions.push(action);
        try {
            localStorage.setItem("fa_user", JSON.stringify(this._user));
        } catch (_e) {}
        if (this._afterActionCb) {
            this._afterActionCb(action);
        }
    }

    get loggedIn() {
        return this._loggedIn;
    }

    get user() {
        return this._user;
    }

    set afterSaveCb(cb: () => void) {
        this._afterSaveCb = cb;
    }
    set afterLoadCb(cb: (user: FakeUser) => void) {
        this._afterLoadCb = cb;
    }
    set afterActionCb(cb: (action: UserAction) => void) {
        this._afterActionCb = cb;
    }
}
