import { nodes as basicNodes, marks } from 'ngx-editor';
import { Schema, NodeSpec } from 'prosemirror-model';

const video: NodeSpec = {
    attrs: {
        src: {},
        poster: { default: null },
        width: {default: ""},
        controls: {default: ""},
        autoplay: {default: ""},
        playsinline: {default: ""},
        class: {default: ""}
    },
    group: "block",
    draggable: true,
    selectable: true,
    parseDOM: [{
        tag: "video", getAttrs(dom) {
            console.log(dom);
            if (typeof dom !== "string") {
                return {
                    src: dom.getAttribute('src'),
                    poster: dom.getAttribute('poster'),
                    width: dom.getAttribute('width'),
                    class: dom.getAttribute('class')
                }
            } else {
                return null;
            }
        }
    }],
    toDOM(node) {
        return ["video", {
            controls: '',
            autoplay: '',
            playsinline: '',
            draggable: 'false',
            width: node.attrs['width'],
            class: node.attrs['class'],
            poster: node.attrs['poster'],
        }, ["source", { src: node.attrs['src'] }]];
    },
};

//@ts-ignore
basicNodes.image.attrs.class = {default: "reader-media"};
//@ts-ignore
basicNodes.image.toDOM = function(node) {
    return ["img", node.attrs];
}

const nodes = Object.assign({}, basicNodes, {
    video: video,
});

const schema = new Schema({
    nodes,
    marks,
});

export default schema;
