import { Component, Input, ViewChild, TemplateRef, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subject } from 'rxjs';
import { ReadablesService } from '../../services/readables.service';
import { PlatformService } from 'src/app/services/platform.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Readable } from 'src/app/models/readable.model';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { SignupReason } from 'src/app/services/analytics/events/signupReason';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'readables-reader-popup',
    templateUrl: './reader-popup.component.html',
    styleUrls: ['./reader-popup.component.scss'],
})
export class ReadablesReaderPopUp implements OnInit, AfterViewInit {
    @Input()
    readable!: Readable;

    @Input()
    start = 0;

    @Input()
    autoTrigger = false;

    @Input()
    onStopReading?: () => void;

    @Input()
    floatingContent?: TemplateRef<HTMLElement>;

    @ViewChild('readerModal') readerModal!: TemplateRef<any>;
    @ViewChild('readerSidebar') readerSidebar!: TemplateRef<any>;

    showSidebar = false;
    slideSidebar = false;
    sidebarView: "change-font-size" | "show-toc" | "" = "";
    sidebarClosable = true;
    sidebarTimer: NodeJS.Timeout | null = null;
    fontSize = 1;

    jumps: Subject<number> = new Subject<number>();

    followsAuthor = false;
    joinedCrew = false;
    showAfterJoinAlert = false;
    afterJoinCb = () => {
        //also follow when they join the crew
        if (!this.followsAuthor) {
            this._followService.followAuthor({source: "after_join_crew_auto"});
        }
        this.showAfterJoinAlert = true;
    };

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _modalService: NgbModal,
        private readonly _readablesService: ReadablesService,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _followService: FollowService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "media_unlock",
            media: this.readable.slug
        };
    }

    get signupReason(): SignupReason {
        return {
            type: "media_unlock",
            media: this.readable.slug
        };
    }

    async ngOnInit() {
        if (this.readable.author && this.readable.author.crew) {
            this._followService.followsAuthor$.subscribe(val => {
                this.followsAuthor = val;
            });
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
            await this._followService.init(
                null,
                this.readable.author,
                null
            );
        }
    }

    async ngAfterViewInit() {
        if (!this._platformService.isBrowser()) return;
        //auto trigger the reader if the query is set
        //has a little hack to stop multiple reader popups from opening
        //(in case the same readable is multiple times on the page)
        const read = (await firstValueFrom(this._route.queryParamMap)).get("read");
        //@ts-ignore
        if (read === this.readable.slug && !window.reading) {
            //@ts-ignore
            window.reading = true;
            await this.startReading();
            //@ts-ignore
        } else if (this.autoTrigger && !window.reading) {
            //@ts-ignore
            window.reading = true;
            await this.startReading();
        }
        //little helper used in .reader-media css class to properly size the media
        document.body.style.setProperty('--scrollbar-width', (window.innerWidth - document.body.clientWidth) + 'px');
    }

    @HostListener('click', ['$event.target'])
    async startReading() {
        this.jumps = new Subject<number>(); //reset the jumps everytime the popup is re-opened
        const options: NgbModalOptions = {
            centered: true,
            scrollable: true,
            // backdrop: "static",
            beforeDismiss: () => {
                this.slideSidebar = false;
                this.showSidebar = false;
                this.sidebarView = "";
                this._chatWidgetService.show();
                if (this.onStopReading) {
                    this.onStopReading();
                }
                return true;
            },
            modalDialogClass: "reader-modal-dialog"
        };
        if (await firstValueFrom(this._platformService.isMobile)) {
            options.fullscreen = true;
        } else {
            options.size = "xl";
        }
        this._chatWidgetService.hide();
        this.showAfterJoinAlert = false;
        await firstValueFrom(
            this._modalService.open(this.readerModal, options).shown
        );
        this._analyticsService.track({event: "start_reading", params: {readable: this.readable}});
    }

    openSidebar(view: typeof this.sidebarView) {
        //toggle if same button is clicked
        if (this.showSidebar && this.sidebarView === view) {
            this.closeSidebar();
            return;
        }
        this.sidebarView = view;
		this.showSidebar = true;
        this.slideSidebar = true;
	}

    closeSidebar() {
        if (!this.sidebarClosable || !this.showSidebar || !this.slideSidebar) return;
        this.slideSidebar = false;
        setTimeout(() => {
            this.showSidebar = false;
            this.sidebarView = "";
        }, 900);
    }

    fontSizeChange(event: Event) {
        //@ts-ignore
        if (event.target?.value) {
            //@ts-ignore
            const fontSize = parseFloat(event.target?.value);
            if (fontSize !== this.fontSize) {
                this.sidebarClosable = false;
                this.fontSize = fontSize;
                if (this.sidebarTimer) {
                    clearTimeout(this.sidebarTimer);
                    this.sidebarTimer = setTimeout(() => {
                        this.sidebarClosable = true;
                        this.closeSidebar();
                    }, 1000);
                } else {
                    this.sidebarTimer = setTimeout(() => {
                        this.sidebarClosable = true;
                        this.closeSidebar();
                    }, 1000);
                }
            }
        }
    }

    async jumpTo(chunk: number) {
        this.jumps.next(chunk);
    }

    get mobile() {
        return this._platformService.isMobile;
    }

    get locked() {
        return false;
    }

    get isLoggedIn$() {
        return this._authService.isLoggedIn$;
    }

    get crewFormSettings() {
        return {
            trigger: "media_unlock",
            media: this.readable.title
        } as const;
    }

    get headerHeight() {
        const header = document.querySelector(".reader-modal-header");
        if (!header) return "0";
        return getComputedStyle(header).height;
    }

    get contentScolled() {
        const content = document.querySelector(".reader-modal-body");
        if (!content) return "0";
        return `${content.scrollTop}px`;
    }

}
