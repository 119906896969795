<div class="d-flex justify-content-center ">
    <div class="row w-100 animated-visibility jumbotron-container {{visible ? 'visible' : 'hidden'}}">
        <div class="col-12 col-md-6 h-md-100 d-flex justify-content-center align-items-center cover mb-3 mb-md-1" style="--cover-color: {{src | cloudinaryPublicId | cloudinaryPrimaryColor | async}}">
            <img class="core-img" *ngIf="src" [routerLink]="url" [src]="src | cloudinaryQuality:80 | cloudinaryScaleWidth:268 | cloudinaryUrl" [alt]="title">
        </div>
        <div class="jumbotron-right-section col-12 col-md-6 c-ps-md-60 h-md-100">
            <div class="d-flex flex-column h-100">
                <div class="d-flex flex-column align-items-center align-items-md-start">
                    <span *ngIf="badgeType != 'none'" class="carousel-badge no-select text-bg-warning c-mb-10">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.74234 0.505214C6.47667 -0.168404 5.52333 -0.168405 5.25766 0.505213L3.91347 3.91347L0.505214 5.25766C-0.168404 5.52333 -0.168405 6.47667 0.505213 6.74234L3.91347 8.08654L5.25766 11.4948C5.52333 12.1684 6.47667 12.1684 6.74234 11.4948L8.08653 8.08653L11.4948 6.74234C12.1684 6.47667 12.1684 5.52333 11.4948 5.25766L8.08653 3.91347L6.74234 0.505214Z" fill="#FFB800"/>
                        </svg>
                        <ng-container *ngIf="badgeType == 'comingSoon'">
                            Coming Soon
                        </ng-container>
    
                        <ng-container *ngIf="badgeType == 'free'">
                            Free
                        </ng-container>
                    </span>
                    <!-- <span *ngIf="badgeType === 'none'" class="c-mb-10" style="height: 30px;"></span> -->
                    <h1 class="carousel-title mb-0">{{title | textLimit:60:'...'}}</h1>
                    <h2 *ngIf="subtitle" class="carousel-subtitle c-mt-10">{{subtitle}}</h2>
                </div>
                <p class="mb-0 c-mt-20 carousel-description" *ngIf="description">{{description | stripHtml | textLimit:400:'...'}}</p>
                <div class="c-pt-40 flex-grow-1">
                    <a *ngIf="url" class="btn btn-outline-secondary btn-round btn-with-icon" [routerLink]="url">
                        {{buttonText}}
                        <feather-icon icon="arrow-right" class="icon-right"></feather-icon>
                    </a>
                </div>
                <div class="d-flex justify-content-start justify-content-md-end c-mt-10">
                    <button *ngIf="showNavButtons" class="btn-icon btn-nav c-me-10 carousel-arrow" (click)="prev.emit()">
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.47803 14.6086L1.86933 7.99995L8.47803 1.39126" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button *ngIf="showNavButtons" class="btn-icon btn-nav carousel-arrow" (click)="next.emit()">
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.52197 1.39136L8.13067 8.00005L1.52197 14.6087" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
