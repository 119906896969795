import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { filter, firstValueFrom, map, of } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { PlatformService } from 'src/app/services/platform.service';
import { PublicUserProfileService } from 'src/app/services/public-user-profile.service';
import { UserService } from 'src/app/services/user.service';
import { EmbeddedAuthService } from '../../services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'change-profile-picture',
  templateUrl: './change-profile-picture.component.html',
  styleUrls: ['./change-profile-picture.component.scss']
})
export class ChangeProfilePictureComponent implements OnInit {
    
    @Input() 
    user!: User;

    @Input()
    header?: string;

    @Input()
    description: string = "Upload your profile picture. This will be shown on your public profile on crewfiction";

    @Input()
    showFooter: boolean = true;

    @Input() 
    buttonText: string = "Update & Continue"

    @Input()
    save!: Function;

    @ViewChild("imageFile")
    imageFile!: ElementRef;
    url: string = "";
    hasError: boolean = false;
    file: File | undefined;
    dirty: boolean = false;

    loading: boolean = false;

    constructor(
        private _platformService: PlatformService,
        private _cloudinaryService: CloudinaryService,
        private _publicUserProfileService: PublicUserProfileService,
        private _userService: UserService,
        private _authService: EmbeddedAuthService,
        private _http: HttpClient,
        private _analyticsService: AnalyticsService
    ) { }    

    onFileInputClick() {
        this.imageFile.nativeElement.click();
    }

    onDeletePfpClick() {
        alert('not implemented');
    }

    ngOnInit(): void {
        if(this._platformService.isBrowser()) {
            this.syncProfilePicture();
        }
    }

    private syncProfilePicture() {
        of(this.user).pipe(
            filter(user => user != undefined),
            map(user => user!.profile.images.main),
        ).subscribe(async (url) => {
            this.url = url;
            try {
                await firstValueFrom(this._http.get(url, { responseType: "blob" }));
            }
            catch (err) {
                // image does not exist, use default
                this.url = this.user.profile.images.fallback as string;
            }
        });
    }

    async onFileSelected() {
        try {
            this.dirty = true;
            this.hasError = false;
            this.file = undefined;
            const fileInput = this.imageFile.nativeElement;
            if(fileInput.files == null) {
                return;
            }
            this.file = fileInput.files[0];
            this.updatePreview(fileInput.files[0]);
        }
        catch (err) {
            this.hasError = true;
            console.error(err);
        }
        finally {
            this.imageFile.nativeElement.value = "";
        }
    }

    async submit() {
        this.loading = true;
        try {
            if (this.file && this.dirty) {
                this.url = await this._publicUserProfileService.uploadProfilePicture(this.file);
                this._analyticsService.identify(this.user.email, {$avatar: this.url});
            }
            this._userService.refreshUserCacheId();
            await this._authService.refreshTokens();
            await this.save();
            // this._platformService.reload();
        }
        catch (err) {
            console.error("Error updating profile picture", err);
        }
        finally {
            this.loading = false;
        }
    }

    private updatePreview(file: File) {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.url = e.target?.result as string;
        };
        reader.readAsDataURL(file);
    }

}
