<form [formGroup]="emailForm" (ngSubmit)="readNow()">
    <div class="col-12 c-pb-12">
        <input type="email" required class="form-control rounded-input dark-input" formControlName="email"
            placeholder="Enter your email" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
    </div>
    <div class="col-12">
        <button type="submit" [disabled]="loading" class="btn btn-dark btn-round w-100">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            {{ data.ctaText }}
        </button>
    </div>
    <div class="col-12 form-errors c-pt-12">
        <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
            <small>
                {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
            </small>
        </div>
        <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
            <small>
                Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
            </small>
        </div>
    </div>
</form>