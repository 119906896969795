import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Pipe({
    name: 'cloudinaryFormat',
    pure: true
})
export class CloudinaryFormatPipe implements PipeTransform {

    constructor(private _cloudinaryService: CloudinaryService) { }

    transform(value: CloudinaryImage | string, format = "auto"): CloudinaryImage {
        return this._cloudinaryService.changeFormat(value, format);
    }
}