import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInPageComponent } from './components/sign-in-page/sign-in-page.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuthCallbackPageComponent } from './components/auth-callback-page/auth-callback-page.component';
import { PasswordlessAuthComponent } from './components/passwordless-auth/passwordless-auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangeUsernameComponent } from './components/change-username/change-username.component';
import { ChangeProfilePictureComponent } from './components/change-profile-picture/change-profile-picture.component';
import {ChangeNameComponent} from './components/change-name/change-name.component';

@NgModule({
    declarations: [
        SignInPageComponent,
        AuthCallbackPageComponent,
        PasswordlessAuthComponent,
        ChangeUsernameComponent,
        ChangeProfilePictureComponent,
        ChangeNameComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule.forChild([
            {
                path: "login",
                component: SignInPageComponent
            },
            {
                path: "callback",
                component: AuthCallbackPageComponent
            }
        ]),
        // Auth0Module.forRoot({
        //     domain: environment.authDomain,
        //     clientId: environment.authClientId,
        //     authorizationParams: {
        //         redirect_uri: typeof window !== 'undefined' ? window.location.origin : "" + "/auth/callback"
        //     }
        // })
    ],
    exports: [
        PasswordlessAuthComponent,
        ChangeUsernameComponent
    ],
    providers: []
})
export class AuthModule { }
