import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';

@Pipe({
    name: 'cloudinaryUrl',
    pure: true
})

export class CloudinaryUrlPipe implements PipeTransform {
    transform(value: CloudinaryImage): string {
        return value?.toURL().split("?")[0];
    }
}