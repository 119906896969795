<div class="row c-pb-20">
    <h2 class="c-mb-0 worldmaker-section-title">Frequently asked questions</h2>
</div>
<div class="row vertical-collection">
    <div class="col-12">
        <div class="card">
            <div class="card-body d-flex flex-column faq-container">
                <div class="d-flex justify-content-between align-items-center header" (click)="faqState[3] = !faqState[3]">
                    <span class="title">
                        1. How do I download/send the books to my Kindle?
                    </span>
                    <span class="trigger" *ngIf="!faqState[3]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#343330" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11 16H21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 11V21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span class="trigger" *ngIf="faqState[3]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM21.7075 13.2925C21.8005 13.3854 21.8742 13.4957 21.9246 13.6171C21.9749 13.7385 22.0008 13.8686 22.0008 14C22.0008 14.1314 21.9749 14.2615 21.9246 14.3829C21.8742 14.5043 21.8005 14.6146 21.7075 14.7075L16.7075 19.7075C16.6146 19.8005 16.5043 19.8742 16.3829 19.9246C16.2615 19.9749 16.1314 20.0008 16 20.0008C15.8686 20.0008 15.7385 19.9749 15.6171 19.9246C15.4957 19.8742 15.3854 19.8005 15.2925 19.7075L10.2925 14.7075C10.1049 14.5199 9.99945 14.2654 9.99945 14C9.99945 13.7346 10.1049 13.4801 10.2925 13.2925C10.4801 13.1049 10.7346 12.9994 11 12.9994C11.2654 12.9994 11.5199 13.1049 11.7075 13.2925L16 17.5863L20.2925 13.2925C20.3854 13.1995 20.4957 13.1258 20.6171 13.0754C20.7385 13.0251 20.8686 12.9992 21 12.9992C21.1314 12.9992 21.2615 13.0251 21.3829 13.0754C21.5043 13.1258 21.6146 13.1995 21.7075 13.2925Z" fill="#343330"/>
                        </svg>
                    </span>
                </div>
                <div class="body c-pt-10" [ngClass]="{'d-none': !faqState[3]}">
                    <span>
                        With Amazon's <span class="fw-bold">Send to Kindle</span>, you can add this book to your Kindle library and enjoy reading on any Kindle app or device!
                    </span>
                    <ol class="c-mt-10">
                        <li>Go to <a href="https://www.amazon.com/sendtokindle" target="_blank" rel="noopener noreferrer" class="faq-link">https://www.amazon.com/sendtokindle</a></li>
                        <li>Log into your Amazon account if you haven't already</li>
                        <li>Follow instructions to upload files to your Kindle library</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body d-flex flex-column faq-container">
                <div class="d-flex justify-content-between align-items-center header" (click)="faqState[0] = !faqState[0]">
                    <span class="title">
                        2. How can I get the free book?
                    </span>
                    <span class="trigger" *ngIf="!faqState[0]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#343330" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11 16H21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 11V21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span class="trigger" *ngIf="faqState[0]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM21.7075 13.2925C21.8005 13.3854 21.8742 13.4957 21.9246 13.6171C21.9749 13.7385 22.0008 13.8686 22.0008 14C22.0008 14.1314 21.9749 14.2615 21.9246 14.3829C21.8742 14.5043 21.8005 14.6146 21.7075 14.7075L16.7075 19.7075C16.6146 19.8005 16.5043 19.8742 16.3829 19.9246C16.2615 19.9749 16.1314 20.0008 16 20.0008C15.8686 20.0008 15.7385 19.9749 15.6171 19.9246C15.4957 19.8742 15.3854 19.8005 15.2925 19.7075L10.2925 14.7075C10.1049 14.5199 9.99945 14.2654 9.99945 14C9.99945 13.7346 10.1049 13.4801 10.2925 13.2925C10.4801 13.1049 10.7346 12.9994 11 12.9994C11.2654 12.9994 11.5199 13.1049 11.7075 13.2925L16 17.5863L20.2925 13.2925C20.3854 13.1995 20.4957 13.1258 20.6171 13.0754C20.7385 13.0251 20.8686 12.9992 21 12.9992C21.1314 12.9992 21.2615 13.0251 21.3829 13.0754C21.5043 13.1258 21.6146 13.1995 21.7075 13.2925Z" fill="#343330"/>
                        </svg>
                    </span>
                </div>
                <div class="body c-pt-10" [ngClass]="{'d-none': !faqState[0]}">
                    To receive your free book, simply enter your email and you can start reading right away!
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body d-flex flex-column faq-container">
                <div class="d-flex justify-content-between align-items-center header" (click)="faqState[1] = !faqState[1]">
                    <span class="title">
                        3. For how long can I keep the free book?
                    </span>
                    <span class="trigger" *ngIf="!faqState[1]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#343330" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11 16H21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 11V21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span class="trigger" *ngIf="faqState[1]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM21.7075 13.2925C21.8005 13.3854 21.8742 13.4957 21.9246 13.6171C21.9749 13.7385 22.0008 13.8686 22.0008 14C22.0008 14.1314 21.9749 14.2615 21.9246 14.3829C21.8742 14.5043 21.8005 14.6146 21.7075 14.7075L16.7075 19.7075C16.6146 19.8005 16.5043 19.8742 16.3829 19.9246C16.2615 19.9749 16.1314 20.0008 16 20.0008C15.8686 20.0008 15.7385 19.9749 15.6171 19.9246C15.4957 19.8742 15.3854 19.8005 15.2925 19.7075L10.2925 14.7075C10.1049 14.5199 9.99945 14.2654 9.99945 14C9.99945 13.7346 10.1049 13.4801 10.2925 13.2925C10.4801 13.1049 10.7346 12.9994 11 12.9994C11.2654 12.9994 11.5199 13.1049 11.7075 13.2925L16 17.5863L20.2925 13.2925C20.3854 13.1995 20.4957 13.1258 20.6171 13.0754C20.7385 13.0251 20.8686 12.9992 21 12.9992C21.1314 12.9992 21.2615 13.0251 21.3829 13.0754C21.5043 13.1258 21.6146 13.1995 21.7075 13.2925Z" fill="#343330"/>
                        </svg>
                    </span>
                </div>
                <div class="body c-pt-10" [ngClass]="{'d-none': !faqState[1]}" *ngIf="!(isLoggedIn$ | async)">
                    Make sure to <a (click)="login()" class="faq-link">sign up</a> for a free crewfiction account to save your new book in your library and track your reading.
                </div>
                <div class="body c-pt-10" [ngClass]="{'d-none': !faqState[1]}" *ngIf="isLoggedIn$ | async">
                    Your new book will always be available in your library for you to read and download.
                </div>
            </div>
        </div>
    </div>


    <div class="col-12">
        <div class="card">
            <div class="card-body d-flex flex-column faq-container">
                <div class="d-flex justify-content-between align-items-center header" (click)="faqState[2] = !faqState[2]">
                    <span class="title">
                        4. Can I read the books on my Kindle/e-reader/tablet/phone?
                    </span>
                    <span class="trigger" *ngIf="!faqState[2]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#343330" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11 16H21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 11V21" stroke="#343330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span class="trigger" *ngIf="faqState[2]">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM21.7075 13.2925C21.8005 13.3854 21.8742 13.4957 21.9246 13.6171C21.9749 13.7385 22.0008 13.8686 22.0008 14C22.0008 14.1314 21.9749 14.2615 21.9246 14.3829C21.8742 14.5043 21.8005 14.6146 21.7075 14.7075L16.7075 19.7075C16.6146 19.8005 16.5043 19.8742 16.3829 19.9246C16.2615 19.9749 16.1314 20.0008 16 20.0008C15.8686 20.0008 15.7385 19.9749 15.6171 19.9246C15.4957 19.8742 15.3854 19.8005 15.2925 19.7075L10.2925 14.7075C10.1049 14.5199 9.99945 14.2654 9.99945 14C9.99945 13.7346 10.1049 13.4801 10.2925 13.2925C10.4801 13.1049 10.7346 12.9994 11 12.9994C11.2654 12.9994 11.5199 13.1049 11.7075 13.2925L16 17.5863L20.2925 13.2925C20.3854 13.1995 20.4957 13.1258 20.6171 13.0754C20.7385 13.0251 20.8686 12.9992 21 12.9992C21.1314 12.9992 21.2615 13.0251 21.3829 13.0754C21.5043 13.1258 21.6146 13.1995 21.7075 13.2925Z" fill="#343330"/>
                        </svg>
                    </span>
                </div>
                <div class="body c-pt-10" [ngClass]="{'d-none': !faqState[2]}">
                    Yes! Our eBooks are compatible with all major e-readers, tablets, and smartphones.
                </div>
            </div>
        </div>
    </div>

</div>