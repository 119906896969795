import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { Genre } from 'src/app/models/genre.model';
import { Trope } from 'src/app/models/trope.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'tropeRouterLink',
    pure: true
})

export class TropeRouterLinkPipe implements PipeTransform {

    constructor(private readonly _utilitiesService: UtilitiesService) {}

    transform(trope: Trope | string): string  {
        let slug = typeof trope === 'string' ? trope : trope.slug;
        return this._utilitiesService.getTropeUrl(slug);
    }
}
