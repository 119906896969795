import { Component, Input, OnInit } from '@angular/core';
import { BadgeConfig } from 'src/app/models/badge-config.model';


@Component({
  selector: 'badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent implements OnInit {
    @Input() 
    data: BadgeConfig[] = [];

    @Input()
    expandable = false;

    @Input()
    showInitial = 6;

    @Input()
    expandCta = "View More";

    @Input()
    disableClick = false;

    @Input()
    horizontalMode = false;

    list!: BadgeConfig[];
    expanded = false;

    ngOnInit(): void {
        if (this.expandable) {
            this.list = this.data.slice(0, this.showInitial);
        } else {
            this.list = this.data;
        }
    }

    expand() {
        this.expanded = true;
        this.list.push(...this.data.slice(this.showInitial, this.data.length));
    }

    badgeClick(item: BadgeConfig) {
        if (item.onClick) {
            item.onClick();
        }
    }
}
