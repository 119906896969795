import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { Comment } from "../../../../models/comment.model";
import { Discussion } from 'src/app/models/discussion.model';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { Auth0User } from 'src/app/modules/auth/types/auth0-user';
import { AddReplyEventArgs } from '../../models/add-reply-event';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { firstValueFrom, BehaviorSubject, Subscription, Observable } from 'rxjs';

@Component({
    selector: 'reply-input',
    templateUrl: './reply-input.component.html',
    styleUrls: ['./reply-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplyInputComponent {
    constructor(
        private _authService: EmbeddedAuthService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _anonymousPersistentState: AnonymousPersistentState
    ) {}

    @Input()
    discussion!: Discussion;

    @Input()
    prompt: string = "What do you think?";

    @Input()
    user?: Auth0User;

    @Input()
    comment!: Comment;

    @Input()
    nested: boolean = false;

    @Input()
    posting = new BehaviorSubject(false);

    @Output()
    reply = new EventEmitter<AddReplyEventArgs>();

    replyText: string = '';
    private readonly maxReplyLength: number = 800;
    lengthError: boolean = false;

    commentMetadata?: {[key: string]: any}

    async postReply(event?: SubmitEvent) {
        if (event) event.preventDefault();
        this.replyText = this.replyText.trim();
        if (!this.replyText) return;
        if (await firstValueFrom(this._authService.isLoggedIn$)) {
            await this._authService.ensureOnboardingCompleted();
            const data = { comment: this.comment, replyText: this.replyText, threadId: this.comment?.id, metadata: this.commentMetadata };
            this.reply.emit({
                data,
                bubble: this.nested
            });
            let sub: Subscription | null = null;
            sub = this.posting.subscribe(posting => {
                if (!posting) {
                    this.replyText = '';
                    this._anonymousPersistentState.clearPendingComment();
                    if (sub) sub.unsubscribe();
                }
            });
        } else {
            this._anonymousPersistentState.savePendingComment({content: this.replyText, threadId: this.comment?.id, discussion: this.discussion.id});
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "discussion"
                    }
                }
            });
            this._authService.login({
                sendStageTitle: "Sign in or sign up",
                sendStageDescription: "Enter your email to create your crewfiction account or sign-in to an existing one",
                signupReason: {type: "discussion"},
                action: "post_comment",
                actionParams: {
                    discussion: this.discussion.id
                }
            })
        }
    }

    onReplyTextChanged() {
        this.lengthError = this.replyText.length > this.maxReplyLength;
    }
}
