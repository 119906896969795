import { Component, Input, ViewChild, TemplateRef, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import emailSpellChecker from '@zootools/email-spell-checker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subject, BehaviorSubject } from 'rxjs';
import { ReadablesService } from '../../services/readables.service';
import { PlatformService } from 'src/app/services/platform.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Readable } from 'src/app/models/readable.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { BooksService } from 'src/app/services/books.service';
import { LibraryService } from 'src/app/services/library.service';

@Component({
    selector: 'readables-reader-popup-v2',
    templateUrl: './reader-popup-v2.component.html',
    styleUrls: ['./reader-popup-v2.component.scss'],
    animations: [
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        ),
        trigger(
            'unlockNotification', 
            [
                transition(
                    ':enter',
                    [
                        style({ "bottom": "-40px" }),
                        animate('0.2s ease-in-out', style({ "bottom": "40px" }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ "bottom": "40px" }),
                        animate('0.2s ease-in-out', style({ "bottom": "-40px" }))
                    ]
                )
            ]
        )
    ]
})
export class ReadablesReaderPopUpV2 implements OnInit, AfterViewInit {
    @Input()
    readable!: Readable;

    @Input()
    start = 0;

    @Input()
    autoTrigger = false;

    @Input()
    onStopReading?: () => void;

    @ViewChild('readerModal') readerModal!: TemplateRef<any>;
    @ViewChild('readerSidebar') readerSidebar!: TemplateRef<any>;

    showSidebar = false;
    slideSidebar = false;
    sidebarView: "change-font-size" | "show-toc" | "" = "";
    sidebarClosable = true;
    sidebarTimer: NodeJS.Timeout | null = null;
    fontSize = 1;
    finishText: string = "Finish";
    jumps: Subject<number> = new Subject<number>();
    lockState = new BehaviorSubject<boolean | null>(null);

    showBonusSceneSectionOnHeader = false;
    showBonusSceneUnlockNotif = false;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _modalService: NgbModal,
        private readonly _readablesService: ReadablesService,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _chatWidgetService: ChatWidgetService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _authService: EmbeddedAuthService,
        private readonly _booksService: BooksService,
        private readonly _libraryService: LibraryService
    ) {}

    async ngOnInit() {
        let email = this._anonymousPersistentState.email;
        email = (await firstValueFrom(this._route.queryParamMap)).get("email") || email;
        if (this._authService.user) {
            email = this._authService.user.email;
        }
        if (email) {
            this.emailFormControl.setValue(email);
        }

        if (this.readable.settings.type === "bonus_scene") {
            this.finishText = "Finish bonus scene";
        } else if (this.readable.settings.type === "free_book" || this.readable.settings.type === "arc") {
            this.finishText = "Finish";
        }
    }

    async ngAfterViewInit() {
        if (!this._platformService.isBrowser()) return;
        //auto trigger the reader if the query is set
        //has a little hack to stop multiple reader popups from opening
        //(in case the same readable is multiple times on the page)
        const read = (await firstValueFrom(this._route.queryParamMap)).get("read");
        //@ts-ignore
        if (read === this.readable.slug && !window.reading) {
            //@ts-ignore
            window.reading = true;
            await this.startReading();
            //@ts-ignore
        } else if (this.autoTrigger && !window.reading) {
            //@ts-ignore
            window.reading = true;
            await this.startReading();
        }
        //little helper used in .reader-media css class to properly size the media
        document.body.style.setProperty('--scrollbar-width', (window.innerWidth - document.body.clientWidth) + 'px');

        //bigger font on mobile
        if (await firstValueFrom(this._platformService.isMobile)) {
            this.fontSize = 1.125;
        }

        //sync arc progress if we don't have it locally (free book sync happens in library state)
        if (this.readable.settings.type === "arc" && !this._anonymousPersistentState.reading[this.readable.slug]) {
            const reading = await firstValueFrom(this._booksService.getArcReadingProgress(
                this.readable.book!.slug,
                this._anonymousPersistentState.email
            ));
            if (reading) {
                this._anonymousPersistentState.saveReading(this.readable.slug, reading);
            }
        }

        this._initPreloader();
    }

    @HostListener('click', ['$event.target'])
    async startReading() {
        if (this._modalService.hasOpenModals()) return;
        this.jumps = new Subject<number>(); //reset the jumps everytime the popup is re-opened
        let bonusSceneSectionObserver: IntersectionObserver | null = null;
        const options: NgbModalOptions = {
            centered: true,
            scrollable: true,
            // backdrop: "static",
            beforeDismiss: () => {
                this.slideSidebar = false;
                this.showSidebar = false;
                this.sidebarView = "";
                this._chatWidgetService.show();
                if (this.onStopReading) {
                    this.onStopReading();
                }
                if (bonusSceneSectionObserver) {
                    bonusSceneSectionObserver.disconnect();
                }
                return true;
            },
            modalDialogClass: "reader-modal-dialog"
        };
        if (await firstValueFrom(this._platformService.isMobile)) {
            options.fullscreen = true;
        } else {
            options.size = "xl";
        }
        this._chatWidgetService.hide();
        await firstValueFrom(
            this._modalService.open(this.readerModal, options).shown
        );
        this._analyticsService.track({event: "start_reading", params: {readable: this.readable}});
        //set up an observer so we can move the bonus scene section to the header or body
        if (this.readable.settings.type === "bonus_scene") {
            bonusSceneSectionObserver = new IntersectionObserver(async (entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        this.showBonusSceneSectionOnHeader = false;
                    } else {
                        this.showBonusSceneSectionOnHeader = true;
                    }
                }
            }, {
                root: document.querySelector(".reader-modal-body"),
                rootMargin: "120px",
                threshold: 1,
            });
            bonusSceneSectionObserver.observe(document.querySelector(".bonus-scene-section")!);
        }
    }

    openSidebar(view: typeof this.sidebarView) {
        //toggle if same button is clicked
        if (this.showSidebar && this.sidebarView === view) {
            this.closeSidebar();
            return;
        }
        this.sidebarView = view;
		this.showSidebar = true;
        this.slideSidebar = true;
	}

    closeSidebar() {
        if (!this.sidebarClosable || !this.showSidebar || !this.slideSidebar) return;
        this.slideSidebar = false;
        setTimeout(() => {
            this.showSidebar = false;
            this.sidebarView = "";
        }, 900);
    }

    fontSizeChange(event: Event) {
        //@ts-ignore
        if (event.target?.value) {
            //@ts-ignore
            const fontSize = parseFloat(event.target?.value);
            if (fontSize !== this.fontSize) {
                //this auto-closes the sidebar on change
                // this.sidebarClosable = false;
                this.fontSize = fontSize;
                // if (this.sidebarTimer) {
                //     clearTimeout(this.sidebarTimer);
                //     this.sidebarTimer = setTimeout(() => {
                //         this.sidebarClosable = true;
                //         this.closeSidebar();
                //     }, 1000);
                // } else {
                //     this.sidebarTimer = setTimeout(() => {
                //         this.sidebarClosable = true;
                //         this.closeSidebar();
                //     }, 1000);
                // }
            }
        }
    }

    async jumpTo(chunk: number) {
        this.jumps.next(chunk);
    }

    get locked() {
        if (this.readable.settings.type === "bonus_scene") {
            if (this._anonymousPersistentState.bonusContent[this.readable.book!.slug]) {
                this.lockState.next(false);
                return false;
            } else {
                this.lockState.next(true);
                return true;
            }
        } else {
            this.lockState.next(null);
            return false;
        }
    }

    get pendingFinish() {
        if (this.readable.settings.type === "bonus_scene") {
            const state = this._anonymousPersistentState.bonusContent[this.readable.book!.slug];
            if (!state || !state.finishedBonusScene) {
                return true;
            } else {
                return false;
            }
        } else if (this.readable.settings.type === "free_book" || this.readable.settings.type === "arc") {
            const state = this._anonymousPersistentState.reading[this.readable.slug];
            if (!state || !state.finished) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    get headerHeight() {
        const header = document.querySelector(".reader-modal-header");
        if (!header) return "0";
        return getComputedStyle(header).height;
    }

    get contentScolled() {
        const content = document.querySelector(".reader-modal-body");
        if (!content) return "0";
        return `${content.scrollTop}px`;
    }

    get bookPageUrl() {
        return `/authors/${this.readable.author!.slug}/books/${this.readable.book!.slug}`;
    }

    emailSuggestionError = "";
    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }

    unlock() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;

        this._anonymousPersistentState.saveEmail(email);
        this._anonymousPersistentState.saveBonusContent(this.readable.book!.slug);
        setTimeout(() => {
            this.showBonusSceneUnlockNotif = true;
            setTimeout(() => {
                this.showBonusSceneUnlockNotif = false;
            }, 3000);
        }, 500);
        this._analyticsService.identifyAndTrack({email, traits: {signupReason: {type: "bonus_content_flow", book: this.readable.book!.slug}}}, {
            event: "unlock_bonus_content",
            params: {
                book: this.readable.book!,
                author: this.readable.author!,
                kindle: false,
                contentType: "bonus_scene"
            }
        });
    }

    finish() {
        if (this.readable.settings.type === "bonus_scene") {
            this.finishBonusScene();
        }
        else {
            this.finishReading();
        }
    }

    finishBonusScene() {
        this._anonymousPersistentState.saveBonusContent(this.readable.book!.slug, {finishedBonusScene: true});
        this._analyticsService.track({event: "finish_bonus_content", params: {
            book: this.readable.book!,
            author: this.readable.author!,
            kindle: false,
            contentType: "bonus_scene"
        }});
        this._router.navigate([`${this.bookPageUrl}/rate`], {
            queryParams: {
                action: "bonus_scene"
            }
        });
        this._modalService.dismissAll();
    }

    finishReading() {
        this._anonymousPersistentState.saveReading(this.readable.slug, {percent: 100, finished: true});
        this._analyticsService.track({event: "finish_reading", params: {readable: this.readable}});
        if (this.readable.settings.type === "free_book") {
            //sync reading progress to user's shelf
            if (this._authService.user) {
                firstValueFrom(this._libraryService.updateFreeBooksReadingProgress({
                    [this.readable.slug]: this._anonymousPersistentState.reading[this.readable.slug]
                }));
            }
        } else if (this.readable.settings.type === "arc") {
            //sync reading progress to the arcContact
            firstValueFrom(this._booksService.updateArcReadingProgress(
                this.readable.book!.slug,
                this._anonymousPersistentState.reading[this.readable.slug],
                this._anonymousPersistentState.email
            ))
        }
        this._router.navigate([`${this.bookPageUrl}/rate`], {
            queryParams: {
                action: "free_book"
            }
        });
        this._modalService.dismissAll();
    }

    private _preloaded = false;
    private _initPreloader() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.register("/readable-cache-sw.js", {
                scope: "/",
            })
            .then(async registration => {
                const init = async () => {
                    if (registration.active && registration.active.state === "activated") {
                        if (!this._preloaded) {
                            // console.log("preloading");
                            await firstValueFrom(this._readablesService.read(this.readable.slug, 0));
                            this._preloaded = true;
                        }
                    }
                };
                if (registration.installing) {
                    registration.installing.addEventListener("statechange", init);
                  }
                if (registration.waiting) {
                    registration.waiting.addEventListener("statechange", init);
                }
                if (registration.active) {
                    registration.active.addEventListener("statechange", init);
                }
                init();
            })
            .catch(e => console.error(e));
        }
    }
}
