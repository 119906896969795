import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Pipe({
    name: 'cloudinaryPublicId',
    pure: true
})

export class CloudinaryPublicIdPipe implements PipeTransform {

    constructor(private _cloudinaryService: CloudinaryService) {}

    transform(url: string): string {
        return this._cloudinaryService.publicIdSimplified(url);
    }
}