import { Component, Input } from '@angular/core';
import { EcommerceHeaderTest } from './components/ecommerce-header-test/ecommerce-header-test.component';
import { EcommerceReadSample } from './components/ecommerce-read-sample/ecommerce-read-sample.component';
import { EcommerceExplainList } from './components/ecommerce-explain-list/ecommerce-explain-list.component';
import { EcommerceFAQ } from './components/ecommerce-faq/ecommerce-faq.component';

const componentMap: {[key: string]: any} = {
    ecommerce_header_test: EcommerceHeaderTest,
    ecommerce_read_sample: EcommerceReadSample,
    ecommerce_explain_list: EcommerceExplainList,
    ecommerce_faq: EcommerceFAQ
};

@Component({
    selector: "custom-component-loader",
    templateUrl: "./custom-component-loader.component.html",
    styleUrl: "./custom-component-loader.component.scss",
})
export class CustomComponentLoader {
    @Input() data!: {
        componentName: string;
        componentData: string;
    }

    constructor() {}

    get loaded() {
        if (!this.data || !this.data.componentName) {
            return null;
        }
        if (!componentMap[this.data.componentName]) {
            return null;
        }

        let input: Record<string, unknown> = {};
        try {
            input = JSON.parse(this.data.componentData);
        } catch (_) {}

        return {
            component: componentMap[this.data.componentName],
            input: input
        };
    }
}
