import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import {DOCUMENT} from "@angular/common"
import { Book } from 'src/app/models/book.model';
import { BookSeries } from 'src/app/models/book-series.model';
import { Readable } from 'src/app/models/readable.model';
import { BooksService } from 'src/app/services/books.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'worldmaker-component-book-info',
    templateUrl: './book-info.component.html',
    styleUrls: ['./book-info.component.scss'],
})
export class BookInfo implements OnInit, OnDestroy {
    @Input() data!: {
        book: Book;
        linksDisabled: boolean;
        readable?: Readable,
        showReadable: boolean,
        readCTA: string,
        showHR: boolean,
        showPageCount: boolean,
        ctaBackground: {
            channel: "manual_image" | "manual_video",
            data: {
                url: string
            }
        }
    };
    book!: Book;
    bookSeries?: BookSeries;

    constructor(
        private readonly _booksService: BooksService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    async ngOnInit() {
        this.book = this.data.book;
        if (this.book.bookSeries) {
            //TODO the extra call can be avoided if book.bookSeries contains the authors
            this.bookSeries = await firstValueFrom(this._booksService.findOneSeriesById(this.book.bookSeries.id!))
        }

        //when we have disabled badge links also hide the navbar links
        if (this.data.linksDisabled) {
            try {
                for (const el of Array.from(this.document.querySelectorAll<HTMLDivElement>(".navlinks"))) {
                    el.style.setProperty("display", "none", "important");
                }
            } catch (_e) {}
        }
    }

    ngOnDestroy(): void {
        //show them again
        if (this.data.linksDisabled) {
            try {
                for (const el of Array.from(this.document.querySelectorAll<HTMLDivElement>(".navlinks"))) {
                    el.style.removeProperty("display");
                }
            } catch (_e) {}
        }
    }

    get readable() {
        if (this.data.showReadable && this.data.readable) {
            return this.data.readable;
        } else {
            return;
        }
    }
}
