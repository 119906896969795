import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    private defaultTitle: string = 'Crewfiction';

    constructor(private title: Title) { }

    setTitle(newTitle: string | undefined, includeDefaultTitle = true): void {
        if (newTitle) {
            if (!includeDefaultTitle) {
                this.title.setTitle(newTitle);
            } else {
                this.title.setTitle(newTitle + " • " + this.defaultTitle);
            }
        } else {
            this.title.setTitle(this.defaultTitle);
        }
    }
}
