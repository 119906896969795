import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay, switchMap, catchError, of, firstValueFrom, filter, Subscription, combineLatest } from 'rxjs';
import { Author } from 'src/app/models/author.model';
import { AuthorsService } from 'src/app/services/authors.service';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { FollowService } from '../../../services/follow.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ActivatedRoute } from '@angular/router';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'author-bio',
    templateUrl: './author-bio.component.html',
    styleUrls: ['./author-bio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorBioComponent implements OnChanges, OnInit {
    @Input()
    author!: Author;

    afterJoinCb = () => this._modalService.dismissAll();
    @ViewChild('joinModal') joinModal!: TemplateRef<any>;
    followsAuthor$ = this._followService.followsAuthor$;
    joinedCrew$ = this._followService.joinedCrew$;

    followRequestInProgress$ = this._activatedRoute.queryParams.pipe(
        filter(params => params["action"] === "follow_author" && params["author"] === this.author.slug),
        filter(() => this._platformService.isBrowser())
    );

    private _subs: Subscription[] = [];
    @ViewChild("authorBioContainer")
    authorBioContainer!: ElementRef;

    constructor(
        private readonly _authorsService: AuthorsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _followService: FollowService,
        private readonly _modalService: NgbModal,
        private readonly _authService: EmbeddedAuthService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _platformService: PlatformService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    ngAfterViewInit(): void {
        this.authorBioContainer.nativeElement.innerHTML = this.author.bio;
    }

    async ngOnInit() {
        await this._followService.init(
            null,
            this.author,
            null
        );

        this._subs.push(combineLatest([
            this._authService.isLoggedIn$,
            this._authService.onboardingCompleted$,
            this.followRequestInProgress$
        ]).pipe(
            filter(([isLoggedIn, onboardingCompleted, followInProgress]) => isLoggedIn && onboardingCompleted && Boolean(followInProgress)),
        ).subscribe(() => {
            this.followAuthor();
        }));
    }

    author$: BehaviorSubject<Author> = new BehaviorSubject<Author>(this.author);

    authorGenres$: Observable<BadgeConfig[]> = this.author$.asObservable().pipe(
        switchMap((author) => {
            return this._authorsService.getAuthorGenres(<number>author.id);
        }),
        map((genres) => {
            return genres.map(genre => {
                return {
                    text: genre.name,
                    type: "default",
                    url: this._utilitiesService.getGenreUrl(genre.slug)
                };
            }) as BadgeConfig[];
        }),
        catchError((err) => {
            console.error(err);
            return of([]);
        }),
        shareReplay(1)
    );

    authorTropes$: Observable<BadgeConfig[]> = this.author$.asObservable().pipe(
        switchMap((author) => {
            return this._authorsService.getAuthorTropes(<number>author.id);
        }),
        catchError((err) => {
            console.error(err);
            return of([]);
        }),
        map((tropes) => {
            return tropes.map(trope => {
                return {
                    text: trope.name,
                    type: "default",
                    url: this._utilitiesService.getTropeUrl(trope.slug)
                };
            }) as BadgeConfig[];
        }),
        shareReplay(1)
    );

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['author']) {
            this.author$.next(changes['author'].currentValue);
        }
    }
    
    get optionalAuthorProperties(): {label: string; value: string}[] {
        const optionalPropertyTypes = [{
            label: "Born",
            resolver: (author: Author) => author.bornAt ? new Date(author.bornAt).toLocaleDateString() : "",
        }, {
            label: "Deceased",
            resolver: (author: Author) => author.deceasedAt ? new Date(author.deceasedAt).toLocaleDateString() : ""
        }, {
            label: "Nationality",
            resolver: (author: Author) => author.nationality ?? ""
        }, {
            label: "Official Website",
            resolver: (author: Author) => author.officialWebsite?.replace("https://", "")?.replace("http://", "") ?? ""
        }
        // , {
        //     label: "Relations",
        //     resolver: (author: Author) => author.relations?.join(", ") ?? ""
        // }, {
        //     label: "Influences",
        //     resolver: (author: Author) => author.influences?.join(", ") ?? ""
        // }
        ];

        return optionalPropertyTypes.map(p => {
            return {
                label: p.label,
                value: p.resolver(this.author)
            }
        }).filter(p => p.value);
    }

    get crewFormSettings() {
        return {
            trigger: "author_follow"
        } as const;
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "author_page"
        };
    }

    async followAuthor() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "author_page_follow_author",
                        author: this.author.slug
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: `Sign in or sign up`,
                sendStageDescription: `Sign-in or register for free to follow ${this.author.name}`,
                skipOnboarding: false,
                signupReason: {type: "follow_author", author: this.author.slug},
                action: "follow_author",
                actionParams: {
                    author: this.author.slug
                }
            });
        }
        this._followService.followAuthor({source: "author_page"});

        if (!await firstValueFrom(this.joinedCrew$) && this.author.crew) {
            this._chatWidgetService.hide();
            //TODO make modalService.close() to wait for modal hidden event
            await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
            const that = this;
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        that._chatWidgetService.show();
                        return true;
                    },
                }).shown
            );
        }
    }
    async unfollowAuthor() {
        await this._followService.unfollowAuthor();
    }
}
