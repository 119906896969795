import { Component } from '@angular/core';

@Component({
  selector: 'auth-callback-page',
  templateUrl: './auth-callback-page.component.html',
  styleUrls: ['./auth-callback-page.component.scss']
})
export class AuthCallbackPageComponent {

}
