import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Pipe({
    name: 'cloudinaryScaleHeight',
    pure: true
})

export class CloudinaryScaleHeightPipe implements PipeTransform {
    constructor(private _cloudinaryService: CloudinaryService) { }
    
    transform(url: CloudinaryImage | string, height: number): CloudinaryImage {
        return this._cloudinaryService.scaleByHeight(url, height);
    }
}