import { Component, Input, OnInit } from '@angular/core';
import { JumbotronConfig } from 'src/app/models/jumbotron-config.model';
import { Book } from 'src/app/models/book.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PlatformService } from 'src/app/services/platform.service';
import { World } from 'src/app/models/world.model';

export interface FeaturedCaruselItem {
    title: string;
    description: string;
    imageUrl: string;
    ctaUrl: string;
    ctaText: string;
}

export interface FeaturedCarouselWorldMakerConfig {
    items: FeaturedCaruselItem[];
    header: string;
}

type Media = {
    channel: 'manual_image';
    data: { url: string; title: string; description: string, category: string };
}
//just images implemented for now
// | {
//     channel: 'manual_video';
//     data: { url: string; title: string; description: string, category: string };
// };

@Component({
  selector: 'worldmaker-component-featured-carousel',
  templateUrl: './featured-carousel.component.html',
  styleUrls: ['./featured-carousel.component.scss']
})
export class FeaturedCarouselComponent implements OnInit {

    constructor(
        private readonly _utilitiesService: UtilitiesService,
        private readonly _platformService: PlatformService
    ) {}
    
    @Input()
    data!: {
        header: string,
        showHR: boolean,
        [key: `featured_${number}_book`]: Book | undefined,
        [key: `featured_${number}_world`]: World | undefined,
        [key: `featured_${number}_custom_title`]: string | undefined,
        [key: `featured_${number}_custom_subtitle`]: string | undefined,
        [key: `featured_${number}_custom_description`]: string | undefined,
        [key: `featured_${number}_custom_link`]: string | undefined,
        [key: `featured_${number}_custom_media`]: Media | undefined,
        [key: `featured_${number}_cta_text`]: string | undefined,
        [key: `featured_${number}_badge`]: "free" | "comingSoon" | "none" | "useBookValue" | undefined,
        [key: `featured_${number}_enable`]: boolean,
    };
    carouselConfig: JumbotronConfig[] = [];

    ngOnInit() {
        for (let i = 1; i <= 10; i++) {
            if (!this.data[`featured_${i}_enable`]) continue;
            let title = "";
            if (this.data[`featured_${i}_custom_title`]) {
                title = this.data[`featured_${i}_custom_title`]!;
            } else if (this.data[`featured_${i}_book`]) {
                title = this.data[`featured_${i}_book`]!.title;
            } else if (this.data[`featured_${i}_world`]) {
                title = this.data[`featured_${i}_world`]!.title;
            }
            let subtitle = "";
            if (this.data[`featured_${i}_custom_subtitle`]) {
                subtitle = this.data[`featured_${i}_custom_subtitle`]!;
            } else if (this.data[`featured_${i}_book`]) {
                subtitle = this.data[`featured_${i}_book`]!.mainAuthorName;
            } else if (this.data[`featured_${i}_world`]) {
                subtitle = this.data[`featured_${i}_world`]!.books[0].mainAuthorName;
            }
            let description = "";
            if (this.data[`featured_${i}_custom_description`]) {
                description = this.data[`featured_${i}_custom_description`]!;
            } else if (this.data[`featured_${i}_book`]) {
                description = this.data[`featured_${i}_book`]!.description || "";
            } else if (this.data[`featured_${i}_world`]) {
                description = this.data[`featured_${i}_world`]!.description || "";
            }
            let link = "";
            if (this.data[`featured_${i}_custom_link`]) {
                link = this.data[`featured_${i}_custom_link`]!;
            } else if (this.data[`featured_${i}_book`]) {
                link = this._utilitiesService.getBookUrl(this.data[`featured_${i}_book`]!);
            } else if (this.data[`featured_${i}_world`]) {
                link = this._utilitiesService.getWorldUrl(this.data[`featured_${i}_world`]!);
            }
            let media = "";
            if (this.data[`featured_${i}_custom_media`]) {
                media = this.data[`featured_${i}_custom_media`]!.data.url;
            } else if (this.data[`featured_${i}_book`]) {
                media = this.data[`featured_${i}_book`]!.images.cover;
            } else if (this.data[`featured_${i}_world`]) {
                media = this.data[`featured_${i}_world`]!.books[0].images.cover;
            }
            let badge: "free" | "comingSoon" | "none"  = "none";
            if (this.data[`featured_${i}_book`] && this.data[`featured_${i}_badge`] === "useBookValue") {
                if (this.data[`featured_${i}_book`]!.isFree) {
                    badge = "free";
                } else if (this.data[`featured_${i}_book`]!.isPrelaunched) {
                    badge = "comingSoon";
                }
            } else if (this.data[`featured_${i}_badge`] === "free") {
                badge = "free";
            } else if (this.data[`featured_${i}_badge`] === "comingSoon") {
                badge = "comingSoon";
            }
            const ctaText = this.data[`featured_${i}_cta_text`] || "Read now";

            if (!title || !description || !link || !media) continue;

            this.carouselConfig.push({
                src: media,
                title: title,
                subtitle,
                url: link,
                buttonText: ctaText,
                description: description,
                badgeType: badge
            })
        }
    }

    get isMobile() {
        return this._platformService.isMobile;
    }

}
