<div class="d-block d-lg-none mobile-trigger">
    <a class="" (click)="openMobileSearch()">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.53 27.47L22.1075 21.0475C23.9389 18.9413 24.8811 16.2062 24.7353 13.4189C24.5896 10.6316 23.3673 8.00977 21.3262 6.10608C19.285 4.20238 16.5844 3.16556 13.7938 3.21416C11.0031 3.26276 8.34024 4.393 6.36662 6.36662C4.393 8.34024 3.26276 11.0031 3.21416 13.7938C3.16556 16.5844 4.20238 19.285 6.10608 21.3262C8.00977 23.3673 10.6316 24.5896 13.4189 24.7353C16.2062 24.8811 18.9413 23.9389 21.0475 22.1075L27.47 28.53C27.6121 28.6624 27.8002 28.7346 27.9945 28.7311C28.1888 28.7277 28.3742 28.649 28.5116 28.5116C28.649 28.3742 28.7277 28.1888 28.7311 27.9945C28.7346 27.8002 28.6624 27.6121 28.53 27.47ZM4.74997 14C4.74997 12.1705 5.29247 10.3821 6.30887 8.86094C7.32527 7.33979 8.76993 6.15419 10.4601 5.45408C12.1504 4.75397 14.0102 4.57079 15.8046 4.9277C17.5989 5.28462 19.2471 6.16559 20.5407 7.45923C21.8343 8.75286 22.7153 10.4011 23.0722 12.1954C23.4291 13.9897 23.246 15.8496 22.5459 17.5398C21.8457 19.23 20.6601 20.6747 19.139 21.6911C17.6178 22.7075 15.8294 23.25 14 23.25C11.5476 23.247 9.19658 22.2715 7.46252 20.5374C5.72845 18.8033 4.75294 16.4523 4.74997 14Z" fill="#808080"/>
        </svg>
        <span>Search</span>
    </a>
</div>

<div class="d-none d-lg-block dropdown-center" #desktopSearchContainer>
    <div class="desktop-search-toggle">
        <form [formGroup]="searchForm" (ngSubmit)="desktopSearch()">
            <div class="input-group search-input-container fixed-width">
                <span class="input-group-text embedded-search-icon" [ngClass]="{'focused': searchInputActive}">
                    <feather-icon icon="search" width="18" height="18"></feather-icon>
                </span>
                <input
                    name="q"
                    type="text"
                    formControlName="q"
                    class="form-control dark-input rounded-input search-input-with-icon"
                    [placeholder]="searchInputActive ? 'What do you want to read?' : 'Search'"
                    (focus)="initDesktopSearch()"
                    (focusout)="searchInputActive = false"
                    [ngClass]="{'focused': searchInputActive}"
                >
                <span
                    class="input-group-text search-bar-control clear"
                    *ngIf="(queryControl.value || results) && desktopSearchActive" (click)="clear()"
                    [ngClass]="{'focused': searchInputActive}"><span [@searchControlTransition]>Clear</span></span>
                <span class="input-group-text search-bar-control close"
                    (click)="stopAndClearSearch()"
                    [ngClass]="{'focused': searchInputActive}">
                    <span *ngIf="desktopSearchActive" [@searchControlTransition]>
                        <feather-icon icon="x" width="20" height="20"></feather-icon>
                    </span>
                </span>
            </div>
        </form>
    </div>
    <div class="dropdown-menu desktop-search-dropdown">
        <ng-container [ngTemplateOutlet]="searchSections"></ng-container>
    </div>
</div>

<div class="desktop-backdrop" *ngIf="desktopSearchActive" (click)="stopSearch()" [@backdropTransition]></div>

<ng-template #mobileModal let-modal>
    <div class="modal-body c-px-20">
        <div class="mobile-header-container">
            <form [formGroup]="searchForm" (ngSubmit)="mobileSearch()">
                <div class="input-group search-input-container">
                    <span class="input-group-text embedded-search-icon" [ngClass]="{'focused': searchInputActive}">
                        <feather-icon icon="search" width="18" height="18"></feather-icon>
                    </span>
                    <input
                        name="q"
                        type="text"
                        formControlName="q"
                        class="form-control dark-input rounded-input search-input-with-icon"
                        [placeholder]="searchInputActive ? 'What do you want to read?' : 'Search'"
                        (focus)="searchInputActive = true"
                        (focusout)="searchInputActive = false"
                        [ngClass]="{'focused': searchInputActive}"
                    >
                    <span
                        class="input-group-text search-bar-control clear"
                        *ngIf="queryControl.value || results" (click)="clear()"
                        [ngClass]="{'focused': searchInputActive}"><span [@searchControlTransition]>Clear</span></span>
                    <span class="input-group-text search-bar-control close"
                        (click)="stopAndClearSearch()"
                        [ngClass]="{'focused': searchInputActive}">
                        <span>
                            <feather-icon icon="x" width="20" height="20"></feather-icon>
                        </span>
                    </span>
                </div>
            </form>
        </div>
        <div class="c-pt-30">
            <ng-container [ngTemplateOutlet]="searchSections"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #searchSections>
    <div class="categories" *ngIf="categories && categories.length">
        <span *ngFor="let category of categories" (click)="activeCategory = category" [ngClass]="{'active': activeCategory === category}">{{category}}</span>
    </div>
    <div *ngIf="noResultsQuery" class="not-found d-flex flex-column justify-content-center align-items-center">
        <div>
            <feather-icon icon="search" width="24" height="24"></feather-icon>
        </div>
        <h4 class="c-mt-24 c-mb-12">No search results</h4>
        <p class="text-muted">There are no search results for '{{noResultsQuery}}'</p>
    </div>
    <ng-container *ngIf="!results && defaultState">
        <div class="section">
            <h6 class="section-label">Trending Books</h6>
            <content-grid [dataSource]="defaultState" [columnLayout]="layoutConfig" [lazy]="true"></content-grid>
        </div>
    </ng-container>
    <div *ngIf="results">
        <div class="section" *ngIf="bookResults && activeCategory === 'Books'">
            <h6 class="section-label">Books</h6>
            <content-grid [dataSource]="bookResults" [columnLayout]="layoutConfig"></content-grid>
        </div>
        <div class="section" *ngIf="seriesResults && activeCategory === 'Series'">
            <h6 class="section-label">Series</h6>
            <content-grid [dataSource]="seriesResults" [columnLayout]="layoutConfig"></content-grid>
        </div>
        <div class="section" *ngIf="authorResults && activeCategory === 'Authors'">
            <h6 class="section-label">Authors</h6>
            <content-grid [dataSource]="authorResults" [columnLayout]="layoutConfig" [hideSubtitle]="true" template="bubbles"></content-grid>
        </div>
        <div class="section" *ngIf="genreResults && activeCategory === 'Genres'">
            <h6 class="section-label">Genres</h6>
            <badge-list [data]="genreResults" [expandable]="true" [showInitial]="10"></badge-list>
        </div>
        <div class="section" *ngIf="tropeResults && activeCategory === 'Tropes'">
            <h6 class="section-label">Tropes</h6>
            <badge-list [data]="tropeResults" [expandable]="true" [showInitial]="10"></badge-list>
        </div>
        <div class="section" *ngIf="worldResults && activeCategory === 'Worlds'">
            <h6 class="section-label">Worlds</h6>
            <div class="row vertical-collection">
                <div class="col-12" *ngFor="let w of worldResults">
                    <title-card [title]="w.title" [url]="'/worlds/' + w.slug" [src]="w.banner || ''" [onClick]="w.onClick"></title-card>
                </div>
            </div>
        </div>
    </div>
</ng-template>