import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[explore_page_template_slot_header]',
})
export class ExplorePageTemplateSlotHeader {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section1]',
})
export class ExplorePageTemplateSlotSection1 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section2]',
})
export class ExplorePageTemplateSlotSection2 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section3]',
})
export class ExplorePageTemplateSlotSection3 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section4]',
})
export class ExplorePageTemplateSlotSection4 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section5]',
})
export class ExplorePageTemplateSlotSection5 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section6]',
})
export class ExplorePageTemplateSlotSection6 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section7]',
})
export class ExplorePageTemplateSlotSection7 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section8]',
})
export class ExplorePageTemplateSlotSection8 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section9]',
})
export class ExplorePageTemplateSlotSection9 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[explore_page_template_slot_section10]',
})
export class ExplorePageTemplateSlotSection10 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
