import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, signal, effect, SimpleChanges } from '@angular/core';

@Component({
  selector: 'add-star-rating',
  templateUrl: './add-star-rating.component.html',
  styleUrl: './add-star-rating.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddStarRatingComponent implements OnChanges {
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.active.set(this.value);
        }
    }
    @Input()
    value: number = 0;

    @Output()
    valueChange: EventEmitter<number> = new EventEmitter<number>();

    active = signal(this.value);

    onClick(value: number): void {
        this.value = value;
        this.valueChange.emit(this.value);
    }

    onMouseEnter(value: number): void {
        this.active.set(value);
    }

    onMouseLeave(): void {
        this.active.set(this.value);
    }

}
