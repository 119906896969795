import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[embedded_page_template_slot_section1]',
})
export class EmbeddedPageTemplateSlotSection1 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[embedded_page_template_slot_section2]',
})
export class EmbeddedPageTemplateSlotSection2 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[embedded_page_template_slot_section3]',
})
export class EmbeddedPageTemplateSlotSection3 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[embedded_page_template_slot_section4]',
})
export class EmbeddedPageTemplateSlotSection4 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[embedded_page_template_slot_section5]',
})
export class EmbeddedPageTemplateSlotSection5 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[embedded_page_template_slot_section6]',
})
export class EmbeddedPageTemplateSlotSection6 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
