import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BooksService } from 'src/app/services/books.service';

@Component({
    selector: 'free-book-page',
    templateUrl: './free-book-page.component.html',
    styleUrls: ['./free-book-page.component.scss']
})
export class FreeBookPageComponent implements OnInit {
    hasError: boolean = false;
    
    constructor(
        private _booksService: BooksService,
        private _activatedRoute: ActivatedRoute
    ) {
        
    }

    async ngOnInit() {
        try {
            
        }
        catch (error) {
            this.hasError = true;
        }
    }
}
