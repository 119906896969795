import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, filter, firstValueFrom, from, lastValueFrom, map, of } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { PasswordlessAuthComponent } from 'src/app/modules/auth/components/passwordless-auth/passwordless-auth.component';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BooksService } from 'src/app/services/books.service';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { ModalService } from 'src/app/services/modal.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy  {

    isLoggedIn: boolean = false;
    user: User | undefined = undefined;

    blogUrl: string = environment.blogUrl;

    private _subs: Subscription[] = [];

    isSplashScreen: boolean = false;

    isExplore: boolean = false;

    headerHasLink: boolean = true;

    constructor(
        private _platform: PlatformService,
        private _router: Router,
        private elementRef: ElementRef,
        private _userService: UserService,
        private _embeddedAuthService: EmbeddedAuthService,
        private _analyticsService: AnalyticsService,
        private _modalService: ModalService
    ) {}

    ngAfterViewInit(): void {
        this.watchAuthStatus();
    }

    private watchAuthStatus() {
        this._subs.push(this._userService.isLoggedIn$.subscribe(loggedIn => {
            this.isLoggedIn = loggedIn;
        }));

        this._subs.push(this._userService.user$.subscribe(user => {
            this.user = user;
        }));
    }

    get isBrowser() {
        return this._platform.isBrowser();
    }

    get isMobile() {
        return this._platform.isMobile;
    }

    ngOnDestroy(): void {
        this._subs.forEach(s => s.unsubscribe());
    }
    
    ngOnInit(): void {
        if (this._platform.isBrowser()) {
            this._router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.isSplashScreen = event.urlAfterRedirects === "/" || event.urlAfterRedirects.startsWith("/author-pages");
                    this.isExplore = event.urlAfterRedirects === "/explore";
                    this.headerHasLink = !event.urlAfterRedirects.startsWith("/pages");
                }
            })
        }
        else {
            this.headerHasLink = !this._router.url.startsWith("/pages");
        }
    }

    @HostListener('window:scroll')
    onWindowScroll() {
        // const offset = this.elementRef.nativeElement.offsetTop;
        // this.isScrolled = window.scrollY > offset;
    }

    async login() {
        try {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "navbar"
                    }
                }
            });
            this._embeddedAuthService.login({
                signupReason: {type: "generic"},
                skipOnboarding: false
            });
            // this._embeddedAuthService.renderAuthModal({
            //     redirectUrl: this._platform.getHomeUrl() + "explore",
            //     languageDictionary: {
            //         title: ""
            //     }
            // });
        }
        catch (err) {
            console.error(err);    
        }
    }

    async logout() {
        this._analyticsService.track({event: "sign_out"});
        //give some time for the event to fire
        setTimeout(() => {
            this._embeddedAuthService.logout();
        }, 100);
    }

    isScrolled = true;

    async changeProfilePicture() {
        // this._router.navigate(["user", "profile-picture"]);
        this._router.navigate(["account-settings"]);
    }

    trackBlogVisit() {
        this._analyticsService.track({event: "click_blog"});
    }
}
