import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from '../services/platform.service';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { AnonymousPersistentState } from '../services/anonymous-persistent-state';
import { EmbeddedAuthService } from '../modules/auth/services/embedded-auth.service';
import { firstValueFrom, combineLatest, map } from 'rxjs';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { UtilitiesService } from '../services/utilities.service';


@Injectable({
    providedIn: 'root'
})
export class PWAService {
    private baseUrl: string = environment.baseUrl + "/api/push";
    constructor(
        private readonly _platformService: PlatformService,
        private readonly _location: PlatformLocation,
        private readonly _http: HttpClient,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _authService: EmbeddedAuthService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService
    ) {
        const url = new URL(this._location.href);
        if (url.searchParams.get("pwa")) {
            this.isPWA = true;
        }
    }

    isPWA = false;

    //check this to show install button
    readyForInstall$ = new BehaviorSubject<boolean>(false);


    private _notificationsAvailable$ = new BehaviorSubject<boolean>(false);
    //check this to show "Enable Notifications" button
    canRequestNotifications$ = combineLatest([this._notificationsAvailable$, this._authService.user$, this._anonymousPersistentState.email$]).pipe(
        map(([available, user, email]) => !!(available && (user || email)))
    )

    private _installPrompt: Event | null = null;
    private _appInstallFired = false;

    async init() {
        if (!this._platformService.isBrowser()) return;
        if ("serviceWorker" in navigator) {
            try {
                await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
                    scope: "/firebase-cloud-messaging-push-scope",
                });
                this._installSetup();
                await this._notificationsSetup();
            } catch (e) {
                console.error(e);
            }
        }
    }

    async install() {
        if (!this._installPrompt) {
            throw new Error("Install prompt not fired yet");
        }
        //@ts-ignore
        this._installPrompt.prompt();
        //@ts-ignore
        const { outcome } = await this._installPrompt.userChoice as {outcome: "accepted" | "dismissed"};
        this._installPrompt = null;
        if (outcome === "accepted") {
            this._appInstallFired = true;
            this._onInstallAccept("custom_prompt");
        } else if (outcome === "dismissed") {
            this._onInstallDismiss();
        }
    }

    async requestNotificationPermission() {
        const init = Date.now();
        const status = await Notification.requestPermission();
        const completed = Date.now();
        if (status === "granted") {
            this._notificationsAvailable$.next(false);
            await this._initPushSubscription();
            this._analyticsService.track({event: "accept_notification_prompt", params: {device: this._utilitiesService.getDeviceName()}});
        } else {
            this._notificationsAvailable$.next(false);
            //autoblocking takes ~1.2-1.5s so we check that to hopefully have cleaner data
            //if they are fast with their normal decline click, it will count them as an autoblock
            //if needed we can add a new observable here notificationsBlocked$("denied"|"auto") to show messages
            if (completed - init <= 1600) {
                this._analyticsService.track({event: "autoblock_notification_prompt", params: {device: this._utilitiesService.getDeviceName()}});
            } else {
                this._analyticsService.track({event: "decline_notification_prompt", params: {device: this._utilitiesService.getDeviceName()}});
            }
        }
    }

    private _installSetup() {
        //TODO this only works in android
        //for IOS we should make our condition and update readyForInstall$
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault();
            // Save the event
            this._installPrompt = e;
            //notify listeners to show custom install button (only when we are not already in the app)
            if (!this.isPWA) {
                this.readyForInstall$.next(true);
            }
        });
        //chrome only - this captures manual installations as well, so we want it
        //we add a small timeout so it won't run twice when installed through the custom prompt
        window.addEventListener("appinstalled", () => {
            setTimeout(() => {
                if (!this._appInstallFired) {
                    this._onInstallAccept("browser");
                }
            }, 1000);
        });
    }

    private _onInstallAccept(from: "custom_prompt" | "browser") {
        console.log("pwa installed", from);
        this.readyForInstall$.next(false);
        //TODO analytics + maybe some thank you modal
    }

    private _onInstallDismiss() {
        console.log("pwa dismissed");
        //TODO analytics + maybe some modal with how to do it later
    }

    private async _notificationsSetup() {
        if (this._utilitiesService.getDeviceName() === "IOS" && !this.isPWA) return; //do nothing on IOS unless we are in the PWA
        if (Notification.permission === "default") {
            this._notificationsAvailable$.next(true);
        } else if (Notification.permission === "granted") {
            await this._initPushSubscription();
        }
    }

    private async _initPushSubscription() {
        // Initialize Firebase
        const app = initializeApp(environment.push.firebaseConfig);
        const messaging = getMessaging(app);
        const token = await getToken(messaging, {
            vapidKey: environment.push.vapidKey
        });
        //save our subscription
        const email = this._authService.user?.email || this._anonymousPersistentState.email;
        if (email) {
            await firstValueFrom(this._http.post<any>(`${this.baseUrl}/subscription`, {
                token,
                email,
                device: this._utilitiesService.getDeviceName()
            }));
        }

        // //this runs if at the time of the notification we were active on the foreground
        // //don't care right now, later we could spawn some in-app notif
        // onMessage(messaging, (payload) => {
        //     console.log('Message received. ', payload);
        //     // ...
        // });
    }
}
