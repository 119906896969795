import { Component } from '@angular/core';

@Component({
    selector: 'sign-in-page',
    templateUrl: './sign-in-page.component.html',
    styleUrls: ['./sign-in-page.component.scss']
})
export class SignInPageComponent {
    constructor() {

    }
    
    authenticate() {
        
    }
}
