import { Component, Input } from '@angular/core';
import { Book } from 'src/app/models/book.model';

@Component({
    selector: 'worldmaker-component-subscribe-cover-header',
    templateUrl: './subscribe-cover-header.component.html',
    styleUrls: ['./subscribe-cover-header.component.scss'],
})
export class SubscribeCoverHeader {
    @Input() data!: {
        book: Book;
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        };
        headerText: string;
        showHeader: boolean;
        amazonCtaText: string,
        showAmazonCta: boolean
        enableStickyFooter: boolean;
        showRating: boolean;
        showSpicyMeter: boolean;
        altCoverIndex?: string;
    };

    constructor() {}
}
