<div class="d-flex flex-column justify-content-between px-4 pt-4 pb-2">

    <div class="header no-select mb-3">
        <h3 class="title text-center" *ngIf="!header">
            Nice, {{user.nickname}} 👋
        </h3>
        <h3 class="title text-center" *ngIf="header">
            {{header}}
        </h3>
    </div>
    <div class="description no-select mb-4">
        <p class=text-center>
            {{description}}
        </p>
    </div>
    <div class="d-flex justify-content-center  my-2">
        <div class="d-flex align-items-center position-relative mb-5">
            <div class="cursor-pointer img-area" [ngStyle]="{'background-image': 'url(' + url + ')'}" (click)="onFileInputClick()">
                <input type="file" #imageFile accept="image/*" id="profile-picture-input" class="custom-file-input"
                (change)="onFileSelected()" file-input="imageFile">
            </div>

            <button class="btn-icon position-absolute add-profile-icon" (click)="onFileInputClick()">
                <div class="icon-bg">
                    <svg fill="white" width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.5 12.75C15.9142 12.75 16.25 12.4142 16.25 12C16.25 11.5858 15.9142 11.25 15.5 11.25V12.75ZM8.5 11.25C8.08579 11.25 7.75 11.5858 7.75 12C7.75 12.4142 8.08579 12.75 8.5 12.75V11.25ZM15.5 11.25H8.5V12.75H15.5V11.25Z" fill="#160042"></path>
                        <path d="M11.25 15.5C11.25 15.9142 11.5858 16.25 12 16.25C12.4142 16.25 12.75 15.9142 12.75 15.5H11.25ZM12.75 8.5C12.75 8.08579 12.4142 7.75 12 7.75C11.5858 7.75 11.25 8.08579 11.25 8.5H12.75ZM12.75 15.5V8.5H11.25V15.5H12.75Z" fill="#160042"></path>
                    </svg>
                </div>
            </button>
        </div>
    </div>
    <div class="form-group d-grid gap-2 mt-4 mb-3">
        <button [disabled]="!url" class="btn btn-dark" (click)="submit()" [disabled]="loading || !file">
            <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
            {{buttonText}}
        </button>
    </div>

    <div *ngIf="showFooter" class="terms d-flex justify-content-center mt-1">
        <small class="text-center footer-text">
            You can change your profile pic at any time from the user profile.
        </small>
    </div>


</div>