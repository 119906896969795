import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { PublicUserProfileService } from 'src/app/services/public-user-profile.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';


@Component({
    selector: 'magic-auth-page',
    templateUrl: './magic-auth-page.component.html',
    styleUrls: ['./magic-auth-page.component.scss'],
    imports: [CommonModule],
    standalone: true
})
export class MagicAuthPageComponent implements OnInit {
    constructor(
        private readonly _router: Router,
        private _utilitiesService: UtilitiesService,
        private _platformService: PlatformService,
        private _location: PlatformLocation,
        private _authService: EmbeddedAuthService,
        private _anonymousPersistentState: AnonymousPersistentState,
        private _publicUserProfileService: PublicUserProfileService,
        private _analyticsService: AnalyticsService
    ) {}

    private readonly persistentStateKey = "AnonymousPersistentState";
    authLoading = false;

    async ngOnInit() {
        const url = new URL(this._location.href);
        const code = url.searchParams.get("code");
        const action = url.searchParams.get("action");
        const actionParamsString = url.searchParams.get("actionParams");
        const skipOnboarding = url.searchParams.get("skipOnboarding") === "1" ? true : false;
        const stateString = url.searchParams.get("state");
        const email = decodeURIComponent(url.searchParams.get("email") || "");
        let redirectPath = decodeURIComponent(url.searchParams.get("redirect") || "");

        //incomplete magic-link
        if (!code || !email || !redirectPath) {
            if (this._platformService.isBrowser()) {
                //load the analytics before redirecting from broken magic-link
                this._analyticsService.load();
            }
            this._router.navigate(["explore"]);
            return;
        }

        //on server initialize loading and return
        if (this._platformService.isServer()) {
            console.log("starting magic-link", email, code, redirectPath);          
            this.authLoading = true;
            return;
        }
        //setting it again on the client to prevent animation glitches
        this.authLoading = true;

        //already have a valid logged-in user
        if (await this._authService.hasValidStoredUser()) {
            //load the analytics before redirecting if we have an existing user
            this._analyticsService.load();
            this._router.navigateByUrl(redirectPath);
            this.authLoading = false;
            return;
        }

        //restore anonymousState
        if (stateString) {
            await this.restoreAnonymousState(stateString);
        }

        //load the analytics after we have restored the anonymousState
        this._analyticsService.load();
        this._analyticsService.trackPageView(undefined, "Magic Auth Page", {});

        //restore this as well
        this._authService.skipOnboarding = skipOnboarding;

        //build full redirect path
        if (action) {
            redirectPath += `?action=${action}`;
            if (actionParamsString) {
                const actionParams = await this._utilitiesService.base64ToObject(actionParamsString);
                for (const [key, val] of Object.entries(actionParams)) {
                    redirectPath += `&${key}=${val}`;
                }
            }
        }

        //verify code
        try {
            const authResult = await this._authService.verifyCode(email, code, {
                code,
                state: this._anonymousPersistentState.state,
                redirectPath,
                skipOnboarding
            });
            if (authResult && authResult.accessToken) {
                //check if pending name change
                if (this._anonymousPersistentState.name && this._authService.userProfile && !this._authService.userProfile.name) {
                    try {
                        await this._authService.changeName(this._anonymousPersistentState.name!);
                    } catch (_e) {}
                }
                //check if pending tiktok change
                if (this._anonymousPersistentState.tiktok && this._authService.userProfile && !this._authService.userProfile.tiktok) {
                    try {
                        //TODO this doesnt refresh the client auth0, only updates our db
                        await this._publicUserProfileService.changeTiktok(this._anonymousPersistentState.tiktok!);
                    } catch (_e) {}
                }
            } else {
                console.error("code verification failed");
                this._router.navigate(["explore"]);
                this.authLoading = false;
                return;
            }
        } catch (e) {
            //TODO here we should check if the error is expired OTP
            //re-create the startPasswordless with all existing data
            //and show a message that we sent them a new link
            //If the error is invalid-code we can probably have the exact same handling
            //(this can happen if they opened the magic-link in a different browser and then opened it again in a different one)
            console.error(e);
            this._router.navigate(["explore"]);
            this.authLoading = false;
            return;
        }

        console.log(`Redirecting after login: ${redirectPath}`);
        //give it a little more time to play the animation
        setTimeout(() => {
            this._router.navigateByUrl(redirectPath);
            this.authLoading = false;
        }, 2000);
    }

    private async restoreAnonymousState(stateString: string) {
        const state = await this._utilitiesService.base64ToObject(stateString);
        if (state) {
            localStorage.setItem(this.persistentStateKey, JSON.stringify(state));
        }
    }
}
