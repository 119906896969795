<ng-container *ngIf="(ds$ | async) as ds">
    <ng-container *ngIf="ds.data?.length">
        <div class="row c-pb-20">
            <ng-container *ngIf="data.enableAnchor">
                <anchor-header [header]="data.headerText" [anchorSlug]="data.anchorSlug"></anchor-header>
            </ng-container>
            <ng-container *ngIf="!data.enableAnchor">
                <h2 class="mb-0 worldmaker-section-title">{{data.headerText}}</h2>
            </ng-container>
        </div>
        <content-grid [dataSource]="ds" [columnLayout]="layoutConfig" [hideSubtitle]="true" ></content-grid>
        <hr class="mb-0 c-mt-40 d-none d-md-block full-width-hr" *ngIf="data.showHR">
    </ng-container>
</ng-container>