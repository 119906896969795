import { Component, Input } from '@angular/core';
import { Book } from 'src/app/models/book.model';

@Component({
  selector: 'compact-book-listing',
  templateUrl: './compact-book-listing.component.html',
  styleUrls: ['./compact-book-listing.component.scss']
})
export class CompactBookListingComponent {
  @Input()
  book!: Book;

  @Input()
  showOnlyCover: boolean = false;

  @Input()
  containerClass: string = "";

  get numberOfPages() {
    // TODO: add this to the Book.model
    return 420
  }

  get publishDate() {
    if (!this.book.publishedAt) return "";
    const date = new Date(this.book.publishedAt) // May 2023
    return date?.toLocaleDateString()
  }

  get authors() {
    return this.book.authors.map(author => author.name).join(", ")
  }
}
