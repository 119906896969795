import { Component, Input } from '@angular/core';

type WorldPage = {
    id: number,
    name: string,
    path: string
};
type ImageMedia = {
    channel: 'image' | 'manual_image';
    data: { url: string; description: string, category: string };
}

@Component({
    selector: 'worldmaker-component-featured-worlds-grid-manual',
    templateUrl: './featured-worlds-grid-manual.component.html',
    styleUrls: ['./featured-worlds-grid-manual.component.scss'],
})
export class FeaturedWorldsGridManual {
    @Input() data!: {
        world1_title: string,
        world1_worldPage: WorldPage,
        world1_bgImage: ImageMedia;

        world2_title: string,
        world2_worldPage: WorldPage,
        world2_bgImage: ImageMedia;

        world3_title: string,
        world3_worldPage: WorldPage,
        world3_bgImage: ImageMedia;

        world4_title: string,
        world4_worldPage: WorldPage,
        world4_bgImage: ImageMedia;

        world5_title: string,
        world5_worldPage: WorldPage,
        world5_bgImage: ImageMedia;

        world6_title: string,
        world6_worldPage: WorldPage,
        world6_bgImage: ImageMedia;
    };
}
