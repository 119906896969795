import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '../auth/auth.module';
import { DiscussionModule } from '../discussion/discussion.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ReadablesReader } from './components/reader/reader.component';
import { ReadablesReaderPopUp } from './components/reader-popup/reader-popup.component';
import { ReadablesReaderPopUpV2 } from './components/reader-popup-v2/reader-popup-v2.component';
import { ReadablesReaderIntroCard } from './components/reader-intro-card/reader-intro-card.component';
import { DownloadReadable } from './components/download-readable/download-readable.component';
import { SkeletonPlaceholderComponent } from 'src/app/components/skeleton-placeholder/skeleton-placeholder.component';

const COMPONENTS = [ReadablesReader, ReadablesReaderPopUp, ReadablesReaderIntroCard, DownloadReadable, ReadablesReaderPopUpV2];

@NgModule({
    declarations: [...COMPONENTS],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule,
        DiscussionModule,
        NgbTooltipModule,
        SkeletonPlaceholderComponent
    ],
    exports: [...COMPONENTS],
})
export class ReadablesModule {}
