import { Pipe, PipeTransform } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Pipe({
    name: 'bookUrl',
    pure: true
})

export class BookUrlPipe implements PipeTransform {

    constructor(private readonly _utilitiesService: UtilitiesService) {}

    transform(book: Book, launch: boolean = false): string  {
        return this._utilitiesService.getBookUrl(book, launch);
    }
}
