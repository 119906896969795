import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Book } from 'src/app/models/book.model';

import {
    worldMakerComponentTable,
    WorldMakerComponentNames,
} from '../../worldmaker.module';

import {
    BookPageTemplateSlotHeader,
    BookPageTemplateSlotSection1,
    BookPageTemplateSlotSection2,
    BookPageTemplateSlotSection3,
    BookPageTemplateSlotSection4,
    BookPageTemplateSlotSection5,
    BookPageTemplateSlotSection6
} from './book_page_template.slots';

type WorldMakerInput = {
    path: string;
    template: string;
    components: {
        data: object;
        name: WorldMakerComponentNames;
        slot: 'header' | 'section1' | 'section2' | 'section3' | 'section4' | 'section5' | 'section6';
    }[];
};

@Component({
    selector: 'worldmaker-book-page-template',
    templateUrl: './book_page_template.component.html',
    styleUrls: ['./book_page_template.component.scss'],
})
export class BookPageTemplate {
    @ViewChild(BookPageTemplateSlotHeader, { static: true })
    header!: BookPageTemplateSlotHeader;
    @ViewChild(BookPageTemplateSlotSection1, { static: true })
    section1!: BookPageTemplateSlotSection1;
    @ViewChild(BookPageTemplateSlotSection2, { static: true })
    section2!: BookPageTemplateSlotSection2;
    @ViewChild(BookPageTemplateSlotSection3, { static: true })
    section3!: BookPageTemplateSlotSection3;
    @ViewChild(BookPageTemplateSlotSection4, { static: true })
    section4!: BookPageTemplateSlotSection4;
    @ViewChild(BookPageTemplateSlotSection5, { static: true })
    section5!: BookPageTemplateSlotSection5;
    @ViewChild(BookPageTemplateSlotSection6, { static: true })
    section6!: BookPageTemplateSlotSection6;

    @Input()
    set worldMakerData(value: WorldMakerInput){
        this.dataObsevable$.next(value);
    }

    dataObsevable$ = new ReplaySubject<WorldMakerInput>();

    showRecommendations = false;
    showFreeBookFAQ = false;
    recommendationHeader = "";

    ngOnInit() {
        this.dataObsevable$.subscribe((current) => {
            //clear all slots first
            this.header.viewContainerRef.clear();
            this.section1.viewContainerRef.clear();
            this.section2.viewContainerRef.clear();
            this.section3.viewContainerRef.clear();
            this.section4.viewContainerRef.clear();
            this.section5.viewContainerRef.clear();
            this.section6.viewContainerRef.clear();
            //re-create them
            for (const c of current.components) {
                // if (c.slot === 'meta') continue;
                const viewContainerRef = this[c.slot].viewContainerRef;
    
                const toLoad = worldMakerComponentTable[c.name];
                const componentRef = viewContainerRef.createComponent<any>(toLoad);
                componentRef.instance.data = c.data;
            }

            //show some components on all book pages
            try {
                //cycle them once to refresh them on page change
                this.showFreeBookFAQ = true;
                this.showRecommendations = true;
                this.showFreeBookFAQ = false;
                this.showRecommendations = false;
                this.recommendationHeader = "";
                //get the book from the header component
                const headerComponent = current.components.find((c: any) => {
                    return [
                        "smart_book_cover_header",
                        "subscribe_cover_header",
                        "prelaunch_cover_header_2",
                        "free_book_cover_header"
                    ].includes(c.name);
                });
                //@ts-ignore
                const book = headerComponent?.data?.book as Book;
                if (!book) return;
                if (book.isFree) {
                    //in case it has it already
                    if (!current.components.find(c => c.name === "free_book_faq" || c.name === "custom_component_loader")) {
                        this.showFreeBookFAQ = true;
                    }
                    this.showRecommendations = false;
                } else if (book.isPrelaunched) {
                    this.showFreeBookFAQ = false;
                    this.showRecommendations = false;
                } else {
                    this.showFreeBookFAQ = false;
                    //in case it has it already
                    if (!current.components.find(c => c.name === "book_recommendations")) {
                        this.showRecommendations = true;
                        if (book.publisher === "NB") {
                            this.recommendationHeader = "More like this";
                        } else {
                            this.recommendationHeader = "You might also like";
                        }
                    }
                }
            } catch (_) {}
        });
    }
}
