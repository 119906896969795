import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Trope } from '../models/trope.model';
import { BooksService } from './books.service';
import { PaginatedBookResponse } from '../models/paginated-response.model';

@Injectable({
    providedIn: 'root'
})
export class TropesService {
    private apiUrl = `${environment.baseUrl}/api/tropes`;

    constructor(
        private http: HttpClient,
        private booksService: BooksService
    ) { }

    findAll(): Observable<Trope[]> {
        return this.http.get<Trope[]>(`${this.apiUrl}?activeOnly=1`);
    }

    findOneByName(name: string): Observable<Trope> {
        const params = new HttpParams().set('name', name);
        return this.http.get<Trope>(`${this.apiUrl}/name`, { params });
    }

    findOneBySlug(slug: string): Observable<Trope> {
        return this.http.get<Trope>(`${this.apiUrl}/slug/${slug}`);
    }

    findWithBooksBySlug(slug: string, page = 1): Observable<PaginatedBookResponse<Trope>> {
        return this.http.get<PaginatedBookResponse<Trope>>(`${this.apiUrl}/slug/${slug}/books?page=${page}`);
    }

}
