import { Component, Input, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { Readable } from 'src/app/models/readable.model';
import { PlatformService } from 'src/app/services/platform.service';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { Author } from 'src/app/models/author.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { SignupReason } from 'src/app/services/analytics/events/signupReason';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

type Media =
    | {
          channel: 'image';
          exclusive?: boolean;
          data: { url: string; title: string; description: string, category: string };
      }
    | {
          channel: 'manual_image';
          exclusive?: boolean;
          data: { url: string; title: string; description: string, category: string };
      }
    | {
        channel: 'manual_video';
        exclusive?: boolean;
        data: { url: string; title: string; description: string, category: string };
    }
    | {
        channel: 'server_readable',
        exclusive?: boolean;
        data: {readable: Readable, category: string};
    } | {
        channel: 'article',
        exclusive?: boolean;
        data: {url: string; imageUrl: string; title: string; description: string, category: string };
    }

@Component({
    selector: 'worldmaker-component-media-swim-lane',
    templateUrl: './media-swim-lane.component.html',
    styleUrls: ['./media-swim-lane.component.scss'],
})
export class MediaSwimLane implements OnInit {
    @Input() data!: {
        media: Media[];
        header: string;
        author?: Author;
        ctaText: string;
        ctaUrl: string;
        enableCta: boolean;
        enableAnchor: boolean;
        anchorSlug?: string;
    };

    @ViewChild('imageModal') imageModal!: TemplateRef<any>;
    @ViewChild('videoModal') videoModal!: TemplateRef<any>;

    activeMedia!: Media;

    followsAuthor = false;
    joinedCrew = false;
    showAfterJoinAlert = false;
    afterJoinCb = () => {
        //also follow when they join the crew
        if (!this.followsAuthor) {
            this._followService.followAuthor({source: "after_join_crew_auto"});
        }
        this.showAfterJoinAlert = true;
    };

    constructor(
        private _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _followService: FollowService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _router: Router,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        if (this.data.author && this.data.author.crew) {
            this._followService.followsAuthor$.subscribe(val => {
                this.followsAuthor = val;
            });
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
            await this._followService.init(
                null,
                this.data.author,
                null
            );
        }
    }

    locked(media: Media) {
        if (!media.exclusive) {
            return false;
        } else {
            if (this.joinedCrew) {
                return false;
            } else {
                return true;
            }
        }
    }

    get showCta() {
        return this.data.enableCta && this.data.ctaText && this.data.ctaUrl;
    }

    get ctaLinkExternal() {
        if (this.data.ctaUrl.startsWith("http")) {
            return true;
        } else {
            return false;
        }
    }

    get isLoggedIn$() {
        return this._authService.isLoggedIn$;
    }

    get crewFormSettings() {
        if (this.activeMedia.channel !== "server_readable") {
            return {
                trigger: "media_unlock",
                media: this.activeMedia.data.title
            } as const;
        } else {
            return {
                trigger: "media_unlock",
                media: this.activeMedia.data.readable.title
            } as const;
        }
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        if (this.activeMedia.channel === "server_readable") {
            return {
                joinedFrom: "media_unlock",
                media: this.activeMedia.data.readable.slug
            };
        } else {
            return {
                joinedFrom: "media_unlock",
                media: this.activeMedia.data.title
            };
        }
    }

    get signupReason(): SignupReason {
        if (this.activeMedia.channel === "server_readable") {
            return {
                type: "media_unlock",
                media: this.activeMedia.data.readable.slug
            };
        } else {
            return {
                type: "media_unlock",
                media: this.activeMedia.data.title
            };
        }
    }

    async imageModalOpen(modal: TemplateRef<any>, media: Media) {
        if (media.channel !== 'image' && media.channel !== "manual_image") return;
        this.activeMedia = media;
        this.showAfterJoinAlert = false;
        this.trackViewMediaEvent();
        if (this.locked(media)) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "media_unlock",
                        media: this.activeMedia.data.title
                    }
                }
            });
        }
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(modal, {
                centered: true,
                size: "lg",
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                modalDialogClass: "media-modal-dialog",
                beforeDismiss: () => {
                    this._chatWidgetService.show();
                    return true;
                }
            }).shown
        );
    }

    async videoModalOpen(modal: TemplateRef<any>, media: Media) {
        if (media.channel !== 'manual_video') return;
        this.activeMedia = media;
        this.showAfterJoinAlert = false;
        this.trackViewMediaEvent();
        if (this.locked(media)) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "media_unlock",
                        media: this.activeMedia.data.title
                    }
                }
            });
        }
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(modal, {
                centered: true,
                size: "lg",
                fullscreen: await firstValueFrom(this._platformService.isMobile),
                modalDialogClass: "media-modal-dialog",
                beforeDismiss: () => {
                    this._chatWidgetService.show();
                    return true;
                }
            }).shown
        );
    }

    openArticle(media: Media) {
        if (media.channel !== "article") return;
        if (!media.data.url.startsWith("https://crewfiction.com")) { //external links
            window.open(media.data.url, "_blank");
        } else if (media.data.url.startsWith("https://crewfiction.com/blog")) { //blog links
            window.open(media.data.url, "_blank");
        } else { //internal links
            this._router.navigate([media.data.url.replace("https://crewfiction.com", "")]);
        }
    }

    trackViewMediaEvent() {
        if (this.activeMedia.channel !== "server_readable") {
            this._analyticsService.track({
                event: "view_media",
                params: {
                    category: this.activeMedia.data.category,
                    media: this.activeMedia.data.title,
                    mediaType: this.activeMedia.channel === "image" || this.activeMedia.channel === "manual_image" ? "image" : "video"
                }
            });
        }
    }
}
