import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import * as nanoid from "nanoid";
import { ModalOptions } from 'src/app/services/modal.service';

@Component({
    selector: 'modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent implements AfterViewInit {

    @Input()
    config!: ModalOptions;

    private _afterViewInit$ = new BehaviorSubject<boolean>(false);

    containerId = `modal-container-${nanoid.nanoid()}`;

    @ViewChild('modalContent', {
        read: ViewContainerRef
    })
    modalContent!: ViewContainerRef;

    constructor(private _platformService: PlatformService) { }

    ngAfterViewInit(): void {
        this._afterViewInit$.next(true);
    }

    loadComponent(component: any) {
        if (this._platformService.isBrowser()) {
            this._afterViewInit$.subscribe(afterViewInit => {
                if (afterViewInit) {
                    this.modalContent.clear();
                    const componentRef = this.modalContent.createComponent(component);
                    if (this.config.childProps) {
                        for(let key in this.config.childProps) {
                            (componentRef.instance as any)[key] = this.config.childProps[key];
                        }
                    }
                }
            })
        }
    }
}
