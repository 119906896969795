<div class="modal fade"
    [id]="containerId"
    tabindex="-1"
    aria-labelledby="modalContainerLabel"
    aria-hidden="true"
    [attr.data-bs-backdrop]="config.closable ? true : 'static'"
    [attr.data-bs-keyboard]="config.closable ? true : false"
>
    <div
        class="modal-dialog {{config.dialogClass ? config.dialogClass : ''}}"
        [ngClass]="{
            'modal-fullscreen': config.fullscreen,
            'modal-dialog-scrollable': config.scrollable,
            'modal-dialog-centered': config.centered,
            'modal-sm': config.size === 'sm',
            'modal-md': config.size === 'md',
            'modal-lg': config.size === 'lg',
            'modal-xl': config.size === 'xl',
        }"
    >
        <div class="modal-content">
            <div *ngIf="config.showModalHeader" class="modal-header">
                <h5 class="modal-title" id="modalContainerLabel">{{config.title}}</h5>
                <button *ngIf="config.showCloseButton" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container #modalContent></ng-container>
            </div>
        </div>
    </div>
</div>