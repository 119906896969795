import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JumbotronComponent {
    
    @Input() title!: string;

    @Input() subtitle?: string;

    @Input() description: string = "";

    @Input() src!: string;

    @Input() buttonText: string = "CTA"

    @Input() url!: string[] | string;
    
    @Input() badgeType: "free" | "comingSoon" | "none" = "none";

    @Input()
    showNavButtons: boolean = false;

    @Input()
    visible: boolean = true;


    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() prev: EventEmitter<void> = new EventEmitter<void>();
}
