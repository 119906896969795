<div *ngIf="series">
    <div class="breadcrumb-container">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <breadcrumbs></breadcrumbs>
                </div>
            </div>
        </div>
    </div>
    <div class="container-xxl c-pt-30">
        <div class="row">
            <div class="col-md-7 col-12">
                <h1 class="world-header c-mb-20">{{ customTitle || title }}</h1>
                <div class="d-flex flex-column flex-sm-row">
                    <a [href]="book | amazonLink" class="btn w-100 w-sm-auto c-mb-10 btn-primary btn-round btn-with-icon c-me-10" target="_blank" rel="noopener noreferrer" (click)="trackAmazonClick()">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="amazon" fill="#121212" viewBox="0 0 448 512">
                            <path
                                d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z" />
                        </svg>
                        Read Series
                    </a>
                    <button class="w-100 w-sm-auto btn btn-round btn-dark w-fit btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                        <feather-icon icon="user-plus"></feather-icon>
                        Follow Author
                    </button>
                    <button class="w-100 w-sm-auto btn btn-round btn-outline-secondary w-fit btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                        <feather-icon icon="user-check"></feather-icon>
                        Following
                    </button>
                </div>
                <div #description class="series-description c-mt-60 c-mb-0" [ngClass]="{'remove-margin': hasHtmlDescription}"></div>
            </div>
            <div class="col-xxl-3 offset-xxl-2 col-md-5 offset-0 col-12 c-pt-40 c-pt-md-0">
                <div class="d-flex flex-column">
                    <h5 class="badge-section-title c-mb-10" >{{series.authors.length > 1 ? "Authors": "Author"}}</h5>
                    <div class="c-pb-20 c-pt-0 c-px-0">
                        <badge-list [data]="authorBadges"></badge-list>
                    </div>
                    <h5 class="badge-section-title c-mb-10">Genres</h5>
                    <div class="c-pb-20 c-pt-0 c-px-0">
                        <badge-list [data]="genreBadges"></badge-list>
                    </div>
                    <h5 class="badge-section-title c-mb-10">Tropes</h5>
                    <div class="c-pt-0 c-px-0">
                        <badge-list [data]="tropeBadges" [expandable]="true" [showInitial]="6"></badge-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row c-pt-40 c-pb-20">
            <div class="col-12">
                <h2 class="worldmaker-section-title c-mb-0">Books in the series</h2>
            </div>
        </div>
        <content-grid [dataSource]="booksDataSource" [columnLayout]="layoutConfig" [hideSubtitle]="true"></content-grid>
    </div>
</div>

<ng-template #joinModal let-modal>
    <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <join-crew-form
            [author]="mainAuthorData"
            [afterJoinCb]="afterJoinCb"
            [settings]="crewFormSettings"
            [eventMetadata]="joinCrewEventMetadata"
        ></join-crew-form>
    </div>
</ng-template>
