import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[series_page_template_slot_header]',
})
export class SeriesPageTemplateSlotHeader {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[series_page_template_slot_section1]',
})
export class SeriesPageTemplateSlotSection1 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[series_page_template_slot_section2]',
})
export class SeriesPageTemplateSlotSection2 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[series_page_template_slot_section3]',
})
export class SeriesPageTemplateSlotSection3 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[series_page_template_slot_section4]',
})
export class SeriesPageTemplateSlotSection4 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[series_page_template_slot_section5]',
})
export class SeriesPageTemplateSlotSection5 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[series_page_template_slot_section6]',
})
export class SeriesPageTemplateSlotSection6 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
