import { Directive, ElementRef, Renderer2, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Directive({
    selector: '[preloadImage]'
})
export class PreloadImageDirective implements AfterViewInit {

    constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private _document: Document) { }

    ngAfterViewInit(): void {
        const imgElement: HTMLImageElement = this.el.nativeElement;
        const src = imgElement.src;

        if (src) {
            const link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'preload');
            this.renderer.setAttribute(link, 'as', 'image');
            this.renderer.setAttribute(link, 'href', src);


            this.renderer.appendChild(this._document.head, link);
        }
    }
}
