<ng-container *ngIf="data.worlds && data.worlds.length > 0">
    <div class="row c-pb-20">
        <ng-container *ngIf="data.enableAnchor">
            <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
        </ng-container>
        <ng-container *ngIf="!data.enableAnchor">
            <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
        </ng-container>
    </div>
    <div class="row vertical-collection">
        <div class="col-12 col-md-6 col-lg-3" *ngFor="let w of data.worlds">
            <title-card [title]="w.title" [url]="w | worldUrl" [src]="w.banner ? w.banner : w.books[0].images.cover"></title-card>
        </div>
    </div>
    <div class="row c-pt-40" *ngIf="showCta">
        <div class="col-12 d-flex justify-content-center">
            <a [routerLink]="data.ctaUrl" class="d-inline-block btn btn-cta no-decoration slight-hover-no-bg">
                {{data.ctaText}}
            </a>
        </div>
    </div>
    <hr class="mb-0 c-mt-40 d-none d-md-block full-width-hr" *ngIf="data.showHR">
</ng-container>