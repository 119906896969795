import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'horizontal-scroll-ticker',
  templateUrl: './horizontal-scroll-ticker.component.html',
  styleUrls: ['./horizontal-scroll-ticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalScrollTickerComponent implements OnInit {
    
    private readonly _titleAnimationSpeed = 80;

    @Input()
    text: string = "";
    
    @Input()
    bold: boolean = false;

    @Input()
    active: boolean = false;

    constructor(
        private readonly platformService: PlatformService,
        private readonly _host: ElementRef,
    ) { }

    ngOnInit(): void {
        if (!this.platformService.isBrowser()) return;
        //scroller disabled
        // this.initScroller();
    }

    // @HostListener("window:resize")
    private initScroller() {
        const hostWidth = this.getHostWidth();
        const textWidth = this.getTextPixelWidth();
        const properties = {
            '--title-max-width': `${hostWidth}px`,
            '--title-animation-speed': this._titleAnimationSpeed,
            '--title-width': `${textWidth}px`,
            '--title-animation-duration': `${this.deriveTitleAnimationDuration(hostWidth, textWidth)}s`,
        }
        for (const [key, value] of Object.entries(properties)) {
            this._host.nativeElement.style.setProperty(key, value)
        }
    }

    private deriveTitleAnimationDuration(hostWidth: number, textWidth: number) {
        return (textWidth - hostWidth) / this._titleAnimationSpeed;
    }

    private getTextPixelWidth() {
        const span = document.createElement("span");
        span.className = "hscrolled-text";
        span.style.visibility = "hidden";
        span.textContent = this.text;
        document.body.appendChild(span);
        const width = span.offsetWidth - 0.06 * span.offsetWidth; //slide it 6% more than needed
        document.body.removeChild(span);

        return width;
    }

    private getHostWidth() {
        const width = parseInt(getComputedStyle(this._host.nativeElement.parentElement).width.replace("px", ""));
        return width + 0.06 * width;
    }
}
