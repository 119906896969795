import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import * as dayjs from "dayjs";
import { Subscription, interval } from 'rxjs';

@Component({
    selector: 'fomo-countdown',
    templateUrl: './fomo-countdown.component.html',
    styleUrls: ['./fomo-countdown.component.scss'],
})
export class FomoCountdown implements OnInit, OnDestroy {
    constructor(
        private readonly _platformService: PlatformService
    ) {}

    @Input() showCountDown!: boolean;

    @Input() colorScheme: "red" | "black" = "red";

    @Input() from?: Date | string;

    @Input() to!: Date | string;

    @Input() text = "FREE FOR";

    @Input() size: "big" | "small" = "big";

    @Input() showText = true;

    countdown!: ReturnType<typeof this._getCountDown>

    private subs: Subscription[] = [];

    ngOnInit(): void {
        this.countdown = this._getCountDown();
        if (this._platformService.isBrowser()) {
            const sub = interval(1000).subscribe(() => {
                this.countdown = this._getCountDown();
            });
            this.subs.push(sub);
        }
    }

    ngOnDestroy(): void {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    private _getCountDown() {
        const seconds = dayjs(this.to).diff(dayjs(this.from), "seconds");
        const days = Math.floor(seconds / (3600 * 24));
        const hrs = Math.floor((seconds % (3600 * 24)) / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        const formattedHrs = hrs.toString().padStart(2, '0');
        const formattedMins = mins.toString().padStart(2, '0');
        const formattedSecs = secs.toString().padStart(2, '0');
      
        return {
          days: days,
          hours: formattedHrs,
          minutes: formattedMins,
          seconds: formattedSecs
        };
    }
}