import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'join-book-crew-modal',
    templateUrl: './join-book-crew-modal.component.html',
    styleUrls: ['./join-book-crew-modal.component.scss']
})
export class JoinBookCrewModalComponent {

    @Output() onSubmit = new EventEmitter<string>();

    // require email 
    emailControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.email,
    ]);



    constructor(public modalService: NgbModal) {}

    closeModal() {
        this.modalService.dismissAll();
    }

    submitForm(email: string) {
        if (this.emailControl.valid) {
            this.onSubmit.emit(email);
            this.closeModal()
        }
    }
}
