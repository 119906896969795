import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Library, Shelf } from '../models/library.model';
import { environment } from 'src/environments/environment';

export interface FreeShelfOptions {
    altCoverIndex?: number;
    altReadableSlug?: string;
}

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    private baseUrl = `${environment.baseUrl}/api/library`;

    constructor(private http: HttpClient) { }

    find(): Observable<Library> {
        return this.http.get<Library>(this.baseUrl);
    }

    findFreeShelf(): Observable<Shelf> {
        return this.http.get<Shelf>(`${this.baseUrl}/shelf/free/`);
    }

    addBookToFreeShelf(bookId: number, options: FreeShelfOptions = {}): Observable<Library> {
        return this.http.put<Library>(`${this.baseUrl}/shelf/free/books`, { bookId, ...options });
    }

    updateFreeBooksReadingProgress(reading: Shelf["reading"]): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/shelf/free/progress`, {reading});
    }
}
