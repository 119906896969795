import { Component, Input } from '@angular/core';
import { Book } from 'src/app/models/book.model';
import { UserService } from 'src/app/services/user.service';
import { Observable, switchMap, of } from 'rxjs';

@Component({
    selector: 'worldmaker-component-discussion',
    templateUrl: './discussion.component.html',
    styleUrls: ['./discussion.component.scss'],
})
export class Discussion {
    @Input() data!: {
        book: Book;
        header: string;
        enableAnchor: boolean;
        anchorSlug?: string;
    };

    constructor(private readonly _userService: UserService) {}

    user$ = this._userService.user$;

    allowPin$: Observable<boolean> = this.user$.pipe(
        switchMap((user) => {
            if (!user) return of(false);
            try {
                return of(!!this.data.book.authors?.find(a => a.id == user?.authorId));
            }
            catch {
                return of(false)
            }
        })
    )
}
