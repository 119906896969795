import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Book } from 'src/app/models/book.model';

import {
    worldMakerComponentTable,
    WorldMakerComponentNames,
} from '../../worldmaker.module';

import {
    LandingPageTemplateSlotHeader,
    LandingPageTemplateSlotSection1,
    LandingPageTemplateSlotSection2,
    LandingPageTemplateSlotSection3,
    LandingPageTemplateSlotSection4,
    LandingPageTemplateSlotSection5,
    LandingPageTemplateSlotSection6
} from './landing_page_template.slots';

type WorldMakerInput = {
    id: number;
    path: string;
    template: string;
    components: {
        data: object;
        name: WorldMakerComponentNames;
        slot: 'header' | 'section1' | 'section2' | 'section3' | 'section4' | 'section5' | 'section6' | 'meta';
    }[];
}

@Component({
    selector: 'worldmaker-landing-page-template',
    templateUrl: './landing_page_template.component.html',
    styleUrls: ['./landing_page_template.component.scss'],
})
export class LandingPageTemplate {
    @ViewChild(LandingPageTemplateSlotHeader, { static: true })
    header!: LandingPageTemplateSlotHeader;
    @ViewChild(LandingPageTemplateSlotSection1, { static: true })
    section1!: LandingPageTemplateSlotSection1;
    @ViewChild(LandingPageTemplateSlotSection2, { static: true })
    section2!: LandingPageTemplateSlotSection2;
    @ViewChild(LandingPageTemplateSlotSection3, { static: true })
    section3!: LandingPageTemplateSlotSection3;
    @ViewChild(LandingPageTemplateSlotSection4, { static: true })
    section4!: LandingPageTemplateSlotSection4;
    @ViewChild(LandingPageTemplateSlotSection5, { static: true })
    section5!: LandingPageTemplateSlotSection5;
    @ViewChild(LandingPageTemplateSlotSection6, { static: true })
    section6!: LandingPageTemplateSlotSection6;

    @Input()
    set worldMakerData(value: WorldMakerInput){
        this.dataObsevable$.next(value);
    }

    dataObsevable$ = new ReplaySubject<WorldMakerInput>();

    showFreeBookFAQ = false;

    ngOnInit() {
        this.dataObsevable$.subscribe((current) => {
            //clear all slots first
            this.header.viewContainerRef.clear();
            this.section1.viewContainerRef.clear();
            this.section2.viewContainerRef.clear();
            this.section3.viewContainerRef.clear();
            this.section4.viewContainerRef.clear();
            this.section5.viewContainerRef.clear();
            this.section6.viewContainerRef.clear();
            //re-create them
            for (const c of current.components) {
                if (c.slot === 'meta') continue;
                const viewContainerRef = this[c.slot].viewContainerRef;
    
                const toLoad = worldMakerComponentTable[c.name];
                const componentRef = viewContainerRef.createComponent<any>(toLoad);
                componentRef.instance.data = c.data;
            }

            //show some components in all pages
            try {
                //cycle them once to refresh them on page change
                this.showFreeBookFAQ = true;
                this.showFreeBookFAQ = false;
                //get the book from the header component
                const headerComponent = current.components.find((c: any) => {
                    return [
                        "smart_book_cover_header",
                        "subscribe_cover_header",
                        "prelaunch_cover_header_2",
                        "free_book_cover_header"
                    ].includes(c.name);
                });
                //@ts-ignore
                const book = headerComponent?.data?.book as Book;
                if (!book) return;
                if (book.isFree) {
                    //in case it has it already
                    if (!current.components.find(c => c.name === "free_book_faq" || c.name === "custom_component_loader")) {
                        this.showFreeBookFAQ = true;
                    }
                }
            } catch (_) {}
        });
    }
}
