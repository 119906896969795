import { Component } from '@angular/core';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
    selector: "worldmaker-component-free-book-faq",
    templateUrl: "./free-book-faq.component.html",
    styleUrl: "./free-book-faq.component.scss"
})
export class FreeBookFAQ {
    constructor(
        private readonly _authService: EmbeddedAuthService,
        private readonly _analyticsService: AnalyticsService
    ) {}

    readonly FAQS = 4;
    faqState = new Array(this.FAQS).fill(false);

    isLoggedIn$ = this._authService.isLoggedIn$;

    async login() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "free_book_faq"
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: `Sign in or sign up`,
                sendStageDescription: `Sign-in or register for free to save your new book in your library`,
                skipOnboarding: true,
                signupReason: {type: "free_book_faq"}
            });
        }
    }
}