<ng-container *ngIf="canRequestNotifications$ | async">
    <div class="bell-trigger" *ngIf="!showFooter" [@bell] (click)="show()">
        <span>🔔</span>
    </div>
    <div class="card sticky-container-mobile container-xxl" [@stickyFooterMobile] *ngIf="showFooter">
        <div class="card-body c-p-0">
            <div class="d-block d-lg-none row text-center c-pb-20 c-pt-10 c-px-0">
                <div class="col-12 d-flex justify-content-end">
                    <span class="close" (click)="skip()">
                        <feather-icon icon="x" width="25" height="25"></feather-icon>
                    </span>
                </div>
                <!-- <div class="col-12 c-pb-5">
                    <img src="https://res.cloudinary.com/dap6pju8g/image/upload/v1724076068/book-media/okvwevv8ygclgeozmzwa.png" height="50">
                </div> -->
                <div class="col-12 c-pb-12">
                    <h3 class="header c-mb-0">Smart notifications</h3>
                </div>
                <div class="col-12 c-pb-30">
                    <p class="subheader c-mb-0">
                        Select Allow to get notified for free books and hot new releases before everyone else.
                    </p>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary btn-round" (click)="requestNotificationPermission()">😍 Allow 😍</button>
                </div>
                <div class="col-12">
                    <button class="btn btn-naked" (click)="skip()">Skip</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card sticky-container-desktop container-xxl" [@stickyFooterDesktop] *ngIf="showFooter">
        <div class="card-body c-p-0">
            <div class="d-none d-lg-flex justify-content-between align-items-center c-p-20">
                <img class="flex-shrink-1 c-pe-20" src="https://res.cloudinary.com/dap6pju8g/image/upload/v1724076068/book-media/okvwevv8ygclgeozmzwa.png" height="50">
                <div class="flex-grow-1">
                    <h3 class="header c-mb-0">Smart notifications</h3>
                    <p class="subheader c-mb-0">
                        Select Allow to get notified for free books and hot new releases before everyone else.
                    </p>
                </div>
                <div class="d-flex nowrap">
                    <button class="btn btn-primary btn-round" (click)="requestNotificationPermission()" style="text-wrap: nowrap;">😍 Allow 😍</button>
                    <button class="btn btn-naked" (click)="skip()">Skip</button>
                </div>
                <!-- <span class="close desktop" (click)="skip()">
                    <feather-icon icon="x" width="25" height="25"></feather-icon>
                </span> -->
            </div>
        </div>
    </div>
</ng-container>
