import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WorldMakerService {
    private apiUrl = `${environment.baseUrl}/api/worldmaker`;

    constructor(private http: HttpClient) {}

    //TODO type this when the response is finalized
    getWorldMakerPage(
        path: string
    ): Observable<{ exists: false } | { exists: true; data: any }> {
        const params = new HttpParams().set('path', path);
        return this.http.get<{ exists: false } | { exists: true; data: any }>(
            `${this.apiUrl}/get`,
            {
                params,
            }
        )
    }

    getFeaturedCarousel(): Observable<any> {
        return this.http.get<any[]>(`${this.apiUrl}/get/carousel`);
    }

    getTypeformPages(): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/typeform`);
    }
}
