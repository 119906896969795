<div class="container-xxl c-pt-20 c-pt-lg-60 worldmaker-section-container">
    <ng-template explore_page_template_slot_header></ng-template>
    <ng-template explore_page_template_slot_section1></ng-template>
    <ng-template explore_page_template_slot_section2></ng-template>
    <ng-template explore_page_template_slot_section3></ng-template>
    <ng-template explore_page_template_slot_section4></ng-template>
    <ng-template explore_page_template_slot_section5></ng-template>
    <ng-template explore_page_template_slot_section6></ng-template>
    <ng-template explore_page_template_slot_section7></ng-template>
    <ng-template explore_page_template_slot_section8></ng-template>
    <ng-template explore_page_template_slot_section9></ng-template>
    <ng-template explore_page_template_slot_section10></ng-template>
</div>