import { Component, Input } from '@angular/core';
import { Author } from 'src/app/models/author.model';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';

@Component({
    selector: 'join-crew-form',
    templateUrl: './join-crew-form.component.html',
    styleUrls: ['./join-crew-form.component.scss']
})
export class JoinCrewFormComponent {
    @Input()
    author!: Author;

    @Input()
    afterJoinCb?: Function;

    @Input()
    requestLoginCb?: () => Promise<void>

    @Input()
    compact = false;

    @Input()
    settings: {
        trigger: "author_follow",
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        }
    } | {
        trigger: "world_follow",
        world: string
    } | {
        trigger: "media_unlock",
        media: string
    } | {
        trigger: "prelaunch",
        msg: string,
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        }
    } | {
        trigger: null
    } = {trigger: null};

    @Input()
    eventMetadata!: JoinCrewEventMetadata

    constructor(
        private readonly _followService: FollowService,
        private readonly _authService: EmbeddedAuthService
    ) { }

    get authorFirstName() {
        return this.author.name.split(" ")[0];
    }

    get authorFirstNameWithS() {
        const first = this.authorFirstName;
        if (first.endsWith("s")) {
            return `${first}'`;
        } else {
            return `${first}'s`;
        }
    }

    get crewImage() {
        if ((this.settings.trigger === "author_follow" || this.settings.trigger === "prelaunch") && this.settings.joinCrewImage) {
            return this.settings.joinCrewImage.data.url;
        } else if (this.author && this.author.crew) {
            return this.author.crew.joinImage;
        } else {
            return;
        }
    }

    async joinCrew() {
        if (!this._authService.user) {
            if (this.requestLoginCb) {
                await this.requestLoginCb();
            } else {
                //this shouldn't happen
                throw new Error("no user found");
            }
        }
        await this._followService.joinCrew(this.eventMetadata);
        if (this.afterJoinCb) {
            this.afterJoinCb();
        }
    }
}
