import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { StorageType } from '../models/storage-type';
import { Observable } from 'rxjs';

type StorageMethods = {
    setItem: (key: string, value: string, durationInMinutes: number) => void;
    getItem: (key: string) => string | null;
    removeItem: (key: string) => void;
    asObservable: (key: string, interval?: number) => Observable<string | null>;
}

@Injectable({
    providedIn: 'root'
})
export class CountDownService {

    localStorage: StorageMethods = this.buildStorageMethods(StorageType.Local);
    sessionStorage: StorageMethods = this.buildStorageMethods(StorageType.Session);    

    setItem(storageType: StorageType, key: string, value: string, durationInMinutes: number): void {
        const storage = storageType === StorageType.Local ? localStorage : sessionStorage;
        const expirationTimestamp = dayjs().add(durationInMinutes, 'minute').valueOf();
        const data = { value, expirationTimestamp };
        storage.setItem(key, JSON.stringify(data));
    }

    getItem(storageType: StorageType, key: string): string | null {
        const storage = storageType === StorageType.Local ? localStorage : sessionStorage;
        const item = storage.getItem(key);
        try {
            if (item) {
                const data = JSON.parse(item);
                const currentTime = dayjs().valueOf();
                if (currentTime < data.expirationTimestamp) {
                    return data.value;
                }
                else {
                    storage.removeItem(key);
                    return null;
                }
            }
            return null;
        }
        catch {
            return null;
        }
    }

    removeItem(storageType: StorageType, key: string): void {
        const storage = storageType === StorageType.Local ? localStorage : sessionStorage;
        storage.removeItem(key);
    }

    asObservable(storageType: StorageType, key: string, interval: number = 1000): Observable<string | null> {
        return new Observable(subscriber => {
            let currentValue = this.getItem(storageType, key);
            subscriber.next(currentValue);
            const t = setInterval(() => {
                let tempValue = this.getItem(storageType, key);
                if (tempValue != currentValue) {
                    currentValue = tempValue;
                    subscriber.next(tempValue);
                }
            }, interval);
            return () => {
                clearInterval(t);
                subscriber.complete();
            }
        });
    }

    private buildStorageMethods(storageType: StorageType) {
        return {
            setItem: (key: string, value: string, durationInMinutes: number) => this.setItem(storageType, key, value, durationInMinutes),
            getItem: (key: string) => this.getItem(storageType, key),
            removeItem: (key: string) => this.removeItem(storageType, key),
            asObservable: (key: string, interval?: number) => this.asObservable(storageType, key, interval)
        }
    }

}
