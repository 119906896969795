<div *ngIf="book" class="card position-fixed floating-book-crew-cta" (click)="open(content)">
    <div class="w-100 d-flex align-items-center justify-content-between flex-row">
        <div class="ms-1 me-0 p-2" *ngIf="book">
            <compact-book-listing [book]="book" [showOnlyCover]="true"></compact-book-listing>
        </div>

        <div class="d-flex align-items-center">
            Be along the first readers
            <br>
            Unlock exclusive perks
        </div>

        <div>
            <!-- arrow up icon -->
            <div class="d-flex align-items-center justify-content-center me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-up">
                    <polyline points="18 15 12 9 6 15" />
                </svg>
            </div>
        </div>
    </div>
</div>

<ng-template #content>
    <div class="offcanvas-body">
        <ng-content></ng-content>
    </div>
</ng-template>
