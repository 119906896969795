<div *ngIf="!horizontalMode" id="reviews" class="row vertical-collection">
    <div *ngIf="book" class="col-12 col-xl-3 c-mb-40">
        <h3 class="c-mb-20 reviews-header">{{title}}</h3>
        <div class="d-flex align-items-center">
            <strong class="c-me-16 reviews-rating">{{book.rating}}</strong>
            <a *ngIf="linksEnabled" href="{{psdLink}}" target="_blank">
                <star-rating [rating]="book.rating"></star-rating>
            </a>
            <star-rating *ngIf="!linksEnabled" [rating]="book.rating"></star-rating>
        </div>
        <div class="d-flex align-items-center amazon-footer">
            <small class="c-me-10">Based on {{book.ratingCount}} {{book.ratingCount == 1 ? 'review' : 'reviews'}} </small>
        </div>
    </div>
    
    <div class="col-12 {{book ? 'col-xl-9' : ''}} p-0">
        <div class="row vertical-collection">
            <div class="col-12 col-md-6 col-xl-4" *ngFor="let item of items">
                <reviews-list-item [data]="item" [singleBookMode]="singleBookMode" [linkEnabled]="linksEnabled"></reviews-list-item>
            </div>
        </div>
    </div>
</div>

<div *ngIf="horizontalMode" class="d-flex horizontal-collection">
    <reviews-list-item *ngFor="let item of items" [data]="item" [singleBookMode]="singleBookMode" [linkEnabled]="linksEnabled"></reviews-list-item>
</div>