import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Pipe({
    name: 'profilePictureUrl',
    pure: true
})

export class ProfilePictureUrlPipe implements PipeTransform {
    constructor(private readonly _userService: UserService) {}
    
    transform(user: User, isThumbnail: boolean = false): any {
        return this._userService.getProfilePictureUrl(user, isThumbnail);
    }
}