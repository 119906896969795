import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'worldmaker-typeform-embed',
    templateUrl: 'typeform-embed.component.html',
    styleUrls: ['typeform-embed.component.scss']
})

export class TypeformEmbedComponent implements AfterViewInit {
    @Input()
    data!: {
        id: string;
        header: string;
    };

    constructor(private readonly _platformService: PlatformService) {}
    
    ngAfterViewInit(): void {
        if (this._platformService.isBrowser()) {
            const script = document.createElement('script');
            script.src = "https://embed.typeform.com/next/embed.js"
            script.async = true;
            document.body.appendChild(script);
        }
    }
    
}