import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Book, BookReview, BookShortLink } from 'src/app/models/book.model';
import { BooksService } from 'src/app/services/books.service';

@Component({
    selector: 'reviews-list',
    templateUrl: './reviews-list.component.html',
    styleUrls: ['./reviews-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: ''
    }
})
export class ReviewsListComponent implements OnInit, OnChanges {
        
    @Input()
    title: string = "Reviews";

    @Input()
    items: BookReview[] = [];

    @Input()
    linksEnabled: boolean = true;

    @Input()
    book: Book | null = null; // Used for single book mode 

    @Input()
    horizontalMode: boolean = false;

    get singleBookMode(): boolean {
        return !!this.book;
    }

    shortLinks: BookShortLink[] = [];
    psdLink: string = "";

    constructor(
        private readonly booksService: BooksService,
        private readonly el: ElementRef
    ) {

    }

    get showBookCover() {
        if (this.items.length === 0) return false;
        const firstId = this.items[0].book.id!;
        for (const item of this.items) {
            if (item.book.id !== firstId) {
                return true;
            }
        }
        return false;
    }

    ngOnInit(): void {
        this.loadShortLinks();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['horizontalMode']) {
            this.el.nativeElement.classList.add('horizontal');
        }
    }

    private async loadShortLinks() {
        if (!this.book) return;
        try {
            this.shortLinks = await firstValueFrom(this.booksService.getShortLinksById(this.book.id!));
        } 
        catch (error) {
            this.shortLinks = [];
        }
        this.psdLink = this.shortLinks.find(link => link.linkType === 'psd')?.link || '';
    }
}
