import { Component, Input, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { combineLatest, filter, firstValueFrom } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformService } from 'src/app/services/platform.service';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { World } from 'src/app/models/world.model';
import { ActivatedRoute } from '@angular/router';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'worldmaker-component-world-info-manual-2',
    templateUrl: './world-info-manual-2.component.html',
    styleUrls: ['./world-info-manual-2.component.scss'],
})
export class WorldInfoManual2 implements OnInit, AfterViewInit {
    @Input() data!: {
        world: World;
        breadcrumbColor?: string;
    };

    followsWorld = false;
    joinedCrew = false;
    afterJoinCb = () => this._modalService.dismissAll();

    @ViewChild('joinModal') joinModal!: TemplateRef<any>;
    @ViewChild("description") description!: ElementRef<HTMLDivElement>;

    followRequestInProgress$ = this._activatedRoute.queryParams.pipe(
        filter(params => params["action"] === "follow_world" && params["world"] === this.data.world.slug),
        filter(() => this._platformService.isBrowser())
    );

    constructor(
        private readonly _followService: FollowService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        this._followService.followsWorld$.subscribe(val => {
            this.followsWorld = val;
        });
        if (this.authors[0] && this.authors[0].crew) {
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
        }
        await this._followService.init(
            null,
            this.authors[0] || null,
            this.data.world
        );

        combineLatest([
            this._authService.isLoggedIn$,
            this._authService.onboardingCompleted$,
            this.followRequestInProgress$
        ]).pipe(
            filter(([isLoggedIn, onboardingCompleted, followInProgress]) => isLoggedIn && onboardingCompleted && Boolean(followInProgress)),
        ).subscribe(() => {
            this.followWorld();
        });
    }

    ngAfterViewInit(): void {
        this.description.nativeElement.innerHTML = this.data.world.description || "";
    }

    get authors() {
        const book = this.data.world.books[0];
        if (!book) return [];
        return book.authors;
    }

    get series() {
        const book = this.data.world.books[0];
        if (!book) return null;
        return book.bookSeries;
    }

    get crewFormSettings() {
        return {
            trigger: "world_follow",
            world: this.data.world.title
        } as const;
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "world_page",
            world: this.data.world.slug
        };
    }

    async followWorld() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "follow_world",
                        world: this.data.world.slug
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: "Sign in or sign up",
                sendStageDescription: "Enter your email to create your crewfiction account or sign-in to an existing one",
                skipOnboarding: false,
                signupReason: {type: "follow_world", world: this.data.world.slug},
                action: "follow_world",
                actionParams: {
                    world: this.data.world.slug
                }
            });
        }
        this._followService.followWorld();

        if (!this.joinedCrew && this.authors[0] && this.authors[0].crew) {
            this._chatWidgetService.hide();
            const that = this;
            //TODO make modalService.close() to wait for modal hidden event
            await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        that._chatWidgetService.show();
                        return true;
                    },
                }).shown
            );
        }
    }

    async unfollowWorld() {
        await this._followService.unfollowWorld();
    }

    get authorBadges(): BadgeConfig[] {
        return this.authors.map(author => {
            return {
                text: author.name,
                type: "default",
                url: this._utilitiesService.getAuthorUrl(author),
                image: author.images.profile
            }
        });
    }

    get seriesBadge(): BadgeConfig[] {
        if (!this.series) return [];
        return [{
            text: this.series.title,
            type: "default",
            url: this._utilitiesService.getBookSeriesUrl(this.series),
            image: this.series.images.cover
        }]
    }

    get tropeBadges(): BadgeConfig[] {
        if (!this.data.world.books || this.data.world.books.length === 0) [];
        const badges: BadgeConfig[] = [];
        for (const book of this.data.world.books) {
            for (const trope of book.tropes) {
                if (!badges.find(b => b.text === trope.name)) {
                    badges.push({
                        text: trope.name,
                        type: "default",
                        url: this._utilitiesService.getTropeUrl(trope.slug)
                    });
                }
            }
        }
        return badges;
    }

    get genreBadges(): BadgeConfig[] {
        if (!this.data.world.books || this.data.world.books.length === 0) [];
        const badges: BadgeConfig[] = [];
        for (const book of this.data.world.books) {
            for (const genre of book.genres) {
                if (!badges.find(b => b.text === genre.name)) {
                    badges.push({
                        text: genre.name,
                        type: "default",
                        url: this._utilitiesService.getGenreUrl(genre.slug)
                    });
                }
            }
        }
        return badges;
    }
}
