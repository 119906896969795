import { Component, Input, OnDestroy, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { Readable } from 'src/app/models/readable.model';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { firstValueFrom, filter, combineLatest, Subscription, tap, distinctUntilChanged } from 'rxjs';
import { LibraryStateService } from 'src/app/state/library.state.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscussionService } from 'src/app/modules/discussion/discussion.service';
import { DiscussionShareService } from 'src/app/modules/discussion/discussionShare.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformService } from 'src/app/services/platform.service';
import { CountDownService } from 'src/app/services/countdown.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import { LibraryService } from 'src/app/services/library.service';
import { BooksService } from 'src/app/services/books.service';

@Component({
    selector: 'download-readable',
    templateUrl: './download-readable.component.html',
    styleUrls: ['./download-readable.component.scss'],
})
export class DownloadReadable implements OnInit, OnDestroy {
    @Input()
    readable!: Readable

    @Input()
    buttonStyle: "big_dark" | "small_cta" = "small_cta";

    @Input()
    showInstructionsTrigger = false;

    @Input()
    needsSignup = false;

    @Input()
    triggerComment = false;

    loading = false;

    @ViewChild('kindleInstructionsModal') kindleInstructionsModal!: TemplateRef<any>;
    @ViewChild('addCommentModal') addCommentModal!: TemplateRef<any>;

    private readonly maxCommentLength: number = 500;
    commentLengthError: boolean = false;
    commentText = "";
    posting = false;

    private _subs: Subscription[] = [];

    private readonly _downloadInitiatedKey = "download-action-initiated";

    constructor(
        private readonly _authService: EmbeddedAuthService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _analyticsService: AnalyticsService,
        private readonly _libraryStateService: LibraryStateService,
        private readonly _discussionService: DiscussionService,
        private readonly _discussionShareService: DiscussionShareService,
        private readonly _modalService: NgbModal,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _countdownService: CountDownService,
        private readonly _chatWidgetService: ChatWidgetService,
        private readonly _libraryService: LibraryService,
        private readonly _booksService: BooksService
    ) {}


    ngOnInit(): void {
        this._activatedRoute.queryParams.pipe(
            filter(params => params["action"] === "download" && params["download"] === this.readable.slug),
            distinctUntilChanged()
        ).subscribe(() => {
            this.triggerAuthenticatedDownload();
        });
    }

    ngOnDestroy(): void {
        this._subs.forEach(sub => sub.unsubscribe());
    }

    get downloadLink(): {type: "mobi" | "epub" | "pdf", link: string} | null {
        if (!this.readable.settings.downloads) return null;
        if (this.readable.settings.downloads.mobi) {
            return {type: "mobi", link: this.readable.settings.downloads.mobi};
        } else if (this.readable.settings.downloads.epub) {
            return {type: "epub", link: this.readable.settings.downloads.epub};
        } else if (this.readable.settings.downloads.pdf) {
            return {type: "pdf", link: this.readable.settings.downloads.pdf};
        } else {
            return null;
        }
    }

    async download() {
        if (!this.downloadLink) return;

        if (this.needsSignup) {
            if (!this._authService.user) {
                this._analyticsService.track({event: "click_download_free_book", params: {readable: this.readable}});
                this._analyticsService.track({
                    event: "view_sign_up_form",
                    params: {
                        metadata: {
                            triggeredFrom: "free_book_download",
                            author: this.readable.author!.slug,
                            book: this.readable.book!.slug
                        }
                    }
                });
                await this._authService.login({
                    sendStageTitle: "Sign in or sign up",
                    sendStageDescription: "Enter your email to create your crewfiction account or sign-in to an existing one",
                    skipOnboarding: false,
                    action: "download",
                    actionParams: {
                        download: this.readable.slug
                    },
                    signupReason: {
                        type: "media_unlock",
                        media: this.readable.slug
                    }
                });
            } else {
                this.downloadReadable();
            }
        } else {
            this.downloadReadable();
        }
    }

    private triggerAuthenticatedDownload() {
        let sub : Subscription | null = null;
        sub = combineLatest([this._authService.isLoggedIn$, this._libraryStateService.freeShelf$, this._authService.onboardingCompleted$]).pipe(
            filter(([isLoggedIn, shelf, onboardingCompleted]) => isLoggedIn && onboardingCompleted && shelf !== null),
            filter(() => {
                return this._platformService.isBrowser() && !this._countdownService.sessionStorage.getItem(this._downloadInitiatedKey);
            }),
            tap(() => {
                this._countdownService.sessionStorage.setItem(this._downloadInitiatedKey, "true", 5);
            })
        ).subscribe(() => {
            this.downloadReadable();
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    havingTrouble() {
        this._modalService.dismissAll();
        this._chatWidgetService.open();
    }

    async postComment(event: SubmitEvent) {
        event.preventDefault();
        this.commentText = this.commentText.trim();
        if (!this.commentText) return;
        this.posting = true;
        const comment = await this._discussionService.addComment(this.readable.book!.id!, {text: this.commentText});
        this._discussionShareService.addComment(comment);
        this.posting = false;
        this._modalService.dismissAll();
        //wait animation
        await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
        await this.showKindleInstructions();
        this.commentText = "";
    }

    async skipComment() {
        this._modalService.dismissAll();
        //wait animation
        await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
        await this.showKindleInstructions();
        // try {
        //     document.querySelector("discussion")?.scrollIntoView()
        // } catch (_) {}
        this.commentText = "";
    }

    onReplyTextChanged() {
        this.commentLengthError = this.commentText.length > this.maxCommentLength;
    }

    async showKindleInstructions() {
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(this.kindleInstructionsModal, {
                centered: true,
                beforeDismiss() {
                    that._chatWidgetService.show();
                    return true;
                },
            }).shown
        );
    }

    private async downloadReadable() {
        const a = document.createElement("a");
        if (this.downloadLink) {
            let mime = "";
            if (this.downloadLink.type === "epub") {
                mime = "application/epub+zip";
            } else if (this.downloadLink.type === "mobi") {
                mime = "application/x-mobipocket-ebook";
            } else {
                mime = "application/pdf";
            }
            this.loading = true;
            const res = await fetch(this.downloadLink.link);
            const blob = await res.blob();
            const blobWithMime = new Blob([blob], {
                type: mime
            });
            a.href = URL.createObjectURL(blobWithMime);
            a.download = this.downloadLink.link.split("/").pop()!;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loading = false;

            const email = this._authService.user?.email || this._anonymousPersistentState.email;
            if (email) {
                this._analyticsService.identifyAndTrack({email, traits: {kindleReader: true}}, {event: "free_book_download", params: {readable: this.readable}});
            } else {
                this._analyticsService.track({event: "free_book_download", params: {readable: this.readable}});
            }
            this._anonymousPersistentState.saveReading(this.readable.slug, {downloaded: true});
            if (this.readable.settings.type === "free_book") {
                //sync reading progress to user's shelf
                if (this._authService.user) {
                    firstValueFrom(this._libraryService.updateFreeBooksReadingProgress({
                        [this.readable.slug]: this._anonymousPersistentState.reading[this.readable.slug]
                    }));
                }
            } else if (this.readable.settings.type === "arc") {
                //sync reading progress to the arcContact
                firstValueFrom(this._booksService.updateArcReadingProgress(
                    this.readable.book!.slug,
                    this._anonymousPersistentState.reading[this.readable.slug],
                    this._anonymousPersistentState.email
                ))
            }

            //only trigger the comment if the option is set and the page has discussions
            if (this.triggerComment && this.readable.book && document.querySelector("discussion")) {
                //this shows kindleInstructions when it's done
                await this.showCommentModal();
            } else {
                await this.showKindleInstructions();
            }
        }
    }

    private async showCommentModal() {
        this._chatWidgetService.hide();
        const that = this;
        await firstValueFrom(
            this._modalService.open(this.addCommentModal, {
                centered: true,
                backdrop: "static",
                keyboard: false,
                beforeDismiss() {
                    that._chatWidgetService.show();
                    return true;
                },
            }).shown
        );
    }
}
