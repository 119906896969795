<div class="row">
    <div class="col-12">
        <breadcrumbs></breadcrumbs>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <h1>
            {{data.header}}
        </h1>
    </div>
</div>
<div class="tf-container" [attr.data-tf-live]="data.id"></div>