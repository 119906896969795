import { Component, Input, ViewChild, TemplateRef, ElementRef, OnInit } from '@angular/core';
import { BookSeries } from 'src/app/models/book-series.model';
import { Book } from 'src/app/models/book.model';
import { Genre } from 'src/app/models/genre.model';
import { Trope } from 'src/app/models/trope.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformService } from 'src/app/services/platform.service';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { combineLatest, filter, firstValueFrom } from 'rxjs';
import { JoinCrewEventMetadata } from 'src/app/services/analytics/events/joinCrewEventMetadata';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { ActivatedRoute } from '@angular/router';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'series-info',
    templateUrl: './series-info.component.html',
    styleUrls: ['./series-info.component.scss'],
})
export class SeriesInfo implements OnInit {
    @Input() series!: BookSeries;
    @Input() customDescription?: string;
    @Input() customTitle?: string;

    followsAuthor = false;
    joinedCrew = false;
    afterJoinCb = () => this._modalService.dismissAll();

    @ViewChild('joinModal') joinModal!: TemplateRef<any>;

    @ViewChild("description") description!: ElementRef<HTMLDivElement>;

    followRequestInProgress$ = this._activatedRoute.queryParams.pipe(
        filter(params => params["action"] === "follow_author" && params["author"] === this.mainAuthorData.slug),
        filter(() => this._platformService.isBrowser())
    );

    layoutConfig: ColumnLayoutConfig = {
        mobile: 2,
        sm: 3,
        md: 4,
        lg: 6
    }

    constructor(
        private readonly _followService: FollowService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _modalService: NgbModal,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        this._followService.followsAuthor$.subscribe(val => {
            this.followsAuthor = val;
        });
        if (this.mainAuthorData && this.mainAuthorData.crew) {
            this._followService.joinedCrew$.subscribe(val => {
                this.joinedCrew = val;
            });
        }
        await this._followService.init(
            null,
            this.mainAuthorData || null,
            null
        );

        combineLatest([
            this._authService.isLoggedIn$,
            this._authService.onboardingCompleted$,
            this.followRequestInProgress$
        ]).pipe(
            filter(([isLoggedIn, onboardingCompleted, followInProgress]) => isLoggedIn && onboardingCompleted && Boolean(followInProgress)),
        ).subscribe(() => {
            this.followAuthor();
        });
    }

    ngAfterViewInit(): void {
        this.description.nativeElement.innerHTML = this.series.description || this.book.description || "";
    }

    //books published before the rich description still have simple text
    get hasHtmlDescription() {
        return this.book.description?.includes("<p>");
    }

    get title() {
        if (this.series.title.toLowerCase().includes("books in order")) {
            return this.series.title;
        } else {
            return `${this.series.title} Books in Order`;
        }
    }

    get mainAuthorData() {
        //use the first author in the series
        return this.series.authors[0];
    }

    get book() {
        //use the first book in the series
        return this.series.books[0];
    }

    get booksDataSource(): ContentGridDataSource<Book> {
        return this._utilitiesService.getBookDataSource(this.series.books, {subtitle: "none"});
    }

    get authorBadges(): BadgeConfig[] {
        return this.series.authors.map(author => {
            return {
                text: author.name,
                type: "default",
                url: this._utilitiesService.getAuthorUrl(author),
                image: author.images.profile
            }
        });
    }

    get genreBadges(): BadgeConfig[] {
        //make a set of all genres of all the books in the series
        const allGenres: Genre[] = [];
        for (const book of this.series.books) {
            for (const g of book.genres) {
                if (!allGenres.find(ag => ag.id === g.id)) {
                    allGenres.push(g);
                }
            }
        }
        return allGenres.map(genre => {
            return {
                text: genre.name,
                type: "default",
                url: this._utilitiesService.getGenreUrl(genre.slug)
            };
        });
    }

    get tropeBadges(): BadgeConfig[] {
        //make a set of all tropes of all the books in the series
        const allTropes: Trope[] = [];
        for (const book of this.series.books) {
            for (const t of book.tropes) {
                if (!allTropes.find(tt => tt.id === t.id)) {
                    allTropes.push(t);
                }
            }
        }
        return allTropes.map(trope => {
            return {
                text: trope.name,
                type: "default",
                url: this._utilitiesService.getTropeUrl(trope.slug)
            };
        });
    }

    get crewFormSettings() {
        return {
            trigger: "author_follow"
        } as const;
    }

    get joinCrewEventMetadata(): JoinCrewEventMetadata {
        return {
            joinedFrom: "series_page",
            series: this.series.slug
        };
    }

    async followAuthor() {
        if (!this._authService.user) {
            this._analyticsService.track({
                event: "view_sign_up_form",
                params: {
                    metadata: {
                        triggeredFrom: "series_page_follow_author",
                        author: this.mainAuthorData.slug,
                        series: this.series.slug
                    }
                }
            });
            await this._authService.login({
                sendStageTitle: "Sign in or sign up",
                sendStageDescription: `Sign-in or register for free to follow ${this.mainAuthorData.name}`,
                skipOnboarding: false,
                signupReason: {type: "follow_author", author: this.mainAuthorData.slug},
                action: "follow_author",
                actionParams: {
                    author: this.mainAuthorData.slug
                }
            });
        }
        this._followService.followAuthor({source: "series_page", series: this.series.slug});

        if (!this.joinedCrew && this.mainAuthorData && this.mainAuthorData.crew) {
            this._chatWidgetService.hide();
            //TODO make modalService.close() to wait for modal hidden event
            await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
            const that = this;
            await firstValueFrom(
                this._modalService.open(this.joinModal, {
                    centered: true,
                    fullscreen: await firstValueFrom(this._platformService.isMobile),
                    beforeDismiss() {
                        that._chatWidgetService.show();
                        return true;
                    },
                }).shown
            );
        }
    }

    async unfollowAuthor() {
        await this._followService.unfollowAuthor();
    }

    trackAmazonClick() {
        this._analyticsService.track({event: "go_to_amazon", params: {bookOrseries: this.series, clickSource: "web"}});
    }
}
