import { Component, Input } from '@angular/core';
import { BookSeries } from 'src/app/models/book-series.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
    selector: 'worldmaker-component-series-info',
    templateUrl: './series-info.component.html',
    styleUrls: ['./series-info.component.scss'],
})
export class SeriesInfo {
    @Input() data!: {
        series: BookSeries;
        customDescription: string;
        useCustomDescription: boolean;
        customTitle: string;
        useCustomTitle: boolean;
    };

    get customDescription() {
        if (this.data.useCustomDescription && this.data.customDescription) {
            return this.data.customDescription;
        } else {
            return;
        }
    }

    get customTitle() {
        if (this.data.useCustomTitle && this.data.customTitle) {
            return this.data.customTitle;
        } else {
            return;
        }
    }

    constructor(
        
    ) {}
}
