import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { UserProfile } from "../models/user-profile.model";

@Injectable({
    providedIn: 'root'
})
export class PublicUserProfileService {
    private readonly apiUrl = `${environment.baseUrl}/api/public/user-profile`;

    constructor(
        private http: HttpClient
    ) { }

    async getUserMetadata(): Promise<UserProfile> {
        return firstValueFrom(this.http.get<UserProfile>(`${this.apiUrl}`));
    }

    async uploadProfilePicture(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('profilePicture', file);

        let res = await firstValueFrom(this.http.post<any>(`${this.apiUrl}/picture/type/profile`, formData));
        return res.url;
    }

    async getUserProfileById(userId: string): Promise<{url: string}> {
        return await firstValueFrom(this.http.get<{url: string}>(`${this.apiUrl}/picture/type/profile/${userId}`));
    }

    async changeUsername(username: string): Promise<void> {
        await firstValueFrom(this.http.put<{username: string}>(`${this.apiUrl}/username`, {username}));
    }

    async changeName(name: string): Promise<void> {
        await firstValueFrom(this.http.put<{name: string}>(`${this.apiUrl}/name`, {name}));
    }

    async changeTiktok(tiktok: string): Promise<void> {
        await firstValueFrom(this.http.put<{tiktok: string}>(`${this.apiUrl}/tiktok`, {tiktok}));
    }

    async updateOnboardingStage(newOnboardingStage: string): Promise<void> {
        await firstValueFrom(this.http.put<{onboardingStage: string}>(`${this.apiUrl}/onboarding`, {onboardingStage: newOnboardingStage}));
    }
}
