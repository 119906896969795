import { Component, Input, OnInit } from '@angular/core';
import { Readable } from 'src/app/models/readable.model';
import { PlatformService } from 'src/app/services/platform.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
//need to import this here for the tooltip to work
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { firstValueFrom } from 'rxjs';
import * as dayjs from "dayjs";
import { LibraryService } from 'src/app/services/library.service';
import { BooksService } from 'src/app/services/books.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';

@Component({
    selector: 'readables-reader-intro-card',
    templateUrl: './reader-intro-card.component.html',
    styleUrls: ['./reader-intro-card.component.scss'],
})
export class ReadablesReaderIntroCard implements OnInit {
    @Input()
    readable!: Readable

    @Input()
    locked?: boolean;

    @Input()
    timeLimitedFree?: boolean;

    readingProgress = 0;
    finished = false;
    onStopReading = () => {
        this.updateProgress();
    };
    isNew = false;

    constructor(
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _router: Router,
        private readonly _utils: UtilitiesService,
        private readonly _libraryService: LibraryService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _booksService: BooksService
    ) {}

    get bookUrl() {
        if (this.readable.author && this.readable.book) {
            return `/authors/${this.readable.author.slug}/books/${this.readable.book.slug}`;
        } else {
            return null;
        }
    }

    get coverSrc(): string {
        try {
            if (!this.readable || !this.readable.book) return "";
            const slug = this.readable.book.slug;
            const alt = this._anonymousPersistentState.altMetadata;
            if(alt[slug] && alt[slug].altCoverIndex !== undefined && this.readable.book.images.altCovers && this.readable.book.images.altCovers[alt[slug].altCoverIndex]) {
                return this.readable.book.images.altCovers[alt[slug].altCoverIndex];
            }
            return this.readable.book.images.cover || "";
        }
        catch(e) {
            return this.readable.book?.images.cover || "";
        }
    }

    get showCountDown() {
        if (this.timeLimitedFree && this.readable.book?.publishedAt && dayjs(this.readable.book!.publishedAt).isAfter(dayjs())) {
            return true;
        } else {
            return false;
        }
    }

    ngOnInit(): void {
        if (this._platformService.isBrowser()) {
            this.updateProgress();
            //if the free book was added less than a day ago we enable the "New" badge
            const addedAt = localStorage.getItem(`free-book-added-at-${this.readable.book?.slug}`);
            if (addedAt) {
                if (Date.now() - parseInt(addedAt) < 86_400_000) {
                    this.isNew = true;
                }
            }
            if (this._anonymousPersistentState.reading[this.readable.slug]?.finished) {
                this.finished = true;
            }
        }
    }

    updateProgress() {
        this.readingProgress = this._anonymousPersistentState.reading[this.readable.slug]?.percent || 0;
    }

    markFinished() {
        this._anonymousPersistentState.saveReading(this.readable.slug, {percent: 100, finished: true});
        this.finished = true;
        this.readingProgress = 100;
        this._analyticsService.track({event: "finish_reading", params: {readable: this.readable}});
        if (this.readable.settings.type === "free_book") {
            //sync reading progress to user's shelf
            if (this._authService.user) {
                firstValueFrom(this._libraryService.updateFreeBooksReadingProgress({
                    [this.readable.slug]: this._anonymousPersistentState.reading[this.readable.slug]
                }));
            }
        } else if (this.readable.settings.type === "arc") {
            //sync reading progress to the arcContact
            firstValueFrom(this._booksService.updateArcReadingProgress(
                this.readable.book!.slug,
                this._anonymousPersistentState.reading[this.readable.slug],
                this._anonymousPersistentState.email
            ))
        }
        this._router.navigate([this._utils.getBookUrl(this.readable.book!) + '/rate'], {
            queryParams: {
                action: "free_book"
            }
        });
    }
}
