import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, ViewChildren } from '@angular/core';
import { ContentCardComponent } from '../content-card/content-card.component';
import { get } from "lodash-es";
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { PillType } from 'src/app/models/badge-config.model';


export interface ContentGridDataSourceBadgeConfig<T> {
    enabled: boolean | ((d: T) => boolean);
    text: string | ((d: T) => string);
    type: PillType | ((d: T) => PillType);
};

export interface ContentGridDataSource<T extends {[key: string]: any}> {
    data: T[];
    title: string | ((d: T) => string);
    subtitle: string | ((d: T) => string);
    image: string | ((d: T) => string);
    lazy?: boolean | ((d: T) => boolean); // Sets loading=lazy to <img> when true. Overrrides ContentGrid setting
    url: string | string[] | ((d: T) => string | string[]);
    badge?: ContentGridDataSourceBadgeConfig<T>;
    onClick?: (d: T) => () => void;
    bottomBadge?: string | ((d: T) => string);
}

export type ContentGridDataSourceKey = "title" | "subtitle" | "image" | "url" | "badge" | "onClick" | "bottomBadge";

@Component({
  selector: 'content-grid',
  templateUrl: './content-grid.component.html',
  styleUrls: ['./content-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentGridComponent<T extends {[key: string]: any}>{
    @Input()
    dataSource: ContentGridDataSource<T> | null = null;

    @Input()
    hideSubtitle: boolean = false;

    @Input()
    externalLink: boolean = false;

    @Input()
    columnLayout: ColumnLayoutConfig = {
        mobile: 1,
        sm: 2,
        md: 3,
        lg: 4
    };

    @Input()
    template: "default" | "bubbles" = "default";

    @Input()
    horizontallyScollable = false;

    @Input()
    lazy: boolean = false;

    private getPropertyByConfigKey<K>(item: T, key: ContentGridDataSourceKey): any {
        if (this.dataSource && this.dataSource[key] && typeof this.dataSource[key] === 'function') {
            return (this.dataSource[key] as Function)(item);
        }
        else {
            return get(item, key, "");
        }
    }

    getTitle(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "title");
        }
        return "";
    }

    getSubtitle(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "subtitle");
        }
        return "";
    }

    getImage(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "image");
        }
        return "";
    }

    getUrl(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "url");
        }
        return "";
    }

    getBadge(item: T): ContentGridDataSourceBadgeConfig<T> {
        let badgeConfig: ContentGridDataSourceBadgeConfig<T> | undefined = this.dataSource?.badge;
        if (!badgeConfig) {
            return {enabled: false, text: "", type: "primary"};
        }
        let config = {
            ...badgeConfig
        }
        if (typeof badgeConfig.enabled === 'function') {
            config.enabled = badgeConfig.enabled(item);
        }
        if (typeof badgeConfig.text === 'function') {
            config.text = badgeConfig.text(item);
        }
        if (typeof badgeConfig.type === 'function') {
            config.type = badgeConfig.type(item);
        }
        return config;
    }
    
    getLazy(item: T): boolean {
        if (this.dataSource) {
            if (this.dataSource.lazy && typeof this.dataSource.lazy === 'function') {
                return this.dataSource.lazy(item);
            }
            return this.dataSource.lazy || this.lazy;
        }
        return this.lazy;
    }

    getOnClick(item: T) {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "onClick");
        }
        return undefined;
    }

    getBottomBadge(item: T): string {
        if (this.dataSource) {
            return this.getPropertyByConfigKey(item, "bottomBadge");
        }
        return "";
    }
}
