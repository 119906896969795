import { Author } from "./author.model";

export enum OnboardingStage {
    Name = "name",
    Completed = "completed"
}

export interface UserProfile {
    id: number;

    externalUserId: string;

    email: string;

    nickname: string;

    name: string;

    tiktok: string;

    publicUserId: string;

    authorId: number;

    onboardingStage: OnboardingStage;

    author: Author;

    libraryBookVariations: any;
}