import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { CommentComponent } from './components/comment/comment.component';
import { ReplyInputComponent } from './components/reply-input/reply-input.component';
import { FormsModule } from '@angular/forms';
import { UserModule } from '../user/user.module';
import { DiscussionService } from './discussion.service';
import { SharedModule } from '../shared/shared.module';
import { GenericUserIconComponent } from './components/generic-user-icon/generic-user-icon.component';

@NgModule({
    declarations: [
        DiscussionComponent,
        CommentComponent,
        ReplyInputComponent,
        GenericUserIconComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UserModule,
        SharedModule
    ],
    exports: [
        DiscussionComponent
    ],
    providers: [
        DiscussionService
    ]
})
export class DiscussionModule { }
