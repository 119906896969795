import { Component, Input } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: "ecommerce-explain-list",
    templateUrl: "./ecommerce-explain-list.component.html",
    styleUrl: "./ecommerce-explain-list.component.scss",
    standalone: true,
    imports: [CommonModule, SharedModule]
})
export class EcommerceExplainList {
    @Input() header = "Embark on a Νew Highland Romantic Journey each Weekend";
}