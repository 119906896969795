import { Component, Input } from '@angular/core';
import { BookReview } from 'src/app/models/book.model';

@Component({
    selector: 'reviews-list-item',
    templateUrl: './reviews-list-item.component.html',
    styleUrls: ['./reviews-list-item.component.scss'],
    host: {
        class: "d-flex flex-column align-items-center"
    }
})
export class ReviewsListItemComponent {
    @Input()
    data!: BookReview;

    @Input()
    linkEnabled: boolean = true;

    @Input()
    showBookCover: boolean = true;

    @Input()
    singleBookMode!: boolean;

    getStars(rating: number) {
        return new Array(rating);
    }
}
