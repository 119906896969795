import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookType } from 'src/app/models/book-type.model';

@Component({
    selector: 'book-type-grid',
    templateUrl: './book-type-grid.component.html',
    styleUrls: ['./book-type-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookTypeGridComponent {
    @Input()
    items: BookType[] | null = null;

    @Input()
    routePrefix: string = "";

    availableBackgrounds: string[] = [
        "#E8A120",
        "#208138",
        "#F25454",
        "#F976AC",
        "#5174EF"
    ]

    getRouterLink(item: BookType): string[] {
        return [`/${this.routePrefix}`, item.slug];
    }

    getNextAvailableBackground(index: number): string {
        return this.availableBackgrounds[index % this.availableBackgrounds.length];
    }

    getIcon(item: BookType): string | null {
        let iconNames = {
            "historical-romance": "castle-turret.svg",
            "scottish-romance": "sword.svg",
            "regency": "crown.svg",
            "romance": "heart.svg",
            "fantasy": "shooting-star.svg"
        }
        let iconMap = new Map<string, string>(Object.entries(iconNames));
        if (iconMap.has(item.slug)) {
            return `assets/icons/figma/${iconMap.get(item.slug)}`;
        }
        return null;
    }
}
