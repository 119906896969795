import { Book } from "src/app/models/book.model";
import { Author } from "src/app/models/author.model";
import { World } from "src/app/models/world.model";
import { BookSeries } from "src/app/models/book-series.model";
import { Genre } from "src/app/models/genre.model";
import { Trope } from "src/app/models/trope.model";
import { Readable } from "src/app/models/readable.model";

import { JoinCrewEventMetadata } from "./joinCrewEventMetadata";
import { FollowAuthorEventMetadata } from "./followAuthorEventMetadata";
import { SignupReason } from "./signupReason";
import { ViewSignupFormMetadata } from "./viewSignupFormMetadata";

/*
Files in analytics/events are duplicated on server and client.
If you change one make sure to update the other.
Only the model/entities imports are different.

When you add a new event, follow the same signature with a make function that takes a single params object.
This is needed for the event processing and type inference.
*/

export const eventMap = {
    sign_in: {
        make() {
            return {} as const;
        }
    },
    ["sign-up"]: {
        make(params: {user: {signupReason: SignupReason}}) {
            const properties: {[key: string]: any} = {
                signupReason: params.user.signupReason.type
            };
            if (params.user.signupReason.type === "follow_author") {
                properties.author = params.user.signupReason.author;
                properties.acValue = `follow_author--${params.user.signupReason.author}`;
            } else if (params.user.signupReason.type === "follow_world") {
                properties.world = params.user.signupReason.world;
                properties.acValue = `follow_world--${params.user.signupReason.world}`;
            } else if (params.user.signupReason.type === "join_crew") {
                properties.author = params.user.signupReason.author;
                properties.acValue = `join_crew--${params.user.signupReason.author}`;
            } else if (params.user.signupReason.type === "media_unlock" || params.user.signupReason.type === "media_unlock_kindle") {
                properties.media = params.user.signupReason.media;
                properties.acValue = `${params.user.signupReason.type}--${params.user.signupReason.media}`
            } else {
                properties.acValue = params.user.signupReason.type;
            }
            return {
                properties
            } as const;
        }
    },
    sign_out: {
        make() {
            return {} as const;
        }
    },
    click_blog: {
        make() {
            return {} as const;
        }
    },
    follow_book: {
        make(params: {book: Book}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    acValue: `${params.book.mainAuthorSlug}--${params.book.slug}`
                }
            } as const;
        }
    },
    unfollow_book: {
        make(params: {book: Book}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    acValue: `${params.book.mainAuthorSlug}--${params.book.slug}`
                }
            } as const;
        }
    },
    follow_author: {
        make(params: {author: Author, eventMetadata: FollowAuthorEventMetadata}) {
            return {
                properties: {
                    author: params.author.slug,
                    acValue: params.author.slug,
                    ...params.eventMetadata
                }
            } as const;
        }
    },
    unfollow_author: {
        make(params: {author: Author}) {
            return {
                properties: {
                    author: params.author.slug,
                    acValue: params.author.slug
                }
            } as const;
        }
    },
    follow_world: {
        make(params: {world: World}) {
            return {
                properties: {
                    world: params.world.slug,
                    acValue: params.world.slug
                }
            } as const;
        }
    },
    unfollow_world: {
        make(params: {world: World}) {
            return {
                properties: {
                    world: params.world.slug,
                    acValue: params.world.slug
                }
            } as const;
        }
    },
    join_crew: {
        make(params: {author: Author, eventMetadata: JoinCrewEventMetadata}) {
            return {
                properties: {
                    author: params.author.slug,
                    acValue: params.author.slug,
                    ...params.eventMetadata
                }
            } as const;
        }
    },
    leave_crew: {
        make(params: {author: Author}) {
            return {
                properties: {
                    author: params.author.slug,
                    acValue: params.author.slug
                }
            } as const;
        }
    },
    add_comment: {
        make(params: {book: Book, reply: boolean}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    reply: params.reply,
                    acValue: params.book.slug
                }
            } as const;
        }
    },
    remove_comment: {
        make(params: {book: Book}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    acValue: params.book.slug
                }
            } as const;
        }
    },
    add_free_book: {
        make(params: {book: Pick<Book, "slug" | "publisher" | "mainAuthorSlug">}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    acValue: `${params.book.mainAuthorSlug}--${params.book.slug}`
                }
            } as const;
        }
    },
    get_free_book: {
        make(params: {book: Pick<Book, "slug" | "publisher" | "mainAuthorSlug">, type: "free_book" | "arc", placement: "book_page" | "author_recommends" | "bonus_content_flow", acValue?: string}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    type: params.type,
                    placement: params.placement,
                    acValue: params.acValue || `${params.book.mainAuthorSlug}--${params.book.slug}`
                }
            } as const;
        }
    },
    get_free_book_c: {
        make(params: {book: Pick<Book, "slug" | "publisher" | "mainAuthorSlug">, type: "free_book" | "arc", placement: "book_page" | "author_recommends" | "bonus_content_flow", acValue?: string}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    type: params.type,
                    placement: params.placement,
                    acValue: params.acValue || `${params.book.mainAuthorSlug}--${params.book.slug}`
                }
            } as const;
        }
    },
    get_recommended_books: {
        make(params: {books: string}) {
            return {
                properties: {
                    books: params.books
                }
            } as const;
        }
    },
    skip_recommended_books: {
        make() {
            return {} as const;
        }
    },
    bypass_recommended_books: {
        make() {
            return {} as const;
        }
    },
    join_prelaunch: {
        make(params: {book: Pick<Book, "slug" | "publisher" | "mainAuthorSlug">, acValue?: string}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    acValue: params.acValue || `${params.book.mainAuthorSlug}--${params.book.slug}`
                }
            } as const;
        }
    },
    join_arc: {
        make(params: {book: Pick<Book, "id" | "slug" | "publisher" | "mainAuthorSlug">, acValue?: string}) {
            return {
                properties: {
                    book: params.book.slug,
                    publisher: params.book.publisher || undefined,
                    author: params.book.mainAuthorSlug,
                    acValue: params.acValue || `${params.book.mainAuthorSlug}--${params.book.slug}`
                },
                actionData: {
                    bookId: params.book.id!
                }
            } as const;
        },
        actions: ["addToArcList"]
    },
    change_profile_picture: {
        make() {
            return {} as const;
        }
    },
    change_name: {
        make() {
            return {} as const;
        }
    },
    change_username: {
        make() {
            return {} as const;
        }
    },
    change_tiktok: {
        make() {
            return {} as const;
        }
    },
    send_push_notification: {
        make(params: {subscription: {device: string}, notificationId: string}) {
            return {
                properties: {
                    notificationId: params.notificationId,
                    device: params.subscription.device
                }
            } as const
        }
    },
    get_push_notification: {
        make(params: {subscription: {device: string}, notificationId: string}) {
            return {
                properties: {
                    notificationId: params.notificationId,
                    device: params.subscription.device
                }
            } as const
        }
    },
    click_push_notification: {
        make(params: {subscription: {device: string}, notificationId: string}) {
            return {
                properties: {
                    notificationId: params.notificationId,
                    device: params.subscription.device
                }
            } as const
        }
    },
    push_notification_subscription_removed: {
        make(params: {subscription: {device: string}, notificationId: string}) {
            return {
                properties: {
                    notificationId: params.notificationId,
                    device: params.subscription.device
                }
            } as const
        }
    },
    click_skip_notification: {
        make(params: {subscription: {device: string}}) {
            return {
                properties: {
                    device: params.subscription.device
                }
            } as const
        }
    },
    view_notification_trigger: {
        make(params: {subscription: {device: string}}) {
            return {
                properties: {
                    device: params.subscription.device
                }
            } as const
        }
    },
    click_allow_notification: {
        make(params: {subscription: {device: string}}) {
            return {
                properties: {
                    device: params.subscription.device
                }
            } as const
        }
    },
    go_to_amazon: {
        make(params: {bookOrseries: Book | BookSeries, clickSource: "web" | "email" | "push", linkType?: string, utmContent?: string}) {
            const props: any = {
                acValue: `${params.bookOrseries.mainAuthorSlug}--${params.bookOrseries.slug}`,
                publisher: params.bookOrseries.publisher || undefined,
                author: params.bookOrseries.mainAuthorSlug,
                click_source: params.clickSource
            };
            //checking if it has isPublished to know if it's a book or series
            if ("isPublished" in params.bookOrseries) {
                props.book = params.bookOrseries.slug;
            } else {
                props.series = params.bookOrseries.slug;
            }
            if (params.linkType) {
                props['linkType'] = params.linkType;
            }
            if (params.utmContent) {
                props.content = params.utmContent;
            }
            return {
                properties: props
            } as const;
        }
    },
    start_reading: {
        make(params: {readable: Readable}) {
            return {
                properties: {
                    readable: params.readable.slug,
                    type: params.readable.settings.type,
                    book: params.readable.book?.slug,
                    publisher: params.readable.book?.publisher || undefined,
                    author: params.readable.author?.slug
                }
            } as const;
        }
    },
    reading_progress: {
        make(params: {readable: Readable, progress: number}) {
            return {
                properties: {
                    readable: params.readable.slug,
                    type: params.readable.settings.type,
                    book: params.readable.book?.slug,
                    publisher: params.readable.book?.publisher || undefined,
                    author: params.readable.author?.slug,
                    progress: params.progress
                }
            } as const;
        }
    },
    finish_reading: {
        make(params: {readable: Readable}) {
            return {
                properties: {
                    readable: params.readable.slug,
                    type: params.readable.settings.type,
                    book: params.readable.book?.slug,
                    publisher: params.readable.book?.publisher || undefined,
                    author: params.readable.author?.slug
                }
            } as const;
        }
    },
    click_download_free_book: {
        make(params: {readable: Readable}) {
            return {
                properties: {
                    book: params.readable.book?.slug,
                    publisher: params.readable.book?.publisher || undefined,
                    author: params.readable.author?.slug
                }
            } as const;
        }
    },
    free_book_download: {
        make(params: {readable: Readable}) {
            return {
                properties: {
                    book: params.readable.book?.slug,
                    publisher: params.readable.book?.publisher || undefined,
                    author: params.readable.author?.slug
                }
            } as const;
        }
    },
    init_bonus_content_flow: {
        make(params: {book: Book, author: Author, kindle: boolean, flowAlreadyCompleted: boolean, contentType: "bonus_scene" | "character_overviews" | "free_book" | "other", bookSrc?: string}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.author.slug,
                    publisher: params.book.publisher || undefined,
                    flowAlreadyCompleted: params.flowAlreadyCompleted,
                    kindle: params.kindle,
                    bookSrc: params.bookSrc || undefined,
                    contentType: params.contentType
                }
            } as const;
        }
    },
    start_bonus_content_flow: {
        make(params: {book: Book, author: Author, kindle: boolean, flowAlreadyCompleted: boolean, contentType: "bonus_scene" | "character_overviews" | "free_book" | "other", triggeredFrom: "book_page" | "bonus_page"}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.author.slug,
                    publisher: params.book!.publisher || undefined,
                    flowAlreadyCompleted: params.flowAlreadyCompleted,
                    kindle: params.kindle,
                    contentType: params.contentType,
                    triggeredFrom: params.triggeredFrom
                }
            } as const;
        }
    },
    unlock_bonus_content: {
        make(params: {book: Book, author: Author, kindle: boolean, contentType: "bonus_scene" | "character_overviews" | "free_book" | "other"}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.author.slug,
                    publisher: params.book.publisher || undefined,
                    kindle: params.kindle,
                    contentType: params.contentType
                },
                actionData: {
                    bookId: params.book.id!
                }
            } as const;
        },
        actions: ["addToEpilogueList"]
    },
    finish_bonus_content: {
        make(params: {book: Book, author: Author, kindle: boolean, contentType: "bonus_scene" | "character_overviews" | "free_book" | "other"}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.author.slug,
                    publisher: params.book.publisher || undefined,
                    kindle: params.kindle,
                    contentType: params.contentType
                }
            } as const;
        }
    },
    skip_bonus_content_flow: {
        make(params: {book: Book, step: "rate_book" | "recommendations" | "claim_free_book" | "recommendation" | "select_free_book", contentType: "bonus_scene" | "character_overviews" | "free_book" | "other"}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.book.mainAuthorSlug,
                    publisher: params.book.publisher || undefined,
                    step: params.step,
                    contentType: params.contentType
                }
            } as const;
        }
    },
    continue_bonus_content_flow: {
        make(params: {book: Book, step: "rate_book" | "recommendations" | "claim_free_book" | "select_free_book", contentType: "bonus_scene" | "character_overviews" | "free_book" | "other"}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.book.mainAuthorSlug,
                    publisher: params.book.publisher || undefined,
                    step: params.step,
                    contentType: params.contentType
                }
            } as const;
        }
    },
    finish_bonus_content_flow: {
        make(params: {book: Book, contentType: "bonus_scene" | "character_overviews" | "free_book" | "other"}) {
            return {
                properties: {
                    book: params.book.slug,
                    author: params.book.mainAuthorSlug,
                    publisher: params.book.publisher || undefined,
                    contentType: params.contentType
                }
            } as const;
        }
    },
    recommendation_click: {
        make(params: {book: Book, destinationType: "amazon" | "book_page", placement?: string, recommendationType: "New Release" | "Recent Release" | "Older Release", currentUrl: string}) {
            return {
                properties: {
                    author: params.book.mainAuthorSlug,
                    book: params.book.slug, 
                    publisher: params.book.publisher || undefined,
                    destinationType: params.destinationType,
                    placement: params.placement,
                    recommendationType: params.recommendationType,
                    currentUrl: params.currentUrl
                }
            } as const;
        }
    },
    accept_notification_prompt: {
        make(params: {device: string}) {
            return {
                properties: {
                    device: params.device
                }
            } as const;
        }
    },
    autoblock_notification_prompt: {
        make(params: {device: string}) {
            return {
                properties: {
                    device: params.device
                }
            } as const;
        }
    },
    decline_notification_prompt: {
        make(params: {device: string}) {
            return {
                properties: {
                    device: params.device
                }
            } as const;
        }
    },
    search_result_click: {
        make(params: {type: "book", entity: Pick<Book, "slug"> & {featured?: boolean, publisher?: string | null}} |
            {type: "author", entity: Pick<Author, "slug">} |
            {type: "series", entity: Pick<BookSeries, "slug">} |
            {type: "world", entity: Pick<World, "slug">} |
            {type: "genre", entity: Pick<Genre, "slug">} |
            {type: "trope", entity: Pick<Trope, "slug">}
        ) {
            return {
                properties: {
                    type: params.type,
                    [params.type]: params.entity.slug,
                    publisher: params.type === "book" ? params.entity.publisher || undefined : undefined,
                    featured: params.type === "book" ? params.entity.featured || undefined : undefined
                }
            } as const;
        }
    },
    search_init: {
        make() {
            return {} as const;
        }
    },
    search_query: {
        make(params: {query: string}) {
            return {
                properties: {
                    query: params.query
                }
            } as const;
        }
    },
    email_typo_correction: {
        make(params: {old: string, new: string}) {
            return {
                properties: {
                    old: params.old,
                    new: params.new
                }
            } as const;
        }
    },
    social_share: {
        make(params: {method: "email" | "facebook" | "twitter" | "reddit" | "whatsapp" | "native" | "copy"}) {
            return {
                properties: {
                    method: params.method
                }
            } as const;
        }
    },
    view_media: {
        make(params: {category: string, media: string, mediaType: "image" | "video" | "tiktok"}) {
            return {
                properties: {
                    category: params.category,
                    media: params.media,
                    mediaType: params.mediaType
                }
            } as const;
        }
    },
    view_sign_up_form: {
        make(params: {metadata: ViewSignupFormMetadata}) {
            return {
                properties: params.metadata
            } as const;
        }
    }
} as const;