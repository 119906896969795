import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[landing_page_template_slot_header]',
})
export class LandingPageTemplateSlotHeader {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[landing_page_template_slot_section1]',
})
export class LandingPageTemplateSlotSection1 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[landing_page_template_slot_section2]',
})
export class LandingPageTemplateSlotSection2 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[landing_page_template_slot_section3]',
})
export class LandingPageTemplateSlotSection3 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[landing_page_template_slot_section4]',
})
export class LandingPageTemplateSlotSection4 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[landing_page_template_slot_section5]',
})
export class LandingPageTemplateSlotSection5 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[landing_page_template_slot_section6]',
})
export class LandingPageTemplateSlotSection6 {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
