import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
    constructor(
        private readonly _platformService: PlatformService
    ) {}

    ngOnInit(): void {
        if (this._platformService.isServer()) {
            this._platformService.metaTags.setNoIndex();
        }
        else {
            if (environment.production) {
                window.location.href = '/404';
            }
        }
    }
}
