import { Inject, Injectable, NgZone } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { PlatformService } from '../platform.service';
import { UserService } from '../user.service';
import { User } from '../../models/user.model';
import { Auth0User } from '../../modules/auth/types/auth0-user';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AnonymousPersistentState } from '../anonymous-persistent-state';
import { SignupReason } from './events/signupReason';
import { eventMap } from './events/eventMap';

const VALID_EVENTS = Object.keys(eventMap) as unknown as keyof typeof eventMap;

type UserTraits =  {
    name?: string,
    tiktok?: string,
    signupReason?: SignupReason,
    $avatar?: string,
    kindleReader?: boolean
};

declare const analytics: any;
declare const window: Window & {hotjarIntegration: any, intercomSettings: any};

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
    constructor(
        private readonly _platformService: PlatformService,
        private readonly _userService: UserService,
        private readonly http: HttpClient,
        private readonly zone: NgZone,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _location: PlatformLocation
    ) {
        this._userService.user$.subscribe((user) => {
            if (user) {
                this.identify(user.email);
            }
        });
    }

    private apiUrl = `${environment.baseUrl}/api/e`;

    load() {
        if (this._platformService.isBrowser()) {

            // This improves lighthouse score
            window.addEventListener('load', () => {
                analytics.load(`${environment.segmentKey}`, {
                    integrations: {
                        All: true,
                        'Intercom Web (Actions)': false,
                        "Intercom dev": false,
                        "Intercom": false,
                        'Hotjar': false,
                    }
                });
                if (!this._anonymousPersistentState.utms) {
                    const url = new URL(this._location.href);
                    this._anonymousPersistentState.saveUtms({
                        campaign: url.searchParams.get("utm_campaign") || undefined,
                        medium: url.searchParams.get("utm_medium") || undefined,
                        source: url.searchParams.get("utm_source") || undefined,
                        term: url.searchParams.get("utm_term") || undefined,
                        content: url.searchParams.get("utm_content") || undefined,
                    });
                }

                if (this._anonymousPersistentState.email) {
                    this.identify(this._anonymousPersistentState.email);
                }
            })

            //lazy load hotjar when they scroll
            if (environment.production) {
                const listener = () => {
                    if (!window.hotjarIntegration) {
                        this.loadHotjar();
                    }
                    window.removeEventListener("scroll", listener);
                };
                window.addEventListener("scroll", listener);
            }
        }
    }


    trackPageView(category: string = "", name: string = "", properties?: object) {
        if (this._platformService.isBrowser()) {
            this.zone.runOutsideAngular(() => {
                try {
                    //@ts-ignore
                    window['analytics'].ready(() => {
                        //@ts-ignore
                        window['analytics'].page(category, name, properties, {
                            anonymousId: this._anonymousPersistentState.anonId
                        });
                    });
                } catch (_) { }
            });
        }
    }

    /*
        identify and trackEvent go through the server-side event-bridge
    */

    async identify(email: string, traits: UserTraits = {}) {
        if (!this._platformService.isBrowser()) return;
        if (!email) return;
        const res = await firstValueFrom(this.http.post<{email: string}>(`${this.apiUrl}/i`, {
            anonymousId: this._anonymousPersistentState.anonId,
            email,
            traits,
            utms: this._anonymousPersistentState.utms
        }));
        //if email was auto-corrected, save it
        if (res && res.email && this._anonymousPersistentState.email !== res.email) {
            this._anonymousPersistentState.saveEmail(res.email);
        }
    }

    async identifyAndTrack<E extends keyof typeof eventMap>(
        user: {email: string, traits?: UserTraits},
        input: {event: E, params?: Parameters<(typeof eventMap)[E]["make"]>[0]}
    ) {
        if (!this._platformService.isBrowser()) return;
        if (!user.email) return;
        if (!VALID_EVENTS.includes(input.event)) {
            throw new Error("Unknown event");
        }
        //@ts-ignore
        const payload: {properties?: object, actionData?: object} = eventMap[input.event].make.call(null, input.params);
        const res = await firstValueFrom(this.http.post<{email: string}>(`${this.apiUrl}/it`, {
            anonymousId: this._anonymousPersistentState.anonId,
            email: user.email,
            traits: user.traits,
            utms: this._anonymousPersistentState.utms,
            event: input.event,
            properties: payload.properties,
            actionData: payload.actionData
        }));
        //if email was auto-corrected, save it
        if (res && res.email && this._anonymousPersistentState.email !== res.email) {
            this._anonymousPersistentState.saveEmail(res.email);
        }
    }

    async track<E extends keyof typeof eventMap>(input: {event: E, params?: Parameters<(typeof eventMap)[E]["make"]>[0]}) {
        if (!this._platformService.isBrowser()) return;
        if (!VALID_EVENTS.includes(input.event)) {
            throw new Error("Unknown event");
        }

        //@ts-ignore
        const payload: {properties?: object, actionData?: object} = eventMap[input.event].make.call(null, input.params);
        await firstValueFrom(this.http.post(`${this.apiUrl}/t`, {
            anonymousId: this._anonymousPersistentState.anonId,
            email: this._userService.user?.email || this._anonymousPersistentState.email,
            event: input.event,
            properties: payload.properties,
            actionData: payload.actionData
        }));
    }

    trackClientOnly<E extends keyof typeof eventMap>(input: {event: E, params?: Parameters<(typeof eventMap)[E]["make"]>[0]})  {
        if (this._platformService.isBrowser()) {
            if (!VALID_EVENTS.includes(input.event)) {
                throw new Error("Unknown event");
            }
            this.zone.runOutsideAngular(() => {
                //@ts-ignore
                const payload: {properties?: object} = eventMap[input.event].make.call(null, input.params);
                try {
                    //@ts-ignore
                    window['analytics'].ready(() => {
                        //@ts-ignore
                        window['analytics'].track(input.event, payload.properties, {
                            anonymousId: this._anonymousPersistentState.anonId
                        });
                    });
                } catch (_) {}
            });
        }
    }

    loadIntercom() {
        if (this._platformService.isBrowser()) {
            window.intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: "cl6vzqrb"
            };
            const snippet = `(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/cl6vzqrb'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();`;
            let script = document.createElement('script');
            script.innerHTML = snippet;
            document.body.appendChild(script);
        }
    }

    loadTidio() {
        if (this._platformService.isBrowser()) {
            if (!document.body.querySelector("#tidio-load")) {
                const script = document.createElement("script");
                script.src = environment.tidio.scriptUrl;
                script.async = true;
                script.id = "tidio-load";
                document.body.appendChild(script);
            }
        }
    }

    loadHotjar() {
        const snippet = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3883190,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

        let script = document.createElement('script');
        script.innerHTML = snippet;
        document.body.appendChild(script);
    }
}