import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryVideo } from '@cloudinary/url-gen';

@Pipe({
    name: 'cloudinaryThumb',
    pure: true
})

export class cloudinaryThumb implements PipeTransform {
    transform(value: CloudinaryVideo | string): string {
        if (typeof value === "string") {
            return value.replace("/upload/", "/upload/f_jpg/");
        } else {
            return value.toURL().replace("/upload/", "/upload/f_jpg/");
        }
    }
}