import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentCardComponent  {
    @Input() title: string = "";
    @Input() subtitle: string = "";
    @Input() image: string | undefined = "";
    @Input() video: string | undefined = "";
    @Input() url: string | string[] = [];
    @Input() locked?: boolean;
    @Input() badge?:any;
    @Input() hideSubtitle: boolean = false;
    @Input() template: "default" | "bubbles" = "default";
    @Input() onClick?: () => void;
    @Input() externalLink = false;
    @Input() lazy: boolean = false;
    @Input() bottomBadge?: string;


    hovering: boolean = false;

    @HostListener("mouseenter")
    onMouseEnter() {
        this.hovering = true;
    }

    @HostListener("mouseleave")
    onMouseLeave() {
        this.hovering = false;
    }

    cardClicked() {
        if (this.onClick) {
            this.onClick();
        }
    }
}
