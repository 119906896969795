<ng-container *ngIf="readable">
    <ng-content></ng-content>
</ng-container>

<ng-template #readerModal let-modal>
    <ng-container *ngIf="readable">
        <div class="modal-header reader-modal-header c-px-20 c-px-md-40 c-pb-20" *ngIf="!locked">
            <div class="container d-flex justify-content-between align-items-center gap-3 c-px-0">
                <feather-icon width="30" height="30" class="cursor-pointer" icon="arrow-left-circle" (click)="modal.dismiss('Cross click')" aria-label="Close"></feather-icon>
                <h3 class="modal-title flex-grow-1 text-center mb-0">{{readable.title}}</h3>
                <feather-icon width="30" height="30" class="cursor-pointer" icon="list" (click)="openSidebar('show-toc')" *ngIf="readable.toc.length > 0"></feather-icon>
                <span class="cursor-pointer font-change-icon" (click)="openSidebar('change-font-size')">Aa</span>
            </div>
        </div>
        <div class="modal-body reader-modal-body reader-outer-container c-px-20 c-px-md-40 c-pt-10" (scroll)="closeSidebar()" *ngIf="!locked">
            <div class="container reader-inner-container c-px-0" [style]="floatingContent ? 'margin-bottom: 100px' : ''">
                <div class="alert alert-success after-unlock-alert text-start" role="alert" *ngIf="showAfterJoinAlert">
                    <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                    <span class="fw-bold">You are in!</span>
                    <br><br>
                    <p class="mb-0">
                        You’ve just joined the author's Book Crew. <span class="fw-bold">All exclusive content is now unlocked</span>.
                    </p>
                </div>
                <readables-reader [jumps]="jumps.asObservable()" (click)="closeSidebar()" [readable]="readable" [start]="start" [readerRootSteps]="3" [ngStyle]="{'font-size.rem': fontSize}"></readables-reader>
                <div class="reader-sidebar p-4" *ngIf="showSidebar" [class]="slideSidebar ? 'sidebar-open' : 'sidebar-close'" style="--header-height: {{headerHeight}}; --scroll-top: {{contentScolled}}">
                    <div *ngIf="sidebarView === 'show-toc'" class="sidebar-content">
                        <h6>Table of contents</h6>
                        <ul>
                            <li *ngFor="let entry of readable.toc" (click)="jumpTo(entry.pointer)" class="cursor-pointer pb-2">
                                {{entry.title}}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="sidebarView === 'change-font-size'" class="sidebar-content">
                        <h6>Font size</h6>
                        <input type="range" class="form-range" [min]="0.5" [max]="1.5" step="0.1" [value]="fontSize" (input)="fontSizeChange($event)">
                    </div>
                </div>
                <div class="reader-floating-area" *ngIf="floatingContent">
                    <ng-container [ngTemplateOutlet]="floatingContent"></ng-container>
                </div>
            </div>
        </div>
        <div class="modal-header reader-modal-header c-px-20 c-px-md-40" *ngIf="locked">
            <div class="container d-flex justify-content-between align-items-center gap-3 c-px-0">
                <feather-icon width="30" height="30" class="cursor-pointer" icon="arrow-left-circle" (click)="modal.dismiss('Cross click')" aria-label="Close"></feather-icon>
                <h3 class="modal-title flex-grow-1 text-center mb-0" style="margin-left: -30px;">{{readable.title}}</h3>
            </div>
        </div>
        <div class="modal-body intro-modal-body c-px-20 c-px-md-40 c-pt-10" *ngIf="locked">
            <div class="container c-px-0">
                <ng-container *ngIf="isLoggedIn$ | async">
                    <div class="c-pb-20">
                        <join-crew-form [author]="readable.author!" [afterJoinCb]="afterJoinCb" [settings]="crewFormSettings" [eventMetadata]="joinCrewEventMetadata" [compact]="true"></join-crew-form>
                    </div>
                </ng-container>
                <ng-container *ngIf="!(isLoggedIn$ | async)">
                    <div class="d-flex d-flex flex-column align-items-center c-pb-20">
                        <div class="auth-container">
                            <passwordless-auth
                                [skipOnboarding]="true"
                                [inline]="true"
                                [signupReason]="signupReason"
                                sendStageTitle="Sign-in or register for free to unlock exclusive content"
                                action="read"
                                [actionParams]="{read: readable.slug}"
                            ></passwordless-auth>
                        </div>
                    </div>
                </ng-container>
                <!-- <readables-reader-intro
                    class="intro-fade-out-text" [ngClass]="{'mobile': mobile | async}"
                    [readable]="readable"
                    [keepHeaders]="true"
                    [keepExtraWhiteSpace]="true">
                </readables-reader-intro>  -->
            </div>
        </div>
    </ng-container>
</ng-template>
