import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { JsonLdService } from './json-ld.service';
import { TitleService } from './title.service';
import { Book } from '../models/book.model';
import { Comment } from '../models/comment.model';
import { UtilitiesService } from './utilities.service';
import * as dayjs from "dayjs";
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BookSeoService {
    constructor(
        private readonly _platformService: PlatformService,
        private readonly _jsonLdService: JsonLdService,
        private readonly _titleService: TitleService,
        private readonly _utilitiesService: UtilitiesService
    ) { }

    update(book: Book, comments: Comment[] | undefined = undefined) {
        this._titleService.setTitle(book.title);
        this._platformService.metaTags.setTitle(book.title);
        this._platformService.metaTags.setOpenGraphImage(book.images.cover);

        book.description = book.description || "";
        this._platformService.metaTags.setDescription(book.description);
        
        if (comments && comments.length) {
            comments = this.flattenComments(comments);
        }
        this._jsonLdService.addWebPage({
            name: book.title,
            description: book.description,
            url: environment.baseUrl + this._utilitiesService.getBookUrl(book),
        }, comments?.map(comment => {
            return {
                "@id": `${comment.id}`,
                author: {
                    name: comment.username,
                    image: this._utilitiesService.pidToProfilePictureUrl(comment.pid, true),
                },
                datePublished: dayjs(comment.createdAt).format("YYYY-MM-DD HH:mm"),
                text: comment.body,
            }
        }));
        this._jsonLdService.addBook({
            author: book.mainAuthorName,
            name: book.title,
            url: environment.baseUrl + this._utilitiesService.getBookUrl(book),
            description: book.description,
            image: book.images.cover,
            isbn: book.isbns[0],
            publisher: book.publisher!,
            datePublished: book.publishedAt!,
            numberOfPages: book.pageCount,
            sameAs: []
        });
    }

    private flattenComments(comments: Comment[]): Comment[] {
        let result: Comment[] = [];
        comments.forEach(comment => {
            result.push(comment);
            if (comment.comments) {
                result = result.concat(this.flattenComments(comment.comments));
            }
        });
        return result;
    }

}