<ng-container *ngIf="data && data.media">
    <div class="row c-pb-20">
        <ng-container *ngIf="data.enableAnchor">
            <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
        </ng-container>
        <ng-container *ngIf="!data.enableAnchor">
            <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
        </ng-container>
    </div>
    <div class="row vertical-collection">
        <ng-container *ngFor="let media of data.media">
            <ng-container *ngIf="media.channel === 'image' || media.channel === 'manual_image'">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                    <content-card [title]="media.data.title" [image]="media.data.url" [locked]="locked(media)" (click)="imageModalOpen(imageModal, media)"></content-card>
                </div>
            </ng-container>
            <ng-container *ngIf="media.channel === 'manual_video'">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                    <content-card  [title]="media.data.title" [video]="media.data.url" [locked]="locked(media)" (click)="videoModalOpen(videoModal, media)"></content-card>
                </div>
            </ng-container>
            <ng-container *ngIf="media.channel === 'server_readable'">
                <div class="col-12 col-sm-12 col-md-8 col-lg-6">
                    <readables-reader-intro-card [readable]="media.data.readable" [locked]="locked(media)"></readables-reader-intro-card>
                </div>
            </ng-container>
            <ng-container *ngIf="media.channel === 'article'">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="card slight-hover cover" style="--cover-color: {{media.data.imageUrl | cloudinaryPublicId | cloudinaryReadableColor | async}}">
                        <ng-container *ngIf="!media.data.url.startsWith('https://crewfiction.com') || media.data.url.startsWith('https://crewfiction.com/blog'); else internalLink">
                            <a [href]="media.data.url" target="_blank">
                                <img
                                    [src]="media.data.imageUrl"
                                    class="card-img-top img-fluid"
                                    [alt]="media.data.title"
                                />
                            </a>
                        </ng-container>
                        <ng-template #internalLink>
                            <a [routerLink]="media.data.url.replace('https://crewfiction.com', '')">
                                <img
                                    [src]="media.data.imageUrl"
                                    class="card-img-top img-fluid"
                                    [alt]="media.data.title"
                                />
                            </a>
                        </ng-template>
                        <div class="card-body static-size flex-column" (click)="openArticle(media)">
                            <h6 class="card-title">
                                {{ media.data.title.replace(" • Crewfiction", "").replace(" • crewfiction", "") }}
                            </h6>
                            <!-- <p class="card-text text-truncate">
                                {{ media.data.description }}
                            </p> -->
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="row c-pt-40" *ngIf="showCta">
        <div class="d-flex justify-content-center">
            <a *ngIf="ctaLinkExternal" [href]="data.ctaUrl" target="_blank" class="d-inline-block btn btn-cta no-decoration slight-hover-no-bg">
                {{data.ctaText}}
            </a>
            <a *ngIf="!ctaLinkExternal" [routerLink]="data.ctaUrl" class="d-inline-block btn btn-cta no-decoration slight-hover-no-bg">
                {{data.ctaText}}
            </a>
        </div>
    </div>
</ng-container>

<ng-template #imageModal let-modal>
    <div class="modal-header c-px-20">
        <h4 class="modal-title"></h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body image-modal-body c-px-20 c-pt-10">
        <div *ngIf="activeMedia && (activeMedia.channel === 'image' || activeMedia.channel === 'manual_image')">
            <ng-container *ngIf="locked(activeMedia)">
                <ng-container *ngIf="isLoggedIn$ | async">
                    <div class="c-pb-30">
                        <join-crew-form [author]="data.author!" [afterJoinCb]="afterJoinCb" [settings]="crewFormSettings" [eventMetadata]="joinCrewEventMetadata" [compact]="true"></join-crew-form>
                    </div>
                </ng-container>
                <ng-container *ngIf="!(isLoggedIn$ | async)">
                    <div class="d-flex d-flex flex-column align-items-center c-pb-30">
                        <div class="auth-container">
                            <passwordless-auth [skipOnboarding]="true" [inline]="true" [signupReason]="signupReason" sendStageTitle="Sign-in or register for free to unlock exclusive content"></passwordless-auth>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="d-flex flex-column" [ngClass]="{'exclusive': locked(activeMedia)}">
                <div class="alert alert-success after-unlock-alert text-start" role="alert" *ngIf="showAfterJoinAlert">
                    <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                    <span class="fw-bold">You are in!</span>
                    <br><br>
                    <p class="mb-0">
                        You’ve just joined the author's Book Crew. <span class="fw-bold">All exclusive content is now unlocked</span>.
                    </p>
                </div>
                <img
                    [src]="activeMedia.data.url"
                    [alt]="activeMedia.data.title"
                    class="flex-grow-1 media"
                />
                <h5 class="pt-2 text-start">
                    {{ activeMedia.data.title }}
                </h5>
                <p class="text-start">
                    {{ activeMedia.data.description }}
                </p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #videoModal let-modal>
    <div class="modal-header c-px-20">
        <h4 class="modal-title"></h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body video-modal-body c-px-20 c-pt-10">
        <div *ngIf="activeMedia && activeMedia.channel === 'manual_video'" class="d-flex flex-column">
            <ng-container *ngIf="locked(activeMedia)">
                <ng-container *ngIf="isLoggedIn$ | async">
                    <div class="c-pb-30">
                        <join-crew-form [author]="data.author!" [afterJoinCb]="afterJoinCb" [settings]="crewFormSettings" [eventMetadata]="joinCrewEventMetadata" [compact]="true"></join-crew-form>
                    </div>
                </ng-container>
                <ng-container *ngIf="!(isLoggedIn$ | async)">
                    <div class="d-flex d-flex flex-column align-items-center c-pb-30">
                        <div class="auth-container">
                            <passwordless-auth [skipOnboarding]="true" [inline]="true" [signupReason]="signupReason" sendStageTitle="Sign-in or register for free to unlock exclusive content"></passwordless-auth>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="d-flex flex-column" [ngClass]="{'exclusive': locked(activeMedia)}">
                <div class="alert alert-success after-unlock-alert text-start" role="alert" *ngIf="showAfterJoinAlert">
                    <feather-icon icon="check-circle" class="me-2 fw-bold"></feather-icon>
                    <span class="fw-bold">You are in!</span>
                    <br><br>
                    <p class="mb-0">
                        You’ve just joined the author's Book Crew. <span class="fw-bold">All exclusive content is now unlocked</span>.
                    </p>
                </div>
                <video *ngIf="!locked(activeMedia)" controls autoplay playsinline class="media"> 
                    <source [src]="activeMedia.data.url" />
                </video>
                <img
                    *ngIf="locked(activeMedia)"
                    [src]="activeMedia.data.url | cloudinaryThumb"
                    [alt]="activeMedia.data.title"
                    class="flex-grow-1 media"
                />
                <h5 class="pt-2 text-start">
                    {{ activeMedia.data.title }}
                </h5>
                <p class="text-start">
                    {{ activeMedia.data.description }}
                </p>
            </div>
        </div>
    </div>
</ng-template>
