<div class="d-flex flex-column justify-content-between px-4 pt-4 pb-2">

    <div class="header no-select mb-3">
        <h1 class="title">
            You’re in! 🎉
        </h1>
    </div>
    <div class="description no-select mb-4">
        <span class="d-block mb-3">
            Welcome to crewfiction!
        </span>
        <p>
            We generated a username for you. You can update it here. This will be your profile's public name.
        </p>
    </div>
    <div class="username-form my-2">
        <form [formGroup]="form" (ngSubmit)="continue()" class="d-flex flex-column">
            <div class="form-group my-1">
                <input name="username" type="text" formControlName="username" class="form-control dark-input rounded-input w-100">
                <div *ngIf="usernameFormControl.dirty && usernameFormControl.invalid" class="text-danger">
                    <small>
                        {{usernameErrors && usernameErrors.length ? usernameErrors[0] : ''}}
                    </small>
                </div>
            </div>
            <div class="form-group d-grid gap-2 mt-2 mb-3">
                <button class="btn btn-dark btn-round w-100" type="submit" [disabled]="loading">
                    <span class="spinner-border spinner-border-sm c-me-5" role="status" aria-hidden="true" *ngIf="loading"></span>
                    Update username & continue
                </button>
            </div>
        </form>

    </div>
    <div class="terms d-flex justify-content-center mt-5">
        <small class="text-center footer-text">
            You can change your username at any time from the user profile settings.
        </small>
    </div>


</div>