import { Component, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Book } from 'src/app/models/book.model';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { PlatformService } from 'src/app/services/platform.service';
import * as dayjs from "dayjs";

@Component({
    selector: 'book-header-container',
    templateUrl: './book-header-container.component.html',
    styleUrls: ['./book-header-container.component.scss'],
})
export class BookHeaderContainer {
    @Input() book!: Book;

    @Input() headerText!: string;

    @Input() enableBreadcrumbs = true;

    @Input()
    showHeader: boolean = true;

    @Input()
    enableRating: boolean = false;

    @Input()
    showSpicyMeter: boolean = true;

    @Input()
    enableCountDown: boolean = false;

    @Input()
    showPriceCut: boolean = false;

    @Input()
    coverBadgeOverwrite?: string;

    @Input()
    content!: TemplateRef<HTMLDivElement>;

    @Input()
    altCoverIndex?: string;

    get coverSrc(): string {
        try {
            let src;
            if (this.altCoverIndex !== undefined && Number(this.altCoverIndex) >= 0 && this.book.images.altCovers?.length) {
                src = this.book.images.altCovers[Number(this.altCoverIndex)];
            }
            else if (
                this._anonymousState.altMetadata[this.book!.slug] &&
                this._anonymousState.altMetadata[this.book!.slug].altCoverIndex != undefined &&
                this.book.images.altCovers && this.book.images.altCovers[this._anonymousState.altMetadata[this.book!.slug].altCoverIndex]
            ) {
                src = this.book.images.altCovers[this._anonymousState.altMetadata[this.book!.slug].altCoverIndex];
            }
            else {
                src = this.book.images.cover;
            }
            //only overwrite it on normal book pages, not landing pages
            if (!this._router.url.match("/pages/")) {
                this._platformService.metaTags.setOpenGraphImage(src);
            }
            return src;
        }
        catch(e) {
            console.warn("Error in book cover source", e);
            return this.book.images.cover;
        }
    }

    get enableSpicyMeter() {
        return this.showSpicyMeter &&
            this.book.spicyMeter &&
            this.book.spicyMeter >= 1 &&
            this.book.spicyMeter <= 5;
    }

    get header() {
        if (this.showHeader) {
            return this.headerText || this.book.subtitle ||
                "Subscribe and get exclusive content and bonus scenes, free books, advanced reader copies and more";
        } else {
            return "";
        }
    }

    get releaseBadge() {
        if (this.coverBadgeOverwrite) {
            return this.coverBadgeOverwrite;
        }
        if (this.book.isFree) {
            return "Free";
        }
        if (this.book.isPrelaunched) {
            return "Coming Soon";
        }
        if (!this.book.publishedAt) return;
        if (this.book.isPrelaunched || this.book.isFree) return;
        if (dayjs(this.book.publishedAt).isAfter(dayjs().subtract(7, "days"))) {
            return "New Release";
        } else if ((dayjs(this.book.publishedAt).isAfter(dayjs().subtract(14, "days")))) {
            return "Recent Release";
        }
        return;
    }

    get showCountDown() {
        if (this.enableCountDown && this.book.publishedAt && dayjs(this.book.publishedAt).isAfter(dayjs())) {
            return true;
        } else {
            return false;
        }
    }

    constructor(
        private _platformService: PlatformService,
        private _anonymousState: AnonymousPersistentState,
        private _router: Router
    ) {}
}
