import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Book } from "src/app/models/book.model";

@Component({
  selector: 'book-cover',
  templateUrl: './book-cover.component.html',
  styleUrls: ['./book-cover.component.scss']
})
export class BookCoverComponent implements OnInit {
  @Input()
  book: Book | undefined;

  @Input()
  _src: string = '';

  public get src(): string {
    return this._src || this.bookCoverUrl;
  }
  public set src(value: string) {
    this._src = value;
  }

  @Input()
  height: number = 140;

  @Input()
  imageAspectRatio: number = 0.68;

  @ViewChild('imgRef') imgRef!: ElementRef<HTMLImageElement>;

  ngOnInit() {
    if (this.imgRef && this.imgRef.nativeElement.complete) {
      this.handleLoad();
    }
  }

  handleLoad() {
    this.imageAspectRatio = this.imgRef.nativeElement.naturalWidth / this.imgRef.nativeElement.naturalHeight;
    this.imgRef.nativeElement.addEventListener('load', () => {
      this.imageAspectRatio = this.imgRef.nativeElement.naturalWidth / this.imgRef.nativeElement.naturalHeight;
    });
  }

  get bookCoverUrl(): string {
    // @ts-ignore
    return this.book?.images?.cover;
  }
}
