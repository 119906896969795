<ng-container *ngIf="data.reviews">
    <div *ngIf="!singleBook" class="row c-pb-40">
        <ng-container *ngIf="data.enableAnchor">
            <anchor-header [header]="data.header" [anchorSlug]="data.anchorSlug"></anchor-header>
        </ng-container>
        <ng-container *ngIf="!data.enableAnchor">
            <h2 class="mb-0 worldmaker-section-title">{{data.header}}</h2>
        </ng-container>
    </div>
    <reviews-list [title]="data.header" [book]="singleBook" [linksEnabled]="linksEnabled" [items]="data.reviews"></reviews-list>
</ng-container>
