import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { JumbotronConfig } from 'src/app/models/jumbotron-config.model';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'jumbotron-carousel',
  templateUrl: './jumbotron-carousel.component.html',
  styleUrls: ['./jumbotron-carousel.component.scss']
})
export class JumbotronCarouselComponent implements AfterViewInit, OnDestroy {
    
    @Input()
    data: JumbotronConfig[] = [];

    @Input()
    interval: number = 5000;

    private _t: any;

    currentIndex: number = 0;

    constructor(private _platformService: PlatformService) {}

    ngAfterViewInit() {
        this.restart();
    }

    private restart() {
        if (this._platformService.isBrowser() && this.interval > 0) {
            if (this._t) {
                clearInterval(this._t);
            }
            this._t = setInterval(() => {
                this.next();
            }, this.interval);
        }
    }

    ngOnDestroy(): void {
        if (this._t) {
            clearInterval(this._t);
        }
    }

    next() {
        this.currentIndex = (this.currentIndex + 1) % this.data.length;
        this.restart();
    }

    prev() {
        this.currentIndex = (this.currentIndex - 1 + this.data.length) % this.data.length;
        this.restart();
    }
}
