import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Book } from '../models/book.model';
import { Author } from '../models/author.model';
import { World } from '../models/world.model';
import { JoinCrewEventMetadata } from './analytics/events/joinCrewEventMetadata';
import { FollowAuthorEventMetadata } from './analytics/events/followAuthorEventMetadata';

@Injectable({
    providedIn: 'root'
})
export class FollowersService {
    private baseUrl: string = environment.baseUrl + '/api/followers';

    stateChange$: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    followBook(bookId: number): Promise<any> {
        let res = firstValueFrom(this.http.post<any>(`${this.baseUrl}/books/${bookId}`, {}));
        this.stateChange$.next();
        return res;
    }

    unfollowBook(bookId: number): Promise<void> {
        let res = firstValueFrom(this.http.delete<void>(`${this.baseUrl}/books/${bookId}`));
        this.stateChange$.next();
        return res;
    }

    followAuthor(authorId: number, eventMetadata: FollowAuthorEventMetadata): Promise<any> {
        let res = firstValueFrom(this.http.post<any>(`${this.baseUrl}/authors/${authorId}`, {eventMetadata}));
        this.stateChange$.next();
        return res;
    }

    unfollowAuthor(authorId: number): Promise<void> {
        let res = firstValueFrom(this.http.delete<void>(`${this.baseUrl}/authors/${authorId}`));
        this.stateChange$.next();
        return res;
    }

    followWorld(worldId: number): Promise<any> {
        let res = firstValueFrom(this.http.post<any>(`${this.baseUrl}/worlds/${worldId}`, {}));
        this.stateChange$.next();
        return res;
    }

    unfollowWorld(worldId: number): Promise<void> {
        let res = firstValueFrom(this.http.delete<void>(`${this.baseUrl}/worlds/${worldId}`));
        this.stateChange$.next();
        return res;
    }

    joinCrew(authorId: number, eventMetadata: JoinCrewEventMetadata): Promise<any> {
        let res = firstValueFrom(this.http.post<any>(`${this.baseUrl}/crews/${authorId}`, {eventMetadata}));
        this.stateChange$.next();
        return res;
    }

    leaveCrew(authorId: number): Promise<void> {
        let res = firstValueFrom(this.http.delete<void>(`${this.baseUrl}/crews/${authorId}`));
        this.stateChange$.next();
        return res;
    }

    getFollowingBooks(): Promise<Book[]> {
        return firstValueFrom(this.http.get<Book[]>(`${this.baseUrl}/books`));
    }

    getFollowingAuthors(): Promise<Author[]> {
        return firstValueFrom(this.http.get<Author[]>(`${this.baseUrl}/authors`));
    }

    getFollowingWorlds(): Promise<World[]> {
        return firstValueFrom(this.http.get<World[]>(`${this.baseUrl}/worlds`));
    }

    getJoinedCrews(): Promise<Author[]> {
        return firstValueFrom(this.http.get<Author[]>(`${this.baseUrl}/crews`));
    }

    getBookFollowerCount(bookId: number): Promise<number> {
        return firstValueFrom(this.http.get<number>(`${this.baseUrl}/books/count/${bookId}`));
    }

    getAuthorFollowingCount(authorId: number): Promise<number> {
        return firstValueFrom(this.http.get<number>(`${this.baseUrl}/authors/count/${authorId}`));
    }

    getWorldFollowingCount(worldId: number): Promise<number> {
        return firstValueFrom(this.http.get<number>(`${this.baseUrl}/worlds/count/${worldId}`));
    }

    getCrewMembersCount(authorId: number): Promise<number> {
        return firstValueFrom(this.http.get<number>(`${this.baseUrl}/crews/count/${authorId}`));
    }
}
