import { Component, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { firstValueFrom, from, Observable, of, Subject, timer } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { ReadablesService } from '../../services/readables.service';
import { DOMSerializer } from 'prosemirror-model';
import schema from "../../editorSchema";
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Readable } from 'src/app/models/readable.model';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { LibraryService } from 'src/app/services/library.service';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { BooksService } from 'src/app/services/books.service';

@Component({
    selector: 'readables-reader',
    templateUrl: './reader.component.html',
    styleUrls: ['./reader.component.scss'],
})
export class ReadablesReader implements AfterViewInit, OnDestroy {
    @Input()
    readable!: Readable;

    @Input()
    start = 0;

    @Input()
    readerRootSteps = 1;

    @Input()
    jumps!: Observable<number>;

    @Input()
    locked?: Observable<boolean | null>;

    private _bookmarkInterval = 5000;
    private _lastSyncedBookMark = 0;

    private readonly fetchLimit = 100; //TODO should be ENV (same for server+client)
    private scrollObserver!: IntersectionObserver;
    private chunksLoaded: number[] = [];
    private totalChunks = 0;
    private jumping = false;
    private setBookmarkInterval: NodeJS.Timeout | null = null;

    previousLoading = false;
    nextLoading = false;

    @ViewChild("content") protected content!: ElementRef<HTMLDivElement>;
    @ViewChild("previousMarker") protected previousMarker!: ElementRef<HTMLDivElement>;
    @ViewChild("nextMarker") protected nextMarker!: ElementRef<HTMLDivElement>;

    protected debug = false; //shows chunk numbers

    constructor(
        private readonly _platformService: PlatformService,
        private readonly _readablesService: ReadablesService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _libraryService: LibraryService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _booksService: BooksService
    ) {
        
    }

    async ngAfterViewInit() {
        if (!this._platformService.isBrowser()) return;
        //the scrollable root might not always be the parent, so we keep going up based on readerRootSteps
        let readerRoot = this.content.nativeElement.parentElement;
        if (!readerRoot) return;
        for (let i = 1; i < this.readerRootSteps; i++) {
            if (readerRoot.parentElement) {
                readerRoot = readerRoot.parentElement;
            }
        }

        //set initial markers
        this.nextMarker.nativeElement.dataset["marker"] = this.start.toString();
        if (this.start > 0) {
            this.previousMarker.nativeElement.dataset["marker"] = (this.start - this.fetchLimit).toString();
        } else {
            this.previousMarker.nativeElement.dataset["marker"] = "0";
        }

        //restore their progress or start from the beginning
        const bookmark = this.getBookmark();
        if (bookmark >= 20) { //start from the beginning if they didn't move past chunk 20
            await this.jump(bookmark);
        } else {
            //pre-load the first 2 chunks
            await this.loadNext();
            await this.loadNext();
        }

        //start the observer
        const options = {
            root: readerRoot,
            rootMargin: "0px",
            threshold: 1,
        };
        this.scrollObserver = new IntersectionObserver(async (entries) => {
            if (this.jumping) return;
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    if (entry.target === this.nextMarker.nativeElement) {
                        this.nextLoading = true
                        await this.loadNext();
                        this.nextLoading = false;
                    }
                    if (entry.target === this.previousMarker.nativeElement) {
                        this.previousLoading = true;
                        await this.loadPrevious();
                        this.previousLoading = false;
                    }
                }
            }
        }, options);
        this.scrollObserver.observe(this.previousMarker.nativeElement);
        this.scrollObserver.observe(this.nextMarker.nativeElement);  
        this.scrollObserver.observe(this.content.nativeElement);

        //listen for jumps
        this.jumps.subscribe(this.jump.bind(this));
        
        //save progress on an interval
        //only if it is a free book or arc, bonus_scene and sneak_peek are usually very short
        if (this.readable.settings.type === "free_book" || this.readable.settings.type === "arc") {
            this.setBookmarkInterval = setInterval(() => {
                const topVisibleElement = Array.from(document.querySelectorAll(".reader [data-chunk]")).map(d => {
                    const top = d.getBoundingClientRect().top;
                    const chunk = d.getAttribute("data-chunk");
                    return {
                        top, chunk
                    }
                }).filter(d => d.top >= 200)[0];
                if (topVisibleElement && topVisibleElement.chunk) {
                    this.setBookmark(parseInt(topVisibleElement.chunk));
                }
            }, this._bookmarkInterval);
        }

        //listen for locked readable change and setup fade-out/hide and show
        if (this.locked) {
            const chunks = Array.from(document.querySelectorAll(".reader [data-chunk]")) as HTMLElement[];
            let limits = [800, 1300];
            if (await firstValueFrom(this._platformService.isMobile)) {
                limits = [1500, 2000];
            }
            this.locked.subscribe(locked => {
                if (locked === true) {
                    let lengthSoFar = 0;
                    let chunkBlurStart = 0;
                    let chunkBlurEnd = 0;
                    for (const el of chunks) {
                        const chunk = parseInt(el.getAttribute("data-chunk")!);
                        lengthSoFar += el.innerText.length;
                        if (lengthSoFar > limits[0] && lengthSoFar < limits[1]) {
                            if (chunkBlurStart) {
                                continue;
                            } else {
                                chunkBlurStart = chunk;
                            }
                        } else if (lengthSoFar >= limits[1]) {
                            chunkBlurEnd = chunk;
                            break;
                        }
                    }
                    const toChange = (chunkBlurEnd - chunkBlurStart) > 4 ? 4 : chunkBlurEnd - chunkBlurStart;
                    let applied = toChange;
                    for (const el of chunks) {
                        const chunk = parseInt(el.getAttribute("data-chunk")!);
                        if (chunk >= chunkBlurStart && chunk < chunkBlurStart + toChange ) {
                            el.style.setProperty("opacity", `${(toChange) * 20 - ((toChange - applied) * 20)}%`);
                            applied -= 1;
                        } else if (chunk >= chunkBlurStart + toChange) {
                            el.style.setProperty("display", "none");
                        }
                    }
                } else if (locked === false) {
                    for (const el of chunks) {
                        el.style.setProperty("opacity", "unset");
                        el.style.setProperty("display", "block");
                    }
                }
            });
        }
    }

    async jump(chunk: number) {
        this.jumping = true;
        const neededMarker = chunk - (chunk % this.fetchLimit);
        const nextMarker = parseInt(this.nextMarker.nativeElement.dataset["marker"]!);
        const previousMarker = parseInt(this.previousMarker.nativeElement.dataset["marker"]!);
        if (neededMarker === previousMarker) {
            await this.loadPrevious(chunk);
        } else if (neededMarker === nextMarker) {
            await this.loadNext(chunk);
        } else if (neededMarker < previousMarker) {
            this.previousMarker.nativeElement.dataset["marker"] = neededMarker.toString();
            this.nextMarker.nativeElement.dataset["marker"] = (neededMarker + this.fetchLimit).toString();
            await this.loadPrevious(chunk);
        } else if (neededMarker > nextMarker) {
            this.nextMarker.nativeElement.dataset["marker"] = neededMarker.toString();
            this.previousMarker.nativeElement.dataset["marker"] = (neededMarker - this.fetchLimit >= 0 ? neededMarker - this.fetchLimit : 0).toString();
            await this.loadNext(chunk);
        }
        //if the jump chunk is not a header, try to find a header close by and jump to that instead
        let myChunk = document.querySelector(`[data-chunk="${chunk}"]`);
        if (myChunk?.tagName !== "H1" && myChunk?.tagName !== "H2") {
            for (let i = chunk - 10; i < chunk; i++) {
                const thisChunk = document.querySelector(`[data-chunk="${i}"]`);
                if (thisChunk?.tagName === "H1" || thisChunk?.tagName === "H2") {
                    myChunk = thisChunk;
                }
            }
        }
        myChunk?.scrollIntoView();
        //pre-load 2 next + previous chunks after the jump
        await this.loadNext();
        await this.loadNext();
        await this.loadPrevious();
        await this.loadPrevious();
        setTimeout(() => {
            this.jumping = false;
        }, 300);
    }

    ngOnDestroy() {
        if (!this._platformService.isBrowser()) return;
        this.scrollObserver.disconnect();
        if (this.setBookmarkInterval) {
            clearTimeout(this.setBookmarkInterval);
        }
    }

    private async loadNext(jump?: number) {
        const marker = parseInt(this.nextMarker.nativeElement.dataset["marker"]!);
        if (this.chunksLoaded.includes(marker)) {
            //just move the marker
            this.nextMarker.nativeElement.dataset["marker"] = (marker + this.fetchLimit).toString();
            const goTo = jump !== undefined ? jump : marker;
            for (const chunk of Array.from<HTMLDivElement>(document.querySelectorAll(`[data-chunk]`)).reverse()) {
                const chunkNum = parseInt(chunk.dataset["chunk"]!);
                if (chunkNum < goTo + this.fetchLimit / 2) {
                    this.content.nativeElement.insertBefore(this.nextMarker.nativeElement, chunk);
                    break;
                }
            }
            //only move the reverse-marker if it's a jump
            if (jump !== undefined) {
                this.previousMarker.nativeElement.dataset["marker"] = (marker - this.fetchLimit).toString();
            }
            const slot = document.querySelector(`[data-chunk="${jump}"]`);
            if (slot) {
                if (jump !== undefined) {
                    this.content.nativeElement.insertBefore(this.previousMarker.nativeElement, slot.nextSibling);
                }
            }
            return;
        }
        //load
        //TODO handle failure here, maybe retry?
        const data = await firstValueFrom(this._readablesService.read(this.readable.slug, marker));
        this.totalChunks = data.total;
        if (data.content.length === 0) {
            return;
        }
        //remove the markers
        // this.content.nativeElement.removeChild(this.nextMarker.nativeElement);
        // this.content.nativeElement.removeChild(this.previousMarker.nativeElement);

        //create the new nodes and give them a chunk number
        const contentNode = schema.nodeFromJSON({type: "doc", content: data.content});
        const html = DOMSerializer.fromSchema(schema).serializeFragment(contentNode.content);
        for (const [i, child] of Array.from(html.children).entries()) {
            (<HTMLElement>child).dataset["chunk"] = (marker + i).toString();
            if (this.debug) {
                const h3 = document.createElement("h3");
                h3.innerText = (marker + i).toString();
                child.appendChild(h3);
            }
        }

        //update the markers and re-insert them in the middle
        html.insertBefore(this.nextMarker.nativeElement, html.children[Math.floor(html.children.length / 2)]);
        this.nextMarker.nativeElement.dataset["marker"] = (marker + this.fetchLimit).toString();
        if (jump !== undefined) {
            html.insertBefore(this.previousMarker.nativeElement, html.children[Math.floor(html.children.length / 2)]);
            this.previousMarker.nativeElement.dataset["marker"] = (marker - this.fetchLimit >= 0 ? marker - this.fetchLimit : 0).toString();
        }

        //insert the new nodes after the previous last chunk
        //TODO does this need the same treatment as loadPrevious ?
        const slot = document.querySelector(`[data-chunk="${marker - 1}"]`);
        if (slot) {
            this.content.nativeElement.insertBefore(html, slot.nextSibling);
        } else {
            this.content.nativeElement.append(html);
        }

        //keep track
        this.keepTrack(marker);
    }

    private async loadPrevious(jump?: number) {
        const marker = parseInt(this.previousMarker.nativeElement.dataset["marker"]!);
        if (this.chunksLoaded.includes(marker)) {
            //just move the marker
            if (marker - this.fetchLimit >= 0) {
                this.previousMarker.nativeElement.dataset["marker"] = (marker - this.fetchLimit).toString();
            } else {
                this.previousMarker.nativeElement.dataset["marker"] = "0";
            }
            const newMarker = parseInt(this.previousMarker.nativeElement.dataset["marker"]!);
            if (marker !== newMarker) {
                for (const chunk of Array.from<HTMLDivElement>(document.querySelectorAll(`[data-chunk]`))) {
                    const chunkNum = parseInt(chunk.dataset["chunk"]!);
                    if (chunkNum > marker) {
                        this.content.nativeElement.insertBefore(this.previousMarker.nativeElement, chunk);
                        break;
                    }
                }
            }
            //only move the reverse-marker if it's a jump
            if (jump !== undefined) {
                this.nextMarker.nativeElement.dataset["marker"] = (marker + this.fetchLimit).toString();
            }
            const slot = document.querySelector(`[data-chunk="${jump}"]`);
            if (slot) {
                if (jump !== undefined) {
                    this.content.nativeElement.insertBefore(this.nextMarker.nativeElement, slot.nextSibling);
                }
            }
            return;
        }
        //load
        //TODO handle failure here, maybe retry?
        const data = await firstValueFrom(this._readablesService.read(this.readable.slug, marker));
        this.totalChunks = data.total;
        //remove the markers
        // this.content.nativeElement.removeChild(this.previousMarker.nativeElement);
        // this.content.nativeElement.removeChild(this.nextMarker.nativeElement);

        //create the new nodes and give them a chunk number
        const contentNode = schema.nodeFromJSON({type: "doc", content: data.content});
        const html = DOMSerializer.fromSchema(schema).serializeFragment(contentNode.content);
        for (const [i, child] of Array.from(html.children).entries()) {
            (<HTMLElement>child).dataset["chunk"] = (marker + i).toString();
            if (this.debug) {
                const h3 = document.createElement("h3");
                h3.innerText = (marker + i).toString();
                child.appendChild(h3);
            }
        }

        //update the markers and re-insert them in the middle
        html.insertBefore(this.previousMarker.nativeElement, html.children[Math.floor(html.children.length / 2)]);
        this.previousMarker.nativeElement.dataset["marker"] = (marker - this.fetchLimit >= 0 ? marker - this.fetchLimit : 0).toString();
        if (jump !== undefined) {
            html.insertBefore(this.nextMarker.nativeElement, html.children[Math.floor(html.children.length / 2)]);
            this.nextMarker.nativeElement.dataset["marker"] = (marker + this.fetchLimit).toString();
        }

        //insert the new nodes after the previous last chunk
        const slot = document.querySelector(`[data-chunk="${marker + this.fetchLimit}"]`);
        if (slot) {
            this.content.nativeElement.insertBefore(html, slot);
        } else {
            //find the first bigger chunk after marker
            let found = false;
            for (const chunk of Array.from<HTMLDivElement>(document.querySelectorAll(`[data-chunk]`))) {
                const chunkNum = parseInt(chunk.dataset["chunk"]!);
                if (chunkNum > marker) {
                    this.content.nativeElement.insertBefore(html, chunk);
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.content.nativeElement.prepend(html);
            }
        }

        //keep track
        this.keepTrack(marker);
    }

    private keepTrack(marker: number) {
        this.chunksLoaded.push(marker);
    }

    private setBookmark(bookmark: number) {
        if (bookmark !== this._lastSyncedBookMark) {
            this._anonymousPersistentState.saveReading(this.readable.slug, {bookmark});
            this.updateReadingProgress(bookmark);
            this._lastSyncedBookMark = bookmark;
        }
    }

    private updateReadingProgress(bookmark: number) {
        if (!this.totalChunks) return;
        //save it
        const newProgress = Math.floor((bookmark / this.totalChunks) * 100);
        this._anonymousPersistentState.saveReading(this.readable.slug, {percent: newProgress});

        //only track once, every 10%
        let milestone = 0;
        if (newProgress >= 10 && newProgress < 20) {
            milestone = 10;
        } else if (newProgress >= 20 && newProgress < 30) {
            milestone = 20;
        } else if (newProgress >= 30 && newProgress < 40) {
            milestone = 30;
        } else if (newProgress >= 40 && newProgress < 50) {
            milestone = 40;
        } else if (newProgress >= 50 && newProgress < 60) {
            milestone = 50;
        } else if (newProgress >= 60 && newProgress < 70) {
            milestone = 60;
        } else if (newProgress >= 70 && newProgress < 80) {
            milestone = 70;
        } else if (newProgress >= 80 && newProgress < 90) {
            milestone = 80;
        } else if (newProgress >= 90 && newProgress < 100) {
            milestone = 90;
        }

        if (milestone &&
            (!this._anonymousPersistentState.reading[this.readable.slug]?.milestones ||
                !this._anonymousPersistentState.reading[this.readable.slug].milestones.includes(milestone))) {
            this._anonymousPersistentState.saveReading(this.readable.slug, {milestone});
            this._analyticsService.track({event: "reading_progress", params: {readable: this.readable, progress: milestone}});
        }

        if (this.readable.settings.type === "free_book") {
            //sync reading progress to user's shelf
            if (this._authService.user) {
                firstValueFrom(this._libraryService.updateFreeBooksReadingProgress({
                    [this.readable.slug]: this._anonymousPersistentState.reading[this.readable.slug]
                }));
            }
        } else if (this.readable.settings.type === "arc") {
            //sync reading progress to the arcContact
            firstValueFrom(this._booksService.updateArcReadingProgress(
                this.readable.book!.slug,
                this._anonymousPersistentState.reading[this.readable.slug],
                this._anonymousPersistentState.email
            ))
        }

    }

    private getBookmark(): number {
        return this._anonymousPersistentState.reading[this.readable.slug]?.bookmark || 0;
    }
}
