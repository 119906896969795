<div *ngIf="data.books" class="book-header-container">

    <!-- UNCOMMENT TO ENABLE BREADCRUMBS -->
    <!-- <div class="breadcrumb-container">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <breadcrumbs></breadcrumbs>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section-container" style="--cover-color: {{data.coverImage.data.url  | cloudinaryPublicId | cloudinaryPrimaryColor | async | transparentize}}">
        <div class="">
            <div class="container-xxl">
                <!-- mobile + md -->
                <div class="row d-block d-lg-none">
                    <div class="col-12 c-px-0 c-mt-lg-40 c-mt-0 book-cover-container d-flex justify-content-center align-items-center position-relative"
                        style="--cover-color: {{data.coverImage.data.url | cloudinaryPublicId | cloudinaryPrimaryColor | async}}">
                        <span class="badge free-collection-badge position-absolute">Free Romance Collection</span>
                        <img preloadImage class="book-cover"
                            [src]="data.coverImage.data.url | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleHeight:170 | cloudinaryUrl"
                            height="170"
                            [alt]="data.title" loading="eager" fetchPriority="high">
                    </div>
                    
                    <div class="col-12 c-pt-10">
                        <h1 class="book-title c-mb-10 c-mb-md-20">{{data.title}}</h1>
                        <h2 class="book-header c-mb-20 c-mb-md-40" *ngIf="data.aboutHeader">{{data.description}}</h2>
                    </div>

                    <!-- Authors mobile + md -->
                    <div class="col-12">
                        <badge-list [data]="authorsBadges" [horizontalMode]="true" [disableClick]="data.linksDisabled"></badge-list>
                    </div>

                    <!-- CTA mobile + md -->
                    <div class="col-12 c-pb-40 c-pt-10">
                        @defer(when authChecked$ | async) {
                            <form [formGroup]="emailForm" class="main-form" (ngSubmit)="addBundleToLibrary()">
                                <input *ngIf="!(isLoggedIn$ | async) && !hasEnteredEmail" type="email" required class="w-100 form-control rounded-input dark-input main-input-mobile" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()">
                                <button type="submit"
                                        [disabled]="loading"
                                        class="w-100 btn btn-dark btn-round c-mt-12">
                                    {{data.ctaText}}
                                </button>
                                <div class="form-errors c-pt-12">
                                    <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                                        <small>
                                            {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                        </small>
                                    </div>
                                    <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                                        <small>
                                            Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                        </small>
                                    </div>
                                </div>
                            </form>
                        } @placeholder {

                        }
                    </div>

                    <div class="col-12">
                        <h2 class="about-section-title c-mb-20">{{data.aboutHeader}}</h2>
                        <div class="book-description" [ngClass]="{'c-pb-16': !hasHtmlDescription}" [innerHTML]="data.aboutContents"></div>
                    </div>

                    <div class="col-12">
                        <h5 class="badge-section-title c-mb-10">Genres</h5>
                        <div class="c-pb-20 c-pt-0 c-px-0">
                            <badge-list [data]="genresBadges" [expandable]="true"  [disableClick]="data.linksDisabled"></badge-list>
                        </div>
                        <h5 class="badge-section-title c-mb-10">Tropes</h5>
                        <div class="c-pb-20 c-pt-0 c-px-0">
                            <badge-list [data]="tropesBadges" [expandable]="true" [disableClick]="data.linksDisabled"></badge-list>
                        </div>
                    </div>

                </div>

                <!-- desktop >= lg -->
                <div class="row d-lg-block d-none">
                    <div class="col-12 d-flex justify-content-start">
                        <div class="col-4 c-px-0 c-my-md-40 c-my-0 book-cover-container d-flex justify-content-center align-items-center"
                            style="--cover-color: {{data.coverImage.data.url | cloudinaryPublicId | cloudinaryPrimaryColor | async}}">
                            <img class="book-cover"
                                [src]="data.coverImage.data.url | cloudinaryFormat | cloudinaryQuality:100 | cloudinaryScaleHeight:190 | cloudinaryUrl"
                                fetchPriority="high" loading="eager" [alt]="data.title">
                        </div>
                        <div class="col-8 c-ps-lg-100 c-pt-40">
                            <span class="badge free-collection-badge ">Free Romance Collection</span>
                            <h1 class="book-title c-mb-10 c-mb-md-20">{{data.title}}</h1>
                            <h2 class="book-header c-mb-20 c-mb-md-10" *ngIf="data.aboutHeader">{{data.description}}</h2>
                            <div class="c-mb-40">
                                <badge-list [data]="authorsBadges" [expandable]="true" [disableClick]="data.linksDisabled"></badge-list>
                            </div>
                            @defer (when authChecked$ | async) {
                                <form [formGroup]="emailForm" class="d-flex main-form" (ngSubmit)="addBundleToLibrary()">
                                    <input *ngIf="!(isLoggedIn$ | async) && !hasEnteredEmail" type="email" required class="form-control rounded-input dark-input main-input-desktop" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()">
                                    <button type="submit"
                                            [disabled]="loading"
                                            class="btn btn-dark btn-round">
                                        {{data.ctaText}}
                                    </button>
                                    <div class="form-errors">
                                        <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                                            <small>
                                                {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                            </small>
                                        </div>
                                        <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                                            <small>
                                                Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                            </small>
                                        </div>
                                    </div>
                                </form>
                            } @placeholder {

                            }
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
    <div class="container-xxl c-mt-40 d-none d-lg-block">
        
        <div class="row py-40">
            <div class="col-12 col-md-7">
                <h2 class="about-section-title c-mb-20">{{data.aboutHeader}}</h2>
                <div [innerHTML]="data.aboutContents" class="book-description" [ngClass]="{'c-pb-16': !hasHtmlDescription}">
                    
                </div>
            </div>
            <div class="col-xxl-3 offset-xxl-2">
                <h5 class="badge-section-title c-mb-10">Genres</h5>
                <div class="c-pb-20 c-pt-0 c-px-0">
                    <badge-list [data]="genresBadges" [expandable]="true" [disableClick]="data.linksDisabled"></badge-list>
                </div>
                <h5 class="badge-section-title c-mb-10">Tropes</h5>
                <div class="c-pb-20 c-pt-0 c-px-0">
                    <badge-list [data]="tropesBadges" [expandable]="true" [disableClick]="data.linksDisabled"></badge-list>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="card sticky-container container-xxl" [@stickyFooterAnimation] *ngIf="scrolled && isLoggedIn === false">
    <div class="card-body c-py-20 c-px-0">
        <ng-container *ngIf="isMobile | async">
            <div class="row text-center">
                <div class="col-12">
                    <span class="text-reset d-flex justify-content-center align-items-center">
                        Exclusive Scottish Romance Collection
                    </span>
                    <h6 class="book-title text-center c-my-15">{{data.title}}</h6>
                </div>
                <form [formGroup]="emailForm"
                    (ngSubmit)="addBundleToLibrary()">
                    <div class="col-12 c-pb-12">
                        <input *ngIf="!(isLoggedIn$ | async) && !hasEnteredEmail" type="email" required class="w-100 form-control rounded-input dark-input" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
                    </div>
                    <div class="col-12">
                        <button type="submit"
                            [disabled]="loading"
                            class="btn btn-dark btn-round w-100">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                            {{ data.ctaText }}
                        </button>
                    </div>
                    <div class="col-12 form-errors c-pt-12">
                        <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                            <small>
                                {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                            </small>
                        </div>
                        <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                            <small>
                                Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                            </small>
                        </div>
                    </div>
                </form>
            </div>
        </ng-container>
        <ng-container *ngIf="!(isMobile | async)">
            <div class="d-flex justify-content-between c-px-20">
                <div class="d-flex flex-column justify-content-center flex-grow-1">
                    <h6 class=" c-mb-10">{{data.title}}</h6>
                    <span class="text-reset d-flex justify-content-start align-items-center">
                        Exclusive Scottish Romance Collection
                    </span>
                </div>
                <form [formGroup]="emailForm"
                    (ngSubmit)="addBundleToLibrary()"
                    class="d-flex flex-wrap justify-content-end">
                    <div class="c-pe-12">
                        <input *ngIf="!(isLoggedIn$ | async) && !hasEnteredEmail" type="email" required class="form-control rounded-input dark-input mb-3 mb-md-0" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
                    </div>
                    <div>
                        <button type="submit"
                            [disabled]="loading"
                            class="btn btn-dark btn-round w-fit">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                            {{ data.ctaText }}
                        </button>
                    </div>
                    <div class="flex-break"></div>
                    <div class="">
                        <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-pt-12" [@errorMessageAnimation]>
                            <small>
                                {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                            </small>
                        </div>
                        <div *ngIf="emailSuggestionError" class="text-danger c-pt-12" [@errorMessageAnimation]>
                            <small>
                                Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                            </small>
                        </div>
                    </div>
                </form>
            </div>
        </ng-container>
    </div>
</div>