import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { Observable, Subscriber, filter, map, tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CanonicalService {
    constructor(
        @Inject(DOCUMENT) private document: any,
        private readonly _router: Router,
        private _meta: Meta
    ) { }

    watchAndUpdate(): void {
        this._router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => environment.baseUrl + this._router.url),
        ).subscribe(url => {
            this.set(url);
        });
    }

    set(url: string) {
        const head = this.document.getElementsByTagName('head')[0];
        let element: HTMLLinkElement | null = this.document.querySelector(`link[rel='canonical']`);
        if (!element) {
            element = this.document.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
        this._meta.removeTag("property='og:url'");
        this._meta.addTag({ property: "og:url", content: url });
    }
}
